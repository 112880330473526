/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { PRODUCT_GROUP_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const CreateProductGroup = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ ApplicationState, setApplicationState ] = useState({
        ProductGroupName: "",
        Status: true
    });
    const { roomId } = useParams();
    const { ClearFormSet, ProductGroupList, device_id } = useSelector((state) => state.adminReducers);
    const CurrentData_ = ProductGroupList?.list?.filter((elm) => elm?.id === roomId)[0];

    // error state
    const [ errorProductGroupName, seterrorProductGroupName ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-product-group") {
            setApplicationState({
                ...ApplicationState,
                ProductGroupName: CurrentData_?.product_group_name,
                Status: CurrentData_?.is_active
            });
        };
    }, [ CurrentData_ ])

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.ProductGroupName.trim() !== "") {
            Dispatch(ClearFormSetFutios({
                url: LocationRoute === "edit-product-group" ? "product_group_edit" : "product_group_add",
                action: true
            }));

            let param = { 
                "transmit": "broadcast", 
                "request" : { 
                    "product_group_name" : ApplicationState?.ProductGroupName.trim(), 
                    "is_active" : ApplicationState?.Status
                },
                "DeviceId" : device_id
            };
            
            if(LocationRoute === "edit-product-group") {
                param.url = "product_group_edit";
                param.request.product_group_id = CurrentData_?.id;
            } else {
                param.url = "product_group_add";
            };

            wsSend_request(websocket, param);
        } else {
            CheckValid(ApplicationState?.ProductGroupName.trim(), {type: 'ProductGroupName', error: seterrorProductGroupName});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "product_group_add" || ClearFormSet?.url === "product_group_edit")) {
            Navigate(PRODUCT_GROUP_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={PRODUCT_GROUP_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3" >
                            <label className="form-label">Product Group Name<span className="mendatory_star">*</span></label>
                            <input 
                                type="text"
                                className="form-control" 
                                value={ApplicationState?.ProductGroupName} 
                                onChange={(e) => setApplicationState({...ApplicationState, ProductGroupName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'ProductGroupName', error: seterrorProductGroupName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Product Group Name" 
                            />
                            {errorProductGroupName !== "" &&<span className="error_medotory">{errorProductGroupName}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <CommonToggle 
                            isBadgeAllow={true}
                            badgeTitle={"active_inactive"}
                            valueToggle={ApplicationState?.Status}
                            setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                            name={"Status"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button 
                            className="btn formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "product_group_add" || ClearFormSet?.url === "product_group_edit")}
                            onClick={() => CreateBrand()} 
                            variant="primary" 
                        >
                            {LocationRoute === "edit-product-group" ? "Update" : "Add"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default CreateProductGroup;