/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { getTableSorting } from "../../../redux/actions/adminActions";
import { toast } from "react-toastify";
import CommonLoader from "../../Common/CommonLoader";
import CommonLogout from "../../Common/CommonLogout";
import GetRequest from "../../../Api/GetRequest";
import { FBA_ORDER_LOG_ERP_INVOICE_CREATE_API_URL } from "../../Shared/constant";
import CommonTable from "../../Common/CommonTable";

const FbaOrderLogsTab = (props) => {
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ Loader, setLoader ] = useState(false);
    const { device_id, TbaOrderLogsList, sortingfor, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const SOCancelled = () => {
        let param = {
            "transmit": "broadcast",
            "url": "fba_order_log_list",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            SOCancelled();
        }
    }, [websocket, userSearch, currentPage, userLimit, sortingfor]);

    // fba order invoice create api 
    const FBAorderInvoiceCreateSync = async () => {
        setLoader(true);
        const response = await GetRequest(FBA_ORDER_LOG_ERP_INVOICE_CREATE_API_URL, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            toast.info(response?.message);
        } else {
            setLoader(false);
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'display_order_code', label: 'Order Id.', type: "label", sort: "", isCollapsable: false },
        { key: 'channel_name', label: 'Channel Name', type: "label", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "", isCollapsable: false },
        { key: 'shipping_pincode', label: 'SHIP To Pincode', type: "label", sort: "", isCollapsable: false },
        { key: 'shipping_city', label: 'SHIP To City', type: "label", sort: "", isCollapsable: false },
        { key: 'shipping_state', label: 'SHIP To State', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_pincode', label: 'Bill To Pincode', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_city', label: 'Bill To City', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_state', label: 'Bill To State', type: "label", sort: "", isCollapsable: false },
        { key: 'marketplace_status', label: 'Marketplace Status', type: "label", sort: "", isCollapsable: false },
        { key: 'shipping_email', label: 'SHIP To Email', type: "label", sort: "", isCollapsable: true },
        { key: 'shipping_name', label: 'SHIP To Name', type: "label", sort: "", isCollapsable: true },
        { key: 'shipping_phone', label: 'SHIP To Phone', type: "label", sort: "", isCollapsable: true },
        { key: 'bill_email', label: 'Bill To Email', type: "label", sort: "", isCollapsable: true },
        { key: 'bill_phone', label: 'Bill To Phone', type: "label", sort: "", isCollapsable: true },
        { key: 'source', label: 'Order Source', type: "label", sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section fba_order_logs_Main_section">
        <DataTableFilter
            filterType={""}
            searchType={"Order Id"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit) && (<div className="sales_person_uplaod_downlo_wrapper">
            <button className="btn import_monthly_target" id="Create-Sales-Invoice-button" onClick={() => FBAorderInvoiceCreateSync()}>
                <i className="bi bi-arrow-repeat"></i> Create Sales Invoice
            </button>
        </div>)}
        
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(TbaOrderLogsList?.pagination?.total_records) === TbaOrderLogsList?.list?.length ? "calc(100vh - 185px)" : parseInt(TbaOrderLogsList?.pagination?.total_records) > 10 ? "calc(100vh - 221px)" : "calc(100vh - 185px)"
            }}
            srNumber={{
                clickable: false,
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={TbaOrderLogsList} // ORDER LIST
            Pagination={TbaOrderLogsList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={SOCancelled}
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit),
                label: "fba-orders-Api-Failure-Retry",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isActionPreviewAvailable={{
                isShow: true,
                url: "fba_orders_failures_error_view"
            }}
            isOdiFilterRowColorsAllow={false}
        />

        {Loader && (<CommonLoader />)}
    </section>)
}

export default FbaOrderLogsTab;