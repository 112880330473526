/* eslint-disable */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTableSorting } from "../../../redux/actions/adminActions";

const TableHead = (props) => {
    const { Columns, ActionColumn, ActionColumnRead, isCollapsable, ActionPreviewState } = props;
    const { sortingfor } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    return(<thead>
        <tr>
            {isCollapsable && (<th></th>)}
            <th>SNo</th>
            {Columns?.map((elm, index) => {
                if(!elm?.isCollapsable) {
                    if(elm?.sort !== "") {
                        return(
                            <th key={index} className={sortingfor === elm?.sort ? "active" : sortingfor === "-"+elm?.sort ? "inactive" : ""} onClick={() => elm?.sort !== "" && CommonSortingFct(elm?.sort)}>
                                {elm?.label}
                            </th>
                        );
                    } else {
                        return(<th key={index} style={{ width: elm?.label === 'Question' ? "30%" : "" }} >{elm?.label}</th>)
                    };
                };
            })}
            {ActionPreviewState?.isShow && (<th>Error</th>)}
            {(ActionColumn?.isShow || ActionColumnRead?.isShow) && (<th>Action</th>)}
        </tr>
    </thead>)
};

export default TableHead;