/* eslint-disable */
/* eslint-disable */
import {
    AGE_VALUE_REFURBISHED_REPORT,
    DASHBOARD_URL,
    DASHBOARD_SALES_REVENUE_REPORT_URL,
    DASHBOARD_TRANSACTION_HISTORY_URL,
    PRODUCT_APPLICATIONS_URL,
    EDIT_SCHEMES_URL,
    UPDATE_RESALE_BRANDS_URL,
    SCHEMES_URL,
    ADD_RESALE_BRANDS_URL,
    RESALE_BRANDS_URL,
    ADD_POINT_VALUE_SETTINGS,
    ERP_API_FAILURES_LIST,
    UPDATE_POINT_VALUE_SETTINGS,
    REFURBISHED_REPORT_LIST,
    ERP_OUTSTANDING_REPORT,
    AGE_VALUE_REPORT,
    ADD_AGE_VALUE_REPORT,
    EDIT_POINT_VALUE_REFURBISHED_REPORT,
    POINT_VALUE_REFURBISHED_REPORT,
    ADD_POINT_VALUE_REFURBISHED_REPORT,
    SETTINGS_REFURBISHED_REPORT,
    PURCHASE_LIST_URL,
    CATEGORY_URL,
    SUB_CATEGORY_URL,
    PRODUCT_CREATION_URL,
    CREATE_PRODUCT_APLICATION_URL,
    RETURN_AND_REPLACE_REPORT,
    CREATE_CATEGORY_URL,
    CREATE_SUB_CATEGORY_URL,
    CREATE_PRODUCT_URL,
    FRANCHISE_USER_REPORT_URL,
    CREATE_FRANCHISE_USER_REPORT_URL,
    STOCK_INWARD_URL,
    STOCK_LEVEL_URL,
    STORE_DETAILS_URL,
    STORE_INWARD_LOG_URL,
    STORE_DETAILS_REPORT_URL,
    FRANCHISE_REPORT_DETAILS_URL,
    STOCK_TRANSFER_URL,
    WAREHOUSE_URL,
    CREATE_WAREHOUSE_URL,
    WAREHOUSE_QUICK_URL,
    SET_LOGIN_URL,
    SET_WM_LOGIN_URL,
    SET_RM_LOGIN_URL,
    FRANCHISE_ORDERSREPORT_URL,
    E_WAY_BILL_URL,
    FRANCHISE_STOCK_LEVEL_URL,
    E_INVOICE_CANCEL_URL,
    E_INVOICE_DETAILS_URL,
    ORDER_CANCELLED_URL,
    PUR_INVOICE_DETAILS_URL,
    PUR_ORDER_DELIVERED_URL,
    FRANCHISE_PENDING_DELIVERY_URL,
    FRANCHISE_DISPATCH_PENDING_URL,
    FRANCHISE_DISPATCH_URL,
    FRANCHISE_PENDING_ORDERS_URL,
    PO_APPROVALS_URL,
    PO_POSTING_URL,
    PO_PURCHASE_ORDER_URL,
    USER_MANAGEMENT_URL,
    REGION_MASTER_URL,
    RM_LOGIN_URL,
    ADD_REGION_MASTER_URL,
    CITIES_URL,
    ADD_CITIES_URL,
    STATES_URL,
    ADD_STATES_URL,
    PINE_CODES_URL,
    CREATE_USER_MANAGEMENT_URL,
    FRANCHISE_TYPE_REPORT_URL,
    ADD_FRANCHISE_TYPE_REPORT_URL,
    PRODUCT_GROUP_URL,
    CREATE_PRODUCT_GROUP_URL,
    SPARES_CAMPATIBILITY_URL,
    EDIT_PRODUCT_GROUP_URL,
    PURCHASE_ORDER_URL,
    DISPATCH_LIST_URL,
    CREATE_DISPATCH_LIST_URL,
    COURIERS_URL,
    UPDATE_CITIES_URL,
    BOX_URL,
    DEBIT_CREDIT_NOTES,
    DEBIT_CREDIT_NOTES_DETAIL_PAGE,
    ACCOUNTLEDGERREPORT,
    ACCOUNT_LEDGER_REPORT,
    GSTDATAREPORT,
    GST_DATA_REPORT,
    OUT_STANDING_DATA_REPORT,
    DEBIT_CREDIT_NOTE_REPORT,
    DEPOSIT_REPORT,
    PAYMENT_APPROVAL,
    CREDIT_NOTE_REQUEST,
    CREDIT_NOTE_REPORT,
    RETURN_AND_REPLACE_REPORT_LIST,
    ADD_PINCODES_URL,
    FRANCHISE_USER_REPORT_BRAND,
    UPDATE_AGE_VALUE_REPORT,
    FBA_ORDER_LIST,
    FBA_ORDER_DETAILS_LIST,
    RE_ASSIGN_WAREHOUSE_LIST,
    SALES_PERSON_PERFORMANCE_URL,
    ADD_SALES_PERSON_PERFORMANCE_URL,
    INCENTIVE_SLAB_URL,
    ERP_GENERAL_OUTSTANDING_REPORT,
    AFTER_SALES_ORDERS_URL,
    ADD_SCHEMES_URL,
    ADD_INCENTIVE_SLAB_URL,
    EDIT_INCENTIVE_SLAB_URL,
    UNICOMMERCE_API_FAILURES_REPORT,
    TICKETS_URL,
    ADD_SPARES_CAMPATIBILITY_URL,
    EDIT_SPARES_CAMPATIBILITY_URL,
    PURCHASE_LOG_LIST_URL,
    HELP_DESK_CATEGORY,
    ADD_HELP_DESK_CATEGORY,
    EDIT_HELP_DESK_CATEGORY,
    HELP_DESK_SUB_CATEGORY,
    ADD_HELP_DESK_SUB_CATEGORY,
    EDIT_HELP_DESK_SUB_CATEGORY,
    HELP_DESK_FAQ,
    ADD_HELP_DESK_FAQ,
    EDIT_HELP_DESK_FAQ,
    DASHBOARD_SCHEMES_METRICS_URL,
    DASHBOARD_WARRANTY_REGISTRATION_URL,
    AFS_PURCHASE_ORDER_URL,
    QUOTATION_CREATIONS_URL,
    QUOTATION_REPORT_URL,
    QUOTATIONS_REPORT_DETAILS_URL,
    PAYMENT_APPROVAL_QUOTATIONS_REPORT_DETAILS_URL,
    PAYMENT_APPROVAL_URL,
    AFS_ORDER_SYNC_REPORT_URL,
    PAYMENT_APPROVAL_PENDING_QUOTATION_URL,
  } from './components/Shared/constant';
import Dashboard from "./components/Dashboard";
import Productapplications from './components/Inventory/ProductMasters/Productapplications';
import Category from './components/Inventory/ProductMasters/Category';
import SubCategory from './components/Inventory/ProductMasters/SubCategory';
import Productcreation from './components/Inventory/ProductMasters/Productcreation';
import CreateApplications from './components/Inventory/EditInventory/CreateApplications';
import CreateCategory from './components/Inventory/EditInventory/CreateCategory';
import SubCreateCategory from './components/Inventory/EditInventory/SubCreateCategory';
import CreateProduct from './components/Inventory/EditInventory/CreateProduct';
import FranchiseUserReport from './components/Franchise/Franchise/FranchiseUserReport';
import CreateFranchiseUserReport from './components/Franchise/EditFranchise/CreateFranchiseUserReport';
import StockInward from './components/Inventory/Stores/StockInward';
import StockLevel from './components/Inventory/Stores/StockLevel';
import StoreDetails from './components/Inventory/Stores/StoreDetails';
import StoreDetailsReport from './components/Inventory/Stores/StoreDetailsReport';
import FranchiseStoreDetails from './components/Inventory/Stores/FranchiseStoreDetails';
import StockTransfer from './components/Inventory/StockTransfer/StockTransfer';
import Warehouse from './components/Inventory/Warehouse/Warehouse';
import CreateWarehouse from './components/Inventory/EditInventory/CreateWarehouse';
import WQuickLogin from './components/Inventory/Warehouse/WQuickLogin';
import FranchiseOrdersReport from './components/Franchise/Franchise/FranchiseOrdersReport';
import EWayBillReport from './components/Franchise/Franchise/EWayBillReport';
import FranchiseStockLevel from './components/Franchise/Franchise/FranchiseStockLevel';
import EInvoiceCancel from './components/Franchise/Franchise/EInvoiceCancel';
import EInvoiceDetails from './components/Franchise/Franchise/EInvoiceDetails';
import PurOrderRejected from './components/Franchise/Franchise/PurOrderRejected';
import PurOrderDelivered from './components/Franchise/Franchise/PurOrderDelivered';
import FranchisePendingDelivery from './components/Franchise/Franchise/FranchisePendingDelivery';
import FranchisePendingDeliveryEdit from './components/Franchise/Franchise/FranchisePendingDeliveryEdit';
import FranchiseDispatchShipping from './components/Franchise/Franchise/FranchiseDispatchShipping';
import FranchiseDispatch from './components/Franchise/Franchise/FranchiseDispatch';
import POApprovals from './components/Franchise/Franchise/POApprovals';
import POPosting from './components/Franchise/Franchise/POPosting';
import POPurchaseOrder from './components/Franchise/Franchise/POPurchaseOrder';
import User from './components/AdminTools/User';
import RegionMaster from './components/CRM/RegionMaster';
import AddRegionMaster from './components/CRM/Edit/AddRegionMaster';
import Cities from './components/AdminTools/Masters/Cities';
import AddCities from './components/AdminTools/EditMaster/AddCities';
import States from './components/AdminTools/Masters/States';
import AddStates from './components/AdminTools/EditMaster/AddStates';
import Pincodes from './components/AdminTools/Masters/Pincodes';
import AddUser from './components/AdminTools/User/AddUser';
import FranchiseType from './components/CRM/FranchiseType';
import AddFranchiseType from './components/CRM/Edit/AddFranchiseType';
import ProductGroup from './components/Inventory/ProductMasters/ProductGroup';
import CreateProductGroup from './components/Inventory/EditInventory/CreateProductGroup';
import PurchaseOrder from './components/Franchise/Franchise/PurchaseOrder';
import ApprovalOrders from './components/Franchise/EditFranchise/ApprovalOrders';
import WarehouseApprovalOrders from './components/Franchise/EditFranchise/WarehouseApprovalOrders';
import DispatchList from './components/Franchise/Franchise/DispatchList';
import CreateDispatchList from './components/Franchise/EditFranchise/CreateDispatchList';
import FranchiseDispatchEdit from './components/Franchise/Franchise/FranchiseDispatchEdit';
import CouriersListPage from './components/AdminTools/Masters/CouriersListPage';
import AddCourier from './components/AdminTools/EditMaster/AddCourier';
import FranchiseDispatchShippingEdit from './components/Franchise/Franchise/FranchiseDispatchShippingEdit';
import SOCancelled from './components/Franchise/Franchise/SOCancelled';
import Box from './components/AdminTools/Masters/Box';
import RmQuickLogin from './components/AdminTools/RmLogin/RmQuickLogin';
import DebitCreditNotes from './components/Franchise/Payment/DebitCreditNotes';
import DebitCreditNotesDetailPage from './components/Franchise/Payment/DebitCreditNotesDetailPage';
import AccountLedgerReport from './components/Franchise/Payment/AccountLedgerReport';
import GstDataReport from './components/Sales/Report/GstDataReport';
import OutStandingReport from './components/Sales/Report/OutStandingReport';
import DebitCreditNoteReport from './components/Franchise/Payment/DebitCreditNoteReport';
import DepositReport from './components/Franchise/Payment/DepositReport';
import PaymentApproval from './components/Franchise/Payment/PaymentApproval';
import CreditNoteRequest from './components/Franchise/Payment/CreditNoteRequest';
import CreditNoteReport from './components/Franchise/Payment/CreditNoteReport';
import ReturnAndReplace from './components/Franchise/Franchise/ReturnAndReplace';
import AddReturnAndReplace from './components/Franchise/Franchise/AddReturnAndReplace';
import PurchaseList from './components/CRM/PurchaseList';
import EditPurchase from './components/CRM/Edit/EditPurchase';
import AddPincodes from './components/AdminTools/EditMaster/AddPincodes';
import StockInwardLog from './components/Inventory/Stores/StockInwardLog';
import CreateFranchiseBrand from './components/Franchise/Franchise/CreateFranchiseBrand';
import ERPoutstandingReport from './components/Franchise/Payment/ERPoutstandingReport';
import Settings from './components/CRM/Settings';
import PointValue from './components/CRM/PointValue';
import AddPointValue from './components/CRM/Edit/AddPointValue';
import ResallCycle from './components/CRM/ResallCycle';
import AddPointSettings from './components/CRM/Edit/AddPointSettings';
import AgeValue from './components/CRM/AgeValue';
import AddAgeValue from './components/CRM/Edit/AddAgeValue';
import ResaleBrands from './components/CRM/ResaleBrands';
import AddResaleBrands from './components/CRM/Edit/AddResaleBrands';
import ERTApiFailures from './components/Franchise/Franchise/ERTApiFailures';
import FbaOrdersList from './components/Franchise/Franchise/FbaOrdersList';
import FbaOrderDetails from './components/Franchise/Franchise/FbaOrderDetails';
import ReAssignWarehouseOrders from './components/Franchise/EditFranchise/ReAssignWarehouseOrders';
import SalesPersonPerformance from './components/CRM/SalesPersonPerformance';
import IncentiveSlab from './components/CRM/IncentiveSlab';
import ERPGeneraloutstandingReport from './components/Franchise/Payment/ERPGeneraloutstandingReport';
import AfterSalesOrders from './components/Franchise/Franchise/AfterSalesOrders';
import AfterSalesOrdersDetails from './components/Franchise/EditFranchise/AfterSalesOrdersDetails';
import Schemes from './components/CRM/Schemes';
import AddSchemes from './components/CRM/Edit/AddSchemes';
import AddIncentiveSlab from './components/CRM/Edit/AddIncentiveSlab';
import UnicommerceApiFailures from './components/Franchise/Franchise/UnicommerceApiFailures';
import SalesPersonPerformanceNew from './components/CRM/SalesPersonPerformanceNew';
import SparesCampatibiity from './components/Inventory/ProductMasters/SparesCampatibiity';
import Tickets from './components/CRM/Tickets';
import CreateSparesCompatibility from './components/Inventory/EditInventory/CreateSparesCompatibility';
import PurchaseLogList from './components/CRM/PurchaseLogList';
import HelpDeskCategory from './components/CRM/HelpDeskCategory';
import CreateHelpDeskCategory from './components/Inventory/EditInventory/CreateHelpDeskCategory';
import HelpDeskSubCategory from './components/CRM/HelpDeskSubCategory';
import HelpDeskFAQ from './components/CRM/HelpDeskFAQ';
import SalesRevenueReport from './components/Dashboard/SalesRevenueReport';
import TransactionHistory from './components/Dashboard/TransactionHistory';
import DashboardSchemesMetrics from './components/Dashboard/DashboardSchemesMetrics';
import WarrantyRegistrationReport from './components/Dashboard/WarrantyRegistrationReport';
import DashboardSchemesMetricsDetails from './components/Dashboard/DashboardSchemesMetricsDetails';
import QuotationCreations from './components/Franchise/Franchise/QuotationCreations';
import QuotationsReport from './components/Franchise/Franchise/QuotationsReport';
import QuotationsReportDetails from './components/Franchise/EditFranchise/QuotationsReportDetails';
import PaymentApprovalListing from './components/CRM/PaymentApprovalListing';
import AfsOrderSyncTabsWrapper from './components/Franchise/Franchise/AfsOrderSyncTabsWrapper';
import PaymentApprovalPendingQuotations from "./components/Franchise/Franchise/PaymentApprovalPendingQuotations";

// modules route list
export const AppModules = [
    { path: "/", element: <Dashboard />},
    { path: DASHBOARD_URL, element: <Dashboard />},
    { path: DASHBOARD_SALES_REVENUE_REPORT_URL, element: <SalesRevenueReport />},
    { path: DASHBOARD_TRANSACTION_HISTORY_URL, element: <TransactionHistory />},
    { path: DASHBOARD_SCHEMES_METRICS_URL, element: <DashboardSchemesMetrics /> },
    { path: `${DASHBOARD_SCHEMES_METRICS_URL}/:roomId`, element: <DashboardSchemesMetricsDetails /> },
    { path: DASHBOARD_WARRANTY_REGISTRATION_URL, element: <WarrantyRegistrationReport /> },
    { path: PRODUCT_APPLICATIONS_URL, element: <Productapplications />},
    { path: CREATE_PRODUCT_APLICATION_URL, element: <CreateApplications /> },
    { path: '/edit-product-application/:roomId', element: <CreateApplications /> },
    { path: CATEGORY_URL, element: <Category /> },
    { path: CREATE_CATEGORY_URL, element: <CreateCategory /> },
    { path: '/edit-category/:roomId', element: <CreateCategory /> },
    { path: SUB_CATEGORY_URL, element: <SubCategory />},
    { path: CREATE_SUB_CATEGORY_URL, element: <SubCreateCategory />},
    { path: '/edit-subcategory/:roomId', element: <SubCreateCategory /> },
    { path: PRODUCT_CREATION_URL, element: <Productcreation /> },
    { path: CREATE_PRODUCT_URL, element: <CreateProduct />},
    { path: '/edit-product/:roomId', element: <CreateProduct /> },
    { path: PRODUCT_GROUP_URL, element: <ProductGroup /> },
    { path: CREATE_PRODUCT_GROUP_URL, element: <CreateProductGroup /> },
    { path: '/edit-product-group/:roomId', element: <CreateProductGroup />},
    { path: SPARES_CAMPATIBILITY_URL, element: <SparesCampatibiity /> },
    { path: ADD_SPARES_CAMPATIBILITY_URL, element: <CreateSparesCompatibility /> },
    { path: `/${EDIT_SPARES_CAMPATIBILITY_URL}/:roomId`, element: <CreateSparesCompatibility />},
    { path: STOCK_INWARD_URL, element: <StockInward />},
    { path: STOCK_LEVEL_URL, element: <StockLevel />},
    { path: STORE_DETAILS_URL, element: <StoreDetails />},
    { path: STORE_INWARD_LOG_URL, element: <StockInwardLog />},
    { path: STORE_DETAILS_REPORT_URL, element: <StoreDetailsReport />},
    { path: FRANCHISE_REPORT_DETAILS_URL, element: <FranchiseStoreDetails /> },
    { path: STOCK_TRANSFER_URL, element: <StockTransfer /> },
    { path: WAREHOUSE_URL, element: <Warehouse /> },
    { path: CREATE_WAREHOUSE_URL, element: <CreateWarehouse /> },
    { path: '/edit-warehouse/:roomId', element: <CreateWarehouse /> },
    { path: WAREHOUSE_QUICK_URL, element: <WQuickLogin /> },
    { path: AFTER_SALES_ORDERS_URL, element: <AfterSalesOrders /> },
    { path: `/${AFTER_SALES_ORDERS_URL}/:roomId`, element: <AfterSalesOrdersDetails />},
    { path: FRANCHISE_ORDERSREPORT_URL, element: <FranchiseOrdersReport /> },
    { path: E_WAY_BILL_URL, element: <EWayBillReport /> },
    { path: FRANCHISE_STOCK_LEVEL_URL, element: <FranchiseStockLevel /> },
    { path: E_INVOICE_CANCEL_URL, element: <EInvoiceCancel /> },
    { path: E_INVOICE_DETAILS_URL, element: <EInvoiceDetails /> },
    { path: PUR_INVOICE_DETAILS_URL, element: <PurOrderRejected /> },
    { path: ORDER_CANCELLED_URL, element: <SOCancelled /> },
    { path: PUR_ORDER_DELIVERED_URL, element: <PurOrderDelivered /> },
    { path: FRANCHISE_PENDING_DELIVERY_URL, element: <FranchisePendingDelivery /> },
    { path: `${FRANCHISE_PENDING_DELIVERY_URL}/:id`, element: <FranchisePendingDeliveryEdit /> },
    { path: FRANCHISE_DISPATCH_PENDING_URL, element: <FranchiseDispatchShipping /> },
    { path: `${FRANCHISE_DISPATCH_PENDING_URL}/:id`, element: <FranchiseDispatchShippingEdit /> },
    { path: FRANCHISE_DISPATCH_URL, element: <FranchiseDispatch /> },
    { path: `${FRANCHISE_DISPATCH_URL}/:id`, element: <FranchiseDispatchEdit /> },
    { path: '/so-approval/:roomId', element: <ApprovalOrders /> },
    { path: PO_POSTING_URL, element: <POPosting /> },
    { path: PO_APPROVALS_URL, element: <POApprovals /> },
    { path: `${PO_APPROVALS_URL}/:roomId`, element: <WarehouseApprovalOrders /> },
    { path: PO_PURCHASE_ORDER_URL, element: <POPurchaseOrder /> },
    { path: USER_MANAGEMENT_URL, element: <User /> },
    { path: RM_LOGIN_URL, element: <RmQuickLogin /> },
    { path: CREATE_USER_MANAGEMENT_URL, element: <AddUser /> },
    { path: '/edit-user/:roomId', element: <AddUser /> },
    { path: REGION_MASTER_URL, element: <RegionMaster /> },
    { path: ADD_REGION_MASTER_URL, element: <AddRegionMaster /> },
    { path: '/edit-region-master/:roomId', element: <AddRegionMaster /> },
    { path: CITIES_URL, element: <Cities /> },
    { path: ADD_CITIES_URL, element: <AddCities /> },
    { path: `${UPDATE_CITIES_URL}/:id`, element: <AddCities /> },
    { path: COURIERS_URL, element: <CouriersListPage /> },
    { path: `${COURIERS_URL}/add`, element: <AddCourier /> },
    { path: `${COURIERS_URL}/:id`, element: <AddCourier /> },
    { path: STATES_URL, element: <States /> },
    { path: ADD_STATES_URL, element: <AddStates /> },
    { path: `edit-state/:id`, element: <AddStates /> },
    { path: PINE_CODES_URL, element: <Pincodes /> },
    { path: ADD_PINCODES_URL, element: <AddPincodes /> },
    { path: BOX_URL, element: <Box /> },
    { path: FRANCHISE_TYPE_REPORT_URL, element: <FranchiseType /> },
    { path: ADD_FRANCHISE_TYPE_REPORT_URL, element: <AddFranchiseType /> },
    { path: FRANCHISE_USER_REPORT_BRAND, element: <CreateFranchiseBrand /> },
    { path: '/edit-franchise-type-report/:roomId', element: <AddFranchiseType /> },
    { path: FRANCHISE_USER_REPORT_URL, element: <FranchiseUserReport /> },
    { path: CREATE_FRANCHISE_USER_REPORT_URL, element: <CreateFranchiseUserReport /> },
    { path: '/edit-franchise-user-report/:roomId', element: <CreateFranchiseUserReport /> },
    { path: PURCHASE_ORDER_URL, element: <PurchaseOrder /> },
    { path: AFS_PURCHASE_ORDER_URL, element: <PurchaseOrder /> },
    { path: QUOTATION_REPORT_URL, element: <QuotationsReport /> },
    { path: QUOTATION_CREATIONS_URL, element: <QuotationCreations /> },
    { path: `${QUOTATIONS_REPORT_DETAILS_URL}/:roomId`, element: <QuotationsReportDetails /> },
    { path: `${PAYMENT_APPROVAL_QUOTATIONS_REPORT_DETAILS_URL}/:roomId`, element: <QuotationsReportDetails /> },
    { path: AFS_ORDER_SYNC_REPORT_URL, element: <AfsOrderSyncTabsWrapper /> },
    { path: PAYMENT_APPROVAL_PENDING_QUOTATION_URL, element: <PaymentApprovalPendingQuotations /> },
    { path: DISPATCH_LIST_URL, element: <DispatchList /> },
    { path: CREATE_DISPATCH_LIST_URL, element: <CreateDispatchList /> },
    { path: '/edit-dispatch/:roomId', element: <CreateDispatchList /> },
    { path: DEBIT_CREDIT_NOTES, element: <DebitCreditNotes /> },
    { path: `${DEBIT_CREDIT_NOTES}/:roomId`, element: <DebitCreditNotesDetailPage /> },
    { path: DEBIT_CREDIT_NOTE_REPORT, element: <DebitCreditNoteReport /> },
    { path: DEPOSIT_REPORT, element: <DepositReport /> },
    { path: PAYMENT_APPROVAL, element: <PaymentApproval /> },
    { path: CREDIT_NOTE_REQUEST, element: <CreditNoteRequest /> },
    { path: CREDIT_NOTE_REPORT, element: <CreditNoteReport /> },
    { path: GST_DATA_REPORT, element: <GstDataReport /> },
    { path: OUT_STANDING_DATA_REPORT, element: <OutStandingReport /> },
    { path: ACCOUNT_LEDGER_REPORT, element: <AccountLedgerReport /> },
    { path: RETURN_AND_REPLACE_REPORT_LIST, element: <ReturnAndReplace /> },
    { path: RETURN_AND_REPLACE_REPORT, element: <AddReturnAndReplace /> },
    { path: PURCHASE_LIST_URL, element: <PurchaseList /> },
    { path: '/edit-purchase/:roomId', element: <EditPurchase /> },
    { path: PURCHASE_LOG_LIST_URL, element: <PurchaseLogList /> },
    { path: SETTINGS_REFURBISHED_REPORT, element: <Settings /> },
    { path: POINT_VALUE_REFURBISHED_REPORT, element: <PointValue /> },
    { path: ADD_POINT_VALUE_REFURBISHED_REPORT, element: <AddPointValue /> },
    { path: `${EDIT_POINT_VALUE_REFURBISHED_REPORT}/:roomId`, element: <AddPointValue /> },
    { path: ERP_OUTSTANDING_REPORT, element: <ERPoutstandingReport /> },
    { path: ERP_GENERAL_OUTSTANDING_REPORT, element: <ERPGeneraloutstandingReport /> },
    { path: REFURBISHED_REPORT_LIST, element: <ResallCycle /> },
    { path: ADD_POINT_VALUE_SETTINGS, element: <AddPointSettings /> },
    { path: `${UPDATE_POINT_VALUE_SETTINGS}/:roomId`, element: <AddPointSettings /> },
    { path: AGE_VALUE_REPORT, element: <AgeValue /> },
    { path: ADD_AGE_VALUE_REPORT, element: <AddAgeValue /> },
    { path: `${UPDATE_AGE_VALUE_REPORT}/:roomId`, element: <AddAgeValue /> },
    { path: RESALE_BRANDS_URL, element: <ResaleBrands /> },
    { path: ADD_RESALE_BRANDS_URL, element: <AddResaleBrands /> },
    { path: `${UPDATE_RESALE_BRANDS_URL}/:roomId`, element: <AddResaleBrands /> },
    { path: ERP_API_FAILURES_LIST, element: <ERTApiFailures /> },
    { path: FBA_ORDER_LIST, element: <FbaOrdersList /> },
    { path: `${FBA_ORDER_DETAILS_LIST}/:roomId`, element: <FbaOrderDetails /> },
    { path: RE_ASSIGN_WAREHOUSE_LIST, element: <ReAssignWarehouseOrders /> },
    { path: SALES_PERSON_PERFORMANCE_URL, element: <SalesPersonPerformance /> },
    { path: ADD_SALES_PERSON_PERFORMANCE_URL, element: <SalesPersonPerformanceNew /> },
    { path: INCENTIVE_SLAB_URL, element: <IncentiveSlab /> },
    { path: ADD_INCENTIVE_SLAB_URL, element: <AddIncentiveSlab />},
    { path: `${EDIT_INCENTIVE_SLAB_URL}/:roomId`, element: <AddIncentiveSlab /> },
    { path: SCHEMES_URL, element: <Schemes /> },
    { path: ADD_SCHEMES_URL, element: <AddSchemes /> },
    { path: `${EDIT_SCHEMES_URL}/:roomId`, element: <AddSchemes /> },
    { path: TICKETS_URL, element: <Tickets /> },
    { path: UNICOMMERCE_API_FAILURES_REPORT, element: <UnicommerceApiFailures /> },
    { path: HELP_DESK_CATEGORY, element: <HelpDeskCategory /> },
    { path: ADD_HELP_DESK_CATEGORY, element: <CreateHelpDeskCategory /> },
    { path: `${EDIT_HELP_DESK_CATEGORY}/:roomId`, element: <CreateHelpDeskCategory /> },
    { path: HELP_DESK_SUB_CATEGORY, element: <HelpDeskSubCategory /> },
    { path: ADD_HELP_DESK_SUB_CATEGORY, element: <CreateHelpDeskCategory /> },
    { path: `${EDIT_HELP_DESK_SUB_CATEGORY}/:roomId`, element: <CreateHelpDeskCategory /> },
    { path: HELP_DESK_FAQ, element: <HelpDeskFAQ /> },
    { path: ADD_HELP_DESK_FAQ, element: <CreateHelpDeskCategory /> },
    { path: `${EDIT_HELP_DESK_FAQ}/:roomId`, element: <CreateHelpDeskCategory /> },
    { path: PAYMENT_APPROVAL_URL, element: <PaymentApprovalListing /> }
];

// search modules list
export const NavigationsLinks = [
    {   
        module_name: "Dashboard",
        parent_module_name: "Dashboard",
        navigate_url: DASHBOARD_URL, 
    },
    {   
        module_name: "Sales Revenue Report",
        parent_module_name: "Dashboard",
        navigate_url: DASHBOARD_SALES_REVENUE_REPORT_URL, 
    },
    {   
        module_name: "Transaction History",
        parent_module_name: "Dashboard",
        navigate_url: DASHBOARD_TRANSACTION_HISTORY_URL, 
    },
    {   
        module_name: "Schemes Metrics",
        parent_module_name: "Dashboard",
        navigate_url: DASHBOARD_SCHEMES_METRICS_URL, 
    },
    {   
        module_name: "Warranty Registration Report",
        parent_module_name: "Dashboard",
        navigate_url: DASHBOARD_WARRANTY_REGISTRATION_URL, 
    },
    {
        module_name: "Product Applications",
        parent_module_name: "Product Masters",
        navigate_url: PRODUCT_APPLICATIONS_URL,
    },
    {
        module_name: "Category",
        parent_module_name: "Product Masters",
        navigate_url: CATEGORY_URL,
    },
    {
        module_name: "Sub Category",
        parent_module_name: "Product Masters",
        navigate_url: SUB_CATEGORY_URL
    },
    {
        module_name: "Product Group",
        parent_module_name: "Product Masters",
        navigate_url: PRODUCT_GROUP_URL
    },
    {
        module_name: "Product Creation",
        parent_module_name: "Product Masters",
        navigate_url: PRODUCT_CREATION_URL
    },
    {
        module_name: "Spares Compatibility",
        parent_module_name: "Product Masters",
        navigate_url: SPARES_CAMPATIBILITY_URL
    },
    {
        module_name: "Stock Inward",
        parent_module_name: "Stores",
        navigate_url: STOCK_INWARD_URL
    },
    {
        module_name: "Stock Level",
        parent_module_name: "Stores",
        navigate_url: STOCK_LEVEL_URL
    },
    {
        module_name: "Stock Details",
        parent_module_name: "Stores",
        navigate_url: STORE_DETAILS_URL
    },
    {
        module_name: "Stock Inward Log",
        parent_module_name: "Stores",
        navigate_url: STORE_INWARD_LOG_URL
    },
    {
        module_name: "stock details report",
        parent_module_name: "Stores",
        navigate_url: STORE_DETAILS_REPORT_URL
    },
    {
        module_name: "Warehouse",
        parent_module_name: "Warehouse",
        navigate_url: WAREHOUSE_URL
    },
    {
        module_name: "Warehouse Quick Login",
        parent_module_name: "Warehouse",
        navigate_url: WAREHOUSE_QUICK_URL
    },
    {
        module_name: "Dispatch List",
        parent_module_name: "Warehouse",
        navigate_url: DISPATCH_LIST_URL
    },
    {
        module_name: "Users",
        parent_module_name: "Admin Tools",
        navigate_url: USER_MANAGEMENT_URL
    },
    {
        module_name: "Create Franchise",
        parent_module_name: "Admin Tools",
        navigate_url: FRANCHISE_USER_REPORT_URL
    },
    {
        module_name: "Franchise Type Report",
        parent_module_name: "Admin Tools",
        navigate_url: FRANCHISE_TYPE_REPORT_URL
    },
    {
        module_name: "RM Quick Login",
        parent_module_name: "Admin Tools",
        navigate_url: RM_LOGIN_URL
    },
    {
        module_name: "States",
        parent_module_name: "Masters",
        navigate_url: STATES_URL
    },
    {
        module_name: "Cities",
        parent_module_name: "Masters",
        navigate_url: CITIES_URL
    },
    {
        module_name: "Pincodes",
        parent_module_name: "Masters",
        navigate_url: PINE_CODES_URL
    },
    {
        module_name: "Box",
        parent_module_name: "Masters",
        navigate_url: BOX_URL
    },
    {
        module_name: "Couriers",
        parent_module_name: "Masters",
        navigate_url: COURIERS_URL
    },
    {
        module_name: "Region Master",
        parent_module_name: "CRM",
        navigate_url: REGION_MASTER_URL
    },
    {
        module_name: "Purchase List",
        parent_module_name: "CRM",
        navigate_url: PURCHASE_LIST_URL
    },
    {
        module_name: "Purchase Log List",
        parent_module_name: "CRM",
        navigate_url: PURCHASE_LOG_LIST_URL
    },
    {
        module_name: "Sales Person Performance",
        parent_module_name: "CRM",
        navigate_url: SALES_PERSON_PERFORMANCE_URL
    },
    {
        module_name: "Incentive Slab",
        parent_module_name: "Dealer App",
        navigate_url: INCENTIVE_SLAB_URL
    },
    {
        module_name: "Schemes",
        parent_module_name: "Dealer App",
        navigate_url: SCHEMES_URL
    },
    {
        module_name: "Tickets",
        parent_module_name: "Dealer App",
        navigate_url: TICKETS_URL
    },
    {
        module_name: "Help Desk Category",
        parent_module_name: "Dealer App",
        navigate_url: HELP_DESK_CATEGORY
    },
    {
        module_name: "Help Desk Sub Category",
        parent_module_name: "Dealer App",
        navigate_url: HELP_DESK_SUB_CATEGORY
    },
    {
        module_name: "Help Desk Faq",
        parent_module_name: "Dealer App",
        navigate_url: HELP_DESK_FAQ
    },
    {
        module_name: "Resellcycle Reports",
        parent_module_name: "Re-Sale",
        navigate_url: REFURBISHED_REPORT_LIST
    },
    {
        module_name: "Resale Brands",
        parent_module_name: "Re-Sale",
        navigate_url: RESALE_BRANDS_URL
    },
    {
        module_name: "Settings",
        parent_module_name: "Re-Sale",
        navigate_url: SETTINGS_REFURBISHED_REPORT
    },
    {
        module_name: "Age Value",
        parent_module_name: "Re-Sale",
        navigate_url: AGE_VALUE_REFURBISHED_REPORT
    },
    {
        module_name: "Point List",
        parent_module_name: "Re-Sale",
        navigate_url: POINT_VALUE_REFURBISHED_REPORT
    },
    {
        module_name: "All Orders Report",
        parent_module_name: "Order Management",
        navigate_url: FRANCHISE_ORDERSREPORT_URL
    },
    {
        module_name: "SO Creation",
        parent_module_name: "Order Management",
        navigate_url: PURCHASE_ORDER_URL
    },
    {
        module_name: "SO Approvals",
        parent_module_name: "Order Management",
        navigate_url: PO_POSTING_URL
    },
    {
        module_name: "Warehouse Approvals",
        parent_module_name: "Order Management",
        navigate_url: PO_APPROVALS_URL
    },
    {
        module_name: "Pending For Stores",
        parent_module_name: "Order Management",
        navigate_url: FRANCHISE_DISPATCH_URL
    },
    {
        module_name: "Pending For Dispatch",
        parent_module_name: "Order Management",
        navigate_url: FRANCHISE_DISPATCH_PENDING_URL
    },
    {
        module_name: "Pending For Delivery",
        parent_module_name: "Order Management",
        navigate_url: FRANCHISE_PENDING_DELIVERY_URL
    },
    {
        module_name: "Order Delivered",
        parent_module_name: "Order Management",
        navigate_url: PUR_ORDER_DELIVERED_URL
    },
    {
        module_name: "Order Rejected",
        parent_module_name: "Order Management",
        navigate_url: PUR_INVOICE_DETAILS_URL
    },
    {
        module_name: "Order Cancelled",
        parent_module_name: "Order Management",
        navigate_url: ORDER_CANCELLED_URL
    },
    {
        module_name: "E-Invoice Details",
        parent_module_name: "Order Management",
        navigate_url: E_INVOICE_DETAILS_URL
    },
    {
        module_name: "E-Invoice Cancel",
        parent_module_name: "Order Management",
        navigate_url: E_INVOICE_CANCEL_URL
    },
    {
        module_name: "E-Way Bill Report",
        parent_module_name: "Order Management",
        navigate_url: E_WAY_BILL_URL
    },
    {
        module_name: "Return And Replace List",
        parent_module_name: "Order Management",
        navigate_url: RETURN_AND_REPLACE_REPORT_LIST
    },
    {
        module_name: "Erp Order Sync",
        parent_module_name: "Order Management",
        navigate_url: ERP_API_FAILURES_LIST
    },
    {
        module_name: "FBA Orders",
        parent_module_name: "Order Management",
        navigate_url: FBA_ORDER_LIST
    },
    {
        module_name: "Re Assign warehouse",
        parent_module_name: "Order Management",
        navigate_url: RE_ASSIGN_WAREHOUSE_LIST
    },
    {
        module_name: "Unicommerce Order sync",
        parent_module_name: "Order Management",
        navigate_url: UNICOMMERCE_API_FAILURES_REPORT
    },
    {
        module_name: "After Sales Orders",
        parent_module_name: "AFS Order Management",
        navigate_url: AFTER_SALES_ORDERS_URL
    },
    {
        module_name: "So Creations (AFS)",
        parent_module_name: "AFS Order Management",
        navigate_url: AFS_PURCHASE_ORDER_URL
    },
    {
        module_name: "Quotation Report",
        parent_module_name: "AFS Order Management",
        navigate_url: QUOTATION_REPORT_URL
    },
    {
        module_name: "AFS Order Sync",
        parent_module_name: "AFS Order Management",
        navigate_url: AFS_ORDER_SYNC_REPORT_URL
    },
    {
        module_name: "Quotation Creation",
        parent_module_name: "AFS Order Management",
        navigate_url: QUOTATION_CREATIONS_URL
    },
    {
        module_name: "Payment Approval Pending",
        parent_module_name: "AFS Order Management",
        navigate_url: PAYMENT_APPROVAL_PENDING_QUOTATION_URL
    },
    {
        module_name: "Account Ledger Report",
        parent_module_name: "Payment Manager",
        navigate_url: ACCOUNT_LEDGER_REPORT
    },
    {
        module_name: "Debit Credit Notes",
        parent_module_name: "Payment Manager",
        navigate_url: DEBIT_CREDIT_NOTES
    },
    {
        module_name: "Debit Credit Notes Report",
        parent_module_name: "Payment Manager",
        navigate_url: DEBIT_CREDIT_NOTE_REPORT
    },
    {
        module_name: "Payment Approval",
        parent_module_name: "Payment Manager",
        navigate_url: PAYMENT_APPROVAL
    },
    {
        module_name: "GST Data Report",
        parent_module_name: "Payment Manager",
        navigate_url: GST_DATA_REPORT
    },
    {
        module_name: "Erp General Outstanding Report",
        parent_module_name: "Payment Manager",
        navigate_url: ERP_GENERAL_OUTSTANDING_REPORT
    },
    {
        module_name: "Dealer Payment Approval",
        parent_module_name: "Payment Manager",
        navigate_url: PAYMENT_APPROVAL_URL
    },
    {
        module_name: "Logout",
        parent_module_name: "Logout",
        navigate_url: ""
    },
];

export const AvatarColorCodes = {
    a: "#FFC107",
    b: "#2196F3",
    c: "#00BCD4",
    d: "#FF5722",
    e: "#B0BEC5",
    f: "#E040FB",
    g: "#4CAF50",
    h: "#F50057",
    i: "#3F51B5",
    j: "#2E7D32",
    k: "#f0b646",
    l: "#03A9F4",
    m: "#E91E63",
    n: "#1A237E",
    o: "#FF9800",
    p: "#E91E63",
    q: "#aaaaaa",
    r: "#F44336",
    s: "#10b068",
    t: "#009688",
    u: "#3F51B5",
    v: "#8E24AA",
    w: "#3170c1",
    x: "#78909C",
    y: "#FFEB3B",
    z: "#1A237E"
}  
  