/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Form, Spinner, Button } from "react-bootstrap";
import CheckValid from "./CheckValid";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import UploadFileAPI from "../../Api/UploadFileAPI";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";

const CommonRemarkAttachment = (props) => {
    const { setShowModalNew, showModalNew, onClose } = props;
    const { websocket } = useContext(WebSocketContext);
    const { device_id, accessToken, ClearFormSet } = useSelector((state) => state.adminReducers);
    const [ remarkAttachState, setremarkAttachState ] = useState({
        Remarks:"",
        OrderPriority: "",
        Atatchments: []
    });
    const [ Loader, setLoader ] = useState(false);

    // error state
    const [ errorAddress, seterrorAddress ] = useState("");

    useEffect(() => {
        setremarkAttachState({
            ...remarkAttachState,
            Remarks: showModalNew?.Data?.warehouse_remark === null ? "" : showModalNew?.Data?.warehouse_remark,
            OrderPriority: showModalNew?.Data?.order_priority
        })
    }, []);

    let fileUplaoadArrya = [...remarkAttachState?.Atatchments];
    // upload attachments
    const UploadImage = async (e) => {
        setLoader(true);
        const files = e.target.files[0];
        if(files){
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if(fileresponse?.status === 200) {
                setLoader(false);
                const FileObj = {
                    attachment_id: fileresponse?.id,
                    attachment_name: fileresponse?.name,
                }
                fileUplaoadArrya?.push(FileObj);
                setremarkAttachState({
                    ...remarkAttachState,
                    Atatchments: fileUplaoadArrya
                });
                document.getElementById('fileInput').value = null;
            } else {
                setLoader(false);
            };
        }else{
            setLoader(false);
        }
    };

    // delet file
    const deleteFile = (id) => {
        const UpdatefileUplaoadArrya = [];
        fileUplaoadArrya?.map((elm) => {
            if(elm?.attachment_id !== id) {
                UpdatefileUplaoadArrya?.push(elm);
            }
        });
        setremarkAttachState({
            ...remarkAttachState,
            Atatchments: UpdatefileUplaoadArrya
        });
    };

    // add attachments and remarks function
    const AddRemarksAttchemnt = () => {
        if(remarkAttachState?.Remarks !== "") {
            let param = {
                "transmit": "broadcast",
                "url": "order_remark",
                "request": {
                  "order_id": showModalNew?.Data?.id,
                  "warehouse_remark": remarkAttachState?.Remarks,
                  "order_priority": null,
                  "attachments": remarkAttachState?.Atatchments,
                },
                "DeviceId": device_id
            };
            if(showModalNew?.Data?.order_type === "AO" && (showModalNew?.Data?.order_source === "AMAZON_IN_API" || showModalNew?.Data?.order_source === "AMAZON")) {
                param.request.order_priority = remarkAttachState?.OrderPriority;
            }
            wsSend_request(websocket, param);
        } else {
            CheckValid(remarkAttachState?.Remarks, {type: 'Remarks', error: seterrorAddress});
        }
    };

    useEffect(() => {
        if(ClearFormSet?.url === "order_remark" && ClearFormSet?.action === 200) {
            onClose()
            setShowModalNew({
                ...showModalNew, 
                open: false,
                title: "",
                Data: {}
            });
            setremarkAttachState({
                ...remarkAttachState,
                Remarks:"",
                OrderPriority: "",
                Atatchments: []
            });
            setLoader(false);
        };
    }, [ ClearFormSet ]);

    return(<Modal className="commonModal commonModalAttachRemarks" show={showModalNew?.open} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%"}}>
                    {showModalNew?.title}
                </Modal.Title>
            </Modal.Header>
        <Modal.Body>
            <div className="formloginset">
                {(showModalNew?.Data?.order_type === "AO" && (showModalNew?.Data?.order_source === "AMAZON_IN_API" || showModalNew?.Data?.order_source === "AMAZON")) && (<div className="col-md-12">
                    <Form.Group className="mb-3 ">
                        <Form.Label>Select Order Priority</Form.Label>
                        <select 
                            className="form-control" 
                            value={remarkAttachState?.OrderPriority} 
                            onChange={(e) => setremarkAttachState({...remarkAttachState, OrderPriority: e.target.value})}
                        >
                            <option value="">Select Order Priority</option>
                            <option value="Amazon Prime">Prime </option>
                            <option value="Amazon">Non Prime</option>
                        </select>
                    </Form.Group>
                </div>)}
                <div className="col-md-12">
                    <Form.Group className="mb-3 ">
                        <Form.Label>Remarks <span className="mendatory_star">*</span></Form.Label>
                        <Form.Control 
                            type="text" 
                            as="textarea" rows={3}
                            value={remarkAttachState?.Remarks} 
                            onChange={(e) => setremarkAttachState({...remarkAttachState, Remarks: e.target.value})}
                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Remarks', error: seterrorAddress})}
                            onKeyDown={EmptySpaceFieldValid}
                            placeholder="Enter Remarks (Max length 200 characters)" 
                            maxLength={200}
                        />
                        {errorAddress !== "" && (<span className="error_medotory">{errorAddress}</span>)}
                    </Form.Group>
                </div>
                {showModalNew?.Data?.attachments === null && (<div className="col-md-12">
                    <Form.Group className="d-block">
                        <Form.Label>Attachments</Form.Label>
                        <label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN UploadCmnBTNCommon" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                            <i className="bi bi-upload"></i>
                            <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e)}  accept=".pdf" />
                            Upload Attachments
                            {Loader && (<Spinner/>)}
                        </label>
                        {remarkAttachState?.Atatchments?.length > 0 && (<React.Fragment>
                            <ul className="fileuploadListy">
                            {remarkAttachState?.Atatchments?.map((elm, index) => {
                                return(<li className="uplaodedFilename w-100" key={index}>
                                    <i className="bi bi-file-earmark-text"></i>
                                    {elm?.attachment_name}
                                    <i className="bi bi-x-circle-fill" onClick={() => deleteFile(elm?.attachment_id)}></i>
                                </li>)
                            })}
                            </ul>
                        </React.Fragment>)}
                    </Form.Group>
                </div>)}
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => onClose()}>
                No
            </Button>
            <Button variant="primary" className="addbtncmn" onClick={() => AddRemarksAttchemnt()}>
                Yes
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default CommonRemarkAttachment;