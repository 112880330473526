/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { setGetMagicCode } from "../../../redux/actions/adminActions.js"
import getMagiccodeAPI from '../../../Api/getMagiccodeAPI';
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu.jsx";

const WQuickLogin = () => {
    const { websocket } = useContext(WebSocketContext);
    const { wareHouselistall, device_id, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const WareHouseOptions = wareHouselistall?.filter((elm) => elm?.is_active === true  )?.map((item, index)=>({key:item.id, value:item?.id , label:`${item?.warehouse_code} - ${item?.warehouse_name} - (${item?.display_name})`}));
    const [ WarehouseSelect, setWarehouseSelect ] = useState("");
    const dispatch = useDispatch();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // get product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "warehouse_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    const getMagicToken =async (value)=>{
        if(value){
            const warehouseCode = value.split(" - ")[0];
            warehouseCode.trim()
            if(value !== undefined ){
                const payload = {
                    device_type:"web",
                    device_id: device_id
                }
                const response = await getMagiccodeAPI(accessToken, payload);
                if(response?.status === 200){
                    dispatch(setGetMagicCode(response?.data));
                    setTimeout(() => {
                        const url = `${process.env.REACT_APP_WH_URL}/wm-login/${warehouseCode}/${response?.data}/${response?.device_id}/web`;
                        window.open(url, '_blank');
                    }, 1000);
                };
            };
        };
    };

    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-4">
                    <div className="mb-3" >
                        <label className="form-label">Warehouse Code<span className="mendatory_star">*</span></label>
                        <CommonReactSelectMenu 
                            label={"Warehouse"}
                            options={WareHouseOptions} 
                            selectValue={WarehouseSelect} 
                            setSelectValue={setWarehouseSelect}
                            dependantState={{
                                allow: false,
                            }} 
                            isMendatory={false}
                            isAllowClearable={true}
                            customWidth={"100%"}
                            customeHeight={"38px"}
                            isMulti={false}
                        />
                    </div>
                </div>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[1]?.Edit) && (<div className="col-md-1">
                    <div className="mb-3" >
                        <label className="form-label">&nbsp;</label>
                        <button id="whloginhbtn" className="btn whloginhbtn" onClick={() => getMagicToken(WarehouseSelect?.label)} disabled={!WarehouseSelect}>
                            Login
                        </button>
                    </div>
                </div>)}
            </div>
        </div>
    </section>)
}

export default WQuickLogin;