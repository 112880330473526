/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Collapse, Form, OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../App";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonExport from "../../Common/CommonExport";
import ExelIcon from "../../../assets/img/XLS_icon.svg";
import CommonTable from "../../Common/CommonTable";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";
import CommonDatePicker from "../../Common/CommonDatePicker";

const StockLevel = (props) => {
    const { websocket } = useContext(WebSocketContext);
    const { device_id, storedLevelReportList, wareHouselistall, productlistall, categorylistall, selfInfo } = useSelector((state) => state.adminReducers);
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectWarehouse, setselectWarehouse] = useState("");
    const [selectProduct, setselectProduct] = useState("");
    const [selectCategory, setselectCategory] = useState("");
    const [ FromEndDate, setFromEndDate ] = useState([null, null]);
    const [ FromDate, ToDate ] = FromEndDate;
    const [FilterOpen, setFilterOpen] = useState(false);
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const optionsProducts = productlistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_name }));
    const optionsCategory = categorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.category_name }));
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "product_list",
                "request": {
                    "status": true,
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all warehouse list
    useEffect(() => {
        if (selfInfo?.user?.user_type === 'super_admin' || selfInfo?.user?.user_type === 'admin' || selfInfo?.user?.user_type === 'regional_sales_team_asm' || selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm' || selfInfo?.user?.user_type === 'warehouse_manager') {
            let param = {
                "transmit": "broadcast",
                "url": "warehouse_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        }
    }, []);

    // get brand all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : true,
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "store_level_list",
                "request": {
                    "status": userType,
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "warehouse_id": selectWarehouse?.value,
                    "product_id": selectProduct?.value,
                    "category_id": selectCategory?.value,
                },
                "DeviceId": device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            };
            wsSend_request(websocket, param);
        };
    };

    // get product list
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, selectWarehouse, selectProduct, selectCategory, ToDate]);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select limit
    const SelectLimitFct = (e) => {
        setUserLimit(parseInt(e.target.value));
        setCurrentPage(1);
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'product_code', label: 'Product Code', type: "label", sort: "", isCollapsable: false },
        { key: 'product_name', label: 'Product Name', type: "label", sort: "", isCollapsable: false },
        { key: 'warehouse_name', label: 'Warehouse Name', type: "label", sort: "", isCollapsable: false },
        { key: 'inward_qty', label: 'Stock Inward', type: "label", sort: "", isCollapsable: false },
        { key: 'transfer_qty', label: 'Stock Transfer', type: "label_stock_transfer", sort: "", isCollapsable: false },
        { key: '', label: 'Invoiceable Qty', type: "label_Invoiceable_Qty", sort: "", isCollapsable: false },
        { key: 'balance_qty', label: 'Balance Stock', type: "label", sort: "", isCollapsable: false },
        { key: '', label: 'Stock Value', type: "label_product_stock", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <CommonDatePicker 
                            dateRange={FromEndDate} 
                            setDateRange={setFromEndDate}
                            placeholderText={"Select From Date & To Date"}
                            dependantState={{
                                allow: true,
                                statType: "current_page",
                                stateFunction: setCurrentPage
                            }} 
                        />
                    </div>
                    <div className="col-md-3">
                        {(selfInfo?.user?.user_type === 'super_admin' || selfInfo?.user?.user_type === 'admin' || selfInfo?.user?.user_type === 'regional_sales_team_asm' || selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm' || selfInfo?.user?.user_type === 'warehouse_manager') && (<Form.Group className="mb-3">
                            <CommonReactSelectMenu 
                                label={"Warehouse"}
                                options={optionsWarehouse} 
                                selectValue={selectWarehouse} 
                                setSelectValue={setselectWarehouse} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>)}
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <CommonReactSelectMenu 
                                label={"Category"}
                                options={optionsCategory} 
                                selectValue={selectCategory} 
                                setSelectValue={setselectCategory} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ display: "flex", alignItems: "flex-start" }}>
                        <select className="form-control userLimitselect" id="userLimitselect" value={userLimit} onChange={(e) => SelectLimitFct(e)}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="90">90</option>
                            <option value="100">100</option>
                        </select>
                        <button className="btn exportbtn" id="stocklevelExportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto", float: "right" }} onClick={() => CommonExport("export_stock_level", { "FromDate": FromDate, "ToDate": ToDate, "product_id": selectProduct?.value, "warehouse_id": selectWarehouse?.value, "limit": userLimit })}>
                            <Image src={ExelIcon} alt="excel" />
                        </button>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 100 }}
                            overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                {FilterOpen ? "Filter Hide" : "Filter Show"}
                            </Tooltip>}
                        >
                            <button className="filtercollapsebtn" onClick={() => setFilterOpen(!FilterOpen)} aria-controls="example-collapse-text" aria-expanded={FilterOpen} >
                                {FilterOpen ? (<i className="bi bi-funnel-fill"></i>) : (<i className="bi bi-funnel"></i>)} Filter
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            <Collapse in={FilterOpen}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3">
                                <CommonReactSelectMenu 
                                    label={"Product"}
                                    options={optionsProducts} 
                                    selectValue={selectProduct}
                                    setSelectValue={setselectProduct} 
                                    dependantState={{
                                        allow: true,
                                        statType: "current_page",
                                        stateFunction: setCurrentPage
                                    }} 
                                    isMendatory={false}
                                    isAllowClearable={true}
                                    customWidth={"100%"}
                                    customeHeight={"38px"}
                                    isMulti={false}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: FilterOpen === true ? parseInt(storedLevelReportList?.pagination?.total_records) === storedLevelReportList?.list?.length ? "calc(100vh - 214px)" : parseInt(storedLevelReportList?.pagination?.total_records) > 10 ? "calc(100vh - 248px)" : "calc(100vh - 214px)" : parseInt(storedLevelReportList?.pagination?.total_records) === storedLevelReportList?.list?.length ? "calc(100vh - 159px)" : parseInt(storedLevelReportList?.pagination?.total_records) > 10 ? "calc(100vh - 194px)" : "calc(100vh - 159px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={storedLevelReportList} // ORDER LIST
            Pagination={storedLevelReportList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isTotalAndGrand={{
                isShow: true,
                filterType: "Stock-Level",
                colSpan: 4,
                lastColspan: 4
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default StockLevel;