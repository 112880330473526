/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";
import { useParams } from "react-router-dom";
import { getActiveSchemeListReportDashboard, getActiveAssociatedReportDashboard } from "../../redux/actions/adminActions";
import { DASHBOARD_SCHEMES_METRICS_URL } from "../Shared/constant";
import CommonScrollBar from "../Common/CommonScrollBar";

const DashboardSchemesMetricsDetails = () => {
    const { roomId } = useParams();
    const dispatch = useDispatch();
    const [ DealerskuType, setDealerSkuType ] = useState("");
    const [ OrderDealerStatus, setOrderDealerStatus ] = useState("");
    const { device_id, ActiveSchemeListDashBoardList, ActiveAssociatedListDashBoardList } = useSelector((state) => state.adminReducers);
    const [ userLimit, setUserLimit ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ currentPageAssociates, setCurrentPageAssociates ] = useState(1);
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    const { websocket } = useContext(WebSocketContext);

    // ADMIN SCHEME REPORT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("admin_scheme_report", {
            "franchise_id" : roomId,
            "from_date": startDate, 
            "to_date": endDate, 
            "page_no": currentPage,
            "limit": userLimit
        });
    }, [ websocket, currentPage, userLimit, endDate, roomId ]);

    // ACTIVE ASSOICATE API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("dealer_wise_associates", {
            "franchise_id" : roomId,
            "page_no": currentPageAssociates,
            "limit": userLimit
        });
    }, [ websocket, currentPageAssociates, userLimit, roomId ]);
    
    // COMMON API CALLING
    const DashBoardWebSocketsAPIcalling = (api_url, request_params) => {
        let param;

        if(Object?.keys(request_params)?.length > 0) {
            const { franchise_id, from_date, to_date, page_no, limit, search } = request_params;

            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {
                    "order_by" :"-updated_at"
                },
                "DeviceId": device_id
            };

            // FRANCHISE ID FILTER
            if(franchise_id) {
                param.request.franchise_id = franchise_id;
            };

            // FROM AND TO DATE FILTER
            if((from_date !== null && from_date !== undefined) && (to_date !== null && to_date !== undefined)) {
                param.request.from_date = CommonDateTime(from_date, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(to_date, "YYYY-MM-DD", "hide");
            };

            // SEARCH FILTER 
            if(search) {
                param.request.search = search?.trim();
            };

            if(api_url === "admin_scheme_report") {
                param.module_name = "Active-Schemes-Dashboard";
            };

            // PAGE NO FILTER
            if(page_no) {
                param.request.page_no = page_no;
            };

            // LIMIT FILTER
            if(limit) {
                param.request.limit = limit;
            };
        } else {
            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };
        };
        wsSend_request(websocket, param);
    };


    // ACTIVE SCHEME TABLE COLUMNS ARRAY
    const DealerSchemesAndIncentivesTableColumns = [
        { key: 'scheme_name', label: 'Scheme Name', type: "label", sort: "" },
        { key: 'start_date', label: 'Start Date', type: "date", sort: "" },
        { key: 'end_date', label: 'End Date', type: "date", sort: "" },
        { key: 'end_date', label: 'Due', type: "label_due_of_end_date", sort: "" },
        { key: 'device', label: 'Status', type: "Status_sales_person_performance", sort: "" },
    ];

    // ACTIVE ASSOICATED TABLE COLUMNS ARRAY
    const ActiveAssociatesTableColumns = [
        { key: 'name', label: 'Name', type: "label", sort: "" },
        { key: 'email', label: 'Email ID', type: "label", sort: "" },
        { key: 'mobile', label: 'Contact Number', type: "label", sort: "" },
        { key: 'active_since', label: 'Active Since', type: "date", sort: "" },
        { key: 'active_schemes_count', label: 'Active Schemes', type: "label", sort: "" },
    ];

    window.addEventListener("popstate", function (event) {
        dispatch(getActiveSchemeListReportDashboard({}));
        dispatch(getActiveAssociatedReportDashboard({}));
    });

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <CommonScrollBar height={{ height: "calc(100vh - 62px)" }}>
                {/* Target : Table */}
                <div className="dashboard_main_wrapper" >
                    <CommonHeader 
                        heading={"Target"} 
                        isSearchAllow={false}
                        searchType={""}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        SkuType={DealerskuType}
                        setSkuType={setDealerSkuType} 
                        isOrderStatusAllow={false}
                        OrderStatus={OrderDealerStatus} 
                        setOrderStatus={setOrderDealerStatus}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: true,
                            id: "ActiveSchemeListTable"
                        }}
                        isBackButtonAllow={{
                            open: true,
                            navigate_url: DASHBOARD_SCHEMES_METRICS_URL
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ overflow: "auto" }}>
                        <DashBoardTables 
                            isSrNoAllow={true}
                            Columns={DealerSchemesAndIncentivesTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={ActiveSchemeListDashBoardList} // ORDER LIST
                            PaginationCurrentPage={currentPage}
                            setPaginationCurrentPage={setCurrentPage}
                            PaginationSet={ActiveSchemeListDashBoardList?.pagination} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: false,
                                table_name: "",
                                title: "",
                                colspan: null,
                                data: {},
                            }}
                            height={"350px"}
                            id={"ActiveSchemeListTable"}
                            isActionAllow={{
                                allow: false,
                                functionType: ""
                            }}
                        />
                    </div>
                </div>

                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <CommonHeader 
                        heading={"Active Associates"} 
                        isSearchAllow={false}
                        searchType={""}
                        isDatePickerAllow={false}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setCurrentPageAssociates}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                        isBackButtonAllow={{
                            open: false,
                            navigate_url: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ overflow: "auto" }}>
                        <DashBoardTables 
                            isSrNoAllow={true}
                            Columns={ActiveAssociatesTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={ActiveAssociatedListDashBoardList} // ORDER LIST
                            PaginationCurrentPage={currentPageAssociates}
                            setPaginationCurrentPage={setCurrentPageAssociates}
                            PaginationSet={ActiveAssociatedListDashBoardList?.pagination} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: false,
                                table_name: "",
                                title: "",
                                colspan: null,
                                data: {},
                            }}
                            height={"320px"}
                            id={"DealerSchemesIncentivesTable"}
                            isActionAllow={{
                                allow: true,
                                label: "Action",
                                functionType: "Active-Associates-Modal",
                                franchise_id: roomId
                            }}
                        />
                    </div>
                </div>
            </CommonScrollBar>
        </div>
    </div>)
};

export default DashboardSchemesMetricsDetails;