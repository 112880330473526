/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Spinner } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { POINT_VALUE_REFURBISHED_REPORT, FILE_AUTH_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { NavigationPointList } from "../../../redux/actions/adminActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UploadFileAPI from "../../../Api/UploadFileAPI";
import DefaultImage from "../../../assets/img/DefaultProduct.jpg";
import DeleteIcon from "../../../assets/img/delete_icon.svg";
import CommonScrollBar from "../../Common/CommonScrollBar";

const AddPointValue = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ ApplicationState, setApplicationState ] = useState({
        RefurbishedPoint: "",
        ShowCondition: "",
        PointScore: "",
        Status: true,
        IsImage: false,
        PointImages: [],
        ReferenceImages: [],
    });
    const RnageSelectOptions = [
        "1 - 3",
        "4 - 7",
        "8 - 10"
    ];
    const [ RanageState, setRanageState ] = useState("");
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const { roomId } = useParams();
    const { ClearFormSet, PointValueList, device_id, accessToken } = useSelector((state) => state.adminReducers);
    const CurrentData_ = PointValueList?.list?.filter((elm) => elm?.id === roomId)[0];
    const exceptThisCostSymbols = ["e", "E", "+", "-"];

    // error state
    const [ errorRefurbishedPoint, setErrorRefurbishedPoint ] = useState("");
    const [ errorShowCondition, setErrorShowCondition ] = useState("");
    const [ errorPointScore, setErrorPointScore ] = useState("");
    const [ errorPointImages, setErrorPointImages ] = useState("");
    const [ errorReferenceImages, setErrorReferenceImages ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-point") {
            setApplicationState({
                ...ApplicationState,
                RefurbishedPoint: CurrentData_?.refurbished_point,
                ShowCondition: CurrentData_?.show_condition === null ? "" : CurrentData_?.show_condition,
                Status: CurrentData_?.is_active,
                IsImage: CurrentData_?.is_image,
                PointScore: CurrentData_?.point_score === null ? "" : CurrentData_?.point_score,
                PointImages: CurrentData_?.point_images === null ? [] : JSON.parse(CurrentData_?.point_images?.replace(/'/g, '"')),
                ReferenceImages: CurrentData_?.reference_images === null ? [] : JSON.parse(CurrentData_?.reference_images?.replace(/'/g, '"'))
            });
        };
    }, [ CurrentData_ ]);

    // Show Condition select function
    const OnchangeShowConditons = (e) => {
        if(e?.target?.value !== "") {
            setApplicationState({
                ...ApplicationState,
                ShowCondition: e?.target?.value,
            });
            setErrorShowCondition("");
        } else {
            setApplicationState({
                ...ApplicationState,
                ShowCondition: "",
            });
            setErrorShowCondition("Please select show condition");
        };
    };

    
    const [ ImageLabelState, setImageLabelState ] = useState("");
    let IsImageVar = [...ApplicationState?.PointImages];

    const AddImageLbFct = () => {
        if(ImageLabelState !== "") {
            const ApplicationStatelet = ApplicationState?.PointImages?.filter((elm) => elm === ImageLabelState);
            if(ApplicationStatelet?.length === 0) {
                IsImageVar?.push(ImageLabelState);
                setApplicationState({
                    ...ApplicationState,
                    PointImages: IsImageVar
                });
                setImageLabelState("");
                setErrorPointImages("");
            } else {
                toast.error("Image label allready exist")
            };
        };
    };

    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            AddImageLbFct();
        };
    };

    // delete image label
    const deleteImageLael = (label) => {
        const UpdateIsImageVar = IsImageVar?.filter((elm) => {
            if(elm !== label) {
                return elm;
            }
        });
        setApplicationState({
            ...ApplicationState,
            PointImages: UpdateIsImageVar
        });
    };

    // upload product image 
    const ReferncImage = [...ApplicationState?.ReferenceImages];
    const UploadImage = async (e, range) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            const obj = {
                id: range + 1,
                range: range,
                images: fileresponse,
            }
            ReferncImage?.push(obj);
            setApplicationState({
                ...ApplicationState,
                ReferenceImages: ReferncImage,
            });
            setRanageState("");
            setUploadFileLoader(false);
            document.getElementById('fileInput').value = null;
        } else {
            setUploadFileLoader(false);
        };
    };

    // delete image label
    const deleteRferenceImage = (id) => {
        const UpdateIsImageVar = ReferncImage?.filter((elm) => {
            if(elm?.id !== id) {
                return elm;
            }
        });
        setApplicationState({
            ...ApplicationState,
            ReferenceImages: UpdateIsImageVar
        });
    };

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.RefurbishedPoint.trim() !== "" && ApplicationState?.ShowCondition !== "" && ApplicationState?.PointScore !== "") {
            if(RanageState !== "" || ApplicationState?.ReferenceImages?.length > 0) {
                if(ApplicationState?.ReferenceImages?.length !== 0 && ApplicationState?.ReferenceImages?.length === 3) {
                    if(LocationRoute === "edit-point") {
                        if(ApplicationState?.IsImage) {
                            if(ApplicationState?.PointImages?.length > 0) {
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "refurbished_point_edit",
                                    "request" : { 
                                        "refurbished_point_id" : CurrentData_?.id,
                                        "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                        "show_condition" : ApplicationState?.ShowCondition,
                                        "is_image" : ApplicationState?.IsImage,
                                        "point_images" : JSON.stringify(ApplicationState?.PointImages),
                                        "reference_images" : JSON.stringify(ApplicationState?.ReferenceImages),
                                        "point_score" : ApplicationState?.PointScore,
                                        "is_active" : ApplicationState?.Status
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);    
                            } else {
                                if(ApplicationState?.PointImages?.length === 0) {
                                    setErrorPointImages("Point images cannot be empty");
                                } else {
                                    setErrorPointImages("");
                                };
                            };
                        } else {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "refurbished_point_edit",
                                "request" : { 
                                    "refurbished_point_id" : CurrentData_?.id,
                                    "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                    "show_condition" : ApplicationState?.ShowCondition,
                                    "is_image" : ApplicationState?.IsImage,
                                    "reference_images" : JSON.stringify(ApplicationState?.ReferenceImages),
                                    "is_active" : ApplicationState?.Status,
                                    "point_score" : ApplicationState?.PointScore,
                                },
                                "DeviceId" : device_id
                            };
                            wsSend_request(websocket, param);
                        };
                    } else {
                        if(ApplicationState?.IsImage) {
                            if(ApplicationState?.PointImages?.length > 0) {
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "refurbished_point_add",
                                    "request" : { 
                                        "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                        "show_condition" : ApplicationState?.ShowCondition,
                                        "is_image" : ApplicationState?.IsImage,
                                        "point_images" : JSON.stringify(ApplicationState?.PointImages),
                                        "reference_images" : JSON.stringify(ApplicationState?.ReferenceImages),
                                        "point_score" : ApplicationState?.PointScore,
                                        "is_active" : ApplicationState?.Status,
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                if(ApplicationState?.PointImages?.length === 0) {
                                    setErrorPointImages("Point images cannot be empty");
                                } else {
                                    setErrorPointImages("");
                                };
                            };
                        } else {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "refurbished_point_add",
                                "request" : { 
                                    "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                    "show_condition" : ApplicationState?.ShowCondition,
                                    "point_score" : ApplicationState?.PointScore,
                                    "reference_images" : JSON.stringify(ApplicationState?.ReferenceImages),
                                    "is_image" : ApplicationState?.IsImage,
                                    "is_active" : ApplicationState?.Status,
                                },
                                "DeviceId" : device_id
                            };
                            wsSend_request(websocket, param);
                        };
                    };
                } else {
                    setErrorReferenceImages("Please upload all reference images within the specified range.")
                }
            } else {
                if(LocationRoute === "edit-point") {
                    if(ApplicationState?.IsImage) {
                        if(ApplicationState?.PointImages?.length > 0) {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "refurbished_point_edit",
                                "request" : { 
                                    "refurbished_point_id" : CurrentData_?.id,
                                    "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                    "show_condition" : ApplicationState?.ShowCondition,
                                    "is_image" : ApplicationState?.IsImage,
                                    "point_images" : JSON.stringify(ApplicationState?.PointImages),
                                    "point_score" : ApplicationState?.PointScore,
                                    "is_active" : ApplicationState?.Status
                                },
                                "DeviceId" : device_id
                            };
                            wsSend_request(websocket, param);    
                        } else {
                            if(ApplicationState?.PointImages?.length === 0) {
                                setErrorPointImages("Point images cannot be empty");
                            } else {
                                setErrorPointImages("");
                            };
                        };
                    } else {
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "refurbished_point_edit",
                            "request" : { 
                                "refurbished_point_id" : CurrentData_?.id,
                                "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                "show_condition" : ApplicationState?.ShowCondition,
                                "is_image" : ApplicationState?.IsImage,
                                "is_active" : ApplicationState?.Status,
                                "point_score" : ApplicationState?.PointScore,
                            },
                            "DeviceId" : device_id
                        };
                        wsSend_request(websocket, param);
                    };
                } else {
                    if(ApplicationState?.IsImage) {
                        if(ApplicationState?.PointImages?.length > 0) {
                            let param = { 
                                "transmit": "broadcast", 
                                "url": "refurbished_point_add",
                                "request" : { 
                                    "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                    "show_condition" : ApplicationState?.ShowCondition,
                                    "is_image" : ApplicationState?.IsImage,
                                    "point_images" : JSON.stringify(ApplicationState?.PointImages),
                                    "point_score" : ApplicationState?.PointScore,
                                    "is_active" : ApplicationState?.Status,
                                },
                                "DeviceId" : device_id
                            };
                            wsSend_request(websocket, param);
                        } else {
                            if(ApplicationState?.PointImages?.length === 0) {
                                setErrorPointImages("Point images cannot be empty");
                            } else {
                                setErrorPointImages("");
                            };
                        };
                    } else {
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "refurbished_point_add",
                            "request" : { 
                                "refurbished_point" : ApplicationState?.RefurbishedPoint.trim(),
                                "show_condition" : ApplicationState?.ShowCondition,
                                "point_score" : ApplicationState?.PointScore,
                                "is_image" : ApplicationState?.IsImage,
                                "is_active" : ApplicationState?.Status,
                            },
                            "DeviceId" : device_id
                        };
                        wsSend_request(websocket, param);
                    };
                };
            };
        } else {
            CheckValid(ApplicationState?.RefurbishedPoint.trim(), {type: 'RefurbishedPoint', error: setErrorRefurbishedPoint});
            CheckValid(ApplicationState?.PointScore, {type: 'PointScore', error: setErrorPointScore});
            if(ApplicationState?.ShowCondition === "") {
                setErrorShowCondition("Please select show condition");
            } else {
                setErrorShowCondition("");
            };
            if(ApplicationState?.PointImages?.length === 0) {
                setErrorPointImages("Point images cannot be empty");
            } else {
                setErrorPointImages("");
            };
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(POINT_VALUE_REFURBISHED_REPORT);
            Dispatch(NavigationPointList("Point_list"));
        };
    }, [ ClearFormSet ]);

    

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    
    // selecting range value
    const checkSelectRange = (range) => {
        if(ApplicationState?.ReferenceImages?.length === 0) {
            return range;
        } else {
            const updaterange = ApplicationState?.ReferenceImages?.map((elm) => elm?.range)?.toString();
            return  updaterange.includes(range) !== true && range;
        }
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={POINT_VALUE_REFURBISHED_REPORT} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="">
            <CommonScrollBar height={{ height: "calc(100vh - 135px)" }} className="ScrollbarsSidebar">
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <Form.Label>Point Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.RefurbishedPoint} 
                                    onChange={(e) => setApplicationState({...ApplicationState, RefurbishedPoint: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'RefurbishedPoint', error: setErrorRefurbishedPoint})}
                                    onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                    placeholder="Enter Name" 
                                />
                                {errorRefurbishedPoint !== "" &&<span className="error_medotory">{errorRefurbishedPoint}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <Form.Label>Show Condition<span className="mendatory_star">*</span></Form.Label>
                                <select 
                                    value={ApplicationState?.ShowCondition}
                                    onChange={(e) => OnchangeShowConditons(e)}
                                >
                                    <option value="">Select Show Condition</option>
                                    <option value="all">All</option>
                                    <option value="geared">Geared</option>
                                </select>
                                {errorShowCondition !== "" &&<span className="error_medotory">{errorShowCondition}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" >
                                <Form.Label>Point Score<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    className="inputBoxs"
                                    value={ApplicationState?.PointScore} 
                                    onChange={(e) => setApplicationState({...ApplicationState, PointScore: e.target.value <= 100 && e?.target?.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'PointScore', error: setErrorPointScore})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Point Score" 
                                    min="0"
                                />
                                {errorPointScore !== "" && (<span className="error_medotory">{errorPointScore}</span>)}
                            </Form.Group>
                        </div>
                        <div className="col-md-2">
                            <CommonToggle 
                                isBadgeAllow={true}
                                badgeTitle={"active_inactive"}
                                valueToggle={ApplicationState?.Status}
                                setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                name={"Status"}
                            />
                        </div>
                        <div className="col-md-2">
                            <CommonToggle 
                                isBadgeAllow={true}
                                badgeTitle={"active_inactive"}
                                valueToggle={ApplicationState?.IsImage}
                                setValueToggle={() => setApplicationState({...ApplicationState, IsImage: !ApplicationState?.IsImage})}
                                name={"Allow Images"}
                            />
                        </div>
                        {ApplicationState?.IsImage && (<React.Fragment>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Add Image Labels<span className="mendatory_star">*</span></Form.Label>
                                    <div className="add_images_wraper">
                                        <Form.Control
                                            type="text" 
                                            value={ImageLabelState} 
                                            onChange={(e) => setImageLabelState(e.target.value)}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e);
                                                onEnterSearch(e);
                                            }}
                                            placeholder="Enter Image Label"
                                        />
                                        <button className="btn_add" onClick={() => AddImageLbFct()}>
                                            <i class="bi bi-plus-lg"></i>
                                        </button>
                                    </div>
                                    {errorPointImages && (<span className="error_medotory">{errorPointImages}</span>)}
                                </Form.Group>
                                <ul className="images_labels_wrapper images_labels_wrapper_edit_point">
                                    {ApplicationState?.PointImages?.map((elm, index) => {
                                        return(<li key={index}>
                                            {elm} 

                                            <img src={DeleteIcon} className="delete_btn" onClick={() => deleteImageLael(elm)} alt="edit" /> 
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </React.Fragment>)}
                        <div className="col-md-5">
                            <Form.Group className="mb-3" >
                                <Form.Label>Reference Images With Range</Form.Label>
                                <div className="rangerefrence_wrapper" id="rangerefrence_wrapper">
                                    <select className="refrence" value={RanageState} onChange={(e) => setRanageState(e?.target?.value)}>
                                        <option value={""}>Select Range</option>
                                        {RnageSelectOptions?.filter((elm) => checkSelectRange(elm))?.map((elm, index) => {
                                            return(<option value={elm} key={index}>{elm}</option>)
                                        })}
                                    </select>
                                    <label id="UploadCmnBTN" aria-disabled={RanageState === ""} className="btn head_cmn_btn w-100 UploadCmnBTN UploadCmnAttchmentBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                        <i className="bi bi-upload"></i>
                                        <input type="file" name={"Attachments"} disabled={RanageState === ""} id="fileInput" onChange={(e) => UploadImage(e, RanageState)} accept=".png, .jpg, .pdf, .webp, .jpeg" />
                                        Upload
                                        {UploadFileLoader && (<Spinner />)}
                                    </label>
                                </div>
                                {(ApplicationState?.ReferenceImages?.length !== 3 && errorReferenceImages !== "") && (<span className="error_medotory">{errorReferenceImages}</span>)}
                            </Form.Group>
                            <div className="uploade_images_wrapper">
                                {ApplicationState?.ReferenceImages?.map((elm, index) => {
                                    return(<div className="wrapps_upload_img" key={index}>
                                            <span>Range : {elm?.range} <img src={DeleteIcon} className="delete_image_range" onClick={() => deleteRferenceImage(elm?.id)} /></span>
                                            <div className="image_preview_wraps" key={index}>
                                            <img src={FILE_AUTH_URL + elm?.images?.view_file_url} onError={(e) => e.target.src = DefaultImage} alt="image_preiew" />
                                        </div>
                                    </div>)
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Button className="formbtn" style={{ width: "auto", float: "right" }} onClick={() => CreateBrand()} variant="primary" >
                                {LocationRoute === "edit-point" ? "Update" : "Add"}
                            </Button>
                        </div>
                    </div>
                </div>
            </CommonScrollBar>
        </div>
    </section>)
}

export default AddPointValue;