/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { updateAFSOrderManagementFilter } from "../../../redux/actions/adminActions";
import AFSOrderDataTableFilter from "../../Common/AFSOrderDataTableFilter";
import CommonLogout from "../../Common/CommonLogout";
import CommonTable from "../../Common/CommonTable";

const AfterSalesCancelledOrders = (props) => {
    const { tab_url } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, AfterSalesCancelledOrderList,selfInfo, AFSOrderManagmentFilter, sortingfor, accessToken } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        Dispatch(updateAFSOrderManagementFilter({
            [tab_url]: {
                CurrentPage: page,
            }
        }));
    };

    // get order_list all
    useEffect(() => {
        const { UserLimit = 10, 
                UserSearch = '',
                SelectRegion = '', 
                FromDate = null,
                ToDate = null,
                CurrentPage = 1,
                is_warranty_order = null,
        } = AFSOrderManagmentFilter[tab_url] || {};

        let is_warranty_order_update = is_warranty_order === "all_orders" ? "" : is_warranty_order;

        let param = {
            "transmit": "broadcast",
            "url": "afs_order_list",
            "request": {
                "status": "CANCELLED",
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "region_id": SelectRegion,
                "order_by": sortingfor,
                "sale_type_id": is_warranty_order_update
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [ websocket, sortingfor, AFSOrderManagmentFilter[tab_url] ]);

    useEffect(() => {
        if(AFSOrderManagmentFilter === undefined) {
            CommonLogout(accessToken, Dispatch);
        }
    }, [ AFSOrderManagmentFilter ]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'order_code', label: 'Order Id.', type: "navigate", sort: "order_code", isCollapsable: false },
        { key: 'erp_order_type', label: 'Seller Type', type: "label", sort: "erp_order_type", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "customer_identity", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "created_at", isCollapsable: false },
        { key: 'customer_name', label: 'SHIP To Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'billing_id', label: 'BILL To Name', type: "label_get_from_franchise", sort: "billing_id", isCollapsable: false },
        { key: 'warehouse_name', label: 'Ship From Warehouse', type: "", sort: "", isCollapsable: false },
        { key: 'erp_invoice_code', label: 'Invoice No.', type: "invoice_modal_open_navigate", sort: "erp_invoice_code", isCollapsable: false },
        { key: 'invoice_at', label: 'Invoice Date', type: "date", sort: "invoice_at", isCollapsable: false },
        { key: 'courier_name', label: 'Transporter', type: "label", sort: "courier_name", isCollapsable: false },
        { key: 'delivered_date', label: 'Delivered Date', type: "date", sort: "delivered_date", isCollapsable: false },
        { key: 'docket_number', label: 'Docket Number', type: "hyper_link_shipment_url", sort: "docket_number", isCollapsable: false },
        { key: 'erp_id', label: 'ERP Sales Order Code', type: "label", sort: "", isCollapsable: false },
        { key: 'erp_invoice_code', label: 'ERP Invoice Status', type: "status", sort: "", isCollapsable: false },
        { key: 'frame_number', label: 'Frame Number', type: "label", sort: "", isCollapsable: false },
        { key: 'ticket_id', label: 'Tickets Id', type: "label", sort: "", isCollapsable: false },
        { key: 'cancel_at', label: 'Cancellation Date', type: "date", sort: "", isCollapsable: false },
        { key: 'updated_at', label: 'Pending Since', type: "pending_since", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section">
        {/* AFS FILTERS */}
        <AFSOrderDataTableFilter 
            filterType={""}
            tabName={tab_url}
            searchType={"Order Code, Customer code, Customer name, Sales order code, Sales invoice code, Tickets id, Quotation Id"}
            searchdisable={true}
            buttonvisible={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(AfterSalesCancelledOrderList?.pagination?.total_records) === AfterSalesCancelledOrderList?.list?.length ? "calc(100vh - 242px)" : parseInt(AfterSalesCancelledOrderList?.pagination?.total_records) > 10 ? "calc(100vh - 280px)" : "calc(100vh - 242px)"
            }}
            srNumber={{
                clickable: true,
                events: "AFS_ORDERS"
            }}
            isCollapsable={false}
            tabName={tab_url} // TAB URL
            PaginationCurrentPage={AFSOrderManagmentFilter[tab_url]?.CurrentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={AfterSalesCancelledOrderList} // ORDER LIST
            Pagination={AfterSalesCancelledOrderList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AFSOrderManagement[0]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: tab_url,
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default AfterSalesCancelledOrders;