import React, { useRef, useEffect } from "react";
import Select, { components } from 'react-select';

const CommonReactSelectMenu = (props) => {
    const { label, options, selectValue, setSelectValue, dependantState, isMendatory, errorState, setErrorState, isAllowClearable, customWidth, customeHeight, disabledMenu, isMulti } = props;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
        control: (provided) => ({
            ...provided,
            minHeight: customeHeight, // Adjust the height
            height: customeHeight,
            border: "0px",
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: customeHeight,
            padding: "0 8px",
        }),
        input: (provided) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: customeHeight,
        }),
    };

    const customStylesMilti = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: customeHeight, // Adjust the height
            height: customeHeight,
            border: "0px",
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: customeHeight,
            padding: "0 8px",
        }),
        input: (provided) => ({
            ...provided,
            margin: "0px",
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: customeHeight,
        }),
        multiValueLabel: (provided, { data }) => ({
            ...provided,
            maxWidth: '50px', // Ensure the label inside is also constrained
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            title: data.label, // Set the title attribute for native tooltip
        }),
    };

    // Custom ValueContainer to enable scrolling and auto-scroll on select
    const CustomValueContainer = ({ children, ...props }) => {
        const containerRef = useRef(null);
    
        useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
        }, [props.selectProps.value]);
    
        return (
        <components.ValueContainer {...props}>
            <div
            ref={containerRef}
            className="godown-select__value-container"
            style={{
                maxHeight: '30px', // Height of the container
                overflowY: 'auto', // Add scrollbar
                display: 'flex',
                flexWrap: 'wrap',
            }}
            >
            {children}
            </div>
        </components.ValueContainer>
        );
    };

    const CustomMultiValueLabel = (props) => {
        return (
          <components.MultiValueLabel {...props}>
            <span title={props.data.label} style={{ maxWidth: '80px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {props.data.label}
            </span>
          </components.MultiValueLabel>
        );
    };

    // SELECT ONCHANGE FUNCTIONS
    const CommonSelectOnChange = (value) => {
        if(value !== null) {
            setSelectValue(value);
            if(isMendatory) {
                setErrorState("");
            };
            if(dependantState?.allow) {
                if(dependantState?.statType === "clear_state") {
                    dependantState?.stateFunction("");
                } else if(dependantState?.statType === "current_page") {
                    dependantState?.stateFunction(1);
                } else {

                };
            };
        } else {
            setSelectValue("");
            if(isMendatory) {
                setErrorState(`Please select ${label}`);
            };
            if(dependantState?.allow) {
                if(dependantState?.statType === "clear_state") {
                    dependantState?.stateFunction("");
                } else if(dependantState?.statType === "current_page") {
                    dependantState?.stateFunction(1);
                } else {

                };
            };
        };
    };

    return(<div className={isMulti ? "selectMultiselect selectMultiselectAllow" : "selectMultiselect"} style={{ width: customWidth }}>
        {/* COMMON SELECT MENU LIST */}
        <Select 
            value={selectValue}
            onChange={(e) => CommonSelectOnChange(e)} 
            isClearable={isAllowClearable}
            options={options}
            className="godown-select-container" 
            classNamePrefix="godown-select"
            styles={isMulti ? customStylesMilti : customStyles}
            components={isMulti && { ValueContainer: CustomValueContainer, MultiValueLabel: CustomMultiValueLabel }}
            placeholder={`Select ${label}`}
            menuPlacement="auto"
            menuPortalTarget={document.body}
            isDisabled={disabledMenu}
            isMulti={isMulti}
        />

        {/* ERROR STATE */}
        {isMendatory && (<React.Fragment>
            {errorState !== "" && (<span className="error_medotory">{errorState}</span>)}
        </React.Fragment>)}
    </div>)
};

export default CommonReactSelectMenu;