/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";
import CommonScrollBar from "../Common/CommonScrollBar";

const DashboardSchemesMetrics = () => {
    const [ DealerskuType, setDealerSkuType ] = useState("");
    const [ OrderDealerStatus, setOrderDealerStatus ] = useState("");
    const { device_id, DealerSchemeAndIncentiveDashboardReport } = useSelector((state) => state.adminReducers);
    const [ userLimit, setUserLimit ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    const { websocket } = useContext(WebSocketContext);

    // SALES REVENUE DAILY API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("dealer_scheme_and_incentive", {
            "from_date": startDate, 
            "to_date": endDate, 
            "page_no": currentPage,
            "limit": userLimit
        });
    }, [ websocket, currentPage, userLimit, endDate ]);
    
    // COMMON API CALLING
    const DashBoardWebSocketsAPIcalling = (api_url, request_params) => {
        let param;

        if(Object?.keys(request_params)?.length > 0) {
            const { from_date, to_date, page_no, limit, search } = request_params;

            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };

            // FROM AND TO DATE FILTER
            if((from_date !== null && from_date !== undefined) && (to_date !== null && to_date !== undefined)) {
                param.request.from_date = CommonDateTime(from_date, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(to_date, "YYYY-MM-DD", "hide");
            };

            // SEARCH FILTER 
            if(search) {
                param.request.search = search?.trim();
            };

            // PAGE NO FILTER
            if(page_no) {
                param.request.page_no = page_no;
            };

            // LIMIT FILTER
            if(limit) {
                param.request.limit = limit;
            };
        } else {
            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };
        };
        wsSend_request(websocket, param);
    };


    // DEALER SCHEMES & INCENTIVES TABLE COLUMNS ARRAY
    const DealerSchemesAndIncentivesTableColumns = [
        { key: 'franchise_code', label: 'Franchise Code', type: "label_redirect_summery", sort: "" },
        { key: 'franchise_name', label: 'Franchise Name', type: "label", sort: "" },
        { key: 'region_name', label: 'Region', type: "label", sort: "" },
        { key: 'active_schemes_count', label: 'Active Schemes', type: "label", sort: "" },
        { key: 'active_sales_count', label: 'Active Associates', type: "label", sort: "" },
    ];

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <CommonScrollBar height={{ height: "calc(100vh - 62px)" }}>
                {/* Dealer Schemes & Incentives : Table */}
                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <CommonHeader 
                        heading={"Dealer Schemes & Incentives"} 
                        isSearchAllow={false}
                        searchType={"Franchise Code, Franchise Name"}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        SkuType={DealerskuType}
                        setSkuType={setDealerSkuType} 
                        isOrderStatusAllow={false}
                        OrderStatus={OrderDealerStatus} 
                        setOrderStatus={setOrderDealerStatus}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: true,
                            id: "DealerSchemesIncentivesTable"
                        }}
                        isBackButtonAllow={{
                            open: false,
                            navigate_url: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ overflow: "auto" }}>
                        <DashBoardTables 
                            isSrNoAllow={true}
                            Columns={DealerSchemesAndIncentivesTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DealerSchemeAndIncentiveDashboardReport} // ORDER LIST
                            PaginationCurrentPage={currentPage}
                            setPaginationCurrentPage={setCurrentPage}
                            PaginationSet={DealerSchemeAndIncentiveDashboardReport?.pagination} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: false,
                                table_name: "",
                                title: "",
                                colspan: null,
                                data: {},
                            }}
                            height={"320px"}
                            id={"DealerSchemesIncentivesTable"}
                            isActionAllow={{
                                allow: false,
                                functionType: ""
                            }}
                        />
                    </div>
                </div>
            </CommonScrollBar>
        </div>
    </div>)
};

export default DashboardSchemesMetrics;