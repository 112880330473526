/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Table, Form  } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios, GetOrderReportDetails, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { FRANCHISE_DISPATCH_URL } from "../../Shared/constant";
import FrameSelectionItem from "../../Common/FrameSelectionItem";
import PurchaseOrderReport from "../../Common/PurchaseOrderReport";
import CancelOrder from "../../Common/CancelOrder";
import Select from 'react-select';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CommonewModel from "../../Common/CommonewModel";
import { toast } from "react-toastify";
import OrderStApproval from "../../../Api/OrderStApproval";
import CommonLoader from "../../Common/CommonLoader";
import GetWhatsappLmeAPI from "../../../Api/GetWhatsappLmeAPI";
import CommonLogout from "../../Common/CommonLogout";
import OrderErpInvoice from "../../../Api/OrderErpInvoice";
import CommonScrollBar from "../../Common/CommonScrollBar";
import DeleteIcon from "../../../assets/img/delete_icon.svg";

const OrderDetailsViewer = (props) => {
    const { OrderViewerModal, setOrderViewerModal } = props;
    const { OrderReportDetails, device_id, OptionList, ClearFormSet, AllboxList, accessToken, commonMainLoader } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const Navigate = useNavigate();
    const Location = useLocation();
    const [disable, setDisable] = useState(true)
    const CurrentPathname = Location?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);

    const getOptionsItem = () =>{
        const frameList = [];
        const AccessoriesList = [];
        let QTY = 0;
        OrderReportDetails?.order_items_list?.map((item, index)=>{
            if(item?.is_bike){
                frameList?.push(item);
                QTY = QTY + item?.qty;
            }else{
                AccessoriesList?.push(item);
                QTY = QTY + item?.qty;
            }
        })
        return {frameList, AccessoriesList, quantity:QTY}
    }
    const {frameList, AccessoriesList, quantity} = useMemo(getOptionsItem, [OrderReportDetails, OrderReportDetails?.order_items_list?.length, OptionList]);
    const roomId = useParams();

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const exceptThisCostSymbolsNew = ["e", "E", "+", "-"];

    const [ selectBox, setSelectBox ] = useState("");
    const GetOnlyAccessoriesProduct = OrderReportDetails?.order_items_list?.filter((elm) => elm?.is_bike === false);
    const GetOnlyBikesProduct = OrderReportDetails?.order_items_list?.filter((elm) => elm?.is_bike === true);
    const totalQuantity = calculateTotalQuantity(GetOnlyAccessoriesProduct);
    const totalBikesQuantity = calculateTotalQuantity(GetOnlyBikesProduct);
    const [ partialArray, setpartialArray ] = useState([]);
    const [ optionsBox, SetOptionsBox ] = useState([]);
    const [ optionsProduct, SetOptionsProduct ] = useState([]);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: ""
    });
    const [ MainLoader, setMainLoader ] = useState(false);

    useEffect(() => {
        if(AllboxList?.length > 0) {
            SetOptionsBox(()=>AllboxList?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.box_name })));
        };
    }, [ AllboxList ])

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "box_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if(roomId?.id) {
            Dispatch(StartMainCommonLoader({
                url: "order_detail",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request" : { 
                    "order_id" : roomId?.id,
                    "is_weight" : true,
                    get_franchise:"yes"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId?.id, websocket ]);

    useEffect(() => {
        if(ClearFormSet?.url !== "box_add") {
            if(ClearFormSet?.action === 200) {
                Dispatch(ClearFormSetFutios({
                    url:"",
                    action:false
                }));
                if(CurrentPathname !== "after-sales-orders") {
                    Navigate(FRANCHISE_DISPATCH_URL);
                };
            };
        };
    }, [ ClearFormSet ]);

    const callBack = (item, index, url) =>{
        const getItems = items?.filter((item, index1)=>item?.id != index);
        getItems.push({id:index, store_ids: item});
        let len = 0;
        getItems?.map((item)=>{
            len = len + item?.store_ids?.length
        })
        
        let frameListArray = [];
        getItems?.filter((elm) => {
            frameList?.filter((elmz) => {
                if(elmz?.id === elm?.id) {
                    frameListArray?.push(elm)
                };
            })
        });

        // Function to calculate total quantity
        function calculateTotalFrameQuantity(items) {
            if(items !== undefined) {
                let totalQuantity = 0;
                for (let i = 0; i < items.length; i++) {
                    totalQuantity += items[i].store_ids?.length;
                }
                return totalQuantity;
            }
        };

        if(AccessoriesList?.length === 0 && frameList?.length > 0) {
            if(url !== "Accessories") {
                if(len === quantity){
                    setDisable(false);
                }else{
                    setDisable(true);
                };
            }
        } else if(AccessoriesList?.length > 0 && frameList?.length > 0) {
            if(calculateTotalFrameQuantity(frameListArray) === totalBikesQuantity) {
                setDisable(false)
            } else {
                setDisable(true)
            };
        } else if(AccessoriesList?.length > 0 && frameList?.length === 0) {
            setDisable(false)
        };
        setItems(getItems)
    };

    const EmptyAccessories = AccessoriesList?.map((elm) => ({id: elm?.id, store_ids: [""]}));
    const callSubmitForm = async (e) =>{
        e.preventDefault();
        setMainLoader(true);
        // if(!disable){
            const partialArrayset = partialArray?.map((elm) => {
                return {
                    box_id: elm?.box_id?.id,
                    parcel_id: elm?.parcel_id,
                    box_weight: elm?.box_weight,
                    items: elm?.items?.map((elms) => {
                        return{ order_item_id: elms?.order_item_id, qty: elms?.qty}
                    }),
                }
            })
            if(AccessoriesList?.length > 0 && frameList?.length === 0) {
                if(items?.length > 0) {
                    // let param = { 
                    //     "transmit": "broadcast", 
                    //     "url": "st_approval",
                    //     "request" : { 
                    //         "order_id" : roomId?.id,
                    //         "items": items, 
                    //         "parcels": partialArrayset
                    //     },
                    //     "DeviceId" : device_id
                    // };
                    // wsSend_request(websocket, param);

                    const payload = JSON.stringify({
                        request : {
                            order_id : roomId?.id,
                            items: items, 
                            parcels: partialArrayset
                        }
                    });
                    const response = await OrderStApproval(payload,accessToken);
                    if(response?.status === 200){
                        setMainLoader(false);
                        toast.info(response.msg);
                        Navigate(FRANCHISE_DISPATCH_URL);
                        // if (response?.data?.order_type === "AO") {
                        //     GetWhatsappLmeAPICall(accessToken, response?.data?.id);
                        // } else {
                        //     setMainLoader(false);
                        //     toast.info(response.msg);
                        //     Navigate(FRANCHISE_DISPATCH_URL);
                        // };
                        if (response?.data?.order_type === "AO") {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, false);
                            } else {
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, true);
                            };
                        } else {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                            } else {
                                // setMainLoader(false);
                                // toast.info(response.msg);
                                // Navigate(FRANCHISE_DISPATCH_URL);
                            };
                        };
                    } else {
                        if(response?.message === "User is not authorized." && response?.status === 400) {
                            setMainLoader(false);
                            toast.error(response.message);
                            CommonLogout(accessToken, Dispatch);
                            localStorage?.removeItem("access_token");
                        } else {
                            setMainLoader(false);
                            toast.error(response.msg);
                        };
                    };
                } else {
                    // let param = { 
                    //     "transmit": "broadcast", 
                    //     "url": "st_approval",
                    //     "request" : { 
                    //         "order_id" : roomId?.id,
                    //         "items": EmptyAccessories,
                    //         "parcels": partialArrayset
                    //     },
                    //     "DeviceId" : device_id
                    // };
                    // wsSend_request(websocket, param);

                    const payload = JSON.stringify({
                        request : {
                            order_id : roomId?.id,
                            items: EmptyAccessories, 
                            parcels: partialArrayset
                        }
                    });
                    const response = await OrderStApproval(payload,accessToken);
                    if(response?.status === 200){
                        setMainLoader(false);
                        toast.info(response.msg);
                        Navigate(FRANCHISE_DISPATCH_URL);
                        // if (response?.data?.order_type === "AO") {
                        //     GetWhatsappLmeAPICall(accessToken, response?.data?.id);
                        // } else {
                        //     setMainLoader(false);
                        //     toast.info(response.msg);
                        //     Navigate(FRANCHISE_DISPATCH_URL);
                        // };
                        if (response?.data?.order_type === "AO") {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, false);
                            } else {
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, true);
                            };
                        } else {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                            } else {
                                // setMainLoader(false);
                                // toast.info(response.msg);
                                // Navigate(FRANCHISE_DISPATCH_URL);
                            };
                        };
                    } else {
                        if(response?.message === "User is not authorized." && response?.status === 400) {
                            setMainLoader(false);
                            toast.error(response.message);
                            CommonLogout(accessToken, Dispatch);
                            localStorage?.removeItem("access_token");
                        } else {
                            setMainLoader(false);
                            toast.error(response.msg);
                        };
                    };
                };
            } else if(AccessoriesList?.length > 0 && frameList?.length > 0) {
                if(items?.length > 0) {
                    // let param = { 
                    //     "transmit": "broadcast", 
                    //     "url": "st_approval",
                    //     "request" : { 
                    //         "order_id" : roomId?.id,
                    //         "items": items, 
                    //         "parcels": partialArrayset
                    //     },
                    //     "DeviceId" : device_id
                    // };
                    // wsSend_request(websocket, param);
                    const payload = JSON.stringify({
                        request : {
                            order_id : roomId?.id,
                            items: items, 
                            parcels: partialArrayset
                        }
                    });
                    const response = await OrderStApproval(payload,accessToken);
                    if(response?.status === 200){
                        setMainLoader(false);
                        toast.info(response.msg);
                        Navigate(FRANCHISE_DISPATCH_URL);
                        // if (response?.data?.order_type === "AO") {
                        //     GetWhatsappLmeAPICall(accessToken, response?.data?.id);
                        // } else {
                        //     setMainLoader(false);
                        //     toast.info(response.msg);
                        //     Navigate(FRANCHISE_DISPATCH_URL);
                        // };
                        if (response?.data?.order_type === "AO") {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, false);
                            } else {
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, true);
                            };
                        } else {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                            } else {
                                // setMainLoader(false);
                                // toast.info(response.msg);
                                // Navigate(FRANCHISE_DISPATCH_URL);
                            };
                        };
                    } else {
                        if(response?.message === "User is not authorized." && response?.status === 400) {
                            setMainLoader(false);
                            toast.error(response.message);
                            CommonLogout(accessToken, Dispatch);
                            localStorage?.removeItem("access_token");
                        } else {
                            setMainLoader(false);
                            toast.error(response.msg);
                        };
                    };
                } else {
                    // let param = { 
                    //     "transmit": "broadcast", 
                    //     "url": "st_approval",
                    //     "request" : { 
                    //         "order_id" : roomId?.id,
                    //         "items": EmptyAccessories,
                    //         "parcels": partialArrayset
                    //     },
                    //     "DeviceId" : device_id
                    // };
                    // wsSend_request(websocket, param);
                    const payload = JSON.stringify({
                        request : {
                            order_id : roomId?.id,
                            items: EmptyAccessories,
                            parcels: partialArrayset
                        }
                    });
                    const response = await OrderStApproval(payload,accessToken);
                    if(response?.status === 200){
                        setMainLoader(false);
                        toast.info(response.msg);
                        Navigate(FRANCHISE_DISPATCH_URL);
                        // if (response?.data?.order_type === "AO") {
                        //     GetWhatsappLmeAPICall(accessToken, response?.data?.id);
                        // } else {
                        //     setMainLoader(false);
                        //     toast.info(response.msg);
                        //     Navigate(FRANCHISE_DISPATCH_URL);
                        // };
                        if (response?.data?.order_type === "AO") {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, false);
                            } else {
                                GetWhatsappLmeAPICall(accessToken, response?.data?.id, true);
                            };
                        } else {
                            if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                                OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                            } else {
                                // setMainLoader(false);
                                // toast.info(response.msg);
                                // Navigate(FRANCHISE_DISPATCH_URL);
                            }
                        };
                    } else {
                        if(response?.message === "User is not authorized." && response?.status === 400) {
                            setMainLoader(false);
                            toast.error(response.message);
                            CommonLogout(accessToken, Dispatch);
                            localStorage?.removeItem("access_token");
                        } else {
                            setMainLoader(false);
                            toast.error(response.msg);
                        };
                    };
                };
            } else {
                // let param = { 
                //     "transmit": "broadcast", 
                //     "url": "st_approval",
                //     "request" : { 
                //         "order_id" : roomId?.id,
                //         "items": items
                //     },
                //     "DeviceId" : device_id
                // };
                // wsSend_request(websocket, param);
                const payload = JSON.stringify({
                    request : {
                        order_id : roomId?.id,
                        items: items
                    }
                });
                const response = await OrderStApproval(payload,accessToken);
                if(response?.status === 200){
                    setMainLoader(false);
                    toast.info(response.msg);
                    Navigate(FRANCHISE_DISPATCH_URL);
                    // if (response?.data?.order_type === "AO") {
                    //     GetWhatsappLmeAPICall(accessToken, response?.data?.id);
                    // } else {
                    //     setMainLoader(false);
                    //     toast.info(response.msg);
                    //     Navigate(FRANCHISE_DISPATCH_URL);
                    // };
                    if (response?.data?.order_type === "AO") {
                        if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                            OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                            GetWhatsappLmeAPICall(accessToken, response?.data?.id, false);
                        } else {
                            GetWhatsappLmeAPICall(accessToken, response?.data?.id, true);
                        };
                    } else {
                        if(response?.data?.erp_delivery_code !== null && response?.data?.erp_delivery_code !== "") {
                            OrderErpInvoiceAPICall(accessToken, response?.data?.id);
                        } else {
                            // setMainLoader(false);
                            // toast.info(response.msg);
                            // Navigate(FRANCHISE_DISPATCH_URL);
                        }
                    };
                } else {
                    if(response?.message === "User is not authorized." && response?.status === 400) {
                        setMainLoader(false);
                        toast.error(response.message);
                        CommonLogout(accessToken, Dispatch);
                        localStorage?.removeItem("access_token");
                    } else {
                        setMainLoader(false);
                        toast.error(response.msg);
                    };
                };
            }
        // }
    };

    // whatsapp lime api calling
    const GetWhatsappLmeAPICall = async (Token, id, toaster_show) => {
        const response = await GetWhatsappLmeAPI(Token, id);
        if(response?.status === 200) {
            if(toaster_show) {
                // setMainLoader(false);
                // toast.info("Order approved successfully.");
                // Navigate(FRANCHISE_DISPATCH_URL);
            };
        } else {
            if(toaster_show) {
                // Navigate(FRANCHISE_DISPATCH_URL);
            };
        };
    };

    // order erp invoice api calling
    const OrderErpInvoiceAPICall = async (Token, id) => {
        const response = await OrderErpInvoice(Token, id, "false");
        if(response?.status === 200) {
            // setMainLoader(false);
            // toast.info("Order approved successfully.");
            // Navigate(FRANCHISE_DISPATCH_URL);
        } else {
            // toast.info("Order approved successfully.");
            // Navigate(FRANCHISE_DISPATCH_URL);
        };
    };

    useEffect(() => {
        if(GetOnlyAccessoriesProduct !== undefined) {
            SetOptionsProduct(GetOnlyAccessoriesProduct?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name })));
        };
    }, [ OrderReportDetails ]);

    const [ ItemAddState, setItemAddState ] = useState({
        product_id: "",
        Quantity: "",
    });
    
    const totalpartialArrayQuantity = calculateTotalQuantityMain(partialArray);

    // Function to calculate total quantity
    function calculateTotalQuantity(items) {
        if(items !== undefined) {
            let totalQuantity = 0;
            for (let i = 0; i < items.length; i++) {
                totalQuantity += items[i].qty;
            }
            return totalQuantity;
        }
    };

    // Function to calculate total quantity
    function calculateTotalQuantityMain(arrayOfObjects) {
        let totalQuantity = 0;

        // Iterate over the outer array
        arrayOfObjects.forEach(obj => {
            // Iterate over the inner array of each object
            obj.items.forEach(item => {
                // Add quantity of each item to totalQuantity
                totalQuantity += parseInt(item.qty);
            });
        });

        return totalQuantity;
    };

    const BoxValueSelect = (value) => {
        if(value !== null) {
            setSelectBox(value);
        } else {
            setSelectBox("");
        }
    };

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    const partialtempArray = [...partialArray];
    const AddBoxFunction = () => {
        const obj = {
            parcel_id: generateRandomId(),
            box_id: selectBox,
            box_weight: "",
            items: []
        };
        partialtempArray?.push(obj);
        setpartialArray(partialtempArray);
        setSelectBox("");
    };

    // product selection
    const ProductSelection = (value) => {
        if(value !== null) {
            setItemAddState({...ItemAddState, product_id: value});
        } else {
            setItemAddState({...ItemAddState, product_id: ""});
        };
    };

    let updatedData = [...partialArray];
    // Function to add a new subItem to an item by ID
    const addSubItemById = (id, newSubItem) => {
        const itemIndex = partialArray.findIndex(item => item.parcel_id === id);
        if (itemIndex !== -1) {
            updatedData[itemIndex].items.push(newSubItem);
            let mergedArray = mergeAndUpdate(updatedData, id);
            const updatedDataArray = updatedData?.map((elm) => {
                if(elm?.parcel_id === id) {
                    return{
                        ...elm,
                        items: mergedArray,
                    }
                } else {
                    return elm;
                };
            });
            
            setpartialArray(updatedDataArray);
        } else {
            console.log('Item not found with id:', id);
        }
    };

    // merge array and add quantity
    function mergeAndUpdate(arr, id) {
        let updatedItems = {};
        arr.forEach(obj => {
            if(obj?.parcel_id === id) {
                obj.items.forEach(item => {
                    const productId = item.order_item_id;
                    if (!updatedItems[productId]) {
                        updatedItems[productId] = { ...item };
                    } else {
                        updatedItems[productId].qty += parseInt(item.qty);
                    }
                });
            };
        });
       
        let updatedData = Object.keys(updatedItems).map(productId => {
            return {
                id: updatedItems[productId].id,
                order_item_id: productId,
                qty: updatedItems[productId].qty,
                product_id: updatedItems[productId].product_id
            };
        });
        
        return updatedData;
    };
   
    // create new box modal
    const AddNewBoxModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Create New Box",
            subtitle: "Create New Box",
            modalType: "form",
            button: "Create"
        })
    };

    const PartialArrayTotalQuantity = calculateProductTotalQuantity(partialArray);

    const AddProductItemFunction = (data) => {
        if(ItemAddState?.Quantity !== "" && ItemAddState?.product_id !== "") {
            const Checkqnty = optionsProduct?.filter((elm) => {
                if(ItemAddState?.product_id?.id === elm?.id) {
                    if(PartialArrayTotalQuantity[elm?.id] === undefined) {
                        return parseInt(ItemAddState?.Quantity) <= elm?.qty;    
                    } else {
                        return (parseInt(ItemAddState?.Quantity) + parseInt(PartialArrayTotalQuantity[elm?.id])) <= elm?.qty;
                    }
                }
            })

            if(Checkqnty?.length > 0) {
                const Objd = {
                    id: generateRandomId(),
                    order_item_id:  ItemAddState?.product_id?.id,
                    product_id: ItemAddState?.product_id,
                    qty: parseInt(ItemAddState?.Quantity),
                };
                addSubItemById(data?.parcel_id, Objd);
    
                setItemAddState({
                    ...ItemAddState,
                    product_id: "",
                    Quantity: "",
                });
            } else {
                return toast.error("The product quantity should not be greater than the actual product quantity.");
            }
        };
    };

    const PartialArrayUpdate = [... partialArray];
    // update box weight total
    const UpdateBoxTotalWeight = (value, data_) => {
        const PartialArrayUpdateMain = PartialArrayUpdate?.map((elm) => {
            if(elm?.parcel_id === data_?.parcel_id) {
                return{
                    ...elm,
                    box_weight: value,
                }
            } else 
            return elm;
        });
        
        setpartialArray(PartialArrayUpdateMain);
    };

    // delete item
    const deleteItem = (elm, elm_item) => {
        const partialArraysss = partialArray.map((obj) => {
            if(elm?.parcel_id === elm?.parcel_id) {
                return({
                    ...obj,
                    items: obj.items.filter(item => item.id !== elm_item?.id)
                })
            };
        });
        setpartialArray(partialArraysss);
    };

    
    const [ optionsProductNew, setoptionsProductNew ] = useState([]);

    useEffect(() => {
        setoptionsProductNew(() => optionsProduct?.filter((elm) => {
            if(PartialArrayTotalQuantity[elm?.id] !== parseInt(elm?.qty)) {
                return elm;
            };
        }))
    }, [ optionsProduct, partialArray ]);

    // Function to calculate total quantity
    function calculateProductTotalQuantity(arrayOfObjects) {
        let productQuantities = {};
        
        arrayOfObjects.forEach(obj => {
            // Iterate through the products array in each object
            obj.items.forEach(product => {
                const { order_item_id, qty } = product;
                // Check if the product ID already exists in the productQuantities object
                if (productQuantities[order_item_id]) {
                    // If it exists, add the quantity to the existing quantity
                    productQuantities[order_item_id] += qty;
                } else {
                    // If it doesn't exist, create a new entry with the quantity
                    productQuantities[order_item_id] = qty;
                }
            });
        });

        return productQuantities;
    };

    
    const CheckTotalWieght = partialArray?.filter((elm) => elm?.box_weight === "")?.length;
    const CheckTotalProductArray = partialArray?.filter((elm) => elm?.items?.length === 0)?.length;

    const DisableBtn = (url) => {
        let status = true;
        const entyEqual = totalpartialArrayQuantity === totalQuantity;
        if(url === "accesoririeswithBike") {
            if(entyEqual) {
                if(disable || CheckTotalWieght > 0) {
                    status = true;
                }else {
                    status = false;
                };
            } 
            // else {
            //     if(totalQuantity === 1){
            //         status = false
            //     }
            // };
            return status;
        } else {
            // if(totalQuantity === 1){
            //     status =false
            // }
            // else 
            if(entyEqual && CheckTotalWieght === 0 ) {
                status = false;
            } else {
                status = true;
            };
            return status;
        };
    };

    // Function to calculate total quantity
    function calculateTotalProductBox(productBoxArray) {
        let totalboxweight = 0;
        let totalQuantity = 0;
       
        // Iterate over the outer array
        productBoxArray.forEach(obj => {
           totalboxweight += parseFloat(obj.product_id.product.box_weight);
           totalQuantity += parseInt(obj?.qty);
        });

        return totalboxweight * totalQuantity;
    };
    
    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // common function
    const CommonFunction = async (id) => {
        // const printContents = document.getElementById(id).innerHTML;
        // const originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
        const printContent = document.getElementById(id);
        const newWindow = window.open('', '_blank', "width=1200,height=600");
        newWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Window</title>
            <!-- Add Bootstrap CSS link -->
            <style>
                table.printTable {
                    border-collapse: collapse;
                }
                .printTable th, .printTable td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                }
                .printTable th {
                    background-color: #f2f2f2;
                }
                .sotklevelbtn {
                    display: none;
                }
            </style>
        </head>
        <body>
            ${printContent.innerHTML}
        </body>
        </html>`);
        newWindow.document.close();
        newWindow.print();

        // Listen for the print dialog close event
        const printDialogClosed = () => {
            newWindow.close();
            window.removeEventListener('focus', printDialogClosed);
        };
    
        // Event listener to handle print dialog close
        window.addEventListener('focus', printDialogClosed);
    };

    return(
        <React.Fragment>
            <section className="Main_section warehouse_Approval_details">
                <div className="MainHeader">
                    <button  className='btn btn-icon p-1' id="print_btn_function" onClick={() => CommonFunction("contentToPrint")}>
                        <i className="bi bi-printer"></i>
                    </button>
                    <Link className="btn btn-link backbtn" to={FRANCHISE_DISPATCH_URL} onClick={() => Dispatch(GetOrderReportDetails({}))}><i className="bi bi-arrow-left-short"></i> Back</Link>
                </div>
                    <CommonScrollBar
                        height={{ height: "calc(100vh - 135px)"}}                      
                        className={"ScrollbarsSidebar ScrollbarsSidebarHorizontal"} 
                    >
                        <div className="OrderDetailsModal" id="OrderDetailsModalNew">
                            <div >
                                <h5>{OrderViewerModal?.Title}</h5>
                            </div>
                            {/* print content */}
                            <div className="orderDetailsViewr" id="contentToPrint" style={{ display: "none" }}>
                                {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                    <PurchaseOrderReport  OrderReportDetails={OrderReportDetails}/>
                                </div>) : (<>
                                    Please Wait...
                                </>)}
                            </div>

                            <div className="orderDetailsViewr">
                                {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                    <PurchaseOrderReport OrderReportDetails={OrderReportDetails}/>
                                    {AccessoriesList?.length >0 ? (<div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                        <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Accessories Items:
                                        </h6>
                                        <div className="tableView">
                                            <Table className="printTable mt" >
                                                <thead>
                                                    <tr>
                                                        <th >Sl. No.</th>
                                                        <th >Product Code</th>
                                                        <th >Product Name</th>
                                                        <th >Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {AccessoriesList?.map((elm, index) => {
                                                        let indexNumber = index+1;
                                                        return(
                                                            <React.Fragment key={index}>
                                                                    <FrameSelectionItem warehouse_id={OrderReportDetails?.order?.warehouse_id} indexNumber={indexNumber} callBack={callBack} item={elm} index={index} />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>):<React.Fragment/>}
                                    {frameList?.length >0 ? (<div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                        <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Bike Items:
                                        </h6>
                                        <div className="tableView">
                                            <Table className="printTable mt" >
                                                <thead>
                                                    <tr>
                                                        {/* <th >Sl. No.</th> */}
                                                        <th >Product Code</th>
                                                        <th >Product Name</th>
                                                        <th >Frame Number</th>
                                                        <th >Battery Number</th>
                                                        <th >Motor Number</th>
                                                        <th>Charger Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="frame_selection_table">
                                                    {frameList?.map((elm, index) => {
                                                        let indexNumber = index+1;
                                                        return(
                                                            <React.Fragment key={index}>
                                                                <FrameSelectionItem 
                                                                    warehouse_id={OrderReportDetails?.order?.warehouse_id} 
                                                                    indexNumber={indexNumber} 
                                                                    callBack={callBack} 
                                                                    frameState 
                                                                    item={elm} 
                                                                    index={index} 
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>):<React.Fragment/>}

                                {/* box add */}
                                {(GetOnlyAccessoriesProduct?.length > 0) && (<div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                        <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Box Items:
                                        </h6>
                                    <div className="FranchiseDispatchBox">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label style={{ width: "100%" }}>Select Box
                                                    <span className="selectBoxAnchor" onClick={() => AddNewBoxModal()}>Add New Box</span>
                                                </Form.Label>
                                                <div className="selectMultiselect" id="selectMultiselectBrand">
                                                    <Select
                                                        value={selectBox}
                                                        onChange={(e) => BoxValueSelect(e)} 
                                                        // defaultValue={defaultCategoryOption} 
                                                        isClearable 
                                                        options={optionsBox}
                                                        className="godown-select-container" 
                                                        classNamePrefix="godown-select"
                                                        styles={customStyles}
                                                        placeholder="Select Box"
                                                    />
                                                    {/* {errorRegionValue !== "" && <span className="error_medotory">{errorRegionValue}</span>} */}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label style={{ width: "100%"}}>&nbsp;</Form.Label>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <button className="btn addBoxbtns" onClick={() => AddBoxFunction()} disabled={(selectBox === "" || totalQuantity === totalpartialArrayQuantity) || CheckTotalProductArray > 0} >
                                                        Add
                                                    </button>
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    {partialArray && partialArray?.map((elm, index) => {
                                        return(<div className="formloginset" key={index}>
                                            <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                                                <h6 className="subtitleForm PurchaseOrderCheckbox">
                                                    Box Name: {elm?.box_id?.box_name}
                                                </h6>
                                                {totalQuantity !== totalpartialArrayQuantity && (<div className="row">
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3 " >
                                                            <Form.Label>Product</Form.Label>
                                                            <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                                                <Select 
                                                                    onChange={(e) => ProductSelection(e)} 
                                                                    value={ItemAddState?.product_id}
                                                                    isClearable
                                                                    options={optionsProductNew}
                                                                    className="godown-select-container" 
                                                                    classNamePrefix="godown-select"
                                                                    placeholder="Select Product"
                                                                    styles={customStyles}
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3 " >
                                                            <Form.Label>Quantity</Form.Label>
                                                            <Form.Control 
                                                                type="number" 
                                                                className="inputBoxs"
                                                                value={ItemAddState?.Quantity} 
                                                                onChange={(e) => {
                                                                    setItemAddState({...ItemAddState, Quantity: e.target.value.replace(/^0/, "")})
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    EmptySpaceFieldValid(e)
                                                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                                }}
                                                                placeholder="Enter Quantity" 
                                                                maxLength={3}
                                                                min="1" max="10"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label style={{ width: "100%"}}>&nbsp;</Form.Label>
                                                            <button className="btn addBoxbtns" disabled={ItemAddState?.Quantity === "" || ItemAddState?.product_id === ""} id="addBoxbtns"  onClick={() => AddProductItemFunction(elm)} >
                                                                Add
                                                            </button>
                                                        </Form.Group>
                                                    </div>
                                                </div>)}
                                                {elm?.items?.length > 0 && (<div className="tableView">
                                                    <Table className="printTable t-body-nowrap table-common mt" >
                                                        <thead>
                                                            <tr>
                                                                <th>SNO.</th>
                                                                <th>Product Name</th>
                                                                <th>Box Weight</th>
                                                                <th>Quantity</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {elm?.items?.map((elm_, index) => {
                                                                return(<tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{elm_?.product_id?.product_name}</td>
                                                                    <td>{elm_?.product_id?.product?.box_weight}</td>
                                                                    <td>{elm_?.qty}</td>
                                                                    <td>
                                                                        <img src={DeleteIcon} className="deleteItemBtn" onClick={() => deleteItem(elm, elm_)} />
                                                                    </td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>)}
                                                <div className="tableforms">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <Form.Group className="mb-3 " >
                                                                <Form.Label>Total Weight</Form.Label>
                                                                <Form.Control 
                                                                    type="number" 
                                                                    className="inputBoxs"
                                                                    value={elm?.box_weight} 
                                                                    onChange={(e) => UpdateBoxTotalWeight(e?.target?.value.replace(/^0/, ""), elm)}
                                                                    onKeyDown={(e) => {
                                                                        EmptySpaceFieldValid(e)
                                                                        exceptThisCostSymbolsNew.includes(e.key) && e.preventDefault()
                                                                    }}
                                                                    placeholder="Enter Total" 
                                                                    maxLength={3}
                                                                    min="1" max="10"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Form.Group className="mb-3 " >
                                                                <Form.Label>Estimate Total (kg)</Form.Label>
                                                                <Form.Control 
                                                                    type="number" 
                                                                    className="inputBoxs"
                                                                    value={calculateTotalProductBox(elm?.items)} 
                                                                    // onChange={(e) => UpdateBoxTotalWeight(e?.target?.value, elm)}
                                                                    // onKeyDown={(e) => {
                                                                    //     EmptySpaceFieldValid(e)
                                                                    //     exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                                    // }}
                                                                    placeholder="Estimate Total" 
                                                                    maxLength={2}
                                                                    max={2}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    })}
                                </div></div>)}
                                <div className="text-center">
                                    {(AccessoriesList?.length > 0 && frameList?.length === 0) ? (<Button className="formbtn me-2" id="commonbtnstyle" disabled={DisableBtn("accesoriries")} variant={"exportbtn"} onClick={callSubmitForm}>Save</Button>) : 
                                    (<Button className="formbtn me-2" id="commonbtnstyle" disabled={DisableBtn("accesoririeswithBike")} variant={"exportbtn"} onClick={callSubmitForm}>Save</Button>)}
                                    <CancelOrder className={"cancel-reset-Btn cancel-reset-Btn-New"} text={"Cancel Order"} order_status={"CANCELLED"} data={OrderReportDetails} />
                                </div>
                                </div>) : (<>
                                    Please Wait...
                                </>)}
                            </div>
                        </div>
                    </ CommonScrollBar>
            </section>
            {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}

            {(MainLoader || ((commonMainLoader?.url === "order_cancelled" || commonMainLoader?.url === "order_detail") && commonMainLoader?.loader)) && (<CommonLoader />)}
        </React.Fragment>
    )
}

export default OrderDetailsViewer;