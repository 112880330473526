/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Table, Form, Button } from 'react-bootstrap';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link, useParams, useNavigate  } from "react-router-dom";
import { PO_APPROVALS_URL } from "../../Shared/constant";
import { GetOrderReportDetails, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import SelectReact from "../../Common/SelectReact";
import CancelOrder from "../../Common/CancelOrder";
import CommonewModel from "../../Common/CommonewModel";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import CommonLoader from "../../Common/CommonLoader";
import CommonScrollBar from "../../Common/CommonScrollBar";

const WarehouseApprovalOrders = (props) => {
    const { selfInfo, commonMainLoader } = useSelector((state) => state.adminReducers);
    const { RouteName } = props;
    const { roomId } = useParams();
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ClearFormSet, OrderReportDetails, wareHouselistall, AllDispacchList, accessToken } = useSelector((state) => state.adminReducers);
    const getDispatchOption = () =>{
        return AllDispacchList?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({
            ...item,
            key:item.id, value:item?.id , label: item?.dispatch_name
        }))
    };
    const getWareHouselOption = () =>{
        return wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({
            key:item.id, value:item?.id , label:`${item?.warehouse_name} (${item?.warehouse_code}) - (${item?.display_name})`
        }));
    };
    const optionsWarehouse =  useMemo(getWareHouselOption, [wareHouselistall]);
    const optionsDispatch = useMemo(getDispatchOption, [AllDispacchList]);
    const [ selectWarehouse, setselectWarehouse ] = useState("");
    const [ selectDispatch, setselectDispatch ] = useState("");
    const [ OrderPriority, setOrderPriority ] = useState("");

    const [ Address, setAddress ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // error state
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorSelectWarehouse, setErrorSelectWarehouse ] = useState("");
    const [ errorSelectDispatch, setErrorSelectDispatch ] = useState("");
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    const callDispatchList = (warehouse_id) =>{
        if(websocket){
            let param = { 
                "transmit": "broadcast", 
                "url": "dispatch_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            if(warehouse_id){
                param.request.warehouse_id = warehouse_id;
            }
            wsSend_request(websocket, param);
        }
    };

    // get all warehouse list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[3]?.Edit || PermissionsAccess?.OrderManagement[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "warehouse_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [websocket]);

    // get order_list all
    useEffect(() => {
        if(roomId) {
            Dispatch(StartMainCommonLoader({
                url: "order_detail",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request" : { 
                    "order_id" : roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId, websocket ]);

    useEffect(() => {
        setOrderPriority((OrderReportDetails?.order?.order_priority === null || OrderReportDetails?.order?.order_priority === "") ? "" : OrderReportDetails?.order?.order_priority);
    }, [ OrderReportDetails ]);

    // wareehouse select
    const WareHouseSelect = (e) => {
        const value = e.target.value
        if(value !== null) {
            callDispatchList(value)
            setTimeout(()=>{
                setselectWarehouse(value);
                setselectDispatch("");
                CheckValid(value, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
            }, 400)
        } else {
            setselectWarehouse("");
            setselectDispatch("");
            CheckValid(value === null ? "" : value, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
        }
    };

    // dispatch select
    const DispatchSelect = (e) => {
        const value = e.target.value
        if(value !== null) {
            setselectDispatch(value);
            CheckValid(value, {type: 'Select Dispatch', error: setErrorSelectDispatch});
        } else {
            setselectDispatch("");
            CheckValid(value === null ? "" : value, {type: 'Select Dispatch', error: setErrorSelectDispatch});
        }
    };
    
    const confirmOrderApproval = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Order Warehouse Approve",
            description: `Do you want to order approve ?</h5>`,
            modalType: "not form",
            button: "Yes"
        });
    }

    const [ stateFormData, setStateFormData ] = useState([]);

    let fileUplaoadArrya = [...stateFormData];
    // upload attachments
    const UploadImage = async (e) => {
        const files = e.target.files[0];
        if(files){
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if(fileresponse?.status === 200) {
                const FileObj = {
                    attachment_id: fileresponse?.id,
                    attachment_name: fileresponse?.name,
                }
                fileUplaoadArrya?.push(FileObj);
                setStateFormData(fileUplaoadArrya);
                document.getElementById('fileInput').value = null;
            } else {
                // setUploadFileLoader(false);
            };
        }else{
            // setFormData((data)=>({
            //     ...data,
            //     [e.target.name]:"",
            //     fileName: "",
            // }))
        }
    };

    // delet file
    const deleteFile = (id) => {
        const UpdatefileUplaoadArrya = [];
        fileUplaoadArrya?.map((elm) => {
            if(elm?.attachment_id !== id) {
                UpdatefileUplaoadArrya?.push(elm);
            }
        });
        setStateFormData(UpdatefileUplaoadArrya);
    };

    const AdminOrderApproval = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Are you sure?",
            subtitle: "Order Admin Approve",
            description: `Do you want to order approve ?</h5>`,
            modalType: "not form",
            button: "Yes",
            Data:{order_id:roomId,warehouse_id:selectWarehouse,warehouse_remark:Address,dispatch_id:selectDispatch,attachments:stateFormData,order_priority: OrderPriority}
        });
    }

    // warehouse order approval
    const OrderApproval = () => {
        if(selectWarehouse !== "" && Address.trim() !== "" && selectDispatch !== "") {
            let param = { 
                "transmit": "broadcast", 
                "url": "wh_approval",
                "request" : { 
                    "order_id" : roomId,
                    "warehouse_id" : selectWarehouse,
                    "warehouse_remark" : Address,
                    "dispatch_id" : selectDispatch,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            CheckValid(Address.trim(), {type: 'Remarks', error: seterrorAddress});
            CheckValid(selectWarehouse === null ? "" : selectWarehouse, {type: 'Select Warehouse', error: setErrorSelectWarehouse});
            CheckValid(selectDispatch === null ? "" : selectDispatch, {type: 'Select Dispatch', error: setErrorSelectDispatch});
        }
    };
    
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(PO_APPROVALS_URL);
        };
    }, [ ClearFormSet ]);

    

    const AdminSubmitVisible = (OrderReportDetails?.order?.order_type === "AO" && (OrderReportDetails?.order?.order_source === "AMAZON_IN_API" || OrderReportDetails?.order?.order_source === "AMAZON")) ? (!Address || !selectWarehouse || !selectDispatch || !OrderPriority) : (!Address || !selectWarehouse || !selectDispatch);

    return(<section className="Main_section warehouse_Approval_details">
        <div className="MainHeader">
            <Link to={PO_APPROVALS_URL} onClick={() => Dispatch(GetOrderReportDetails({}))} className="btn btn-link backbtn">
                <i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<CommonScrollBar
                height={{ height: "calc(100vh - 135px)"}} 
                className={"ScrollbarsSidebar ScrollbarsSidebarHorizontal"} 
            >
                <div className="filtr_Form"  style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
                    <div className="formloginset formloginsetStaticData">
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Order No.</Form.Label>
                                    <div className="defaultvalueField">
                                        {OrderReportDetails?.order?.order_code}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Order Date</Form.Label>
                                    <div className="defaultvalueField">
                                        {CommonDateTime(OrderReportDetails?.order?.created_at, "LL", "show")}
                                    </div>
                                </Form.Group>
                            </div>
                            {/* In AO show customer_name and order id */}
                            {/* In CO show customer_name only */}
                            {(OrderReportDetails?.order?.order_type === "CO" || OrderReportDetails?.order?.order_type === "AO") && (<div className="col-md-3" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Customer Name</Form.Label>
                                    <div className="defaultvalueField">
                                    {OrderReportDetails?.order?.billing?.customer_name}
                                    </div>
                                </Form.Group>
                            </div>)}
                            {OrderReportDetails?.order?.order_type === "AO" && (<div className="col-md-3" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Order ID</Form.Label>
                                    <div className="defaultvalueField">
                                    {OrderReportDetails?.order?.billing?.ecom_order_id}
                                    </div>
                                </Form.Group>
                            </div>)}
                            {OrderReportDetails?.order?.order_type !== "CO" && (<div className="col-md-3" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Franchise Name</Form.Label>
                                    <div className="defaultvalueField">
                                    {OrderReportDetails?.order?.billing?.customer_name}
                                    </div>
                                </Form.Group>
                            </div>)}
                            <div className="col-md-3" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Total Qty</Form.Label>
                                    <div className="defaultvalueField">
                                    {OrderReportDetails?.order?.qty}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>Bill To</Form.Label>
                                    <div className="defaultvalueFieldaddress">
                                        {OrderReportDetails?.order?.order_type === "CO" ? (<ul>
                                            <li>Name: {OrderReportDetails?.order?.billing?.customer_name}</li>
                                            <li>Address: {OrderReportDetails?.order?.billing?.address} </li>
                                            <li>Mobile: {OrderReportDetails?.order?.billing?.mobile} </li>
                                            <li>City: {OrderReportDetails?.order?.billing_pin_data?.district?.district_name} </li>
                                            <li>PIN Code: {OrderReportDetails?.order?.billing_pin_data?.pin_code?.pin_code}</li>
                                            <li>GST: {OrderReportDetails?.order?.billing?.gst_no === null ? "-" : OrderReportDetails?.order?.billing?.gst_no}</li>
                                        </ul>) : (<ul>  
                                            <li>Name: {OrderReportDetails?.order?.billing?.customer_name}</li>
                                            <li>Address: {OrderReportDetails?.order?.billing?.address} </li>
                                            <li>Mobile: {OrderReportDetails?.order?.billing?.mobile} </li>
                                            <li>City: {OrderReportDetails?.order?.billing_pin_data?.district?.district_name} </li>
                                            <li>PIN Code: {OrderReportDetails?.order?.billing_pin_data?.pin_code?.pin_code}</li>
                                            <li>GST: {OrderReportDetails?.order?.billing?.gst_no}</li>
                                        </ul>)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6" >
                                <Form.Group className="mb-3 ">
                                    <Form.Label>SHIP To</Form.Label>
                                    <div className="defaultvalueFieldaddress">
                                        <ul>
                                            <li>Name: {OrderReportDetails?.order?.customer_name}</li>
                                            <li>Address: {OrderReportDetails?.order?.address} </li>
                                            <li>Mobile: {OrderReportDetails?.order?.mobile} </li>
                                            <li>City: {OrderReportDetails?.order?.district?.district_name}</li>
                                            <li>PIN Code: {OrderReportDetails?.order?.pin_code?.pin_code}</li>
                                            <li>GST: {OrderReportDetails?.order?.gstin_no}</li>
                                        </ul>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tableView">
                    <Table>
                        <thead>
                            <tr>
                                <th>Sr. no</th>
                                <th>Product Code</th>
                                <th>Product Name</th>
                                <th>Unit Amount</th>
                                <th>Tax Amount</th>
                                <th>Quantity</th>
                                <th>Total Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {OrderReportDetails?.order_items_list?.map((elm, index) => {
                                return(<tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{elm?.hsn_code?.replace("PC", "")}</td>
                                        <td>{elm?.product_name}</td>
                                        <td>{AmountNumberFormat(elm?.price)}</td>
                                        <td>{elm?.sub_gst}</td>
                                        <td>{elm?.qty}</td>
                                        <td>{AmountNumberFormat(elm?.sub_total)}</td>
                                    </tr>)
                            })}
                        </tbody>
                    </Table>
                    <div className="tabsWrappers" style={{ maxWidth: "50%" }}>
                        <div className="formloginset">
                        {(selfInfo?.user?.user_type === 'super_admin' || (selfInfo?.user?.user_type !== "warehouse_manager" && PermissionsAccess?.OrderManagement[3]?.Edit)) &&
                            (<div className="row">
                                <div className="col-md-12">
                                    <SelectReact
                                        onChange={WareHouseSelect}
                                        value={selectWarehouse}
                                        options={optionsWarehouse}
                                        isClearable
                                        label={"Select Warehouse"}
                                        className="godown-select-container"
                                        error={errorSelectWarehouse}
                                        required
                                        classNameForm={"mb-3"}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <SelectReact
                                        onChange={DispatchSelect}
                                        value={selectDispatch}
                                        options={optionsDispatch}
                                        isClearable
                                        label={"Select Dispatch"}
                                        className="godown-select-container"
                                        error={errorSelectDispatch}
                                        required
                                        classNameForm={"mb-3"}
                                        disabled={selectWarehouse?false:true}
                                    />
                                </div>
                                {(OrderReportDetails?.order?.order_type === "AO" && (OrderReportDetails?.order?.order_source === "AMAZON_IN_API" || OrderReportDetails?.order?.order_source === "AMAZON")) && (<div className="col-md-12">
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Select Order Priority <span className="mendatory_star">*</span></Form.Label>
                                        <select 
                                            className="form-control" 
                                            value={OrderPriority} 
                                            onChange={(e) => setOrderPriority(e?.target?.value)}
                                        >
                                            <option value="">Select Order Priority</option>
                                            <option value="Amazon Prime">Prime </option>
                                            <option value="Amazon">Non Prime</option>
                                        </select>
                                    </Form.Group>
                                </div>)}
                                <div className="col-md-12">
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Remarks <span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            as="textarea" rows={3}
                                            value={Address} 
                                            onChange={(e) => setAddress(e.target.value)}
                                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'Remarks', error: seterrorAddress})}
                                            onKeyDown={EmptySpaceFieldValid}
                                            placeholder="Enter Remarks(Max length 200 characters)" 
                                            maxLength={200}
                                        />
                                        {errorAddress !== "" &&<span className="error_medotory">{errorAddress}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-12">
                                    <Form.Group className="mb-3 ">
                                        <Form.Label>Attachments</Form.Label>
                                        <label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                            <i className="bi bi-upload"></i>
                                            <input type="file" name={"Attachments"} id="fileInput" onChange={(e) => UploadImage(e)}  accept=".pdf" />
                                            Upload Attachments
                                        </label>
                                        {stateFormData?.length > 0 && (<React.Fragment>
                                            <ul className="fileuploadListy">
                                            {stateFormData?.map((elm, index) => {
                                                return(<li className="uplaodedFilename w-100" key={index}>
                                                    <i className="bi bi-file-earmark-text"></i>
                                                    {elm?.attachment_name}
                                                    <i className="bi bi-x-circle-fill" onClick={() => deleteFile(elm?.attachment_id)}></i>
                                                </li>)
                                            })}
                                            </ul>
                                        </React.Fragment>)}
                                    </Form.Group>
                                </div>
                                <div className="col-md-12 mb-3" style={{ textAlign: "right" }}>
                                    <Button className="formbtn me-2" onClick={() => AdminOrderApproval()} id="approvlaMargin" style={{ width: "auto" }} variant="primary" disabled={AdminSubmitVisible} >
                                        Submit
                                    </Button>
                                    <CancelOrder className={"cancel-reset-Btn cancel-reset-Btn-New"} data={OrderReportDetails}/>
                                </div>
                                <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
                            </div>)}
                            {(selfInfo?.user?.user_type === "warehouse_manager" &&  <div className="row">
                                <div className="col-md-12 mb-3" style={{ textAlign: "right" }}>
                                    <Button className="formbtn me-2" id="margintopRemove" onClick={() => confirmOrderApproval()} style={{ width: "auto" }} variant="primary">
                                        Submit
                                    </Button>
                                    <CancelOrder className={"cancel-reset-Btn cancel-reset-Btn-New"} data={OrderReportDetails}/>
                                </div>
                                <CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />
                            </div>)}
                        </div>
                    </div>
                </div>
        </CommonScrollBar>) : (<>
            Please Wait...
        </>)}

        {((commonMainLoader?.url === "wh_approval" || commonMainLoader?.url === "order_detail") && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default WarehouseApprovalOrders;