/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { CATEGORY_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";

const CreateCategory = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ CategoryState, setCategoryState ] = useState({
        CategoryName: "",
        Status: true
    });
    const [ BrandName, setBrandName ] = useState("");
    const { brandlistall, ClearFormSet, categoryList, device_id } = useSelector((state) => state.adminReducers);
    const optionsBrand = brandlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.brand_name }));

    // error state
    const [ errorCategoryName, seterrorCategoryName ] = useState("");
    const [ errorSelectBrandName, seterrorSelectBrandName ] = useState("");
    const { roomId } = useParams();
    const CurrentData_ = categoryList?.list?.filter((elm) => elm?.id === roomId)[0];

    useEffect(() => {
        if(LocationRoute === "edit-category") {
            const Currentbrand = optionsBrand?.filter((elm) => elm?.key === CurrentData_?.brand_id)[0];
            setCategoryState({
                ...CategoryState,
                CategoryName: CurrentData_?.category_name,
                Status: CurrentData_?.is_active
            });
            setBrandName(Currentbrand);
        };
    }, [ CurrentData_ ]);

    // create category
    const CreateCategory = () => {
        if(BrandName !== "" && CategoryState?.CategoryName.trim() !== "") {
            Dispatch(ClearFormSetFutios({
                url: LocationRoute === "edit-category" ? "category_edit" : "category_add",
                action: true
            }));

            let param = { 
                "transmit": "broadcast", 
                "request" : { 
                    "brand_id" : BrandName?.value,
                    "category_name" : CategoryState?.CategoryName.trim(),
                    "is_active" : CategoryState?.Status
                },
                "DeviceId" : device_id
            };

            if(LocationRoute === "edit-category") {
                param.url = "category_edit";
                param.request.category_id = CurrentData_?.id;
            } else {
                param.url = "category_add";
            };
            
            wsSend_request(websocket, param);
        } else {
            CheckValid(CategoryState?.CategoryName.trim(), {type: 'CategoryName', error: seterrorCategoryName});
            CheckValid(BrandName, {type: 'SelectBrand', error: seterrorSelectBrandName});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "category_add" || ClearFormSet?.url === "category_edit")) {
            Navigate(CATEGORY_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={CATEGORY_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3" >
                            <label className="form-label">Brand<span className="mendatory_star">*</span></label>
                            <CommonReactSelectMenu 
                                label={"brand"}
                                options={optionsBrand} 
                                selectValue={BrandName} 
                                setSelectValue={setBrandName} 
                                dependantState={{
                                    allow: false,
                                }} 
                                isMendatory={true}
                                errorState={errorSelectBrandName} 
                                setErrorState={seterrorSelectBrandName}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3" >
                            <label className="form-label">Category Name<span className="mendatory_star">*</span></label>
                            <input 
                                type="text" 
                                className="form-control"
                                value={CategoryState?.CategoryName} 
                                onChange={(e) => setCategoryState({...CategoryState, CategoryName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CategoryName', error: seterrorCategoryName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Category Name"
                            />
                            {errorCategoryName !== "" && (<span className="error_medotory">{errorCategoryName}</span>)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <CommonToggle
                            isBadgeAllow={true}
                            badgeTitle={"active_inactive"}
                            valueToggle={CategoryState?.Status}
                            setValueToggle={() => setCategoryState({...CategoryState, Status: !CategoryState?.Status})}
                            name={"Status"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button 
                            className="btn formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "category_add" || ClearFormSet?.url === "category_edit")}
                            onClick={() => CreateCategory()} 
                            variant="primary" 
                        >
                            {LocationRoute === "edit-category" ? "Update" : "Add"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default CreateCategory;