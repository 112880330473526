/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import GetCurrentTableSRNo from "../GetCurrentTableSRNo";
import { Button, Badge, Image, Collapse, Table, Modal, FormControl } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { AFTER_SALES_ORDERS_URL, FILE_AUTH_URL, MARKET_PLACE_INVOICE_API_URL, GENERATE_INVOICE_API_URL, QUOTATIONS_REPORT_DETAILS_URL, PAYMENT_APPROVAL_QUOTATIONS_REPORT_DETAILS_URL } from "../../Shared/constant";
import CommonDateTime from "../CommonDateTime";
import { TimeSense } from "../TimeSense";
import { toast } from "react-toastify";
import ErpAfsRetryAPI from "../../../Api/ErpAfsRetryAPI";
import ErpRetryAPI from "../../../Api/ErpRetryAPI";
import CommonLogout from "../CommonLogout";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import AvatarSet from "../AvatarSet";
import MoreIcon from '../../../assets/img/more_options.png';
import QuotationsStatus from "../QuotationsStatus";
import FileDownload from "../FileDownload";
import EditIcon from "../../../assets/img/edit_icon.svg";
import DownloadFile from "../../../assets/img/download_icon.svg";
import DeleteIcon from "../../../assets/img/delete_icon.svg";
import DefaultImage from "../../../assets/img/DefaultProduct.jpg";
import AmountNumberFormat from "../AmountNumberFormat";
import moment from "moment";
import BrowserUniqueTabID from "../BrowserUniqueTabID";
import CommonStatus from "../CommonStatus";
import { getColor } from "../TimeSense";
import CancelStageStatus from "../CancelStageStatus";
import GenerateInvoiceAPI from "../../../Api/GenerateInvoiceAPI";
import FBAorderLogRetryAPI from "../../../Api/FBAorderLogRetryAPI";

const TableBody = (props) => {
    const { srNumber, OrderList, RowData, 
        Pagination, EditAccess, OrderNewModel, 
        setOrderNewModel, ActionColumn, setLoader,
        showModalNew, setShowModalNew, APIcalling, isCollapsable,
        ActionColumnRead, isTotalAndGrand, tabName,
        deleteCommonStateModal, setdeleteCommonStateModal, OrderViewerModal, setOrderViewerModal,
        showRemarkAttachment, setShowRemarkAttachment, showModal, setShowModal, errorShowModal, setErrorShowModal, isOdiRowColorsAllow, ActionPreviewState,
        PreviewErrorModal, setPreviewErrorModal
    } = props;
    const { accessToken, device_id, subCategorylistall, categorylistall, citylistall, statelistall, user_id, selfInfo, wareHouselistall, productlistall, ClearFormSet } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const optionWarehouseType = [
        { key:"E-Bikes, Transportation Charges", value:"E-Bikes, Transportation Charges",label:"E-Bikes" },
        { key:"Spares, Service, Transportation Charges", value:"Spares, Service, Transportation Charges",label:"Spares" },
        { key:"Accessories, Transportation Charges", value:"Accessories, Transportation Charges",label:"Accessories" },
        { key:"Marketing, Transportation charges", value:"Marketing, Transportation charges",label:"Marketing" }
    ];
    const [ CopyUrl, setCopyUrl ] = useState({
        id: "",
        label: "Copy Link"
    });
    const Dispatch = useDispatch();
    const Location = useLocation();
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ errorReason, setErrorReason ] = useState("");
    var tabID = BrowserUniqueTabID();

    // VIEW MORE COLLAPOSABLE DETAILS BUTTON
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // VIEW LESS COLLAPOSABLE DETAILS BUTTON
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // ORDER LOGS
    const showOrder = (value, url) => {
        let current_url = url === "AFS_ORDERS" ? "AFS Order Log" : url === "AFS_QUOTATIONS" ? "AFS Quotation Log" : "Order Log";

        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: current_url,
            subtitle: "",
            Data: value
        });
    };

    // OPEN ORDER DETAILS MODAL
    const OrderDetailsModal = (order_code) => {
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": order_code,
            },
            "DeviceId": device_id,
        };
        wsSend_request(websocket, param);
        setOrderViewerModal({ ...OrderViewerModal, open: !OrderViewerModal?.open });
    };

    // INVOICE DETAILS
    const callInvoiceModal = (e, code, url) => {
        e.preventDefault();
        let param = { 
            "transmit": "broadcast", 
            "url": "afs_order_detail",
            "request" : { 
                "order_id" : code,
            },
            "DeviceId" : device_id,
            modal: true
        };
        if(url === "regular_order") {
            param.url = "order_detail";
        } else {
            param.request.is_weight = true;
        };
        wsSend_request(websocket, param);
    };

    // REGULAR INVOICE DETAILS
    const callRegularInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    };

    // GET FRANCHISE NAME
    const GetFranchiseName = (billing_id) => {
        const current = OrderList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name === undefined ? "-" : current?.customer_name
        };
    };

    // GET CATEGORY NAME FROM PRODUCT LIST
    const GetCategoryFrromProductsName = (product_id) => {
        const CurrentProduct = OrderList?.product?.filter((elm) => elm?.id === product_id)[0];
        const CurrentSubCategory = subCategorylistall?.filter((elm) => elm?.id === CurrentProduct?.sub_category_id)[0];
        const CurrentCategory = categorylistall?.filter((elm) => elm?.id === CurrentSubCategory?.category_id)[0]?.category_name;
        return CurrentCategory;
    };

    // RETRY ERP ORDER
    const RtryFunction = async (data, url) => {        
        if(url === "invoice") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Get ERP Invoice",
                subtitle: "Get ERP Invoice",
                modalType: "form",
                button: "Create",
                Data: data,
            });
        } else {
            CommonAPICall(data);
        };
    };

    // SHOW FRAMES DETAILS
    const showTable = (stock_log_id, data_) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Stock Inward Log Details",
            subtitle: "Stock Inward Log Details",
            modalType: "form",
            stock_log_id: stock_log_id,
            Txn_ID: data_?.inward_id === null ? "-": data_?.inward_id,	
            Data: data_,
        });
    };

    // SHOW ANSWER OF FAQ
    const ViewAnswer = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "FAQ Answer",
            modalType: "not form FAQ",
            description: `<div class="faq_wrapper">
                <label>${data?.question}</label>
                <p>${data?.answer}</p>
            </div>`,
            button: "",
        });
    };

    // FRANCHISE LATEST THREE ORDERS
    const FranchiseLastThreeOrders = (data) => {
        setShowModalNew({
            ...showModalNew,
            title: "Franchise Bikes Orders ( Latest 3 )",
            subtitle: "Franchise Orders Tables",
            modalType: "form",
            open: !showModalNew?.open,
            button: "",
            Data: data?.id,
        });
    };

    // EDIT BOX MODAL OPEN
    const EditModal = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Update New Box",
            subtitle: "Update New Box",
            modalType: "form",
            button: "Update",
            Data: data
        })
    };

    // ACCEPT REJECT ORDER MODAL
    const ModalAcceptOpen = (data , url) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: `Order ${url}`,
            Data: data
        })
    };

    // SHOW SUMMARY DETAILS
    const showSummaryDetails = (data, url) => {
        if(url === "list_summary") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Summary Details",
                subtitle: "Table",
                modalType: "form",
                Data: data
            });
        } else if(url === "erp_list_summary") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Summary Details",
                subtitle: "Table-ERP-General",
                modalType: "form",
                last_update_on_date: OrderList?.list[0]?.updated_at,
                Data: data
            });
        } else if(url === "delete_age_value") {
            setdeleteCommonStateModal({
                ...deleteCommonStateModal,
                title: "Delete Point",
                description: `Are you sure want to delete this Age Value ?`,
                open: true,
                delete_id: data?.id,
                delete_url: "Age-Value"
            });
        } else if(url === "delete_point") {
            setdeleteCommonStateModal({
                ...deleteCommonStateModal,
                title: "Delete Point",
                description: `Are you sure want to delete this <b>${data?.start_point}-${data?.end_point}</b> range ?`,
                open: !deleteCommonStateModal?.open,
                delete_id: data?.id,
                delete_url: "Delete-Point"
            });
        } else if(url === "delete_scheme") {
            setdeleteCommonStateModal({
                ...deleteCommonStateModal,
                title: "Delete Scheme",
                description: `Are you sure want to delete this scheme ?`,
                open: true,
                delete_id: data?.id,
                delete_url: "scheme-delete"
            });
        };
    };

    // REMARK AND ATTCHMENT MODAL OPEN FUNCTION
    const OpenRemarkAttchModal = (order) => {
        setShowRemarkAttachment({
            ...showRemarkAttachment,
            open: !showRemarkAttachment?.open,
            title: "Add Remark & Attachemnt",
            Data: order
        });
    };

    // ERROR SHOW MODAL
    const HandleErrorShow = (data) => {
        setErrorShowModal({
            ...errorShowModal,
            open: !errorShowModal?.open,
            title: "Error Details",
            Data: data
        });
    };
    
    // PAYMENT APPROVAL
    const PaymentApproval = (data, url) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: ActionColumn?.label === "quotations_payment" ? "Enter Payment Details" : "Update Payment Details",
            subtitle: ActionColumn?.label === "quotations_payment" ? "Enter Payment Details" : "Update Payment Details",
            modalType: "form",
            button: "OK",
            Data: data,
        });
    };

    // CREATE SALES ORDER FUNCTIONS
    const CreateSalesOrderFunction = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Confirmation",
            subtitle: "SALE-CREATE-ORDER",
            description: "Are you sure to create sale Order?",
            modalType: "not form",
            button: "Yes",
            Data: data,
        });
    };

    // API CALLING FUCNTION
    const CommonAPICall = async (data) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const response = await ErpAfsRetryAPI(formData, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            APIcalling();
            toast.info(response?.message);
        } else {
            setLoader(false);
            APIcalling();
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // Retry ERP order
    const RetryErpApiErrorFunction = async (data) => {
        const GetCurrentERPList = OrderList?.erp_order_log_list?.filter((elm) => elm?.order_id === data?.id)[0];
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const Response = GetCurrentERPList?.is_spares ? await ErpAfsRetryAPI(formData, accessToken) : await ErpRetryAPI(formData, accessToken);
        if(Response?.status === 200) {
            setLoader(false);
            APIcalling();
            toast.info(Response?.message);
        } else {
            setLoader(false);
            APIcalling();
            if(Response?.error) {
                toast.error(Response?.error);
            } else {
                toast.error(Response?.message);
            };
            if(Response?.message === "User is not authorized." && Response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // Retry ERP order
    const RetryFbaApiErrorFunction = async (data) => {
        setLoader(true);
        const formData = new FormData();
        formData.append("order_id", data?.id);
        const response = await FBAorderLogRetryAPI(formData, accessToken);
        if(response?.status === 200) {
            setLoader(false);
            APIcalling();
            toast.info(response?.message);
        } else {
            setLoader(false);
            APIcalling();
            if(response?.error) {
                toast.error(response?.error);
            } else {
                toast.error(response?.message);
            };
            if(response?.message === "User is not authorized." && response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // order success reject flow
    const OrderSuccesReject = (url) => {
        if(showModal?.Reason?.trim() !== "") {
            setErrorReason("");
            const param = {
                "transmit": "broadcast",
                "url": "order_cancelled",
                "request" :{
                    "order_id" : showModal?.Data?.id,
                    "cancel_reason" : showModal?.Reason?.trim(),
                    "order_status" : url === "Order Accept" ? "CANCELLED" : showModal?.Data?.cancel_stage,
                },
                'DeviceId':device_id,
            }
            wsSend_request(websocket, param);
        } else {
            setErrorReason("Please enter reason")
        };
    };

    // Generate Invoice
    const GenerateInvoicFunction = async (elm) => {
        setLoader(true);
        const GenerateInvoiceAPIData_ = await GenerateInvoiceAPI(`${GENERATE_INVOICE_API_URL}/${elm?.invoice_no}`, accessToken);
        if(GenerateInvoiceAPIData_?.status === 200) {
            setLoader(false);
        } else {
            const response = GenerateInvoiceAPIData_?.msg;
            setLoader(false)
            if(response !== "Invoice not found") {
                setErrorShowModal({
                    ...errorShowModal,
                    open: !errorShowModal?.open,
                    title: "Error Details",
                    Data: response
                });
            } else {
                toast.error(response);
            };
        };
    };
    
    // close modal
    const onClose = () => {
        setShowModal({
            ...showModal,
            open: false,
        })
        setErrorReason("");
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            setShowModal({
                ...showModal,
                open: false,
                title: "",
                modalType: "",
                Reason: "",
                Data: {}
            });
        };
    }, [ ClearFormSet ]);

    // COMMON BUTTON FUNCTION
    const CommonButton = ({ data }) => {
        return(<td>
            {ActionColumn?.label === "retry" ? (<Button 
                className="btn edittable edittableretry btn btn-primary" 
                onClick={() => RtryFunction(data, (data?.erp_id === null || data?.erp_id === "") ? "retry" : "invoice")}
            >
                {(data?.erp_id === null || data?.erp_id === "") ? (<>
                    <i className="bi bi-arrow-repeat"></i> Retry
                </>) : (<>
                    <i className="bi bi-receipt"></i> Get Invoice
                </>)}
            </Button>) : ActionColumn?.label === "edit_box_modal" ? (<Button className="btn edittable edittableretry btn btn-primary" onClick={() => EditModal(data, "edit_box")} >
                <Image src={EditIcon} alt="edit" />
            </Button>) : ActionColumn?.label === "edit_file_purchase_download" ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                <Link className="btn edittable" to={`${ActionColumn?.naviagte}${data?.id}`}>
                    <Image src={EditIcon} alt="edit" />
                </Link>
                {data?.view_file_url !== null && (<a className="btn edittable edittableretry btn btn-primary" href={process.env.REACT_APP_BASE_URL + "file/" + data?.view_file_url} target="__blank">
                    <Image src={DownloadFile} alt="edit" />
                </a>)}
            </div>) : (ActionColumn?.label === "edit_delete" || ActionColumn?.label === "edit_delete_point" || ActionColumn?.label === "edit_delete_scheme") ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                <Link className="btn edittable" to={`${ActionColumn?.naviagte}${data?.id}`}>
                    <Image src={EditIcon} alt="edit" />
                </Link>
                <Button 
                    className="btn edittable edittableretry btn btn-primary" 
                    onClick={() => showSummaryDetails(data, ActionColumn?.label === "edit_delete_point" ? "delete_point" : ActionColumn?.label === "edit_delete_scheme" ? "delete_scheme" : "delete_age_value")}
                >
                    <Image src={DeleteIcon} alt="edit" />
                </Button>
            </div>) : ActionColumn?.label === "edit_help_desk_faq" ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                <button 
                    className="btn view_more_btn"
                    onClick={() => ViewAnswer(data)}
                    aria-controls="example-collapse-text"
                >
                    <i className="bi bi bi-eye"></i> Answer
                </button>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[5]?.Edit) && (<Link className="btn edittable" to={`${ActionColumn?.naviagte}${data?.id}`}>
                    <Image src={EditIcon} alt="edit" />
                </Link>)}
            </div>) : ActionColumn?.label === "add_remark_modal" ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                <button className="btn add_remark" onClick={() => OpenRemarkAttchModal(data)}>
                    <i className="bi bi-chat-left-text"></i>
                </button>
            </div>) : ActionColumn?.label === "order_accept_reject" ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                {data?.order_type === "AO" && (data?.order_status === "CANCELLED_REQUEST" || data?.order_status === "RETURN_REQUEST" || data?.order_status === "REPLACE_REQUEST") ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                    <Button variant="primary" size="sm" onClick={() => ModalAcceptOpen(data, "Accept")}>Accept</Button>
                    <Button variant="danger" size="sm"  className="btn" onClick={() => ModalAcceptOpen(data, "Reject")}>Reject</Button>
                </div>) : ("-")}
            </div>) : ActionColumn?.label === "Erp-Api-Failure-Retry" ? (<Button 
                className="btn edittable edittableretry btn btn-primary" 
                onClick={() => RetryErpApiErrorFunction(data)}
            >
                <i className="bi bi-arrow-repeat"></i> Retry
            </Button>) : ActionColumn?.label === "fba-orders-Api-Failure-Retry" ? (<Button 
                className="btn edittable edittableretry btn btn-primary" 
                onClick={() => RetryFbaApiErrorFunction(data)}
            >
                <i className="bi bi-arrow-repeat"></i> Retry
            </Button>) : (ActionColumn?.label === "quotations_payment" || ActionColumn?.label === "quotations_payment_approval") ? (<Button 
                className="btn donwload_btn" 
                onClick={() => PaymentApproval(data, ActionColumn?.label)}
            >
                {ActionColumn?.label === "quotations_payment" ? "Payment Details" : "Update Payment Details"}
            </Button>) : ActionColumn?.label === "create_so_from_quotation" ? (<React.Fragment>
                {(data?.erp_quotation_code !== null && data?.erp_quotation_code !== "" ) ? (<>
                    {!data?.is_afs_order_created && (<Button 
                        className="btn donwload_btn" 
                        onClick={() => CreateSalesOrderFunction(data)}
                    >
                        Create Sale Order
                    </Button>)}
                </>) : "-"}
            </React.Fragment>) : (<Link className="btn edittable edittableretry btn btn-primary" to={`${ActionColumn?.naviagte}${data?.id}`}>
                <Image src={EditIcon} alt="edit" />
            </Link>)}
        </td>)
    };

    // COMMON BUTTON READ
    const CommonButtonRead = ({ data }) => {
        if(ActionColumnRead?.url === "stock-inward-log") {
            return(<td>
                <div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                    {data?.is_bike && (<span className="btn edittable" onClick={() => showTable(data?.id, data)}>
                        <i className="bi bi-eye"></i>
                    </span>)}
                    {data?.view_file_url !== null && (<a href={process.env.REACT_APP_BASE_URL + "file/" + data?.view_file_url}  className="downloadaction" target="__blank">
                        <i className="bi bi-file-earmark-arrow-down"></i>
                    </a>)}
                </div>
            </td>)
        };
    };

    // error message modal open 
    const ErrorMessageShow = (data, url) => {
        if(url === "erp_order_synce_error_view") {
            const GetCurrentERPList = OrderList?.erp_order_log_list?.filter((elm) => elm?.order_id === data?.id)[0];
            setPreviewErrorModal({
                ...PreviewErrorModal,
                open: open,
                Title: "Sales Order Error",
                Data: GetCurrentERPList,
            });
        } else if(url === "fba_order_synce_error_view") {
            const GetCurrentERPList = OrderList?.erp_invoice_log_list?.filter((elm) => elm?.order_id === data?.id)[0];
            setPreviewErrorModal({
                ...PreviewErrorModal,
                open: open,
                Title: "Fba Order Error",
                Data: GetCurrentERPList,
            });
        };
    };

    // COMMON PREIVEW BUTTON
    const CommonPreviewButton = ({ data }) => {
        if(ActionPreviewState?.url === "erp_api_failures_error_view") {
            return(<td>
                <div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                    <Button className="btn edittable btn btn-primary" onClick={()=> ErrorMessageShow(data, "erp_order_synce_error_view")}>
                        <i className="bi bi-eye"></i>
                    </Button>
                </div>
            </td>)
        } else if(ActionPreviewState?.url === "fba_orders_failures_error_view") {
            return(<td>
                <div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                    <Button className="btn edittable btn btn-primary" onClick={()=> ErrorMessageShow(data, "fba_order_synce_error_view")}>
                        <i className="bi bi-eye"></i>
                    </Button>
                </div>
            </td>)
        };
    };

    let CurrentColspan = RowData?.length + 1;

    if(EditAccess && ActionColumn?.isShow){
        CurrentColspan += 1;
    };

    // GET NAME
    const GetName = (id, url) => {
        const currentName = OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0]?.[url?.label];
        return currentName === undefined ? "-" : currentName;
    };

    // GET STATE NAME FROM PINCODES
    const GetPincodeSearch = (id, url) => {
        const CurrentProduct = OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0];
        const GetCurrentStateName = statelistall?.filter((elm) => CurrentProduct?.state_id === elm?.id)[0];
        return {
            state_name: GetCurrentStateName?.state_name,
            gst_code: GetCurrentStateName?.gst_code,
        };
    };

    // GET FS CODE WITH PRODUCT NAME
    const GetAFSporductName = (id, url) => {
        const CurrentProduct = OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0];
        return CurrentProduct?.product_name + ` - (${CurrentProduct?.product_code})`;
    };

    // GET CITY NAME
    const GetCityName = (id, url) => {
        const CurrentProduct = OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0];
        const CurrentCity =  citylistall?.filter((elm) => CurrentProduct?.district_id === elm?.id)[0];
        return CurrentCity?.district_name;
    };

    // GET STATE NAME
    const GetStateName = (id, url) => {
        const CurrentProduct = OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0];
        const CurrentCity = citylistall?.filter((elm) => CurrentProduct?.district_id === elm?.id)[0];
        const CurrentState = statelistall?.filter((elm) => CurrentCity?.state_id === elm?.id)[0];
        return CurrentState?.state_name;
    };

    // get ship from warehouse name
    const GetCurrentWarehouse = (warehouse_id) => {
        const CurrentWAREHOUSE = wareHouselistall?.filter((elm) => elm?.id === warehouse_id)[0];
        return CurrentWAREHOUSE?.display_name;
    };

    // GET BRAND NAME
    const GetBrandName = (sub_category) => {
        const SubCategoryListx = OrderList?.sub_category?.filter((elm) => sub_category === elm?.id)[0];
        const CategoryListX = OrderList?.category?.filter((elm) => SubCategoryListx?.category_id === elm?.id)[0];
        const BrandName = OrderList?.brand_list?.filter((elm) => CategoryListX?.brand_id === elm?.id)[0]?.brand_name;
        return BrandName;
    };

    // GET CATEGORY NAME
    const GetCategoryName = (sub_category) => {
        const SubCategoryListx = OrderList?.sub_category?.filter((elm) => sub_category === elm?.id)[0];
        const CategoryListX = OrderList?.category?.filter((elm) => SubCategoryListx?.category_id === elm?.id)[0]?.category_name;
        return CategoryListX;
    };

    // GET WAREHOUSE TYPE NAME
    const GetWarehouseTypeName = (warehouse_type) => {
        const CurrentWarehosueTypes = optionWarehouseType?.filter((elm) => elm?.value === warehouse_type)[0];
        return CurrentWarehosueTypes?.label === undefined ? "-" : CurrentWarehosueTypes?.label;
    };

    // GEST REGION NAME
    const GetRegionName = (region_id) => {
        if(region_id !== undefined) {
            const CurrentRegion = OrderList?.region_list?.filter((elm) => region_id === elm?.id)[0];
            return CurrentRegion === undefined ? "-" : CurrentRegion?.region_name;
        } else {
            return "-";
        };
    };

    // TOTAL TABLE GRAND AND NORMAL TOTAL
    const TotalTableCount = (list, url) => {
        if (list?.length > 0) {
            if (url === "credit_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.credit_amt);
                }
                return sum;
            } else if (url === "debit_amt") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.debit_amt);
                }
                return sum;
            } else if (url === "inward_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.inward_qty);
                }
                return sum;
            } else if (url === "transfer_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.transfer_qty);
                }
                return sum;
            } else if (url === "inward_qty - transfer_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.inward_qty - list[i]?.transfer_qty);
                }
                return sum;
            } else if(url === "inward_qty - invoiceable_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.inward_qty - list[i]?.invoiceable_qty);
                }
                return sum;
            } else if(url === "TotalProduct") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += GetProductSTockValue(list[i]?.product_id, list[i]?.inward_qty, list[i]?.transfer_qty);
                }
                return sum;
            };
        } else {
            return 0;
        };
    };

    // COMMON DATE FUNCTION
    const CommonDateTimeCommon = (date, url) => {
        if(url === "desktop") {
            if (date !== null) {
                const currentTimezone = moment.tz.guess();
                return moment(date).tz(currentTimezone).format('MMMM Do YYYY, h:mm:ss a');
            } else {
                return "-";
            };
        } else {
            if (date !== null) {
                const currentTimezone = moment.tz.guess();
                return moment(date).tz(currentTimezone).format('MM/DD/YYYY');
            } else {
                return "-";
            };
        };
    };

    // GET SALES PERSON PERFORMANCE STATUS
    function getStatus(data) {
        const currentDate = new Date();
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);

        const resetTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const currentDateWithoutTime = resetTime(currentDate);
        const startDateWithoutTime = resetTime(startDate);
        const endDateWithoutTime = resetTime(endDate);
    
        if (currentDateWithoutTime < startDateWithoutTime) {
            return (<Badge bg="info" className="status_mark yet_to_start">Yet to start</Badge>);
        } else if (currentDateWithoutTime >= startDateWithoutTime && currentDateWithoutTime <= endDateWithoutTime) {
            if (parseInt(data.target_achived) >= parseInt(data.target)) {
                return (<Badge bg="success" className="status_mark">Completed</Badge>);
            };
            return (<Badge bg="warning" className="status_mark in_progress">In progress</Badge>);
        } else if (currentDateWithoutTime > endDateWithoutTime) {
            if (parseInt(data.target_achived === null ? 0 : data.target_achived) < parseInt(data.target)) {
                return (<Badge bg="danger" className="status_mark">Expired</Badge>);
            }
        };
    };

    // Market Place Invocie
    const MarketPlaceInvocie = async (invoice_code, uni_code) => {
        window.open(MARKET_PLACE_INVOICE_API_URL + "?invoice_code=" + invoice_code + "&uni_code=" + uni_code, '_blank');
    };

    const AddOrderColorRow = (elm) => {
        return elm?.is_odi ? "is_odi_order_active" : elm?.is_courier_pin_code ? "is_courier_pin_code_order_active" : "";
    };

    const AddRowClassName = (data) => {
        let page_url = Location?.pathname?.split("/")[1];

        if(page_url === "users") {
            return data?.id === user_id ? "active_user" : "";
        } else if(page_url === "create-franchise") {
            if(!data?.is_active) {
                return "Order-NoActive-Row";
            } else if(data?.order_count === 0 && data?.is_active) {
                return "Order-NotBilled-Row";
            } else {
    
            };
        };
    };

    // get total balance and Credit Limit	
    const GetCurrentOrderRate = (current) => {
        const getCurrentFranchise = OrderList?.franchise?.filter((elm) => elm?.id === current?.billing_id)[0];
        const getCurrentFranchiseType = OrderList?.franchise_type?.filter((elm) => elm?.id === getCurrentFranchise?.franchise_type_id)[0];

        let creditlimit = 0;
        let wallet = 0

        if (getCurrentFranchiseType?.franchise_type_credit !== null && getCurrentFranchiseType?.franchise_type_credit !== "") {
            wallet = parseInt(getCurrentFranchiseType?.franchise_type_credit);
            creditlimit = parseInt(getCurrentFranchiseType?.franchise_type_credit);
        };
        if (getCurrentFranchise?.credit_addon !== null && getCurrentFranchise?.credit_addon !== "") {
            wallet = parseInt(getCurrentFranchise?.credit_addon) + wallet;
            creditlimit = parseInt(getCurrentFranchise?.credit_addon) + creditlimit;
        };
        if (getCurrentFranchise?.advance_amount !== null && getCurrentFranchise?.advance_amount !== "") {
            wallet = wallet + parseInt(getCurrentFranchise?.advance_amount);
        };

        return {
            Wallet_balance: (getCurrentFranchise?.advance_amount === null || getCurrentFranchise?.advance_amount === "") ? 0 : parseInt(getCurrentFranchise?.advance_amount),
            credit_limit: creditlimit,
            available_balance: wallet,
        };
    };

    function displayZeroIfNegative(number) {
        if (number < 0) {
            return 0;
        } else {
            return number;
        }
    };

    // get product inward_qty
    const GetProductSTockValue = (product_id, inward_qty, transfer_qty) => {
        const CurrentProduct = productlistall?.filter((elm) => elm?.id === product_id)[0];
        return isNaN(Math.round((parseFloat(CurrentProduct?.mrp) * (parseInt(inward_qty) - parseInt(transfer_qty))) * 100) / 100) ? 0 : Math.round((parseFloat(CurrentProduct?.mrp) * (parseInt(inward_qty) - parseInt(transfer_qty))) * 100) / 100;
    };

    // payment url copy
    const PaymentLinkCompy = (payment_id) => {
        var urlText = document.getElementById(payment_id).innerText; // Get the text from <p>
        navigator.clipboard.writeText(urlText).then(() => {
            setCopyUrl({
                ...CopyUrl,
                id: payment_id,
                label: "URL Copied !"
            });

            setTimeout(() => {
                setCopyUrl({
                    ...CopyUrl,
                    id: "",
                    label: "Copy Link"
                });
            }, 2000);
        }).catch(err => {
            setCopyUrl({
                ...CopyUrl,
                id: "",
                label: "Copy Link"
            });
        });
    };

    return(<tbody>
        {OrderList?.list?.map((item, rowIndex) => {
            return(<React.Fragment key={rowIndex}>
            <tr className={isOdiRowColorsAllow ? AddOrderColorRow(item) : AddRowClassName(item)} key={rowIndex}>
                {isCollapsable && (<td>
                    <Button 
                        className={activeTableDetails === item?.id ? "btn actionbtn active" : "btn actionbtn"}
                        onClick={() => activeTableDetails === item?.id ? ElseMoreDetails() : ViewMoreDetails(item?.id)}
                        aria-controls="example-collapse-text"
                        aria-expanded={activeTableDetails === item?.id ? true : false}
                    >
                        <Image src={MoreIcon} alt="more icon" />
                    </Button>
                </td>)}
                <td>
                    {srNumber?.clickable ? (
                        <Button 
                            className="btn-link btn-link-color text-decoration-none" 
                            onClick={() => showOrder(item?.id, srNumber?.events)}
                        >
                            {GetCurrentTableSRNo(rowIndex, Pagination?.current_page, Pagination?.record_limit)}
                        </Button>) 
                    : GetCurrentTableSRNo(rowIndex, Pagination?.current_page, Pagination?.record_limit)}
                </td>
                {RowData?.map((row, ind) => {
                    if(!row?.isCollapsable) {
                        if(row?.type === "navigate") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${AFTER_SALES_ORDERS_URL}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "navigate_regular_order") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${row?.navigate_url}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "order_details") {
                            return(<td key={ind}>
                                <b className="tableBolanchor" onClick={() => OrderDetailsModal(item?.id)}>{item[row?.key] || "-"}</b>
                            </td>)
                        } else if(row?.type === "invoice_details") {
                            return(<td key={ind}>
                                {selfInfo?.user?.user_type === 'super_admin' ? (<>
                                    {!item?.invoice_code ? "-" : (<b className="tableBolanchor" onClick={(e) => callRegularInvoiceModal(e, item?.id)}>{item?.invoice_code}</b>)}
                                </>) : (<>
                                    {item?.order_status === "AC_APPROVED" ? (<b className="tableBolanchor" style={{ cursor: "default" }} >{item?.invoice_code}</b>) : (<React.Fragment>
                                        {!item?.invoice_code ? "-" : (<b className="tableBolanchor" onClick={(e) => callRegularInvoiceModal(e, item?.id)}>{item?.invoice_code}</b>)}
                                    </React.Fragment>)}
                                </>)}
                            </td>)
                        } else if(row?.type === "label_self") {
                            return(<td key={ind}>
                                {item?.id !== user_id ? item?.full_name : "You"}
                            </td>)
                        } else if(row?.type === "label_user_type") {
                            return(<td style={{ width: "130px", textTransform: "capitalize" }} key={ind}>
                                {item[row?.key] === "regional_sales_team_asm" ? "Assistant Sales Manager" : item[row?.key] === "regional_sales_team_rm" ? "Regional Manager" : item[row?.key]?.replaceAll("_", " ")}
                            </td>)
                        } else if(row?.type === "label_region_name") {
                            return(<td key={ind}>
                                {(item?.user_type === "regional_manager" || item?.user_type === "regional_sales_team_rm") ? GetRegionName(item?.related_id) : item?.user_type === "regional_sales_team_asm" ? GetRegionName(item?.region_id) : "-"}
                            </td>)
                        } else if(row?.type === "label_get_from_franchise_data") {
                            return(<td key={ind}>
                                {item?.franchise_data?.franchise_name}
                            </td>)
                        } else if(row?.type === "label_get_from_product_data") {
                            return(<td key={ind}>
                                {item?.product_details?.product_name}
                            </td>)
                        } else if(row?.type === "date") {
                            return(<td key={ind}>
                                {(item[row?.key] !== null && item[row?.key] !== "None") ? CommonDateTime(item[row?.key], "LL", "show") : "-"}
                            </td>)
                        } else if(row?.type === "months_show") {
                            return(<td key={ind}>
                                {moment().month(item?.scheme_date).format('MMMM')}
                            </td>)
                        } else if(row?.type === "date_time") {
                            return(<td key={ind}>
                                {CommonDateTimeCommon(item[row?.key], "desktop")}
                            </td>)
                        } else if(row?.type === "navigate_quotations") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${QUOTATIONS_REPORT_DETAILS_URL}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "navigate_quotations_payment_approval") {
                            return(<td key={ind}>
                                {EditAccess ? (<Link className="btn-link btn-link-color text-decoration-none" to={`${PAYMENT_APPROVAL_QUOTATIONS_REPORT_DETAILS_URL}/${item?.id}`}>
                                    <b className="tableBolanchor">{item[row?.key] || "-"}</b>
                                </Link>) : (<b className="tableBolanchor">{item[row?.key] || "-"}</b>)}
                            </td>)
                        } else if(row?.type === "date") {
                            return(<td key={ind}>
                                {CommonDateTime(item[row?.key], "LL", "show")}
                            </td>)
                        } else if(row?.type === "label_get_from_franchise") {
                            return(<td key={ind}>
                                {GetFranchiseName(item[row?.key])?.name}
                            </td>)
                        } else if(row?.type === "label_old_warehouse") {
                            return(<td key={ind}>
                                {item?.is_resign ? item?.old_warehouse_name : "-"}
                            </td>)
                        } else if(row?.type === "label_franchise_code") {
                            return(<td key={ind}>
                                {item[row?.key]?.toString().includes("FC") ? item[row?.key]?.replace("FC", "") : item[row?.key]}
                            </td>)
                        } else if(row?.type === "label_get_ship_warehouse") {
                            return(<td key={ind}>
                                {GetCurrentWarehouse(item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_parent_id") {
                            return(<td key={ind}>
                                {item[row?.key] === null ? "Dealer" : "Sub Dealer"}
                            </td>)
                        } else if(row?.type === "label_redirect_location_url") {
                            return(<td key={ind}>
                                {(item[row?.key] !== null && item[row?.key] !== "") ? (<a href={item[row?.key]} target="__blank"><i className="bi bi-geo-alt"></i> Location</a>) : "-"}
                            </td>)
                        } else if(row?.type === "label_get_category_name_from_product") {
                            return(<td key={ind}>
                                {GetCategoryFrromProductsName(item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_capitalized") {
                            return<td key={ind} style={{ textTransform: "capitalize" }}>
                                {item[row?.key]}
                            </td>
                        } else if(row?.type === "label_get_from_list") {
                            return(<td key={ind}>
                                {GetName(item[row?.key], row?.filterFrom)}
                            </td>)
                        } else if(row?.type === "image") {
                            return(<td key={ind}>
                                <AvatarSet
                                    type={"product"}
                                    url={item[row?.key]}
                                    alt={"tableimg"}
                                />
                            </td>)
                        } else if(row?.type === "invoice_modal_open_navigate") {
                            return(<td key={ind}>
                                {EditAccess ? (
                                    <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, item?.id, "afs_order")}>{item[row?.key] || "-"}</b>
                                ) : (
                                    <b className="tableBolanchor" style={{ cursor: "default" }} >{item[row?.key] || "-"}</b>
                                )}
                            </td>)
                        } else if(row?.type === "invoice_regular_modal_open_navigate") {
                            return(<td key={ind}>
                                {EditAccess ? (
                                    <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, item?.id, "regular_order")}>{item[row?.key] || "-"}</b>
                                ) : (
                                    <b className="tableBolanchor" style={{ cursor: "default" }} >{item[row?.key] || "-"}</b>
                                )}
                            </td>)
                        } else if(row?.type === "pending_since") {
                            return(<td key={ind}>
                                {TimeSense(item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_opening_balance") {
                            return(<td key={ind}>
                                0
                            </td>)
                        } else if(row?.type === "label_price") {
                            return(<td key={ind}>
                                {AmountNumberFormat(item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_invoice_no_purchase_list") {
                            return(<td key={ind}>
                                {item?.order === undefined ? "-" : item?.order?.invoice_code}
                            </td>)
                        } else if(row?.type === "label_invoice_date_list") {
                            return(<td key={ind}>
                                {item?.order === undefined ? "-" : CommonDateTime(item?.order?.invoice_at, "LL", "show")}
                            </td>)
                        } else if(row?.type === "label_closing_balance") {
                            return(<td key={ind}>
                                {AmountNumberFormat(parseFloat(item.total_credit) - parseFloat(item.total_debit))}
                            </td>)
                        } else if(row?.type === "label_get_from_list") {
                            return(<td key={ind}>
                                {GetName(item[row?.key], row?.filterFrom)}
                            </td>)
                        } else if(row?.type === "Status_sales_person_performance") {
                            return(<td key={ind}>
                                {getStatus(item)}
                            </td>)
                        } else if(row?.type === "label_order_status") {
                            return(<td key={ind}>
                                {CommonStatus(item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_cancel_status") {
                            return(<td key={ind}>
                                {CancelStageStatus(item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_get_from_list_summary_details") {
                            return(<td key={ind}>
                                <Button className="btn-link btn-link-color text-decoration-none" onClick={() => showSummaryDetails(item, "list_summary")}>
                                    <b className="tableBolanchor">{GetName(item[row?.key], row?.filterFrom)}</b>
                                </Button>
                            </td>)
                        } else if(row?.type  === "label_get_from_list_erp_general_summary_details") {
                            return(<td key={ind}>
                                <Button className="btn-link btn-link-color text-decoration-none" onClick={() => showSummaryDetails(item, "erp_list_summary")}>
                                    <b className="tableBolanchor">{GetName(item[row?.key], row?.filterFrom)}</b>
                                </Button>
                            </td>)
                        } else if(row?.type === "label_get_state_from_pincodes") {
                            return(<td key={ind}>
                                {GetPincodeSearch(item[row?.key], row?.filterFrom)?.state_name}
                            </td>)
                        } else if(row?.type === "label_get_gst_from_pincodes") {
                            return(<td key={ind}>
                                {GetPincodeSearch(item[row?.key], row?.filterFrom)?.gst_code}
                            </td>)
                        } else if(row?.type === "label_get_from_fscode_list") {
                            return(<td key={ind}>
                                {GetAFSporductName(item[row?.key], row?.filterFrom)}
                            </td>)
                        } else if(row?.type === "label_franchise_last_three_orders") {
                            return(<td key={ind}>
                                {item[row?.key] === 0 ? item[row?.key] : (<b className="tableBolanchor" onClick={() => FranchiseLastThreeOrders(item)}>{item[row?.key]}</b>)}
                            </td>)
                        } else if(row?.type === "city_label_get_from_warehouse") {
                            return(<td key={ind}>
                                {GetCityName(item[row?.key], row?.filterFrom)}
                            </td>)
                        } else if(row?.type === "state_label_get_from_warehouse") {
                            return(<td key={ind}>
                                {GetStateName(item[row?.key], row?.filterFrom)}
                            </td>)
                        } else if(row?.type === "status_user_verification_active") {
                            return(<td key={ind}>
                                {item?.user_type === "sale_franchise_person" ? item?.user_verification ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>) : "-"}
                            </td>)
                        } else if(row?.type === "status_yes_no") {
                            return(<td key={ind}>
                                {item[row?.key] ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>)}
                            </td>)
                        } else if(row?.type === "status_Docket_EWB") {
                            return(<td key={ind}>
                                {item[row?.key] ? (<Badge bg="success">Docket Number</Badge>) : (<Badge bg="danger">EWB Number</Badge>)}
                            </td>)
                        } else if(row?.type === "status") {
                            return(<td key={ind}>
                                {(item[row?.key] === null || item[row?.key] === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                            </td>)
                        } else if(row?.type === "warehouse_type_category_custom") {
                            return(<td key={ind}>
                                {GetWarehouseTypeName(item[row?.key])}
                            </td>)
                        } else if(row?.type === "status_active") {
                            return(<td key={ind}>
                                {item[row?.key] ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                            </td>)
                        } else if(row?.type === "label_erp_invoice_delivery_note_api_status") {
                            return(<td key={ind}>
                                {(item[row?.key] === null || item[row?.key] === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                            </td>)
                        } else if(row?.type === "status_franchise_active") {
                            return(<td key={ind}>
                                {(item?.is_active && item?.order_count === 0) ? (<>
                                    {item?.order_count === 0 && (<Badge className="not_billed_orange" bg="danger">Not Billed</Badge>)}
                                </>) : (item?.is_active && item?.order_count > 0) ? (<>
                                    {item?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                                </>) : (<>
                                    {item?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}
                                </>)}
                            </td>)
                        } else if(row?.type === "status_frame_active") {
                            return(<td key={ind}>
                                {item[row?.key] ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>)}
                            </td>)
                        } else if(row?.type === "status_sold_unsold") {
                            return(<td key={ind}>
                                {item[row?.key] === "SOLD" ? (<Badge bg="success">Sold</Badge>) : (<Badge bg="danger">Un-Sold</Badge>)}
                            </td>)
                        } else if(row?.type === "hyper_link_shipment_url") {
                            return(<td key={ind}>{
                                item[row?.key] === null ?
                                    "-" :
                                    item?.shipment_url === null ? item[row?.key] : (<a href={item?.shipment_url} target="__blank">{item[row?.key] ? item[row?.key] : "-"}</a>)
                            } </td>)
                        } else if(row?.type === "label_day_price") {
                            return(<td key={ind}>
                                {item[row?.key] === null ? "0" : item[row?.key]}
                            </td>)
                        } else if(row?.type === "label_day_ammount") {
                            return(<td key={ind}>
                                ₹{AmountNumberFormat(item[row?.key] === null ? "0" : item[row?.key])}
                            </td>)
                        } else if(row?.type === "label_Criticality") {
                            const get_color = getColor(item);
                            return(<td key={ind}>
                                <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                            </td>)
                        } else if(row?.type === "label_order_status") {
                            return(<td key={ind}>
                                {CommonStatus(item[row?.key])}
                            </td>)
                        } else if(row?.type === "pod_link_navigate") {
                            return(<td key={ind}>
                                {(item[row?.key] === "" || item[row?.key] === null) ? "-" : (<a href={item[row?.key]} target="__blank">{item[row?.key]}</a>)}
                            </td>)
                        } else if(row?.type === "label_E_Invoice_error_view") {
                            return(<td key={ind}>
                                <Button className="btn edittable btn btn-primary" onClick={()=> HandleErrorShow(item)}>
                                    <i className="bi bi-eye"></i>
                                </Button>
                            </td>)
                        } else if(row?.type === "label_Generate_Invoice") {
                            return(<td key={ind}>
                                {item?.invoice_no === null ? "-" : (
                                    <Button className="btn edittable btn btn-primary" 
                                        onClick={() => GenerateInvoicFunction(item)}
                                    >
                                        <i className="bi bi-eye"></i>
                                    </Button>
                                )}
                            </td>)
                        } else if(row?.type === "label_Invoiceable_Qty") {
                            return(<td key={ind}>
                                {displayZeroIfNegative(item?.inward_qty - item?.transfer_qty - item?.invoiceable_qty)}
                            </td>)
                        } else if(row?.type === "label_product_stock") {
                            return(<td key={ind}>
                                {GetProductSTockValue(item?.product_id, item?.inward_qty, item?.transfer_qty)}
                            </td>)
                        } else if(row?.type === "quotation_status_type") {
                            return(<td key={ind}>
                                {QuotationsStatus(item[row?.key], "badge")}
                            </td>)
                        } else if(row?.type === "quotation_erp_status_type") {
                            return(<td key={ind}>
                                {(item[row?.key] === null || item[row?.key] === "") ? (<Badge bg="danger">Failed</Badge>) : (<Badge bg="success">Success</Badge>)}
                            </td>)
                        } else if(row?.type === "label_stock_transfer") {
                            return(<td key={ind}>{item[row?.key] === 0 ? "0" : item[row?.key]}</td>)
                        } else if(row?.type === "label_Type_Of_Transaction") {
                            return(<td key={ind}>
                                <b>{item[row?.key] || "-"}</b>
                            </td>)
                        } else {
                            return(<td key={ind}>{item[row?.key] || "-"}</td>)
                        };
                    };
                })}
                {ActionPreviewState?.isShow && (<CommonPreviewButton data={item} />)}
                {(EditAccess && ActionColumn?.isShow) && (<CommonButton data={item} />)}
                {ActionColumnRead?.isShow && (<CommonButtonRead data={item} />)}
            </tr>
            <Collapse in={activeTableDetails === item?.id ? true : false}>
                <tr className="tablecollapsewraps" id={"tablecollpase"+item?.id}>
                    <td colSpan="9" style={{ padding: "0px" }} >
                        <Table className="table" >
                            <tbody>
                                {RowData?.map((row, ind) => {
                                    if(row?.isCollapsable) {
                                        if(row?.type === "label_get_brand_name") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{GetBrandName(item[row?.key])}</td>
                                            </tr>)
                                        } else if(row?.type === "label_get_category_name") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{GetCategoryName(item[row?.key])}</td>
                                            </tr>)
                                        } else if(row?.type === "payment_link_label") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {EditAccess ? (<React.Fragment>
                                                        {item[row?.key] === null ? "-" : 
                                                        (<React.Fragment>
                                                            {row?.isDisable ? item[row?.key] : (<>
                                                                <a href={item[row?.key]} id={"payment_link" + item?.id} style={{ color: "#00f" }} target="__blank" >{item[row?.key]}</a>
                                                                <Button className={CopyUrl?.id === "payment_link" + item?.id ? "btn btn-copy-link active" : "btn btn-copy-link"} onClick={() => PaymentLinkCompy("payment_link" + item?.id)}>
                                                                    <i className="bi bi-copy"></i> {CopyUrl?.id === "payment_link" + item?.id ? CopyUrl?.label : "Copy Link"}
                                                                </Button>
                                                            </>)}
                                                        </React.Fragment>)}
                                                    </React.Fragment>) : (<React.Fragment style={{ color: "red" }}>Don't have permission to make a payment.</React.Fragment>)}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "payment_Attachment_preview_download") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {item?.payment_slip === null ? "-" : (<React.Fragment>
                                                        <a href={FILE_AUTH_URL + "view/file/" + item?.payment_slip?.id} style={{ color: "#00f" }} target="__blank" >{item?.payment_slip?.original_name}</a>
                                                        <Button className="btn btn-copy-link" onClick={() => FileDownload(item?.payment_slip?.id, accessToken)}><i className="bi bi-download"></i> Download file</Button>
                                                    </React.Fragment>)}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "date") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{item[row?.key] === null ? "-" : CommonDateTime(item[row?.key], "LL", "show")}</td>
                                            </tr>)
                                        } else if(row?.type === "city_label_get_from_warehouse") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{GetCityName(item[row?.key], row?.filterFrom)}</td>
                                            </tr>)
                                        } else if(row?.type === "state_label_get_from_warehouse") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{GetStateName(item[row?.key], row?.filterFrom)}</td>
                                            </tr>)
                                        } else if(row?.type === "label_get_from_list") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{GetName(item[row?.key], row?.filterFrom)}</td>
                                            </tr>)
                                        } else if(row?.type === "status_yes_no") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{item[row?.key] ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>)}</td>
                                            </tr>)
                                        } else if(row?.type === "label_point_images") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    <ul style={{ margin: "0px" }}>
                                                        {item[row?.key] === null ? "-" : JSON.parse(item[row?.key]?.replace(/'/g, '"'))?.map((elm, index) => {
                                                            return(<li key={index}>{elm}</li>)
                                                        })}
                                                    </ul>
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "label_reference_images") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {item[row?.key] === null ? "-" : (
                                                        <ul className="uploade_images_wrapper_list" style={{ margin: "0px" }}>
                                                            {JSON.parse(item[row?.key]?.replace(/'/g, '"'))?.map((elm,index) => {
                                                                return(<li key={index}>
                                                                    <div className="wrapps_upload_img">
                                                                            <span>Range : {elm?.range}</span>
                                                                            <div className="image_preview_wraps" key={index}>
                                                                            <Image src={FILE_AUTH_URL + elm?.images?.view_file_url} onError={(e) => e.target.src = DefaultImage} alt="image_preiew" />
                                                                        </div>
                                                                    </div>
                                                                </li>)
                                                            })}
                                                        </ul>
                                                    )}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "labelOrderTypeFoShow") {
                                            return(<React.Fragment key={ind}>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Wallet Balance</th>
                                                    <td>{AmountNumberFormat(GetCurrentOrderRate(item)?.Wallet_balance)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Credit Limit</th>
                                                    <td>{AmountNumberFormat(GetCurrentOrderRate(item)?.credit_limit)}</td>
                                                </tr>
                                            </React.Fragment>)
                                        } else if(row?.type === "Attachments_SoApprovals") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>Attachments</th>
                                                <td>
                                                    {item?.attachments !== null ? (<ul className="fileuploadListytable">
                                                        {item?.attachments?.map((elm, index) => {
                                                            return (<li key={index}>
                                                                <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                            </li>)
                                                        })}
                                                    </ul>) : "-"}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "Attachments") {
                                            return(<tr key={ind}>
                                                {item?.attachments !== null && (<ul className="fileuploadListytable">
                                                    {item?.attachments?.map((elm, index) => {
                                                        return (<li key={index}>
                                                            <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                        </li>)
                                                    })}
                                                </ul>)}
                                                {(item?.attachments !== null && OrderList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === item?.id && item?.order_type === "AO"))?.length > 0) && (<br/>)}
                                                {OrderList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === item?.id && item?.order_type === "AO"))?.length > 0 && OrderList?.uni_com_invoice_list?.map((elm__, index) => {
                                                    if(elm__?.order_id === item?.id && item?.order_type === "AO") {
                                                        return(<React.Fragment key={index}>
                                                            {elm__?.invoiceCode !== null && item?.uni_code !== null && (
                                                                <a key={index} className="anchorDownoad" onClick={() => MarketPlaceInvocie(elm__?.invoiceCode, item?.uni_code)}><i className="bi bi-file-earmark-text"></i> Market place invoice</a>
                                                            )}
                                                        </React.Fragment>)
                                                    }
                                                })}
                                                {(OrderList?.uni_com_invoice_list?.filter((elm__) => (elm__?.order_id === item?.id && item?.order_type === "AO"))?.length > 0 && item?.uni_shipment_url !== null) && (<br/>)}
                                                {item?.uni_shipment_url !== null && (
                                                    <a href={item?.uni_shipment_url} download={item?.uni_shipment_url} target="__blank"><i className="bi bi-file-earmark-text"></i> Shipping label</a>
                                                )}
                                            </tr>) 
                                        } else if(row?.type === "label_order_status") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {CommonStatus(item[row?.key])}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "label_Additional_Status") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>
                                                    {item[row?.key] === null ? (<Badge bg="success">Regular Order</Badge>) : (<Badge bg="primary">Replacement Order</Badge>)}
                                                </td>
                                            </tr>)
                                        } else if(row?.type === "label_day_ammount") {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td key={ind}>₹{AmountNumberFormat(item[row?.key] === null ? "0" : item[row?.key])}</td>
                                            </tr>)
                                        } else {
                                            return(<tr key={ind}>
                                                <th style={{ width: "20%" }}>{row?.label}</th>
                                                <td>{item[row?.key] || "-"}</td>
                                            </tr>)
                                        };
                                    }
                                })}
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </Collapse>
        </React.Fragment>)
        })}
        {OrderList?.list?.length === 0 && (<tr>
            <td style={{ textAlign: "center" }} colSpan={CurrentColspan}>List not found</td>
        </tr>)}

        {isTotalAndGrand?.isShow && (<React.Fragment>
            {isTotalAndGrand?.filterType === "Debit-credit-note-report" ? (<React.Fragment>
                <tr className="totaltd">
                    <th colSpan={isTotalAndGrand?.colSpan} className="text-center">Page total</th>
                    <td>{AmountNumberFormat(TotalTableCount(OrderList?.list, "credit_amt"))}</td>
                    <td colSpan={isTotalAndGrand?.lastColspan}>{AmountNumberFormat(TotalTableCount(OrderList?.list, "debit_amt"))}</td>
                </tr>
                <tr className="totaltd">
                    <th colSpan={isTotalAndGrand?.colSpan} className="text-center">Grand total</th>
                    <td>{AmountNumberFormat(OrderList?.grand_credit)}</td>
                    <td colSpan={isTotalAndGrand?.lastColspan}>{AmountNumberFormat(OrderList?.grand_debit)}</td>
                </tr>
            </React.Fragment>) : isTotalAndGrand?.filterType === "Stock-Level" ? (<React.Fragment>
                <tr className="totaltd">
                    <th colSpan={isTotalAndGrand?.colSpan} style={{ textAlign: "center" }}>Page Total</th>
                    <td>{TotalTableCount(OrderList?.list, "inward_qty")}</td>
                    <td>{TotalTableCount(OrderList?.list, "transfer_qty")}</td>
                    <td>{TotalTableCount(OrderList?.list, "inward_qty - invoiceable_qty")}</td>
                    <td>{TotalTableCount(OrderList?.list, "inward_qty - transfer_qty")}</td>
                    <td>{TotalTableCount(OrderList?.list, "TotalProduct")}</td>
                </tr>
                <tr className="totaltd">
                    <th colSpan={isTotalAndGrand?.colSpan} style={{ textAlign: "center" }}>Grand Total</th>
                    <td>{OrderList?.grand?.grand_in}</td>
                    <td>{OrderList?.grand?.grand_tran}</td>
                    <td>{OrderList?.grand?.grand_inv}</td>
                    <td>{OrderList?.grand?.grand_bal}</td>
                    <td>{OrderList?.grand?.grand_val}</td>
                </tr>
            </React.Fragment>) : (<React.Fragment>

            </React.Fragment>)}
        </React.Fragment>)}

        {showModal?.open && (<Modal show={showModal?.open} onHide={onClose} className='commonModal' centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>{showModal?.title} <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3'>
                <FormControl 
                    value={showModal?.Reason} 
                    onChange={(e) => {
                        setShowModal({
                            ...showModal,
                            Reason: e?.target?.value,
                        });
                    }}
                    placeholder='Enter a reason' as='textarea' 
                />
                {errorReason !== "" &&(<small className='text-danger' >{errorReason}</small>)}
            </div>
           <div className='d-flex  px-3 pb-3'>
                <button className='btn button-1 w-auto ms-auto' onClick={() => OrderSuccesReject(showModal?.title)}>Submit</button>
           </div>
        </Modal>)}
    </tbody>)
}

export default TableBody;