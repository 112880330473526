/* eslint-disable */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
    getBrandList, 
    ClearFormSetFutios, 
    setStoreOptionBeforeLoadCouriersNewList, 
    getAllSaleTypeList, 
    SearchBillingPincodeList, 
    SearchShippingPincodeList, 
    getSparesCampatibilityList, 
    getAllSparesCampatibilityList, 
    GetUnicommerceInvoiceFalureList, 
    GetUnicommerceShipmentFalureList, 
    GetUnicommerceTrackStatusFalureList, 
    addIncentiveSlabList, 
    updateIncentiveSlabList, 
    addFranchiseSchemeList, 
    updateFranchiseSchemeList, 
    getFranchiseSchemeList, 
    getAllFranchiseSchemeList, 
    getBundleItemAllList, 
    GetafterSalesOrdersDetails, 
    getOrderCancelStatus, 
    addAgeValue, 
    addResallBrand, 
    getRelatedCourierList, 
    SoApprovalStoredLevelReport, 
    StoredLevelAllReport, 
    GetOrderDetailByOrderCode, 
    getFBAOrderList, 
    getErpPurchaseTrueList, 
    getErpPurchaseFalseList, 
    getResellcycleCustomerReportsList, 
    getErpDeliveryNoteFrameNumberFailuresList, 
    getErpApiFailuresList, 
    getErpInvoiceApiFailuresList, 
    UpdateRefurbishedSettings, 
    getResallBrandList, 
    getAgeValueList, 
    updateResallBrand, 
    updateAgeValue, 
    updatePointSettings, 
    deleteAgeValue, 
    deletePointSettings, 
    getPointSettings, 
    addPointSettings, 
    getERPAccountList, 
    getDashboardAgeingERPAccountList, 
    GetRefurbishedSettings, 
    getResellcycleReportsList, 
    getErpAccountLeadgerList, 
    getErpGeneralLeadgerList, 
    addNewBrandList, 
    getPointValueList, 
    addPointValueList, 
    updatePointValueList, 
    getFranchiseOrderLatestThreeList, 
    getWareHousePinCodeListList, 
    addPaymentList, 
    getInwardLogList, 
    ErrorFramesMessageFromRedux, 
    GetFrameList, 
    getPurchaseListFct, 
    getGSTReportList, 
    addDebitCreditNoteList, 
    getDncnLedgerList, 
    getAllReasonTypeList, 
    getPaymentApprovalList, 
    getDebitCreditNoteReportList, 
    getAllReasonList, 
    editBrandList, 
    GetReturnReplaceInvoiceOrderDetails, 
    GetReturnReplaceFramesOrderDetails, 
    getAllUserList, 
    getAllSalesPersonPerformanceList, 
    GetReturnReplaceOrderList, 
    getCategoryList, 
    getAllBrandList, 
    addCategoryList, 
    updateCategoryList, 
    getSubCategoryList, 
    getAllCategoryList, 
    addSubCategoryList, 
    updateSubCategoryList, 
    getProductList, 
    getAllSubCategoryList, 
    addProductList, 
    updateProductList, 
    getCityList, 
    addCityList, 
    updateCityList, 
    getStateList, 
    getPinCodeList, 
    getAllCityList, 
    getRegionMasterList, 
    addRegionMasterList, 
    updateRegionMasterList, 
    getUserList, 
    getAllRegionList, 
    addUserList, 
    updateUserList, 
    getWareHouseList, 
    getAllStateList, 
    SearchCommonPincodeList, 
    SearchPincodeList, 
    StartCommonLoader, 
    StartMainCommonLoader, 
    ErrorMessageFromRedux, 
    addWareHouseList, 
    updateWareHouseList, 
    getAllWareHouseList, 
    StoredDetailLogReport, 
    StoredDetailReport, 
    getAllProductList, 
    getAllProductCategoryList, 
    StoredLevelReport, 
    GetFranchiseTypeList, 
    updateFranchiseTypeList, 
    addFranchiseTypeList, 
    GetFranchiseUserTypeList, 
    GetAllFranchiseTypeList, 
    addFranchiseUserTypeList, 
    GetAllDealerFranchiseUserTypeList, 
    GetProductGroupList, 
    addProductGroupList, 
    updateProductGroupList, 
    GetAllProductGroupList, 
    updateFranchiseUserTypeList, 
    FranchiseOrdersReportList, 
    getAfterSalesOrdersList, 
    getAFSPendingDispatchOrdersList, 
    getAFSPendingDeliveryOrdersList, 
    getAFSDeliveredOrdersList, 
    getAFSCancelledOrdersList, 
    GetOrderReportDetails, 
    GetPOApprovalsOrderList, 
    GetWareHouseApprovalsOrderList, 
    PendingForAccountsOrderList, 
    PendingForStoresOrderList, 
    GetDispatchList, 
    addDispatchList, 
    updateDispatchList, 
    GetAllDispatchList, 
    setStoreAccessoriesList, 
    setStoreOptionList, 
    setStoreOptionListClear, 
    setStoreCouriersList, 
    setStoreAccessoriesReport, 
    setStorePendingDispatchList, 
    setStoreOptionCouriersList, 
    setStoreOptionCouriersNewList, 
    setStorePendingDeliveryList, 
    setStoreDeliveredList, 
    setStoreRejectedOrderList, 
    clearRedux, 
    setStoreModalInvoiceDetails, 
    setStoreInvoiceList, 
    setStoreEInvoiceSuccessList, 
    setStoreEInvoiceFailureList, 
    setStoreCancelledOrderList, 
    GetStockInwardList, 
    addStateList, 
    updateStateList, 
    getBoxList, 
    getAllBoxList, 
    addBoxList, 
    updateBoxList, 
    updatePurchaseListFct, 
    getOrderLogList, 
    getSalesPersonPerformanceList, 
    getIncentiveSlabList, 
    GetTicketsList, 
    getFBAOrderLogsList, 
    GetTicketsLogsList, 
    getAFSReturnedOrdersList, 
    errorCreateSparesCampatibilityList, 
    updateSparesCampatibilityList, 
    getPurchaseLogListFct, 
    getAllAfterSalesOrderList, 
    GetUnicommerceManifestFalureList, 
    GetHelpDeskCategoryList, 
    addHelpDeskCategoryList, 
    updateHelpDeskCategoryList, 
    GetHelpDeskSubCategoryList, 
    addHelpDeskSubCategoryList, 
    updateHelpDeskSubCategoryList, 
    GetAllHelpDeskCategoryList, 
    GetAllHelpDeskSubCategoryList, 
    GetHelpDeskFAQList, 
    addHelpDeskFAQList, 
    updateHelpDeskFAQList, 
    TicketsVerfiyAPI, 
    getSalesMonthlyRevinueDashboard, 
    getSalesDailyRevinueDashboard, 
    getProductMonthlyRevinueDashboard, 
    getProductDailyRevinueDashboard, 
    getDealerMonthlyRevinueDashboard, 
    getDealerDailyRevinueDashboard, 
    getWarrantyRegistrationReportDashboard, 
    getWarrantyAgeCategoryUseCaseDashboard, 
    getWarrantyStateVsRegistrationsDashboard, 
    getWarrantyProductVsRegistrationsDashboard, 
    getUserDetails, 
    getWarrantyRegionVsRegistrationsDashboard, 
    getCategoryGroupWiseClassificationsDashboard, 
    getDealerWiseAvailableInvetoryDashboard, 
    getDealerSchemeAndIncentiveReportDashboard, 
    getActiveSchemeListReportDashboard, 
    getActiveAssociatedReportDashboard, 
    getActiveAssociatesOwnReportDashboard, 
    getAvailableFrameInventoryDashboard,
    getQuotationsAllReport,
    getQuotationsCreatedPaymentPendReport,
    getQuotationsPaymentApprovalPendReport,
    getQuotationsPaymentPaidReport,
    getQuotationsExpiredReport,
    getQuotationsPaymentCancelledReport,
    GetQuotationsReportDetails,
    getAFSorderSyncQuotationsFailedListing,
    getAFSorderSyncPaymentFailedListing,
    getAfsOrderSyncSaleOrderFailedList,
    GetPaymentReceiptListing
} from '../../redux/actions/adminActions';
import CommonLogout from '../../components/Common/CommonLogout';
import wsSend_request from './ws_request';
import GetWhatsappLmeAPI from '../GetWhatsappLmeAPI';
import BrowserUniqueTabID from '../../components/Common/BrowserUniqueTabID';
import CommonRestQueryParamAPI from '../CommonRestQueryParamAPI';
import OrderErpInvoice from '../OrderErpInvoice';
import GetRequest from '../GetRequest';
import { SEND_QUOTATION_NOTIFICATIONS_API_URL } from '../../components/Shared/constant';

export const ws_response = ({ evt, ws }) => {
    return (async (dispatch, getState) => {
        const ws_onmessage = JSON.parse(evt.data);
        const { device_id, accessToken } = getState()?.adminReducers;
        // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
        var tabID = BrowserUniqueTabID();

        // PRODUCT ID AND WAREHOUSE ID MATCH GROUPS
        const ProductIdMatchGroups = (products_ids, url) => {
            const grouped = {};
            if(url === "available") {
                products_ids.forEach(item => {
                    const key = `${item.warehouse_id}_${item.product_id}`;
                    if (!grouped[key]) {
                        if(url === "available") {
                            grouped[key] = [];
                        } else {
                            grouped[key] = null;
                        };
                    };
                    grouped[key].push(item);
                });
            };
            return grouped;
        };

        // order erp invoice api calling
        const OrderErpInvoiceAPICall = async (Token, id) => {
            const response = await OrderErpInvoice(Token, id, "true");
            if(response?.status === 200) {
                // setMainLoader(false);
                // toast.info("Order approved successfully.");
                // Navigate(FRANCHISE_DISPATCH_URL);
            } else {
                // toast.info("Order approved successfully.");
                // Navigate(FRANCHISE_DISPATCH_URL);
            };
        };

        // Sw notification setup
        if (ws_onmessage?.response?.status === 403) {
            if (accessToken !== "" && accessToken !== null) {
                CommonLogout(accessToken, dispatch);
                dispatch(clearRedux());
            }
        };
        switch (ws_onmessage.url) {
            case "brand_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllBrandList(ws_onmessage?.response));
                        } else {
                            dispatch(getBrandList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getBrandList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "brand_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addNewBrandList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "brand_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editBrandList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "brand_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "category_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllCategoryList(ws_onmessage?.response));
                        } else {
                            dispatch(getCategoryList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(getCategoryList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "category_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "category_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "category_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "subcategory_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllSubCategoryList(ws_onmessage?.response));
                        } else {
                            dispatch(getSubCategoryList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getSubCategoryList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "subcategory_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateCityList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "subcategory_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateSubCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "subcategory_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "spare_item_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllSparesCampatibilityList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getSparesCampatibilityList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(getSparesCampatibilityList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "sale_type_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getAllSaleTypeList(ws_onmessage?.response?.data?.data));
                    } else {
                        dispatch(getAllSaleTypeList(ws_onmessage?.response?.data?.data));
                    };
                };
                break;
            case "spare_item_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "spare_item_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(errorCreateSparesCampatibilityList(ws_onmessage?.response?.data));
                        dispatch(ClearFormSetFutios({
                            url: "spare_item_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "spare_item_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateSparesCampatibilityList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "spare_item_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "spare_item_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "product_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllProductList(ws_onmessage?.response?.data?.data));
                            dispatch(getAllProductCategoryList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getProductList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getProductList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "bundle_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getBundleItemAllList(ws_onmessage?.response?.data));
                        } else {

                        };
                    } else {
                        dispatch(getBundleItemAllList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "product_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addProductList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "product_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateProductList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "franchise_scheme_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllFranchiseSchemeList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getFranchiseSchemeList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getFranchiseSchemeList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "district_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllCityList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getCityList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getCityList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "district_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addCityList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "district_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(editBrandList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "district_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "state_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllStateList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getStateList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getStateList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "state_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addStateList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "state_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateStateList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "state_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "pin_code_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getPinCodeList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getPinCodeList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "region_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllRegionList(ws_onmessage?.response?.data?.data))
                        } else {
                            dispatch(getRegionMasterList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getRegionMasterList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "region_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addRegionMasterList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "region_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateRegionMasterList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "region_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "user_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            if (ws_onmessage?.request?.user_type === "sale_franchise_person") {
                                dispatch(getAllSalesPersonPerformanceList(ws_onmessage?.response?.data?.data));
                            } else {
                                dispatch(getAllUserList(ws_onmessage?.response?.data?.data));
                            };
                        } else {
                            dispatch(getUserList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getUserList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "user_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addUserList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                        dispatch(StartMainCommonLoader({
                            url: "user_add",
                            loader: false
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                        dispatch(StartMainCommonLoader({
                            url: "user_add",
                            loader: false
                        }));
                    };
                };
                break;
            case "user_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateUserList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "user_edit",
                            loader: true
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "user_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                        dispatch(StartMainCommonLoader({
                            url: "user_edit",
                            loader: false
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "user_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                        dispatch(StartMainCommonLoader({
                            url: "user_edit",
                            loader: false
                        }));
                    };
                };
                break;
            case "user_details":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(getUserDetails(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(StartMainCommonLoader({
                                url: "user_details",
                                loader: false
                            }));
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(StartMainCommonLoader({
                                url: "user_details",
                                loader: false
                            }));
                        };
                    };
                };
                break;
            case "warehouse_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllWareHouseList(ws_onmessage?.response?.data?.data));
                            dispatch(getWareHousePinCodeListList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getWareHouseList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(getWareHouseList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "warehouse_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addWareHouseList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "warehouse_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateWareHouseList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "warehouse_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "wh_cancelled":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "wh_order_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                            // whatsapp lime api calling
                            if (ws_onmessage?.response?.data?.order_type === "AO") {
                                GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                            };
                        };
                        dispatch(ClearFormSetFutios({
                            url: "wh_order_approval",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_order_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "pin_code_search":
                if(ws_onmessage?.WareHouseManualSearch === undefined) {
                    if(ws_onmessage?.request_of_diffrent) {
                        if(ws_onmessage?.request_of_diffrent === "pin_code_search_billing") {
                            if (ws_onmessage?.response?.status === 200) {
                                if (ws_onmessage?.DeviceId === device_id) {
                                    dispatch(StartMainCommonLoader({
                                        url: ws_onmessage?.request_of_diffrent,
                                        loader: true
                                    }));
                                    dispatch(SearchBillingPincodeList(ws_onmessage?.response?.data));
                                    dispatch(ErrorMessageFromRedux({
                                        API: ws_onmessage?.request_of_diffrent,
                                        Error: "",
                                    }));
                                    setTimeout(() => {
                                        dispatch(StartMainCommonLoader({
                                            url: ws_onmessage?.request_of_diffrent,
                                            loader: false
                                        }));
                                    }, 1000);
                                };
                            } else {
                                if (ws_onmessage?.DeviceId === device_id) {
                                    dispatch(StartMainCommonLoader({
                                        url: ws_onmessage?.request_of_diffrent,
                                        loader: true
                                    }));
                                    dispatch(ErrorMessageFromRedux({
                                        API: ws_onmessage?.request_of_diffrent,
                                        Error: "Incorrect PIN. Please try again.",
                                    }));
                                    setTimeout(() => {
                                        dispatch(StartMainCommonLoader({
                                            url: ws_onmessage?.request_of_diffrent,
                                            loader: false
                                        }));
                                    }, 1000);
                                };
                            };
                        } else if(ws_onmessage?.request_of_diffrent === "pin_code_search_customer") {
                            if (ws_onmessage?.response?.status === 200) {
                                if (ws_onmessage?.DeviceId === device_id) {
                                    dispatch(SearchShippingPincodeList(ws_onmessage?.response?.data));
                                    dispatch(StartMainCommonLoader({
                                        url: ws_onmessage?.request_of_diffrent,
                                        loader: true
                                    }));
                                    dispatch(ErrorMessageFromRedux({
                                        API: ws_onmessage?.request_of_diffrent,
                                        Error: "",
                                    }));
                                    setTimeout(() => {
                                        dispatch(StartMainCommonLoader({
                                            url: ws_onmessage?.request_of_diffrent,
                                            loader: false
                                        }));
                                    }, 1000);
                                };
                            } else {
                                if (ws_onmessage?.DeviceId === device_id) {
                                    dispatch(StartMainCommonLoader({
                                        url: ws_onmessage?.request_of_diffrent,
                                        loader: true
                                    }));
                                    dispatch(ErrorMessageFromRedux({
                                        API: ws_onmessage?.request_of_diffrent,
                                        Error: "Incorrect PIN. Please try again.",
                                    }));
                                    setTimeout(() => {
                                        dispatch(StartMainCommonLoader({
                                            url: ws_onmessage?.request_of_diffrent,
                                            loader: false
                                        }));
                                    }, 1000);
                                };
                            };
                        };
                    } else {
                        if (ws_onmessage?.response?.status === 200) {
                            if (ws_onmessage?.DeviceId === device_id) {
                                dispatch(StartCommonLoader(true));
                                dispatch(SearchPincodeList(ws_onmessage?.response?.data));
                                dispatch(ErrorMessageFromRedux({
                                    API: "pin_code_search",
                                    Error: "",
                                }));
                                setTimeout(() => {
                                    dispatch(StartCommonLoader(false));
                                }, 1000);
                            };
                        } else {
                            if (ws_onmessage?.DeviceId === device_id) {
                                dispatch(StartCommonLoader(true));
                                dispatch(ErrorMessageFromRedux({
                                    API: "pin_code_search",
                                    Error: "Incorrect PIN. Please try again.",
                                }));
                                setTimeout(() => {
                                    dispatch(StartCommonLoader(false));
                                }, 1000);
                            };
                        };
                    };
                } else {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.DeviceId === device_id) {
                            dispatch(SearchCommonPincodeList(ws_onmessage?.response?.data));
                        };
                    } else {
                        if (ws_onmessage?.DeviceId === device_id) {
                            dispatch(SearchCommonPincodeList(ws_onmessage?.response?.data));
                        };
                    };
                }
                break;
            case "store_frame_list":
                if (ws_onmessage?.DeviceId === device_id && ws_onmessage?.stock_url === "StockInwardLogDetails") {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StoredDetailLogReport(ws_onmessage?.response?.data));
                    } else {
                        dispatch(StoredDetailLogReport(ws_onmessage?.response?.data));
                    };
                } else {
                    if (ws_onmessage?.response?.status === 200) {
                        if(ws_onmessage?.DeviceId === device_id) {
                            dispatch(StoredDetailReport(ws_onmessage?.response?.data));
                        };
                    } else {
                        if(ws_onmessage?.DeviceId === device_id) {
                            dispatch(StoredDetailReport(ws_onmessage?.response?.data));
                        };
                    };
                };
                
                if (ws_onmessage?.response?.status === 200) {
                    let ProductIdMatchArray = ProductIdMatchGroups(ws_onmessage?.response?.data?.data, "available");
                    dispatch(setStoreOptionList(ProductIdMatchArray));
                } else {
                    let ProductIdMatchArray = ProductIdMatchGroups(ws_onmessage?.response?.data?.data, "");
                    dispatch(setStoreOptionList(ProductIdMatchArray));
                };
                break;
            case "store_level_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(StoredLevelAllReport(ws_onmessage?.response?.data));
                        } else {
                            dispatch(StoredLevelReport(ws_onmessage?.response?.data));
                        };
                    } else {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(StoredLevelAllReport(ws_onmessage?.response?.data));
                        } else {
                            dispatch(StoredLevelReport(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "franchise_type_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllFranchiseTypeList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(GetFranchiseTypeList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetFranchiseTypeList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "franchise_type_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addFranchiseTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "franchise_type_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateFranchiseTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_type_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "franchise_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllDealerFranchiseUserTypeList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(GetFranchiseUserTypeList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetFranchiseUserTypeList(ws_onmessage?.response?.data));
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllDealerFranchiseUserTypeList(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "franchise_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addFranchiseUserTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "franchise_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateFranchiseUserTypeList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "franchise_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "product_group_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllProductGroupList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(GetProductGroupList(ws_onmessage?.response?.data));
                        }
                    } else {
                        dispatch(GetProductGroupList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "product_group_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addProductGroupList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "product_group_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateProductGroupList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_group_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_add",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "user_edit",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_add",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "order_add",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.response?.msg?.wh_from) {
                                toast.error(ws_onmessage?.response?.msg?.wh_from);
                            } else if (ws_onmessage?.response?.msg?.wh_to) {
                                toast.error(ws_onmessage?.response?.msg?.wh_to);
                            } else if (ws_onmessage?.response?.msg?.wh_from || ws_onmessage?.response?.msg?.wh_to) {
                                toast.error(ws_onmessage?.response?.msg?.wh_from + " " + ws_onmessage?.response?.msg?.wh_to);
                            } else {
                                toast.error(ws_onmessage?.response?.msg);
                            };
                        };
                    };
                };
                break;
            case "afs_order_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_add",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "order_add",
                            loader: false
                        }));

                        if (ws_onmessage?.TabId === tabID) {
                            if(ws_onmessage?.response?.data?.order_type === "FO" || ws_onmessage?.response?.data?.order_type === "CO") {
                                let query_params = `?order_id=${ws_onmessage?.response?.data?.id}&is_afs=true&is_uni_com=false&is_order=true&email_status=confirmed`
                                CommonRestQueryParamAPI(accessToken, query_params, "send_mail_pdf_unicommerce_api_call");
                            };
    
                            // whatsapp lime api calling
                            GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);

                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_add",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "order_add",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.response?.msg?.wh_from) {
                                toast.error(ws_onmessage?.response?.msg?.wh_from);
                            } else if (ws_onmessage?.response?.msg?.wh_to) {
                                toast.error(ws_onmessage?.response?.msg?.wh_to);
                            } else if (ws_onmessage?.response?.msg?.wh_from || ws_onmessage?.response?.msg?.wh_to) {
                                toast.error(ws_onmessage?.response?.msg?.wh_from + " " + ws_onmessage?.response?.msg?.wh_to);
                            } else {
                                toast.error(ws_onmessage?.response?.msg);
                            };
                        };
                    };
                };
                break;
            case "quotation_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            // CALLING SEND QUOTATION NOTIFICATION
                            await GetRequest(`${SEND_QUOTATION_NOTIFICATIONS_API_URL}/${ws_onmessage?.response?.data?.id}`, accessToken);
                            
                            dispatch(ClearFormSetFutios({
                                url: "quotation_add",
                                action: ws_onmessage?.response?.status
                            }));
                            dispatch(StartMainCommonLoader({
                                url: "quotation_add",
                                loader: false
                            }));
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "quotation_add",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "quotation_add",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "st_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                            // whatsapp lime api calling
                            if (ws_onmessage?.response?.data?.order_type === "AO") {
                                GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                            };
                        };
                        
                        dispatch(ClearFormSetFutios({
                            url: "st_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(setStoreOptionListClear({
                            url: "st_approval",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "st_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.request?.order_data === "list") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(FranchiseOrdersReportList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(FranchiseOrdersReportList(ws_onmessage?.response?.data));
                        }
                    } else {
                        if (ws_onmessage?.request?.status === "OPEN,SO_HALF_APPROVAL") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(GetPOApprovalsOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(GetPOApprovalsOrderList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "SO_APPROVED" || ws_onmessage?.request?.status === "WH_PENDING") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(GetWareHouseApprovalsOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(GetWareHouseApprovalsOrderList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "WH_APPROVED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(PendingForAccountsOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(PendingForAccountsOrderList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "AC_APPROVED,WH_APPROVED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(PendingForStoresOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(PendingForStoresOrderList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "ST_APPROVED,HALF_DISPATCH") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(setStorePendingDispatchList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(setStorePendingDispatchList({
                                    data: [],
                                    pagination: {}
                                }))
                            };
                        } else if (ws_onmessage?.request?.status === "DISPATCHED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(setStorePendingDeliveryList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(setStorePendingDeliveryList({
                                    data: [],
                                    pagination: {}
                                }))
                            };
                        } else if (ws_onmessage?.request?.status === "DELIVERED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(setStoreDeliveredList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(setStoreDeliveredList({ data: [], pagination: {} }))
                            };
                        } else if (ws_onmessage?.request?.status === "REJECTED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(setStoreRejectedOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(setStoreRejectedOrderList({ data: [], pagination: {} }))
                            };
                        } else if (ws_onmessage?.request?.status === "CANCELLED,CANCELLED_REQUEST") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(setStoreCancelledOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(setStoreCancelledOrderList({ data: [], pagination: {} }))
                            };
                        } else if (ws_onmessage?.request?.status === "RETURN,REPLACE,RETURN_REQUEST,RETURN_IN_TRANSIT,REPLACE_REQUEST") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(GetReturnReplaceOrderList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(GetReturnReplaceOrderList(ws_onmessage?.response?.data));
                            };
                        } else {
                            // if (ws_onmessage?.response?.status === 200) {
                            //     dispatch(FranchiseOrdersReportList(ws_onmessage?.response?.data));
                            // } else {
                            //     dispatch(FranchiseOrdersReportList(ws_onmessage?.response?.data));
                            // };
                        };
                    }
                };
                break;
            case "afs_order_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.request?.order_data === "list") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getAllAfterSalesOrderList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getAllAfterSalesOrderList(ws_onmessage?.response?.data));
                        };
                    } else {
                        if (ws_onmessage?.request?.status === "OPEN,INVOICE_PENDING") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getAfterSalesOrdersList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getAfterSalesOrdersList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "DISPATCH_PENDING") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getAFSPendingDispatchOrdersList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getAFSPendingDispatchOrdersList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "DISPATCHED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getAFSPendingDeliveryOrdersList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getAFSPendingDeliveryOrdersList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "DELIVERED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getAFSDeliveredOrdersList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getAFSDeliveredOrdersList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "CANCELLED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getAFSCancelledOrdersList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getAFSCancelledOrdersList(ws_onmessage?.response?.data));
                            };
                        } else if (ws_onmessage?.request?.status === "RETURN") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getAFSReturnedOrdersList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getAFSReturnedOrdersList(ws_onmessage?.response?.data));
                            };
                        };
                    };
                };
                break;
            case "invoice_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.request?.invoice_status === "") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreInvoiceList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreInvoiceList({ data: [], pagination: {} }));
                            dispatch(setStoreEInvoiceSuccessList({ data: [], pagination: {} }));
                        };
                    } else if (ws_onmessage?.request?.status !== "CAN") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreEInvoiceSuccessList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreEInvoiceSuccessList({ data: [], pagination: {} }));
                        };
                    } else if (ws_onmessage?.request?.status == "CAN") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(setStoreEInvoiceFailureList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(setStoreEInvoiceFailureList({ data: [], pagination: {} }));
                        };
                    }
                }
                break;
            case "ac_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "ac_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "ac_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_detail":
                if (ws_onmessage?.modal && ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartCommonLoader(false));
                        const payload = ws_onmessage?.response?.data;
                        if (ws_onmessage?.request?.qr_code) {
                            payload.qr_code = ws_onmessage?.request?.qr_code;
                        }
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(setStoreModalInvoiceDetails(payload));
                        };
                    } else {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(setStoreModalInvoiceDetails(null));
                        };
                    }
                } else if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.order_details_type === undefined) {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(StartCommonLoader(false));
                            if (ws_onmessage?.request?.order_by === "invoice") {
                                dispatch(GetReturnReplaceInvoiceOrderDetails(ws_onmessage?.response?.data));
                                if (ws_onmessage?.response?.data?.order?.warehouse_id) {
                                    let ProductId = ws_onmessage?.response?.data?.order_items_list?.filter((elm) => elm?.is_bike)?.map((elm) => elm?.product_id)?.toString();

                                    if(ProductId?.length > 0) {
                                        const request = {
                                            "transmit": "broadcast",
                                            "url": "store_frame_list",
                                            "request": {
                                                "status": "",
                                                "limit": 5000,
                                                "page_no": 1,
                                                "search": "",
                                                "from_date": null,
                                                "to_date": null,
                                                "warehouse_id": ws_onmessage?.response?.data?.order?.warehouse_id,
                                                "product_id": ProductId,
                                                "page_no": "1",
                                                "search": "",
                                                "order_by": "-updated_at",
                                                "timezone": "",
                                                "DeviceId": device_id
                                            }
                                        };
                                        wsSend_request(ws, request);  
                                    };
                                }
                                dispatch(StartMainCommonLoader({
                                    url: "order_detail_invoice",
                                    loader: false
                                }));
                            } else if (ws_onmessage?.request?.order_by === "frame") {
                                dispatch(GetReturnReplaceFramesOrderDetails(ws_onmessage?.response?.data));
                                if (ws_onmessage?.response?.data?.frame?.warehouse_id) {
                                    const request = {
                                        "transmit": "broadcast",
                                        "url": "store_frame_list",
                                        "request": {
                                            "status": "",
                                            "limit": 5000,
                                            "page_no": 1,
                                            "search": "",
                                            "from_date": null,
                                            "to_date": null,
                                            "warehouse_id": ws_onmessage?.response?.data?.frame?.warehouse_id,
                                            "product_id": ws_onmessage?.response?.data?.frame?.product_id,
                                            "page_no": "1",
                                            "search": "",
                                            "order_by": "-updated_at",
                                            "timezone": "",
                                            "DeviceId": device_id
                                        }
                                    };
                                    wsSend_request(ws, request);
                                }
                                dispatch(StartMainCommonLoader({
                                    url: "order_detail_frame",
                                    loader: false
                                }));
                            } else {
                                dispatch(StartMainCommonLoader({
                                    url: "order_detail",
                                    loader: false
                                }));
                                dispatch(GetOrderReportDetails(ws_onmessage?.response?.data));
                                if (ws_onmessage?.request?.get_franchise === "yes" && ws_onmessage?.response?.data?.order?.warehouse_id) {
                                    let ProductId = ws_onmessage?.response?.data?.order_items_list?.filter((elm) => elm?.is_bike)?.map((elm) => elm?.product_id)?.toString();
                                    let ProductIdAccessories = ws_onmessage?.response?.data?.order_items_list?.filter((elm) => !elm?.is_bike)?.map((elm) => elm?.product_id)?.toString();

                                    let api_url = "";
                                    if(ProductId?.length > 0) {
                                        api_url = "store_frame_list";   
                                    } else {
                                        api_url = "store_accessories_list";   
                                    };
                                    const request = {
                                        "transmit": "broadcast",
                                        "url": api_url,
                                        "request": {
                                            "status": "UNSOLD",
                                            "limit": "5000",
                                            "from_date": "",
                                            "to_date": "",
                                            "order_type": "",
                                            "warehouse_id": ws_onmessage?.response?.data?.order?.warehouse_id,
                                            // "product_id": ProductId,
                                            "page_no": "1",
                                            "search": "",
                                            "order_by": "-updated_at",
                                            "timezone": "",
                                            "DeviceId": device_id
                                        }
                                    };
                                    if(ProductId?.length > 0) {
                                        request.request.product_id = ProductId;   
                                    } else {
                                        request.request.product_id = ProductIdAccessories;   
                                    };
                                    wsSend_request(ws, request);
                                }
                            }
                        } else {
                            if (ws_onmessage?.DeviceId === device_id) {
                                if (ws_onmessage?.request?.order_by === "invoice") {
                                    dispatch(GetReturnReplaceInvoiceOrderDetails(ws_onmessage?.response?.data));
                                    if (ws_onmessage?.TabId === tabID) {
                                        toast.error(ws_onmessage?.response?.msg);
                                    };
                                    dispatch(StartMainCommonLoader({
                                        url: "order_detail_invoice",
                                        loader: false
                                    }));
                                } else if (ws_onmessage?.request?.order_by === "frame") {
                                    dispatch(GetReturnReplaceFramesOrderDetails(ws_onmessage?.response?.data));
                                    if (ws_onmessage?.TabId === tabID) {
                                        toast.error(ws_onmessage?.response?.msg);
                                    };
                                    dispatch(StartMainCommonLoader({
                                        url: "order_detail_frame",
                                        loader: false
                                    }));
                                } else {
                                    dispatch(StartMainCommonLoader({
                                        url: "order_detail",
                                        loader: false
                                    }));
                                    dispatch(GetOrderReportDetails(ws_onmessage?.response?.data));
                                };
                            };
                        };
                    } else {
                        if (ws_onmessage?.response?.status === 200) {
                            if (ws_onmessage?.TabId === tabID) {
                                dispatch(StartMainCommonLoader({
                                    url: "order_detail_warehouse_resign",
                                    loader: false
                                }));
                                dispatch(GetOrderDetailByOrderCode(ws_onmessage?.response?.data));
                            };
                        } else {
                            if (ws_onmessage?.TabId === tabID) {
                                dispatch(StartMainCommonLoader({
                                    url: "order_detail_warehouse_resign",
                                    loader: false
                                }));
                                dispatch(GetOrderDetailByOrderCode({}));
                                toast.error(ws_onmessage?.response?.msg);
                            };
                        }
                    }
                };
                break;
            case "afs_order_detail":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(GetafterSalesOrdersDetails(ws_onmessage?.response?.data));
                            setTimeout(() => {
                                dispatch(StartMainCommonLoader({
                                    url: "afs_order_detail",
                                    loader: false
                                }));
                            }, 1000);

                            if(ws_onmessage?.modal) {
                                dispatch(setStoreModalInvoiceDetails(ws_onmessage?.response?.data));
                            };
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(StartMainCommonLoader({
                                url: "afs_order_detail",
                                loader: false
                            }));
                            dispatch(GetafterSalesOrdersDetails(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "quotation_detail":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(GetQuotationsReportDetails(ws_onmessage?.response?.data));
                            setTimeout(() => {
                                dispatch(StartMainCommonLoader({
                                    url: "quotation_detail",
                                    loader: false
                                }));
                            }, 1000);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(StartMainCommonLoader({
                                url: "quotation_detail",
                                loader: false
                            }));
                            dispatch(GetQuotationsReportDetails(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "store_accessories_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(setStoreAccessoriesReport(ws_onmessage?.response?.data));
                    } else {
                        dispatch(setStoreAccessoriesReport(ws_onmessage?.response?.data));
                    };
                };
                if (ws_onmessage?.response?.status === 200) {
                    // dispatch(setStoreAccessoriesList(ws_onmessage?.response?.data))
                    dispatch(setStoreOptionList({ id: `${ws_onmessage?.request?.warehouse_id}_${ws_onmessage?.request?.product_id}`, data: ws_onmessage?.response?.data }))
                } else {
                    dispatch(setStoreOptionList({ id: `${ws_onmessage?.request?.warehouse_id}_${ws_onmessage?.request?.product_id}`, data: [] }))
                }
                break;
            case "so_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                        dispatch(ClearFormSetFutios({
                            url: "so_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "so_approval",
                            loader: false
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "so_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "so_approval",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "wh_approval":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                            // whatsapp lime api calling
                            if ((ws_onmessage?.response?.data?.order_type === "AO" || ws_onmessage?.response?.data?.order_type === "FO") && ws_onmessage?.response?.data?.order_status === "WH_APPROVED") {
                                GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                            };
                        };
                        
                        dispatch(ClearFormSetFutios({
                            url: "wh_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "wh_approval",
                            loader: false
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_approval",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "wh_approval",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        }
                    };
                };
                break;
            case "wh_reassign":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                            // whatsapp lime api calling
                            // if ((ws_onmessage?.response?.data?.order_type === "AO" || ws_onmessage?.response?.data?.order_type === "FO") && ws_onmessage?.response?.data?.order_status === "WH_APPROVED") {
                            //     GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                            // };
                        };
                        
                        dispatch(ClearFormSetFutios({
                            url: "wh_reassign",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "wh_reassign",
                            loader: false
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "wh_reassign",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "wh_reassign",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        }
                    };
                };
                break;
            case "dispatch_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllDispatchList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(GetDispatchList(ws_onmessage?.response?.data));
                        };
                    } else {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllDispatchList([]));
                        } else {
                            dispatch(GetDispatchList(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "dispatch_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addDispatchList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_add",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "dispatch_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateDispatchList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_edit",
                            action: ws_onmessage?.response?.status
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dispatch_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "courier_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            const data = ws_onmessage?.response?.data?.data?.map((item) => ({
                                ...item,
                                label: item?.courier_name,
                                value: item?.id
                            }))
                            dispatch(setStoreOptionCouriersList({ data: data, pagination: ws_onmessage?.response?.data?.pagination }))
                        } else {
                            dispatch(setStoreCouriersList(ws_onmessage?.response?.data));
                        }
                    } else {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(setStoreOptionCouriersList({ data: [], pagination: {} }))
                        } else {
                            dispatch(setStoreCouriersList({ data: [], pagination: {} }))
                        }
                    };
                };
                break;
            case "salesperson_performance_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        // dispatch(getSalesPersonPerformanceList(ws_onmessage?.response?.data));
                    } else {
                        // dispatch(getSalesPersonPerformanceList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "admin_scheme_report":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.module_name === "sales-person-performance") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getSalesPersonPerformanceList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getSalesPersonPerformanceList(ws_onmessage?.response?.data));
                        };
                    } else if(ws_onmessage?.module_name === "Active-Schemes-Dashboard") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getActiveSchemeListReportDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getActiveSchemeListReportDashboard(ws_onmessage?.response?.data));
                        };
                    } else if(ws_onmessage?.module_name === "Active-Associates-Listing") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getActiveAssociatesOwnReportDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getActiveAssociatesOwnReportDashboard(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "related_courier_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getRelatedCourierList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getRelatedCourierList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "courier_list_new":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "courier_list_new",
                            loader: false
                        }));
                        if (ws_onmessage?.request?.limit === 5000) {
                            if(ws_onmessage?.request?.is_load_more) {
                                const data = ws_onmessage?.response?.data?.data?.map((item) => ({
                                    ...item,
                                    label: item?.courier_name,
                                    value: item?.id
                                }))
                                dispatch(setStoreOptionCouriersNewList({ data: data, pagination: ws_onmessage?.response?.data?.pagination }))
                            } else {
                                const data = ws_onmessage?.response?.data?.data?.map((item) => ({
                                    ...item,
                                    label: item?.courier_name,
                                    value: item?.id
                                }))
                                dispatch(setStoreOptionBeforeLoadCouriersNewList({ data: data, pagination: ws_onmessage?.response?.data?.pagination }))
                                dispatch(setStoreOptionCouriersNewList({ data: data, pagination: ws_onmessage?.response?.data?.pagination }))
                            };
                        }
                    } else {
                        dispatch(StartMainCommonLoader({
                            url: "courier_list_new",
                            loader: false
                        }));
                        if (ws_onmessage?.request?.limit === 5000) {
                            if(ws_onmessage?.request?.is_load_more) {
                                dispatch(setStoreOptionCouriersNewList({ data: [], pagination: {} }))
                            } else {
                                dispatch(setStoreOptionBeforeLoadCouriersNewList({ data: [], pagination: {} }))
                                dispatch(setStoreOptionCouriersNewList({ data: [], pagination: {} }))
                            };
                        }
                    };
                };
                break;
            case "courier_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "courier_edit":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "courier_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_dispatch":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if(ws_onmessage?.request?.is_reverse) {
                            dispatch(ClearFormSetFutios({
                                url: "order_dispatch_reverse",
                                action: ws_onmessage?.response?.status
                            }));
                        } else {
                            dispatch(ClearFormSetFutios({
                                url: "order_dispatch",
                                action: ws_onmessage?.response?.status
                            }));
                            dispatch(StartCommonLoader(false));

                            if (ws_onmessage?.TabId === tabID) {
                                // whatsapp lime api calling
                                if (ws_onmessage?.response?.data?.order_type === "AO" || ws_onmessage?.response?.data?.order_type === "FO") {
                                    GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                                };
                            };
                        };
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                            dispatch(StartCommonLoader(false));
                            // whatsapp lime api calling
                            if (ws_onmessage?.response?.data?.order_type === "AO" || ws_onmessage?.response?.data?.order_type === "FO") {
                                GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                            };

                            if(ws_onmessage?.response?.data?.order_type === "FO") {
                                let query_params = `?order_id=${ws_onmessage?.response?.data?.id}&is_afs=false&is_uni_com=false&is_order=false&email_status=dispatched`
                                CommonRestQueryParamAPI(accessToken, query_params, "send_mail_pdf_unicommerce_api_call");
                            } else if(ws_onmessage?.response?.data?.order_type === "AO") {
                                let query_params = `?order_id=${ws_onmessage?.response?.data?.id}&is_afs=false&is_uni_com=true&is_order=false&email_status=""`
                                CommonRestQueryParamAPI(accessToken, query_params, "send_mail_pdf_unicommerce_api_call");
                            };
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if(ws_onmessage?.request?.is_reverse) {
                            dispatch(ClearFormSetFutios({
                                url: "order_dispatch_reverse",
                                action: ws_onmessage?.response?.status
                            }));
                        } else {
                            dispatch(ClearFormSetFutios({
                                url: "order_dispatch",
                                action: ws_onmessage?.response?.status
                            }));
                            dispatch(StartCommonLoader(false));
                        };
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.response?.msg?.details !== undefined) {
                                toast.error(ws_onmessage?.response?.msg?.details[0]);
                            };
                            if (Object?.keys(ws_onmessage?.response?.msg)?.length > 0) {
                                toast.error(ws_onmessage?.response?.msg?.distance);
                            };
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "afs_order_dispatch":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_dispatch",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "afs_order_dispatch",
                            action: ws_onmessage?.response?.status
                        }));

                        if (ws_onmessage?.TabId === tabID) {
                            if(ws_onmessage?.response?.data?.order_type === "FO" || ws_onmessage?.response?.data?.order_type === "CO") {
                                let query_params = `?order_id=${ws_onmessage?.response?.data?.id}&is_afs=true&is_uni_com=false&is_order=false&email_status=dispatched`
                                CommonRestQueryParamAPI(accessToken, query_params, "send_mail_pdf_unicommerce_api_call");
                            };
                            // whatsapp lime api calling
                            GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);

                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_dispatch",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "afs_order_dispatch",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.response?.msg?.details !== undefined) {
                                toast.error(ws_onmessage?.response?.msg?.details[0]);
                            };
                            if (Object?.keys(ws_onmessage?.response?.msg)?.length > 0) {
                                toast.error(ws_onmessage?.response?.msg?.distance);
                            };
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "afs_order_delivered":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_delivered",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "afs_order_delivered",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_delivered",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "afs_order_delivered",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.response?.msg?.details !== undefined) {
                                toast.error(ws_onmessage?.response?.msg?.details[0]);
                            };
                            if (Object?.keys(ws_onmessage?.response?.msg)?.length > 0) {
                                toast.error(ws_onmessage?.response?.msg?.distance);
                            };
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_delivered":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);

                            if(ws_onmessage?.response?.data?.order_type === "AO") {
                                let query_params = `?order_id=${ws_onmessage?.response?.data?.id}&is_afs=false&is_uni_com=true&is_order=false&email_status=""`
                                CommonRestQueryParamAPI(accessToken, query_params, "send_mail_pdf_unicommerce_api_call");
                            };
                        };
                        // whatsapp lime api calling
                        // if(ws_onmessage?.response?.data?.order_type === "AO") {
                        //     GetWhatsappLmeAPI(accessToken, ws_onmessage?.response?.data?.id);
                        // };
                        dispatch(ClearFormSetFutios({
                            url: "order_delivered",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "order_delivered",
                            loader: false
                        }));
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_delivered",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "order_delivered",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_cancelled":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "order_cancelled",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "order_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "order_cancelled",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "order_cancelled",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "afs_order_cancelled_reject_return":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_cancelled_reject_return",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "afs_order_cancelled_reject_return",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_cancelled_reject_return",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "afs_order_cancelled_reject_return",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_pause":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_pause",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    }; 
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_pause",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "invoice_cancel":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "invoice_cancel",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "invoice_cancel",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage.response.msg) {
                                if (Array.isArray(ws_onmessage.response.msg)) {
                                    ws_onmessage.response.msg.forEach(message => {
                                        if (typeof message?.msg === "string") {
                                            toast.error(message?.msg);
                                        } else {
                                            // Handle other types of messages if needed
                                        }
                                    });
                                } else if (typeof ws_onmessage.response.msg === "string") {
                                    toast.error(ws_onmessage.response.msg);
                                } else {
                                    // Handle other types of messages if needed
                                }
                            }
                        }
                    };
                };
                break;
            case "stock_inward_add":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "stock_inward_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "stock_inward_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "stock_inward_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetStockInwardList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(GetStockInwardList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "dncn_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.txn_type !== undefined) {
                            dispatch(getPaymentApprovalList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getDebitCreditNoteReportList(ws_onmessage?.response?.data));
                        }
                    } else {
                        if (ws_onmessage?.request?.txn_type !== undefined) {
                            dispatch(getPaymentApprovalList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getDebitCreditNoteReportList(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "box_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(getAllBoxList(ws_onmessage?.response?.data?.data));
                        } else {
                            dispatch(getBoxList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(getBoxList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "box_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addBoxList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "box_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateBoxList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "box_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "reason_types_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 20) {
                            dispatch(getAllReasonTypeList(ws_onmessage?.response?.data?.data));
                        }
                    }
                };
                break;
            case "reason_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 20) {
                            dispatch(getAllReasonList(ws_onmessage?.response?.data?.data));
                        }
                    }
                };
                break;
            case "dncn_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addDebitCreditNoteList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dncn_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dncn_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "dncn_ledger":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getDncnLedgerList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getDncnLedgerList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "get_frame_number":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(StartMainCommonLoader({
                        url: "get_frame_number",
                        loader: false
                    }));
                    dispatch(ErrorFramesMessageFromRedux({
                        API: "get_frame_number",
                        Error: "",
                    }));
                    dispatch(GetFrameList(ws_onmessage?.response?.data));
                } else {
                    dispatch(StartMainCommonLoader({
                        url: "get_frame_number",
                        loader: false
                    }));
                    dispatch(ErrorFramesMessageFromRedux({
                        API: "get_frame_number",
                        Error: ws_onmessage?.response?.msg,
                    }));
                    dispatch(GetFrameList({}));
                };
                break;
            case "dncn_payment":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addPaymentList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dncn_payment",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "dncn_payment",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_rr":
                if (ws_onmessage?.response?.status === 200) {
                    // dispatch(addPaymentList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "order_rr",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "order_rr",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);

                            if (ws_onmessage?.response?.data?.order_type === "AO") {
                                if(ws_onmessage?.response?.data?.r_erp_delivery_code !== null && ws_onmessage?.response?.data?.r_erp_delivery_code !== "") {
                                    OrderErpInvoiceAPICall(accessToken, ws_onmessage?.response?.data?.id);
                                } else {

                                };
                            } else {
                                if(ws_onmessage?.response?.data?.r_erp_delivery_code !== null && ws_onmessage?.response?.data?.r_erp_delivery_code !== "") {
                                    OrderErpInvoiceAPICall(accessToken, ws_onmessage?.response?.data?.id);
                                } else {
                                    
                                };
                            };
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "order_rr",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "order_rr",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "order_replace":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_replace",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_replace",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "gst_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "gst_list",
                            loader: false
                        }));
                        dispatch(getGSTReportList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(StartMainCommonLoader({
                            url: "gst_list",
                            loader: false
                        }));
                        dispatch(getGSTReportList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "purchase_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getPurchaseListFct(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getPurchaseListFct(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "purchase_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updatePurchaseListFct(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "purchase_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "purchase_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "purchase_log_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getPurchaseLogListFct(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getPurchaseLogListFct(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "order_logs":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "order_logs",
                            loader: false
                        }));
                        dispatch(getOrderLogList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getOrderLogList(ws_onmessage?.response?.data));
                        dispatch(StartMainCommonLoader({
                            url: "order_logs",
                            loader: false
                        }));
                    }
                };
                break;
            case "afs_order_logs":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_logs",
                            loader: false
                        }));
                        dispatch(getOrderLogList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getOrderLogList(ws_onmessage?.response?.data));
                        dispatch(StartMainCommonLoader({
                            url: "afs_order_logs",
                            loader: false
                        }));
                    };
                };
                break;
            case "quotation_logs":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "quotation_logs",
                            loader: false
                        }));
                        dispatch(getOrderLogList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getOrderLogList(ws_onmessage?.response?.data));
                        dispatch(StartMainCommonLoader({
                            url: "quotation_logs",
                            loader: false
                        }));
                    };
                };
                break;
            case "stock_inward_logs":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.response !== undefined) {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getInwardLogList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getInwardLogList(ws_onmessage?.response?.data));
                        };
                    }
                };
                break;
            case "get_franchise_order":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "get_franchise_order",
                            loader: false
                        }));
                        dispatch(getFranchiseOrderLatestThreeList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(StartMainCommonLoader({
                            url: "get_franchise_order",
                            loader: false
                        }));
                        dispatch(getFranchiseOrderLatestThreeList([]));
                    };
                };
                break;
            case "refurbished_point_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getPointValueList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getPointValueList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "refurbished_point_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addPointValueList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_point_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updatePointValueList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "erp_account":    
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.module_name === "erp-general-outstanding-report") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getERPAccountList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getERPAccountList(ws_onmessage?.response?.data));
                        };
                    } else {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getDashboardAgeingERPAccountList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getDashboardAgeingERPAccountList(ws_onmessage?.response?.data));
                        };
                    }
                };
                break;
            case "erp_account_leadger_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getErpAccountLeadgerList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getErpAccountLeadgerList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "erp_general_leadger_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getErpGeneralLeadgerList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getErpGeneralLeadgerList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "refurbished_settings_get":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetRefurbishedSettings(ws_onmessage?.response?.data));
                    } else {
                        dispatch(GetRefurbishedSettings(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "refurbished_settings_update":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(UpdateRefurbishedSettings(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if(ws_onmessage?.request?.seller_type === "dealer") {
                            dispatch(getResellcycleReportsList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getResellcycleCustomerReportsList(ws_onmessage?.response?.data));
                        };
                    } else {
                        if(ws_onmessage?.request?.seller_type === "dealer") {
                            dispatch(getResellcycleReportsList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getResellcycleCustomerReportsList(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "refurbished_point_value_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getPointSettings(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getPointSettings(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "refurbished_point_value_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addPointSettings(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_value_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_value_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_point_value_delete":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(deletePointSettings(ws_onmessage));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_value_delete",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_value_delete",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_point_value_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updatePointSettings(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_value_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_point_value_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_age_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getAgeValueList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getAgeValueList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "refurbished_age_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addAgeValue(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_age_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_age_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_age_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateAgeValue(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_age_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_age_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_age_delete":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(deleteAgeValue(ws_onmessage));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_age_delete",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_age_delete",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_brand_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getResallBrandList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getResallBrandList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "refurbished_brand_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addResallBrand(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_brand_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_brand_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "refurbished_brand_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateResallBrand(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_brand_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "refurbished_brand_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "erp_order_log_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.request_type === undefined) {
                        if(!ws_onmessage?.request?.is_invoice && ws_onmessage?.request?.is_delivery === undefined) {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getErpApiFailuresList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getErpApiFailuresList(ws_onmessage?.response?.data));
                            };
                        } else if(ws_onmessage?.request?.is_invoice && ws_onmessage?.request?.is_delivery === undefined) {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getErpInvoiceApiFailuresList(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getErpInvoiceApiFailuresList(ws_onmessage?.response?.data));
                            };
                        } else if(ws_onmessage?.request?.is_invoice === undefined) {
                            if(ws_onmessage?.request?.is_delivery) {
                                if (ws_onmessage?.response?.status === 200) {
                                    dispatch(getErpDeliveryNoteFrameNumberFailuresList(ws_onmessage?.response?.data));
                                } else {
                                    dispatch(getErpDeliveryNoteFrameNumberFailuresList(ws_onmessage?.response?.data));
                                };
                            }
                        };
                    } else {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getAfsOrderSyncSaleOrderFailedList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getAfsOrderSyncSaleOrderFailedList(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "erp_po_pr_log_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.request?.is_po) {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getErpPurchaseTrueList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getErpPurchaseTrueList(ws_onmessage?.response?.data));
                        };
                    } else {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getErpPurchaseFalseList(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getErpPurchaseFalseList(ws_onmessage?.response?.data));
                        };
                    }; 
                };
                break;
            case "fba_order_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getFBAOrderList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getFBAOrderList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "fba_order_log_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getFBAOrderLogsList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getFBAOrderLogsList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "salesperson_incentive_slab":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getIncentiveSlabList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getIncentiveSlabList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "incentive_slab_add":
                if (ws_onmessage?.response?.status === 200) {
                    if(ws_onmessage?.request?.incentive_code === "") {
                        dispatch(addIncentiveSlabList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(updateIncentiveSlabList(ws_onmessage?.response?.data));
                    };
 
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "incentive_slab_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "incentive_slab_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "product_scheme_add":
                if (ws_onmessage?.response?.status === 200) {                    
                    if(ws_onmessage?.request?.scheme_id === null) {
                        dispatch(addFranchiseSchemeList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(updateFranchiseSchemeList(ws_onmessage?.response?.data));
                    };

                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_scheme_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "product_scheme_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "delete_franchise_scheme":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "delete_franchise_scheme",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "delete_franchise_scheme",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "delete_incentive_slabs":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "delete_incentive_slabs",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "delete_incentive_slabs",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "fba_order_detail":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                           dispatch(GetOrderReportDetails(ws_onmessage?.response?.data));
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(GetOrderReportDetails(ws_onmessage?.response?.data));
                        }
                    };
                };
                break;
            case "order_remark":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_remark",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "order_remark",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "ao_order_return":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(StartMainCommonLoader({
                            url: "ao_order_return",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "ao_order_return",
                            action: ws_onmessage?.response?.status
                        }));
                        
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(getOrderCancelStatus({
                                open: true,
                                title: "Order Returned Successfully !",
                                message: ws_onmessage?.response?.msg,
                            }));
                        };
                    } else {
                        dispatch(StartMainCommonLoader({
                            url: "ao_order_return",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "ao_order_return",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            dispatch(getOrderCancelStatus({
                                open: false,
                                title: "",
                                message: "",
                            }));
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "uni_order_log_list":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.request?.is_invoice === true) {
                                dispatch(GetUnicommerceInvoiceFalureList(ws_onmessage?.response?.data));
                            } else if (ws_onmessage?.request?.is_shipment === true) {
                                dispatch(GetUnicommerceShipmentFalureList(ws_onmessage?.response?.data));
                            } else if (ws_onmessage?.request?.is_manifest === true) {
                                dispatch(GetUnicommerceManifestFalureList(ws_onmessage?.response?.data));
                            } else if (ws_onmessage?.request?.is_track_status === true) {
                                dispatch(GetUnicommerceTrackStatusFalureList(ws_onmessage?.response?.data));
                            };
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        if (ws_onmessage?.TabId === tabID) {
                            if (ws_onmessage?.request?.is_invoice === true) {
                                dispatch(GetUnicommerceInvoiceFalureList(ws_onmessage?.response?.data));
                            } else if (ws_onmessage?.request?.is_shipment === true) {
                                dispatch(GetUnicommerceShipmentFalureList(ws_onmessage?.response?.data));
                            } else if (ws_onmessage?.request?.is_manifest === true) {
                                dispatch(GetUnicommerceManifestFalureList(ws_onmessage?.response?.data));
                            } else if (ws_onmessage?.request?.is_track_status === true) {
                                dispatch(GetUnicommerceTrackStatusFalureList(ws_onmessage?.response?.data));
                            };
                        };
                    };
                };
                break;
            case "ticket_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetTicketsList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(GetTicketsList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "ticket_logs":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetTicketsLogsList(ws_onmessage?.response?.data?.ticket_logs));
                        dispatch(StartMainCommonLoader({
                            url: "ticket_logs",
                            loader: false
                        }));
                    } else {
                        dispatch(StartMainCommonLoader({
                            url: "ticket_logs",
                            loader: false
                        }));
                        dispatch(GetTicketsLogsList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "help_desk_category_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllHelpDeskCategoryList(ws_onmessage?.response));
                        } else {
                            dispatch(GetHelpDeskCategoryList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetHelpDeskCategoryList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "help_desk_category_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addHelpDeskCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_category_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_category_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "help_desk_category_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateHelpDeskCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_category_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_category_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "help_desk_subcategory_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        if (ws_onmessage?.request?.limit === 5000) {
                            dispatch(GetAllHelpDeskSubCategoryList(ws_onmessage?.response));
                        } else {
                            dispatch(GetHelpDeskSubCategoryList(ws_onmessage?.response?.data));
                        };
                    } else {
                        dispatch(GetHelpDeskSubCategoryList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "help_desk_subcategory_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addHelpDeskSubCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_subcategory_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_subcategory_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "help_desk_subcategory_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateHelpDeskSubCategoryList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_subcategory_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_subcategory_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "help_desk_faq_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetHelpDeskFAQList(ws_onmessage?.response?.data));
                    } else {
                        dispatch(GetHelpDeskFAQList(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "help_desk_faq_add":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(addHelpDeskFAQList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_faq_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_faq_add",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "help_desk_faq_edit":
                if (ws_onmessage?.response?.status === 200) {
                    dispatch(updateHelpDeskFAQList(ws_onmessage?.response?.data));
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_faq_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "help_desk_faq_edit",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "ticket_search_by_ticket_number":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        setTimeout(() => {
                            dispatch(StartMainCommonLoader({
                                url: "ticket_search_by_ticket_number",
                                loader: false
                            }));
                        }, 500);
                        
                        if(ws_onmessage?.response?.data?.status === "FAILURE") {
                            dispatch(TicketsVerfiyAPI({data: ws_onmessage?.response?.data, status: "FAILURE"}));
                        } else {
                            dispatch(TicketsVerfiyAPI({data: ws_onmessage?.response?.data?.data[0], status: "SUCCESS"}));
                            if (ws_onmessage?.TabId === tabID) {
                                toast.info(ws_onmessage?.response?.msg);
                            };
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "ticket_search_by_ticket_number",
                            loader: false
                        }));
                        dispatch(TicketsVerfiyAPI({}));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "sale_revenue_rm":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.request?.filter_type === "monthly") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getSalesMonthlyRevinueDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getSalesMonthlyRevinueDashboard(ws_onmessage?.response?.data));
                        };
                    } else if(ws_onmessage?.request?.filter_type === "daily") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getSalesDailyRevinueDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getSalesDailyRevinueDashboard(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "quotation_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.quotation_list_type === "all_quotations_listing") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getQuotationsAllReport(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getQuotationsAllReport(ws_onmessage?.response?.data));
                        };
                    } else if(ws_onmessage?.quotation_list_type === "created_payment_pending_quotations_listing") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getQuotationsCreatedPaymentPendReport(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getQuotationsCreatedPaymentPendReport(ws_onmessage?.response?.data));
                        };
                    } else {
                        if(ws_onmessage?.request?.quotation_status === "PAYMENT_APPROVAL_PENDING") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getQuotationsPaymentApprovalPendReport(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getQuotationsPaymentApprovalPendReport(ws_onmessage?.response?.data));
                            };
                        } else if(ws_onmessage?.request?.quotation_status === "PAID") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getQuotationsPaymentPaidReport(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getQuotationsPaymentPaidReport(ws_onmessage?.response?.data));
                            };
                        } else if(ws_onmessage?.request?.quotation_status === "EXPIRED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getQuotationsExpiredReport(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getQuotationsExpiredReport(ws_onmessage?.response?.data));
                            };
                        } else if(ws_onmessage?.request?.quotation_status === "CANCELLED") {
                            if (ws_onmessage?.response?.status === 200) {
                                dispatch(getQuotationsPaymentCancelledReport(ws_onmessage?.response?.data));
                            } else {
                                dispatch(getQuotationsPaymentCancelledReport(ws_onmessage?.response?.data));
                            };
                        };
                    };
                };
                break;
            case "sale_revenue_product":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.request?.filter_type === "monthly") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getProductMonthlyRevinueDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getProductMonthlyRevinueDashboard(ws_onmessage?.response?.data));
                        };
                    } else if(ws_onmessage?.request?.filter_type === "daily") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getProductDailyRevinueDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getProductDailyRevinueDashboard(ws_onmessage?.response?.data));
                        };
                    };
                };
                break;
            case "list_payment_receipt":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(GetPaymentReceiptListing(ws_onmessage?.response?.data));
                    } else {
                        dispatch(GetPaymentReceiptListing(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "update_payment_receipt":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "update_payment_receipt",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "update_payment_receipt",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(StartMainCommonLoader({
                            url: "update_payment_receipt",
                            loader: false
                        }));
                        dispatch(ClearFormSetFutios({
                            url: "update_payment_receipt",
                            action: ws_onmessage?.response?.status
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "sale_revenue_franchise":
                if (ws_onmessage?.DeviceId === device_id) {
                    if(ws_onmessage?.request?.filter_type === "monthly") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getDealerMonthlyRevinueDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getDealerMonthlyRevinueDashboard(ws_onmessage?.response?.data));
                        };
                    } else if(ws_onmessage?.request?.filter_type === "daily") {
                        if (ws_onmessage?.response?.status === 200) {
                            dispatch(getDealerDailyRevinueDashboard(ws_onmessage?.response?.data));
                        } else {
                            dispatch(getDealerDailyRevinueDashboard(ws_onmessage?.response?.data));
                        };
                    }; 
                };
                break;
            case "razor_payment_log_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getAFSorderSyncPaymentFailedListing(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getAFSorderSyncPaymentFailedListing(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "erp_quotation_log_list":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getAFSorderSyncQuotationsFailedListing(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getAFSorderSyncQuotationsFailedListing(ws_onmessage?.response?.data));
                    };
                };
                break;
            case "add_payment_detail":
                if (ws_onmessage?.response?.status === 200) {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "add_payment_detail",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.info(ws_onmessage?.response?.msg);
                            if(ws_onmessage?.response?.data?.quotation_status === "PAID") {
                                // CALLING SEND QUOTATION NOTIFICATION
                                await GetRequest(`${SEND_QUOTATION_NOTIFICATIONS_API_URL}/${ws_onmessage?.response?.data?.id}`, accessToken);
                            }; 
                        };
                    };
                } else {
                    if (ws_onmessage?.DeviceId === device_id) {
                        dispatch(ClearFormSetFutios({
                            url: "add_payment_detail",
                            action: ws_onmessage?.response?.status
                        }));
                        dispatch(StartMainCommonLoader({
                            url: "",
                            loader: false
                        }));
                        if (ws_onmessage?.TabId === tabID) {
                            toast.error(ws_onmessage?.response?.msg);
                        };
                    };
                };
                break;
            case "warranty_register_report":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getWarrantyRegistrationReportDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getWarrantyRegistrationReportDashboard({}));
                    };
                };
                break;
            case "classification_wise_reports":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getWarrantyAgeCategoryUseCaseDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getWarrantyAgeCategoryUseCaseDashboard({}));
                    };
                };
                break;  
            case "state_vs_warranty_register":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getWarrantyStateVsRegistrationsDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getWarrantyStateVsRegistrationsDashboard([]));
                    };
                };
                break;   
            case "product_vs_warranty_register":     
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getWarrantyProductVsRegistrationsDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getWarrantyProductVsRegistrationsDashboard([]));
                    };
                };
                break;   
            case "region_vs_warranty_register":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getWarrantyRegionVsRegistrationsDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getWarrantyRegionVsRegistrationsDashboard([]));
                    };
                };
                break;  
            case "franchise_type_wise_classification_reports": 
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getCategoryGroupWiseClassificationsDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getCategoryGroupWiseClassificationsDashboard([]));
                    };
                };
                break; 
            case "dealer_vs_warranty_register": 
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getDealerWiseAvailableInvetoryDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getDealerWiseAvailableInvetoryDashboard({}));
                    };
                };
                break; 
            case "dealer_scheme_and_incentive":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getDealerSchemeAndIncentiveReportDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getDealerSchemeAndIncentiveReportDashboard({}));
                    };
                };
                break; 
            case "dealer_wise_associates":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getActiveAssociatedReportDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getActiveAssociatedReportDashboard({}));
                    };
                };
                break; 
            case "dealer_available_inventory":
                if (ws_onmessage?.DeviceId === device_id) {
                    if (ws_onmessage?.response?.status === 200) {
                        dispatch(getAvailableFrameInventoryDashboard(ws_onmessage?.response?.data));
                    } else {
                        dispatch(getAvailableFrameInventoryDashboard({}));
                    };
                };
                break; 
            case "unauthorized":
                CommonLogout(accessToken, dispatch);
                dispatch(clearRedux());
                break;
            default:
                return;
        }
    })
};