/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { PRODUCT_CREATION_URL, FILE_AUTH_URL } from "../../Shared/constant";
import { Scrollbars } from 'react-custom-scrollbars-2';
import CommonToggle from "../../Common/CommonToggle";
import { Button, Form, Image } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select'
import UploadFileAPI from "../../../Api/UploadFileAPI";
import FileAuthConvert from "../../../Api/FileAuthConvert";
import DefaultImage from "../../../assets/img/DefaultProduct.jpg";
import SelectReact from "../../Common/SelectReact";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonLoader from "../../Common/CommonLoader";

const CreateProduct = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ subCategoryState, setSubCategoryState ] = useState({
        BrandName: "",
        CategoryName: "",
        SubCategoryName: "",
        Status: true,
        custom_is_transportation: false,
        is_spare: false,
        MasterSKU: "",
        ProductName: "",
        ProductGroup: "",
        ProductDescription: "",
        ProductImage: "",
        HSNCode: "",
        ProductType: "",
        EligibleForReturn: "",
        MRP: "",
        FranchiseRate: "",
        ProductColor: "",
        IGSTRate: "",
        CGSTRate: "",
        SGSTRate: "",
        DemoRate: "",
        DiscountAmount: "",
        ECommPrice: "",
        Returnremark: "",
        box_height: "",
        box_width: "",
        box_length: "",
        box_weight: "",
        item_tax_template: ""
    });
    const [ EligibleForReturn, setEligibleForReturn ] = useState(false);
    const [ Tab, setTab ] = useState("accessories");
    const { brandlistall, AllProductGroupListAll, ClearFormSet, subcategoryList, productlistall, productList, categorylistall, subCategorylistall, accessToken, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const IsSuperAdmin = selfInfo?.user?.user_type === "super_admin";
    const [ optionsBrands, SetOptionsBrands ] = useState(() => brandlistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.brand_name })));
    const [ optionsCategory, SetOptionsCategory ] = useState(() => categorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.category_name })));
    const [ optionsSubCategory, SetOptionsSubCategory ] = useState(() => subCategorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.sub_category_name })));
    const [ optionsProductGroups, SetOptionsProductGroups ] = useState(() => AllProductGroupListAll?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_group_name })));
    const ProductTypArray = [ "Regular", "Best Selling", "In New Arrival", "Coming Soon", "Trending" ];
    const [ optionProductType, setoptionProductType] = useState(() => ProductTypArray?.map((item, index) => ({ key: item, value: item, label: item })));
    const { roomId } = useParams();
    const checkProductAvailable = productList?.list?.length === 0 ? productlistall : productList?.list;
    const CurrentData_ = checkProductAvailable?.filter((elm) => elm?.id === roomId)[0];
    const BrandlistallIsActive = brandlistall?.filter((elm) => elm?.is_active === true);
    let defaultSubCategoryOption = subCategorylistall?.find((elm) => elm?.id === CurrentData_?.sub_category_id);
    let defaultCategoryGet = categorylistall?.find((elm) => elm?.id === defaultSubCategoryOption?.category_id);
    let defaultBrandGet = BrandlistallIsActive?.filter((elm) => elm?.id === defaultCategoryGet?.brand_id)[0];
    let defaultMainBrand = optionsBrands?.filter((elm) => elm?.key === defaultBrandGet?.id)[0];
    let defaultMainCategory = optionsCategory?.filter((elm) => elm?.key === defaultCategoryGet?.id)[0];
    let defaultMainSubCategory = optionsSubCategory?.filter((elm) => elm?.key === CurrentData_?.sub_category_id)[0];
    let defaultProductGroup = optionsProductGroups?.filter((elm) => elm?.key === CurrentData_?.product_group_id)[0];
    const [ImageDisplay, setImageDisplay] = useState("");
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const exceptThisCostSymbolsBox = ["e", "E", "+", "-"];

    // error state
    const [errorSelectBrandName, seterrorSelectBrandName] = useState("");
    const [errorCategoryName, seterrorCategoryName] = useState("");
    const [errorSubCategoryName, seterrorSubCategoryName] = useState("");
    const [errorMasterSKU, seterrorMasterSKU] = useState("");
    const [errorProductName, seterrorProductName] = useState("");
    const [errorProductGroup, seterrorProductGroup] = useState("");
    const [errorHSNCode, seterrorHSNCode] = useState("");
    // const [ errorEligibleForReturn, seterrorEligibleForReturn ] = useState("");
    const [errorMRP, seterrorMRP] = useState("");
    const [errorfranchise_rate, seterrorfranchise_rate] = useState("");
    const [errorDemoRate, seterrorDemoRate] = useState("");
    const [errorecom_price, seterrorecom_price] = useState("");
    const [errorfs_code, seterrorfs_code] = useState("");
    const [errorproduct_color, seterrorproduct_color] = useState("");
    const [errorigst_rate, seterrorigst_rate] = useState("");
    const [errorReturnremark, seterrorReturnremark] = useState("");
    const [errorBrandImage, seterrorBrandImage] = useState("");
    const [errorbox_height, setErrorbox_height] = useState("");
    const [errorbox_width, setErrorbox_width] = useState("");
    const [errorbox_length, setErrorbox_length] = useState("");
    const [errorbox_weight, setErrorbox_weight] = useState("");
    // const [ erroris_active, seterroris_active ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    useEffect(() => {
        if (LocationRoute === "edit-product") {
            populateCategoryState();
            setTab(!CurrentData_?.is_bike ? "accessories" : "bike");
            setImageDisplay(FILE_AUTH_URL + CurrentData_?.view_thumbnail_url);
        };
    }, [CurrentData_]);

    const populateCategoryState = () => {
        setSubCategoryState((subCategoryState) => ({
            ...subCategoryState,
            BrandName: defaultMainBrand?.value === undefined ? "" : defaultMainBrand,
            // CategoryName: CurrentData_?.sub_category_id,
            SubCategoryName: CurrentData_?.sub_category_id,
            CategoryName: defaultMainCategory?.value === undefined ? "" : defaultMainCategory?.value,
            // SubCategoryName: defaultMainSubCategory?.value === undefined ? "" : defaultMainSubCategory,
            Status: CurrentData_?.is_active,
            custom_is_transportation: CurrentData_?.custom_is_transportation,
            is_spare: CurrentData_?.is_spare,
            MasterSKU: CurrentData_?.sku,
            ProductName: CurrentData_?.product_name,
            ProductGroup: (CurrentData_?.product_group_id === null || CurrentData_?.product_group_id === "None") ? "" : CurrentData_?.product_group_id,
            ProductDescription: CurrentData_?.short_description,
            ProductImage: CurrentData_?.product_image,
            HSNCode: CurrentData_?.hsn_code,
            ProductType: CurrentData_?.product_type === "None" ? "" : CurrentData_?.product_type,
            EligibleForReturn: CurrentData_?.is_return,
            MRP: CurrentData_?.mrp === null ? "" : CurrentData_?.mrp,
            FranchiseRate: CurrentData_?.franchise_rate === null ? "" : CurrentData_?.franchise_rate,
            ProductColor: CurrentData_?.product_color === null ? "" : CurrentData_?.product_color,
            IGSTRate: CurrentData_?.igst_rate === null ? "" : CurrentData_?.igst_rate,
            CGSTRate: CurrentData_?.cgst_rate === null ? "" : CurrentData_?.cgst_rate,
            SGSTRate: CurrentData_?.sgst_rate === null ? "" : CurrentData_?.sgst_rate,
            DiscountAmount: CurrentData_?.discount_amount === null ? "" : CurrentData_?.discount_amount,
            ECommPrice: CurrentData_?.ecom_price === null ? "" : CurrentData_?.ecom_price,
            Returnremark: CurrentData_?.return_remark === null ? "" : CurrentData_?.return_remark,
            DemoRate: CurrentData_?.demo_rate === null ? "" : CurrentData_?.demo_rate,
            box_height: CurrentData_?.box_height,
            box_width: CurrentData_?.box_width,
            box_length: CurrentData_?.box_length,
            box_weight: CurrentData_?.box_weight,
            item_tax_template: CurrentData_?.item_tax_template === null ? "" : CurrentData_?.item_tax_template,
        }));
    };

    // upload product image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if (fileresponse?.status === 200) {
            setSubCategoryState({ ...subCategoryState, ProductImage: fileresponse?.id });
            const Authfileresponse = await FileAuthConvert(accessToken, FILE_AUTH_URL + fileresponse?.view_thumbnail_url);
            if (Authfileresponse?.status === 200) {
                setUploadFileLoader(false);
                setImageDisplay(Authfileresponse?.authImage);
                CheckValid(fileresponse?.id, { type: 'CategoryImage', error: seterrorBrandImage });
            } else {
                setUploadFileLoader(false);
            };
        } else {
            setUploadFileLoader(false);
        };
    };

    // remove image uploaded
    const RemoveUploadImage = () => {
        setSubCategoryState({ ...subCategoryState, ProductImage: "" });
        setImageDisplay("");
    };

    // select brand name
    const BrandSelect = (value) => {
        setSubCategoryState((subCategoryState) => ({ ...subCategoryState, BrandName: value ? value : "", CategoryName: null, SubCategoryName: null, }));
        if (value !== null) {
            // setSubCategoryState({...subCategoryState, BrandName: value, });
            CheckValid(value, { type: 'SelectBrand', error: seterrorSelectBrandName });
            const categorylistallFilter = categorylistall?.filter((elm) => {
                if (elm?.brand_id === value?.value) {
                    return elm;
                };
            })
            SetOptionsCategory(categorylistallFilter?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.category_name })))
        } else {
            // setSubCategoryState({...subCategoryState, BrandName: "", CategoryName: "", SubCategoryName: ""});
            CheckValid(value === null ? "" : value, { type: 'SelectBrand', error: seterrorSelectBrandName });
            SetOptionsCategory(categorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.category_name })));
            SetOptionsSubCategory(subCategorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.sub_category_name })));
        }
    };

    useEffect(() => {
        if (subCategoryState?.BrandName) {
            // const categorylistallFilter = categorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => {
            //     if(elm?.brand_id === subCategoryState?.BrandName?.value) {
            //         return elm;
            //     };
            // })
            // SetOptionsCategory(categorylistallFilter?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name })))
        }
    }, [subCategoryState?.BrandName]);

    // select category name
    const CategorySelect = (e) => {
        const value = e.target.value
        setSubCategoryState((subCategoryState) => ({
            ...subCategoryState,
            SubCategoryName: null,
            CategoryName: value ? value : "",
        }));
        if (value !== null) {
            // setSubCategoryState({...subCategoryState, CategoryName: value});
            CheckValid(value, { type: 'SelectCategory', error: seterrorCategoryName });
            const subcategorylistallFilter = subCategorylistall?.filter((elm) => {
                if (elm?.category_id === value) {
                    return elm;
                };
            })
            SetOptionsSubCategory(subcategorylistallFilter?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.sub_category_name })))
        } else {
            // setSubCategoryState({...subCategoryState, CategoryName: "", SubCategoryName: ""});
            CheckValid(value === null ? "" : value, { type: 'SelectCategory', error: seterrorCategoryName });
            SetOptionsSubCategory(subCategorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.sub_category_name })));
        }
    };

    // select product type
    const ProductTypeSelect = (e) => {
        if (e.target.value !== null) {
            setSubCategoryState({ ...subCategoryState, ProductType: e.target.value });
        } else {
            setSubCategoryState({ ...subCategoryState, ProductType: "" });
        }
    };

    useEffect(() => {
        if (subCategoryState?.CategoryName) {
            // const subcategorylistallFilter = subCategorylistall?.filter((elm) => elm?.is_active === true)?.filter((elm) => {
            //     if(elm?.category_id === subCategoryState?.CategoryName?.value) {
            //         return elm;
            //     };
            // })
            // SetOptionsSubCategory(subcategorylistallFilter?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.sub_category_name })))
        }
    }, [subCategoryState?.CategoryName]);

    // select sub category
    const SubCategorySelect = (e) => {
        const value = e.target.value;
        if (value !== null) {
            setSubCategoryState({ ...subCategoryState, SubCategoryName: value });
            CheckValid(value, { type: 'SelectSubCategory', error: seterrorSubCategoryName });
        } else {
            setSubCategoryState({ ...subCategoryState, SubCategoryName: "" });
            CheckValid(value === null ? "" : value, { type: 'SelectSubCategory', error: seterrorSubCategoryName });
        }
    };

    // select product group
    const ProductGroupSelect = (value) => {
        if (value !== null) {
            setSubCategoryState({ ...subCategoryState, ProductGroup: value?.value });
            CheckValid(value, { type: 'ProductGroupName', error: seterrorProductGroup });
        } else {
            CheckValid(value === null ? "" : value, { type: 'ProductGroupName', error: seterrorProductGroup });
        }
    };

    // add IGSTR function
    const AddIGSTRateValue = (value) => {
        if (value <= 28 || value == "") {
            if (/^\d*\.?\d{0,1}$/.test(value)) {
                setSubCategoryState({ ...subCategoryState, IGSTRate: value, CGSTRate: value === "" ? "" : value / 2, SGSTRate: value === "" ? "" : value / 2 });
            };
        } else {
            // setSubCategoryState({...subCategoryState, IGSTRate: value, CGSTRate: value === "" ? "" : value / 2, SGSTRate: value === "" ? "" : value / 2});
        };
    };

    useEffect(() => {
        if (LocationRoute === "create-product") {
            setSubCategoryState((subCategoryState) => ({
                ...subCategoryState,
                SubCategoryName: "",
                CategoryName: "",
            }));
        } else {
            const tabValue = CurrentData_.is_bike ? "bike" : "accessories";
            
            if(tabValue !== Tab) {
                setSubCategoryState((subCategoryState) => ({
                    ...subCategoryState,
                    SubCategoryName: "",
                    CategoryName: "",
                }));
            } else {
                populateCategoryState();
            } 
        };
        SetOptionsSubCategory(() => subCategorylistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.sub_category_name })));
    }, [Tab]);

    // Regular expression to match numbers, letters, '_', '-', '/', '\', and ','
    const MasterSKUOnchange = (e) => {
        const regex = /^[a-zA-Z0-9_\-\/\\,]*$/;
        if(regex.test(e.target.value)) {
            setSubCategoryState({ ...subCategoryState, MasterSKU: e.target.value?.trim() })
        };
    };

    // create product
    const CreateProduct = () => {
        if (Tab === "bike") {
            if (EligibleForReturn) {
                if (subCategoryState?.BrandName !== "" && subCategoryState?.CategoryName !== "" && subCategoryState?.SubCategoryName !== "" &&
                    subCategoryState?.box_height !== "" && subCategoryState?.box_width !== "" &&
                    subCategoryState?.box_length !== "" && subCategoryState?.box_weight !== "" &&
                    subCategoryState?.MasterSKU?.trim() !== "" && subCategoryState?.ProductName?.trim() !== "" && subCategoryState?.ProductGroup?.trim() !== "" &&
                    subCategoryState?.HSNCode?.trim() !== "" && subCategoryState?.MRP?.trim() !== "" &&
                    subCategoryState?.ProductColor?.trim() !== "" && subCategoryState?.IGSTRate?.trim() !== "" && subCategoryState?.SelectBox !== "" &&
                    subCategoryState?.DemoRate?.trim() !== "" && (subCategoryState?.ECommPrice?.trim() !== "" && parseInt(subCategoryState?.ECommPrice?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) &&
                    (subCategoryState?.FranchiseRate?.trim() !== "" && parseInt(subCategoryState?.FranchiseRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) && subCategoryState?.Returnremark?.trim() !== "" &&
                    (subCategoryState?.DemoRate?.trim() !== "" && parseInt(subCategoryState?.DemoRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim()))
                ) {
                    // seterrorecom_price
                    if (LocationRoute === "edit-product") {
                        Dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_edit",
                            "request": {
                                "product_id": CurrentData_?.id,
                                "erp_id": CurrentData_?.erp_id,
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage,
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "return_remark": subCategoryState?.Returnremark?.trim(),
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        Dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_add",
                            "request": {
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage?.trim(),
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "return_remark": subCategoryState?.Returnremark?.trim(),
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    }
                } else {
                    CheckValid(subCategoryState?.CategoryName, { type: 'SelectCategory', error: seterrorCategoryName });
                    CheckValid(subCategoryState?.BrandName, { type: 'SelectBrand', error: seterrorSelectBrandName });
                    CheckValid(subCategoryState?.SubCategoryName, { type: 'SelectSubCategory', error: seterrorSubCategoryName });
                    CheckValid(subCategoryState?.MasterSKU?.trim(), { type: 'MasterSKU', error: seterrorMasterSKU });
                    CheckValid(subCategoryState?.ProductName?.trim(), { type: 'ProductName', error: seterrorProductName });
                    CheckValid(subCategoryState?.ProductGroup?.trim(), { type: 'ProductGroup', error: seterrorProductGroup });
                    CheckValid(subCategoryState?.HSNCode?.trim(), { type: 'HSNCode', error: seterrorHSNCode });
                    CheckValid(subCategoryState?.MRP?.trim(), { type: 'MRP', error: seterrorMRP });
                    CheckValid(subCategoryState?.FranchiseRate?.trim(), { type: 'FranchiseRate', error: seterrorfranchise_rate });
                    CheckValid(subCategoryState?.ProductColor?.trim(), { type: 'ProductColor', error: seterrorproduct_color });
                    CheckValid(subCategoryState?.IGSTRate?.trim(), { type: 'IGSTRate', error: seterrorigst_rate });
                    CheckValid(subCategoryState?.ECommPrice?.trim(), { type: 'ECommPrice', error: seterrorecom_price });
                    CheckValid(subCategoryState?.Returnremark?.trim(), { type: 'Returnremark', error: seterrorReturnremark })
                    CheckValid(subCategoryState?.DemoRate?.trim(), { type: 'DemoRate', error: seterrorDemoRate });
                    CheckValid(subCategoryState?.box_height, { type: 'box_height', error: setErrorbox_height });
                    CheckValid(subCategoryState?.box_width, { type: 'box_width', error: setErrorbox_width });
                    CheckValid(subCategoryState?.box_length, { type: 'box_length', error: setErrorbox_length });
                    CheckValid(subCategoryState?.box_weight, { type: 'box_weight', error: setErrorbox_weight })

                    if (parseInt(subCategoryState?.ECommPrice?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorecom_price("Ecom price must be less than the MRP.");
                    };

                    if (parseInt(subCategoryState?.FranchiseRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorfranchise_rate("Franchise rate must be less than the MRP.");
                    };

                    if (parseInt(subCategoryState?.DemoRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorDemoRate("Demo rate must be less than the MRP.");
                    };
                }
            } else {
                if (subCategoryState?.BrandName !== "" && subCategoryState?.CategoryName !== "" && subCategoryState?.SubCategoryName !== "" &&
                    subCategoryState?.box_height !== "" && subCategoryState?.box_width !== "" &&
                    subCategoryState?.box_length !== "" && subCategoryState?.box_weight !== "" &&
                    subCategoryState?.MasterSKU?.trim() !== "" && subCategoryState?.ProductName?.trim() !== "" && subCategoryState?.ProductGroup?.trim() !== "" &&
                    subCategoryState?.HSNCode?.trim() !== "" && subCategoryState?.MRP?.trim() !== "" &&
                    subCategoryState?.FranchiseRate?.trim() !== "" && subCategoryState?.ProductColor?.trim() !== "" && subCategoryState?.IGSTRate?.trim() !== "" && subCategoryState?.SelectBox !== "" &&
                    (subCategoryState?.ECommPrice?.trim() !== "" && parseInt(subCategoryState?.ECommPrice?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) &&
                    subCategoryState?.ECommPrice?.trim() !== "" && subCategoryState?.DemoRate?.trim() !== "" &&
                    (subCategoryState?.FranchiseRate?.trim() !== "" && parseInt(subCategoryState?.FranchiseRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) &&
                    (subCategoryState?.DemoRate?.trim() !== "" && parseInt(subCategoryState?.DemoRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim()))
                ) {
                    if (LocationRoute === "edit-product") {
                        Dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_edit",
                            "request": {
                                "product_id": CurrentData_?.id,
                                "erp_id": CurrentData_?.erp_id,
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage,
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        Dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_add",
                            "request": {
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage,
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    }
                } else {
                    CheckValid(subCategoryState?.CategoryName, { type: 'SelectCategory', error: seterrorCategoryName });
                    CheckValid(subCategoryState?.BrandName, { type: 'SelectBrand', error: seterrorSelectBrandName });
                    CheckValid(subCategoryState?.SubCategoryName, { type: 'SelectSubCategory', error: seterrorSubCategoryName });
                    CheckValid(subCategoryState?.MasterSKU?.trim(), { type: 'MasterSKU', error: seterrorMasterSKU });
                    CheckValid(subCategoryState?.ProductName?.trim(), { type: 'ProductName', error: seterrorProductName });
                    CheckValid(subCategoryState?.ProductGroup?.trim(), { type: 'ProductGroup', error: seterrorProductGroup });
                    CheckValid(subCategoryState?.HSNCode?.trim(), { type: 'HSNCode', error: seterrorHSNCode });
                    CheckValid(subCategoryState?.MRP?.trim(), { type: 'MRP', error: seterrorMRP });
                    CheckValid(subCategoryState?.FranchiseRate?.trim(), { type: 'FranchiseRate', error: seterrorfranchise_rate });
                    CheckValid(subCategoryState?.ProductColor?.trim(), { type: 'ProductColor', error: seterrorproduct_color });
                    CheckValid(subCategoryState?.IGSTRate?.trim(), { type: 'IGSTRate', error: seterrorigst_rate });
                    CheckValid(subCategoryState?.ECommPrice?.trim(), { type: 'ECommPrice', error: seterrorecom_price });
                    CheckValid(subCategoryState?.DemoRate?.trim(), { type: 'DemoRate', error: seterrorDemoRate })
                    CheckValid(subCategoryState?.box_height, { type: 'box_height', error: setErrorbox_height });
                    CheckValid(subCategoryState?.box_width, { type: 'box_width', error: setErrorbox_width });
                    CheckValid(subCategoryState?.box_length, { type: 'box_length', error: setErrorbox_length });
                    CheckValid(subCategoryState?.box_weight, { type: 'box_weight', error: setErrorbox_weight })

                    if (parseInt(subCategoryState?.ECommPrice?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorecom_price("Ecom price must be less than the MRP.");
                    };
                    if (parseInt(subCategoryState?.FranchiseRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorfranchise_rate("Franchise rate must be less than the MRP.");
                    };
                    if (parseInt(subCategoryState?.DemoRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorDemoRate("Demo rate must be less than the MRP.");
                    };
                }
            }
        } else {
            if (EligibleForReturn) {
                if (subCategoryState?.BrandName !== "" && subCategoryState?.CategoryName !== "" && subCategoryState?.SubCategoryName !== "" &&
                    subCategoryState?.box_height !== "" && subCategoryState?.box_width !== "" &&
                    subCategoryState?.box_length !== "" && subCategoryState?.box_weight !== "" &&
                    subCategoryState?.MasterSKU?.trim() !== "" && subCategoryState?.ProductName?.trim() !== "" && subCategoryState?.ProductGroup?.trim() !== "" &&
                    subCategoryState?.HSNCode?.trim() !== "" && subCategoryState?.MRP?.trim() !== "" &&
                    subCategoryState?.ProductColor?.trim() !== "" && subCategoryState?.IGSTRate?.trim() !== "" &&
                    subCategoryState?.DemoRate?.trim() !== "" && (subCategoryState?.ECommPrice?.trim() !== "" && parseInt(subCategoryState?.ECommPrice?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) &&
                    (subCategoryState?.FranchiseRate?.trim() !== "" && parseInt(subCategoryState?.FranchiseRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) && subCategoryState?.Returnremark?.trim() !== "" &&
                    (subCategoryState?.DemoRate?.trim() !== "" && parseInt(subCategoryState?.DemoRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim()))
                ) {
                    // seterrorecom_price
                    if (LocationRoute === "edit-product") {
                        Dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_edit",
                            "request": {
                                "product_id": CurrentData_?.id,
                                "erp_id": CurrentData_?.erp_id,
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage,
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "return_remark": subCategoryState?.Returnremark?.trim(),
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        Dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_add",
                            "request": {
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage?.trim(),
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "return_remark": subCategoryState?.Returnremark?.trim(),
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    }
                } else {
                    CheckValid(subCategoryState?.CategoryName, { type: 'SelectCategory', error: seterrorCategoryName });
                    CheckValid(subCategoryState?.BrandName, { type: 'SelectBrand', error: seterrorSelectBrandName });
                    CheckValid(subCategoryState?.SubCategoryName, { type: 'SelectSubCategory', error: seterrorSubCategoryName });
                    CheckValid(subCategoryState?.MasterSKU?.trim(), { type: 'MasterSKU', error: seterrorMasterSKU });
                    CheckValid(subCategoryState?.ProductName?.trim(), { type: 'ProductName', error: seterrorProductName });
                    CheckValid(subCategoryState?.ProductGroup?.trim(), { type: 'ProductGroup', error: seterrorProductGroup });
                    CheckValid(subCategoryState?.HSNCode?.trim(), { type: 'HSNCode', error: seterrorHSNCode });
                    CheckValid(subCategoryState?.MRP?.trim(), { type: 'MRP', error: seterrorMRP });
                    CheckValid(subCategoryState?.FranchiseRate?.trim(), { type: 'FranchiseRate', error: seterrorfranchise_rate });
                    CheckValid(subCategoryState?.ProductColor?.trim(), { type: 'ProductColor', error: seterrorproduct_color });
                    CheckValid(subCategoryState?.IGSTRate?.trim(), { type: 'IGSTRate', error: seterrorigst_rate });
                    CheckValid(subCategoryState?.ECommPrice?.trim(), { type: 'ECommPrice', error: seterrorecom_price });
                    CheckValid(subCategoryState?.Returnremark?.trim(), { type: 'Returnremark', error: seterrorReturnremark })
                    CheckValid(subCategoryState?.DemoRate?.trim(), { type: 'DemoRate', error: seterrorDemoRate });
                    CheckValid(subCategoryState?.box_height, { type: 'box_height', error: setErrorbox_height });
                    CheckValid(subCategoryState?.box_width, { type: 'box_width', error: setErrorbox_width });
                    CheckValid(subCategoryState?.box_length, { type: 'box_length', error: setErrorbox_length });
                    CheckValid(subCategoryState?.box_weight, { type: 'box_weight', error: setErrorbox_weight })

                    if (parseInt(subCategoryState?.ECommPrice?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorecom_price("Ecom price must be less than the MRP.");
                    };

                    if (parseInt(subCategoryState?.FranchiseRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorfranchise_rate("Franchise rate must be less than the MRP.");
                    };

                    if (parseInt(subCategoryState?.DemoRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorDemoRate("Demo rate must be less than the MRP.");
                    };
                }
            } else {
                if (subCategoryState?.BrandName !== "" && subCategoryState?.CategoryName !== "" && subCategoryState?.SubCategoryName !== "" &&
                    subCategoryState?.box_height !== "" && subCategoryState?.box_width !== "" &&
                    subCategoryState?.box_length !== "" && subCategoryState?.box_weight !== "" &&
                    subCategoryState?.MasterSKU?.trim() !== "" && subCategoryState?.ProductName?.trim() !== "" && subCategoryState?.ProductGroup?.trim() !== "" &&
                    subCategoryState?.HSNCode?.trim() !== "" && subCategoryState?.MRP?.trim() !== "" &&
                    subCategoryState?.FranchiseRate?.trim() !== "" && subCategoryState?.ProductColor?.trim() !== "" && subCategoryState?.IGSTRate?.trim() !== "" &&
                    (subCategoryState?.ECommPrice?.trim() !== "" && parseInt(subCategoryState?.ECommPrice?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) &&
                    subCategoryState?.ECommPrice?.trim() !== "" && subCategoryState?.DemoRate?.trim() !== "" &&
                    (subCategoryState?.FranchiseRate?.trim() !== "" && parseInt(subCategoryState?.FranchiseRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim())) &&
                    (subCategoryState?.DemoRate?.trim() !== "" && parseInt(subCategoryState?.DemoRate?.trim()) <= parseInt(subCategoryState?.MRP?.trim()))
                ) {
                    if (LocationRoute === "edit-product") {
                        Dispatch(ClearFormSetFutios({
                            url: "product_edit",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_edit",
                            "request": {
                                "product_id": CurrentData_?.id,
                                "erp_id": CurrentData_?.erp_id,
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage,
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        Dispatch(ClearFormSetFutios({
                            url: "product_add",
                            action: true
                        }));
                        let param = {
                            "transmit": "broadcast",
                            "url": "product_add",
                            "request": {
                                "sku": subCategoryState?.MasterSKU?.trim(),
                                "product_name": subCategoryState?.ProductName?.trim(),
                                "product_group_id": subCategoryState?.ProductGroup?.trim(),
                                "short_description": subCategoryState?.ProductDescription === null ? null : subCategoryState?.ProductDescription?.trim(),
                                "product_image": subCategoryState?.ProductImage,
                                "hsn_code": subCategoryState?.HSNCode?.trim(),
                                "product_type": subCategoryState?.ProductType,
                                "is_return": EligibleForReturn,
                                "mrp": subCategoryState?.MRP?.trim(),
                                "franchise_rate": subCategoryState?.FranchiseRate?.trim(),
                                "discount_amount": subCategoryState?.DiscountAmount?.trim(),
                                "ecom_price": subCategoryState?.ECommPrice?.trim(),
                                "product_color": subCategoryState?.ProductColor?.trim(),
                                "igst_rate": subCategoryState?.IGSTRate,
                                "cgst_rate": subCategoryState?.CGSTRate,
                                "sgst_rate": subCategoryState?.SGSTRate,
                                "is_active": subCategoryState?.Status,
                                "custom_is_transportation": subCategoryState?.custom_is_transportation,
                                "is_spare": subCategoryState?.is_spare,
                                "sub_category_id": subCategoryState?.SubCategoryName,
                                "demo_rate": subCategoryState?.DemoRate?.trim(),
                                "box_height": subCategoryState?.box_height,
                                "box_width": subCategoryState?.box_width,
                                "box_length": subCategoryState?.box_length,
                                "box_weight": subCategoryState?.box_weight,
                                "item_tax_template": subCategoryState?.item_tax_template,
                                "is_bike": Tab === "bike" ? true : false
                            },
                            "DeviceId": device_id
                        };
                        wsSend_request(websocket, param);
                    }
                } else {
                    CheckValid(subCategoryState?.CategoryName, { type: 'SelectCategory', error: seterrorCategoryName });
                    CheckValid(subCategoryState?.BrandName, { type: 'SelectBrand', error: seterrorSelectBrandName });
                    CheckValid(subCategoryState?.SubCategoryName, { type: 'SelectSubCategory', error: seterrorSubCategoryName });
                    CheckValid(subCategoryState?.MasterSKU?.trim(), { type: 'MasterSKU', error: seterrorMasterSKU });
                    CheckValid(subCategoryState?.ProductName?.trim(), { type: 'ProductName', error: seterrorProductName });
                    CheckValid(subCategoryState?.ProductGroup?.trim(), { type: 'ProductGroup', error: seterrorProductGroup });
                    CheckValid(subCategoryState?.HSNCode?.trim(), { type: 'HSNCode', error: seterrorHSNCode });
                    CheckValid(subCategoryState?.MRP?.trim(), { type: 'MRP', error: seterrorMRP });
                    CheckValid(subCategoryState?.FranchiseRate?.trim(), { type: 'FranchiseRate', error: seterrorfranchise_rate });
                    CheckValid(subCategoryState?.ProductColor?.trim(), { type: 'ProductColor', error: seterrorproduct_color });
                    CheckValid(subCategoryState?.IGSTRate?.trim(), { type: 'IGSTRate', error: seterrorigst_rate });
                    CheckValid(subCategoryState?.ECommPrice?.trim(), { type: 'ECommPrice', error: seterrorecom_price });
                    CheckValid(subCategoryState?.DemoRate?.trim(), { type: 'DemoRate', error: seterrorDemoRate })
                    CheckValid(subCategoryState?.box_height, { type: 'box_height', error: setErrorbox_height });
                    CheckValid(subCategoryState?.box_width, { type: 'box_width', error: setErrorbox_width });
                    CheckValid(subCategoryState?.box_length, { type: 'box_length', error: setErrorbox_length });
                    CheckValid(subCategoryState?.box_weight, { type: 'box_weight', error: setErrorbox_weight })

                    if (parseInt(subCategoryState?.ECommPrice?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorecom_price("Ecom price must be less than the MRP.");
                    };
                    if (parseInt(subCategoryState?.FranchiseRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorfranchise_rate("Franchise rate must be less than the MRP.");
                    };
                    if (parseInt(subCategoryState?.DemoRate?.trim()) > parseInt(subCategoryState?.MRP?.trim())) {
                        seterrorDemoRate("Demo rate must be less than the MRP.");
                    };
                }
            }
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200 && (ClearFormSet?.url === "product_add" || ClearFormSet?.url === "product_edit")) {
            Navigate(PRODUCT_CREATION_URL);
        };
    }, [ClearFormSet]);


    // Box Weight validation
    function checkValue(e) {
        // Get the value of the input field
        var inputValue = e.target.value;
        // If the user enters '0', change the input value to '0.1'
        if (inputValue === '0') {
            setSubCategoryState({ ...subCategoryState, box_weight: '0.1' });
        } else {
            setSubCategoryState({ ...subCategoryState, box_weight: inputValue });
        };
    };

    const handleKeyDown = (e) => {
        // If the user presses backspace and the current value is '0.1', clear the input
        if (e.key === 'Backspace' && subCategoryState?.box_weight === '0.1') {
            setSubCategoryState({ ...subCategoryState, box_weight: "" });
        }
    };

    return (<section className="createApplcation_wrapper">
        <div className="MainHeader">
                <ul className="MainHeader_Tab">
                    <li className={Tab === "accessories" ? "active" : ""} onClick={() => setTab("accessories")}>Accessories</li>
                    <li className={Tab === "bike" ? "active" : ""} onClick={() => setTab("bike")}>Bike</li>
            </ul>
            <Link to={PRODUCT_CREATION_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappersmAN">
            <Scrollbars
                style={{ height: "calc(100vh - 135px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <div className="Bigform_custom_scroll" style={{ height: "calc(100vh - 135px)" }} >
                    <div className="formloginset">
                        <div className="HeaderDivider" >
                            <h6 className="subtitleForm">Basic Details</h6>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Brands<span className="mendatory_star">*</span></Form.Label>
                                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                            <Select
                                                onChange={(e) => BrandSelect(e)}
                                                defaultValue={defaultMainBrand}
                                                isClearable
                                                options={optionsBrands}
                                                className="godown-select-container"
                                                classNamePrefix="godown-select"
                                                styles={customStyles}
                                                isDisabled={!IsSuperAdmin}
                                            />
                                            {errorSelectBrandName !== "" && <span className="error_medotory">{errorSelectBrandName}</span>}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    {Tab === "accessories" ? (<SelectReact
                                        onChange={CategorySelect}
                                        value={subCategoryState?.CategoryName}
                                        options={optionsCategory?.filter((elm) => {
                                            if (elm?.label !== "E-Bikes") {
                                                return elm;
                                            }
                                        })}
                                        isClearable
                                        label={"Category"}
                                        className="godown-select-container"
                                        error={errorCategoryName}
                                        required
                                        classNameForm={"mb-3"}
                                        disabled={!IsSuperAdmin}
                                    />) : (<SelectReact
                                        onChange={CategorySelect}
                                        value={subCategoryState?.CategoryName}
                                        options={optionsCategory?.filter((elm) => {
                                            if (elm?.label === "E-Bikes" || elm?.label === "Transportation Charges") {
                                                return elm;
                                            }
                                        })}
                                        isClearable
                                        label={"Category"}
                                        className="godown-select-container"
                                        error={errorCategoryName}
                                        required
                                        classNameForm={"mb-3"}
                                        disabled={!IsSuperAdmin}
                                    />)}
                                </div>
                                {/* <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Category<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                        <Select 
                                            value={subCategoryState?.CategoryName}
                                            onChange={(e) => CategorySelect(e)} 
                                            // defaultValue={defaultMainCategory} 
                                            isClearable 
                                            options={optionsCategory}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                        {errorCategoryName !== "" && <span className="error_medotory">{errorCategoryName}</span>}
                                    </div>
                                </Form.Group>
                            </div> */}
                                <div className="col-md-4">
                                    <SelectReact
                                        onChange={SubCategorySelect}
                                        value={subCategoryState?.SubCategoryName}
                                        options={optionsSubCategory}
                                        isClearable
                                        label={"Sub Category"}
                                        className="godown-select-container"
                                        error={errorSubCategoryName}
                                        classNameForm={"mb-3"}
                                        disabled={!IsSuperAdmin}
                                        required
                                    />
                                </div>
                                {/* <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Sub Category<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                        <Select 
                                            value={subCategoryState?.SubCategoryName}
                                            onChange={(e) => SubCategorySelect(e)} 
                                            defaultValue={defaultMainSubCategory} 
                                            isClearable 
                                            options={optionsSubCategory}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                        {errorSubCategoryName !== "" && <span className="error_medotory">{errorSubCategoryName}</span>}
                                    </div>
                                </Form.Group>
                            </div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group className="mb-3 " >
                                        <Form.Label>Master SKU<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={subCategoryState?.MasterSKU}
                                            onChange={(e) => MasterSKUOnchange(e)}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'MasterSKU', error: seterrorMasterSKU })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Master SKU"
                                        />
                                        {errorMasterSKU !== "" && <span className="error_medotory">{errorMasterSKU}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Product Name<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={subCategoryState?.ProductName}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, ProductName: e.target.value })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'ProductName', error: seterrorProductName })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Product Name"
                                        />
                                        {errorProductName !== "" && <span className="error_medotory">{errorProductName}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Product Group<span className="mendatory_star">*</span></Form.Label>
                                        <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                            <Select
                                                onChange={(e) => ProductGroupSelect(e)}
                                                defaultValue={defaultProductGroup}
                                                isClearable
                                                options={optionsProductGroups}
                                                className="godown-select-container"
                                                classNamePrefix="godown-select"
                                                styles={customStyles}
                                            />
                                            {errorProductGroup !== "" && <span className="error_medotory">{errorProductGroup}</span>}
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Box Height (cm)<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.box_height}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, box_height: Tab === "bike" ? e.target.value : e.target.value.replace(/^0/, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_height', error: setErrorbox_height })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e);
                                                exceptThisCostSymbolsBox.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter Box Height"
                                            disabled={!IsSuperAdmin}
                                        />
                                        {errorbox_height !== "" && <span className="error_medotory">{errorbox_height}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Box Width (cm)<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.box_width}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, box_width: Tab === "bike" ? e.target.value : e.target.value.replace(/^0/, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_width', error: setErrorbox_width })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbolsBox.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter Box Width"
                                            disabled={!IsSuperAdmin}
                                        />
                                        {errorbox_width !== "" && <span className="error_medotory">{errorbox_width}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Box Length (cm)<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.box_length}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, box_length: Tab === "bike" ? e.target.value : e.target.value.replace(/^0/, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_length', error: setErrorbox_length })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbolsBox.includes(e.key) && e.preventDefault()
                                            }}
                                            placeholder="Enter Box Length"
                                            disabled={!IsSuperAdmin}
                                        />
                                        {errorbox_length !== "" && <span className="error_medotory">{errorbox_length}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Box Weight (kg)<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.box_weight}
                                            onChange={(e) => checkValue(e)}
                                            onKeyDown={(e) => {
                                                handleKeyDown(e);
                                                EmptySpaceFieldValid(e);
                                                exceptThisCostSymbolsBox.includes(e.key) && e.preventDefault();
                                            }}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'box_weight', error: setErrorbox_weight })}
                                            id="positiveNumber"
                                            min="0.01"
                                            step="0.01"
                                            placeholder="Enter Box Weight"
                                            disabled={!IsSuperAdmin}
                                        />
                                        {errorbox_weight !== "" && <span className="error_medotory">{errorbox_weight}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Product Color<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control type="text"
                                            value={subCategoryState?.ProductColor}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, ProductColor: e.target.value })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'ProductColor', error: seterrorproduct_color })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Product Color"
                                        />
                                        {errorproduct_color !== "" && <span className="error_medotory">{errorproduct_color}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>HSN Code<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.HSNCode}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, HSNCode: e.target.value == 0 ? "" : e.target.value.replace(/^0/g, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'HSNCode', error: seterrorHSNCode })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter HSN Code"
                                        />
                                        {errorHSNCode !== "" && <span className="error_medotory">{errorHSNCode}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <SelectReact
                                        onChange={ProductTypeSelect}
                                        value={subCategoryState?.ProductType}
                                        options={optionProductType}
                                        isClearable
                                        label={"Product Type"}
                                        className="godown-select-container"
                                        classNameForm={"mb-3"}
                                        disabled={!IsSuperAdmin}
                                    />
                                    {/* <Form.Group className="mb-3" >
                                    <Form.Label>Product Type</Form.Label>
                                    <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                        <Select 
                                            onChange={(e) => ProductTypeSelect(e)} 
                                            defaultValue={defaultProductType} 
                                            isClearable 
                                            options={optionProductType}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                    </div>
                                </Form.Group> */}
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Product Description (short)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea" rows={3}
                                            value={subCategoryState?.ProductDescription}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, ProductDescription: e.target.value })}
                                            onKeyDown={EmptySpaceFieldValid}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Product Description (short)"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2">
                                    <CommonToggle 
                                        isBadgeAllow={true}
                                        badgeTitle={"active_inactive"}
                                        valueToggle={subCategoryState?.Status}
                                        setValueToggle={() => setSubCategoryState({ ...subCategoryState, Status: !subCategoryState?.Status })}
                                        name={"Status"}
                                        disabled={!IsSuperAdmin}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <CommonToggle 
                                        isBadgeAllow={true}
                                        badgeTitle={"yes_no"}
                                        valueToggle={subCategoryState?.custom_is_transportation}
                                        setValueToggle={() => setSubCategoryState({ ...subCategoryState, custom_is_transportation: !subCategoryState?.custom_is_transportation })}
                                        name={"Is Transportation"}
                                        disabled={!IsSuperAdmin}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <CommonToggle 
                                        isBadgeAllow={true}
                                        badgeTitle={"yes_no"}
                                        valueToggle={subCategoryState?.is_spare}
                                        setValueToggle={() => setSubCategoryState({ ...subCategoryState, is_spare: !subCategoryState?.is_spare })}
                                        name={"Is Spare"}
                                        disabled={!IsSuperAdmin}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="HeaderDivider">
                            <h6 className="subtitleForm">Return Policies</h6>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Eligible for return</Form.Label>
                                        <div className="ToggleMainWrap" >
                                            <CommonToggle
                                                valueToggle={EligibleForReturn}
                                                setValueToggle={() => setEligibleForReturn(!EligibleForReturn)}
                                                name={""}
                                                disabled={!IsSuperAdmin}
                                            />
                                            {EligibleForReturn && (<>
                                                <Form.Control
                                                    type="text"
                                                    value={subCategoryState?.Returnremark}
                                                    onChange={(e) => setSubCategoryState({ ...subCategoryState, Returnremark: e.target.value })}
                                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'Returnremark', error: seterrorReturnremark })}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    disabled={UploadFileLoader || !IsSuperAdmin}
                                                />
                                            </>)}
                                        </div>
                                        {errorReturnremark !== "" && <span className="error_medotory">{errorReturnremark}</span>}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>MRP<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control type="number"
                                            value={subCategoryState?.MRP}
                                            className="inputBoxs"
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, MRP: e.target.value == 0 ? "" : e.target.value.replace(/^0/g, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'MRP', error: seterrorMRP })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter MRP" />
                                        {errorMRP !== "" && <span className="error_medotory">{errorMRP}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Franchise Rate<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control type="number"
                                            value={subCategoryState?.FranchiseRate}
                                            className="inputBoxs"
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, FranchiseRate: e.target.value == 0 ? "" : e.target.value.replace(/^0/g, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'FranchiseRate', error: seterrorfranchise_rate })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Franchise Rate"
                                        />
                                        {errorfranchise_rate !== "" && <span className="error_medotory">{errorfranchise_rate}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Demo Rate<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control type="number"
                                            value={subCategoryState?.DemoRate}
                                            className="inputBoxs"
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, DemoRate: e.target.value == 0 ? "" : e.target.value.replace(/^0/g, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'DemoRate', error: seterrorDemoRate })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Demo Price"
                                        />
                                        {errorDemoRate !== "" && <span className="error_medotory">{errorDemoRate}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>E-Comm. Price<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control type="number"
                                            value={subCategoryState?.ECommPrice}
                                            className="inputBoxs"
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, ECommPrice: e.target.value == 0 ? "" : e.target.value.replace(/^0/g, "") })}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'ECommPrice', error: seterrorecom_price })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter E-Comm. Price"
                                        />
                                        {errorecom_price !== "" && <span className="error_medotory">{errorecom_price}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>IGST Rate<span className="mendatory_star">*</span></Form.Label>
                                        <Form.Control 
                                            type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.IGSTRate}
                                            onChange={(e) => AddIGSTRateValue(e.target.value.replace(/^0/g, ""))}
                                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'IGSTRate', error: seterrorigst_rate })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbolsBox.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter IGST Rate" 
                                        />
                                        {errorigst_rate !== "" && <span className="error_medotory">{errorigst_rate}</span>}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>CGST Rate</Form.Label>
                                        <Form.Control type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.CGSTRate}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, CGSTRate: e.target.value.replace(/^0/g, "") })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={true || !IsSuperAdmin}
                                            placeholder="Enter CGST Rate"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>SGST Rate</Form.Label>
                                        <Form.Control type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.SGSTRate}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, SGSTRate: e.target.value.replace(/^0/g, "") })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={true || !IsSuperAdmin}
                                            placeholder="Enter SGST Rate" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Discount Amount</Form.Label>
                                        <Form.Control type="number"
                                            className="inputBoxs"
                                            value={subCategoryState?.DiscountAmount}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, DiscountAmount: e.target.value.replace(/^0/g, "") })}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Discount Amount"
                                        />
                                        {parseInt(subCategoryState?.DiscountAmount) > parseInt(subCategoryState?.MRP) && (<span className="error_medotory">Discount amount must be less than or equal to the MRP.</span>)}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Item Tax Template</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            value={subCategoryState?.item_tax_template}
                                            onChange={(e) => setSubCategoryState({ ...subCategoryState, item_tax_template: e.target.value })}
                                            onKeyDown={(e) => EmptySpaceFieldValid(e)}
                                            disabled={UploadFileLoader || !IsSuperAdmin}
                                            placeholder="Enter Item Tax Template"
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="HeaderDivider">
                            <h6 className="subtitleForm">Upload Images</h6>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Product Image</Form.Label>
                                        {(ImageDisplay !== undefined && ImageDisplay !== "") ? (<div className="imageuploads">
                                            <Image src={ImageDisplay} onError={(e) => e.target.src = DefaultImage} alt="charity avatar" />
                                            {IsSuperAdmin && (<span className="closeIcon" onClick={() => RemoveUploadImage()}></span>)}
                                        </div>) : (<Form.Control type="file" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" disabled={!IsSuperAdmin} />)}
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: "right" }}>
                                <Button 
                                    className="formbtn" 
                                    style={{ width: "auto" }} 
                                    onClick={() => CreateProduct()} 
                                    variant="primary" 
                                    disabled={(parseInt(subCategoryState?.DiscountAmount) > parseInt(subCategoryState?.MRP)) || (ClearFormSet?.action === true && (ClearFormSet?.url === "product_add" || ClearFormSet?.url === "product_edit"))}
                                >
                                    {LocationRoute === "edit-product" ? "Update" : "Add"}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
        {(ClearFormSet?.action === true && (ClearFormSet?.url === "product_add" || ClearFormSet?.url === "product_edit")) && (<CommonLoader />)}
    </section>)
}

export default CreateProduct;