import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CommonDatePicker = (props) => {
    const { dateRange, setDateRange, placeholderText, dependantState } = props;
    const [ startDate, endDate ] = dateRange;
    let today = new Date();
    
    const onChangeDate = (date) => {
        setDateRange(date);

        if(dependantState?.allow) {
            if(dependantState?.statType === "current_page") {
                if(date[1] !== null) {
                    dependantState?.stateFunction(1);
                };
            };
        };
    };
    
    return(<div className="datepickField_new min">
        <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
                onChangeDate(update)
            }}
            maxDate={today}
            placeholderText={placeholderText}
            dateFormat="dd MMM, yyyy"
            isClearable={true}
        />
    </div>)
};

export default CommonDatePicker;