/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Collapse, Image, Button, Modal } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { WebSocketContext } from "../../App";
import { StartMainCommonLoader, GetTicketsLogsList } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import CommonDateTime from "../Common/CommonDateTime";
import MoreIcon from '../../assets/img/more_options.png';
import ZohoRetryAPI from "../../Api/ZohoRetryAPI";
import CommonLogout from "../Common/CommonLogout";
import { toast } from "react-toastify";
import CommonLoader from "../Common/CommonLoader";
import TicketsCommonStatus from "../Common/TicketsCommonStatus";
import CommonScrollBar from "../Common/CommonScrollBar";

const Tickets = (props) => {
    const { ticketsList, device_id, selfInfo, accessToken, commonMainLoader, ticketsLogs } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ statusTickets, setStatusTickets ] = useState("");
    const [ regionSelect, setRegionSelect ] = useState("");
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ Loader, setLoader ] = useState(false);
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "",
        Data: {},
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[2]?.Edit || PermissionsAccess?.DealerApp[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "ticket_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch?.trim(),
                    "status" : statusTickets,
                    "region_id": regionSelect,
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, statusTickets, regionSelect ]);

    // Retry ERP order
    const RtryFunction = async (id) => {
        setLoader(true);
        const Response = await ZohoRetryAPI(id, accessToken);
        if(Response?.status === 200) {
            setLoader(false);
            APICall();
            toast.info(Response?.message);
        } else {
            setLoader(false);
            APICall();
            if(Response?.error) {
                toast.error(Response?.error);
            } else {
                toast.error(Response?.message);
            };
            if(Response?.message === "User is not authorized." && Response?.status === 400) {
                CommonLogout(accessToken, Dispatch);
                localStorage?.removeItem("access_token");
            };
        };
    };

    // error message modal open 
    const ErrorMessageShow = (data) => {
        setOrderViewerModal({
            ...OrderViewerModal,
            open: open,
            Title: "Zoho Error",
            Data: data?.zoho_error,
        });
    };

    // error message modal close
    const CloseErrorMessageModal = () => {
        setOrderViewerModal({
            ...OrderViewerModal,
            open: false,
            Title: "",
            Data: {}
        });
        Dispatch(StartMainCommonLoader({
            url: "",
            loader: false
        }));
        Dispatch(GetTicketsLogsList([]));
    };

    // tickets logs
    const TicketsLogs = (data) => {
        if(data) {
            Dispatch(StartMainCommonLoader({
                url: "ticket_logs",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "ticket_logs",
                "request" : { 
                    "ticket_id" : data?.id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    useEffect(() => {
        if(commonMainLoader?.url === "ticket_logs" && !commonMainLoader?.loader) {
            if(ticketsLogs && ticketsLogs?.length > 0) {
                const CurrentTickets = ticketsList?.list?.filter((elm) => elm?.id === ticketsLogs[0].ticket_id)[0];
            
                setOrderViewerModal({
                    ...OrderViewerModal,
                    open: true,
                    Title: "Ticket History",
                    Data: {
                        ...OrderViewerModal?.TicketsData,
                        current_tickets: CurrentTickets,
                        current_tickets_logs: ticketsLogs
                    },
                });
            } else {
                setOrderViewerModal({
                    ...OrderViewerModal,
                    open: true,
                    Title: "Ticket History",
                    Data: {
                        ...OrderViewerModal?.TicketsData,
                        current_tickets: {},
                        current_tickets_logs: []
                    },
                });
            };
        };
    }, [ commonMainLoader ]);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Ticket_filters"}
            searchType={"Dealer Code, Dealer Name, Customer Mobile, Customer Name, Frame Number, Ticket Number"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            statusTickets={statusTickets}
            setStatusTickets={setStatusTickets}
            regionSelect={regionSelect} 
            setRegionSelect={setRegionSelect}
            ActiveInactiveFilter={false}
        />
        <div className="tableView gstreporttableadded">
            <CommonScrollBar 
                className="ScrollbarsSidebar"
                height={{ height: parseInt(ticketsList?.pagination?.total_records) === ticketsList?.list?.length ? "calc(100vh - 141px)" : parseInt(ticketsList?.pagination?.total_records) > 10 ? "calc(100vh - 175px)" : "calc(100vh - 141px)"}} 
            >
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>Dealer Code</th>
                            <th>Dealer Name</th>
                            <th>Region Name</th>
                            <th>Temporary Ticket Number</th>
                            <th>ZOHO Ticket Number</th>
                            <th>Ticket Creation Date</th>
                            <th>Customer Name</th>
                            <th>Email</th>
                            <th>Contact Number</th>
                            <th>Product Name</th>
                            <th>Zoho Error</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[2]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {ticketsList?.list?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                            <tr key={index}>
                                <td style={{ width: '10px' }}>
                                    <button 
                                        className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                        onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={activeTableDetails === elm?.id ? true : false}
                                    >
                                        <Image src={MoreIcon} alt="more icon" />
                                    </button>
                                </td>
                                <td>
                                    {selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[2]?.Edit ? 
                                        (<b className="tableBolanchor" onClick={() => TicketsLogs(elm)}>{GetCurrentTableSRNo(index, ticketsList?.pagination?.current_page, ticketsList?.pagination?.record_limit)}</b>) : 
                                        GetCurrentTableSRNo(index, ticketsList?.pagination?.current_page, ticketsList?.pagination?.record_limit)
                                    }
                                </td>
                                <td>{elm?.franchise_code === null ? "-" : elm?.franchise_code}</td>
                                <td>{elm?.dealer_name === null ? "-" : elm?.dealer_name}</td>
                                <td>{elm?.region_name === null ? "-" : elm?.region_name}</td>
                                <td>{elm?.temp_ticket_number === null ? "-" : elm?.temp_ticket_number}</td>
                                <td>{elm?.ticket_number === null ? "-" : elm?.ticket_number}</td>
                                <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                <td>{elm?.full_name === null ? "-" : elm?.full_name}</td>
                                <td>{elm?.email === null ? "-" : elm?.email}</td>
                                <td>{elm?.mobile === null ? "-" : elm?.mobile}</td>
                                <td>{elm?.product_name === null ? "-" : elm?.product_name}</td>
                                <td>
                                    {(elm?.zoho_error === null || elm?.zoho_error === "") ? "No Error" : (<Button className="btn edittable btn btn-primary" onClick={()=> ErrorMessageShow(elm)}>
                                        <i className="bi bi-eye"></i>
                                    </Button>)}
                                </td>
                                <td>{TicketsCommonStatus(elm?.status)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[2]?.Edit) && (<td>
                                    {(elm?.zoho_error === null || elm?.zoho_error === "") ? "-" : (<Button className="btn edittable edittableretry btn btn-primary" onClick={() => RtryFunction(elm?.id)}>
                                        <i className="bi bi-arrow-repeat"></i> Retry
                                    </Button>)}
                                </td>)}
                            </tr>
                            <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                    <td colSpan="9" style={{ padding: "0px" }} >
                                        <Table className="table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Frame Number</th>
                                                    <td>{elm?.frame_number === null ? "-" : elm?.frame_number}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Address</th>
                                                    <td>{elm?.address === null ? "-" : elm?.address}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "25%" }}>Description</th>
                                                    <td>{elm?.description === null ? "-" : elm?.description}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </Collapse>
                            </React.Fragment>)
                        })}
                        {ticketsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">list not found</td></tr>}
                    </tbody>
                </Table>
            </CommonScrollBar>
            {/* pagination */}
            {ticketsList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={ticketsList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>

        {((commonMainLoader?.url === "ticket_logs" && commonMainLoader?.loader) || Loader) && (<CommonLoader />)}

        {OrderViewerModal?.open && (
            <Modal show={OrderViewerModal?.open} className={OrderViewerModal?.Title === "Ticket History" ? "errorDetailsModal ticktes_history_details_modal" : "errorDetailsModal"} onHide={()=> CloseErrorMessageModal()}>
                <Modal.Header closeButton>
                <Modal.Title>{OrderViewerModal?.Title !== "Ticket History" ? OrderViewerModal?.Title : (<div className="modal_logs_headers">
                    {OrderViewerModal?.Title}
                    {OrderViewerModal?.Data?.current_tickets !== undefined && Object?.keys(OrderViewerModal?.Data?.current_tickets)?.length > 0 && (<span>Ticket #{OrderViewerModal?.Data?.current_tickets?.ticket_number === null ? OrderViewerModal?.Data?.current_tickets?.temp_ticket_number : OrderViewerModal?.Data?.current_tickets?.ticket_number}</span>)}
                </div>)}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    {OrderViewerModal?.Title !== "Ticket History" ? (<p>
                        {OrderViewerModal?.Data === null ? "No Error Found" : OrderViewerModal?.Data}
                    </p>) : (<ul>
                        {OrderViewerModal?.Data?.current_tickets !== undefined && OrderViewerModal?.Data?.current_tickets_logs?.length > 0 ? OrderViewerModal?.Data?.current_tickets_logs?.map((elm, index) => {
                            function ReturnZohoDate(data_) {
                                return CommonDateTime(data_, "Do MMM YYYY, h:mm A", "show");
                            };

                            return(<li key={index}>
                                <h5>{elm?.ticket_status}</h5>
                                <span>
                                    {ReturnZohoDate(elm.created_at)}
                                </span>
                            </li>)
                        }) : "No logs Found"}
                    </ul>)}
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default Tickets;