/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";
import IncreDeacrementPercentage from "./common/IncreDeacrementPercentage";
import CommonScrollBar from "../Common/CommonScrollBar";
import CommonPieChart from "./common/CommonPieChart";  

const WarrantyRegistrationReport = () => {
    const { device_id, DashboardWarrantyRegistarionReport, DashboardWarrantyAgeGroupCategoryUseCaseReport, DashboardWarrantyStateVsResistrationsReport, DashboardWarrantyProductVsResistrationsReport, DashboardWarrantyRegionVsResistrationsReport, CategoryWiseClassificationsReport, DealerWiseAvailableInventoryReport, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userLimit, setUserLimit ] = useState(5);
    
    // Warranty Registration Report Filter States
    const [ franchiseSelect, setFranchiseSelect ] = useState("");
    const [ stateSelect, setStateSelect ] = useState("");
    const [ regionSelect, setRegionSelect ] = useState("");
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    
    // State Vs Registration Report Filter States
    const [ svrregionSelect, setsvrRegionSelect ] = useState("");
    const [ svrProductselect, setsvrProductselect ] = useState("");
    const [ svrdateRange, setsvrDateRange ] = useState([null, null]);
    const [ startDatesvr, endDateSvr ] = svrdateRange;
    const [ currentPage, setCurrentPage ] = useState(1);

    // Product Vs Registration Report Filter States
    const [ pvrregionSelect, setPvrRegionSelect ] = useState("");
    const [ pvrStateselect, setPvrStateselect ] = useState("");
    const [ pvrdateRange, setpvrDateRange ] = useState([null, null]);
    const [ startDatepvr, endDatePvr ] = pvrdateRange;
    const [ pvrcurrentPage, setPvrCurrentPage ] = useState(1);

    // Region Vs Registration Report Filter States
    const [ regiondateRange, setregionDateRange ] = useState([null, null]);
    const [ startDateregion, endDateregion ] = regiondateRange;
    const [ regioncurrentPage, setregionCurrentPage ] = useState(1);

    // Dealer wise Available Inventory Report Filter States
    const [ daInvdateRange, setdaInvDateRange ] = useState([null, null]);
    const [ startDatedaInv, endDatedaInv ] = daInvdateRange;
    const [ daInvcurrentPage, setdaInvCurrentPage ] = useState(1);
    const [ daInvSearch, setdaInvSearch ] = useState("");

    const { websocket } = useContext(WebSocketContext);

    // GET ALL FRANCHISE LIST
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        if(selfInfo?.user?.user_type === "regional_sales_team_rm") {
            param.request.rm_id = selfInfo?.user?.id;
        };
        wsSend_request(websocket, param);
    }, [ websocket ]);

    // GET ALL STATE LIST
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "state_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        if(selfInfo?.user?.user_type === "regional_sales_team_rm") {
            param.request.region_id = selfInfo?.user?.related_id;
        };
        wsSend_request(websocket, param);
    }, [ websocket ]);

    // GET ALL REGIONS LIST
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, [ websocket ]);

    // GET PRODUCT LIST ALL
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, [ websocket ]);


    // WARRANTY REGISTRATIONS COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("warranty_register_report", {
            "from_date": startDate, 
            "to_date": endDate, 
            "franchise_id": franchiseSelect?.value, 
            "state_id": stateSelect?.value, 
            "region_id": regionSelect?.value
        });
    }, [ websocket, endDate, franchiseSelect, stateSelect, regionSelect ]);

    // STATE VS WARRANTY COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("state_vs_warranty_register", {
            "from_date": startDatesvr, 
            "to_date": endDateSvr, 
            "product_id": svrProductselect?.value, 
            "region_id": svrregionSelect?.value,
            "page_no": currentPage,
            "limit": userLimit
        });
    }, [ websocket, endDateSvr, svrProductselect, svrregionSelect, userLimit, currentPage ]);

    // PRODUCT VS WARRANTY COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("product_vs_warranty_register", {
            "from_date": startDatepvr, 
            "to_date": endDatePvr, 
            "state_id": pvrStateselect?.value, 
            "region_id": pvrregionSelect?.value,
            "page_no": pvrcurrentPage,
            "limit": userLimit
        });
    }, [ websocket, endDatePvr, pvrStateselect, pvrregionSelect, userLimit, pvrcurrentPage ]);

    // REGION VS WARRANTY COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("region_vs_warranty_register", {
            "from_date": startDateregion, 
            "to_date": endDateregion, 
            "page_no": regioncurrentPage,
            "limit": userLimit
        });
    }, [ websocket, endDateregion, userLimit, regioncurrentPage ]);

    // CLASSIFICATIONS WISE API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("classification_wise_reports", {});
    }, [ websocket ]);

    // CATEGORY WISE CLASSIFICATION COUNT API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("franchise_type_wise_classification_reports", {
            "page_no": "1",
            "limit": userLimit
        });
    }, [ websocket, endDateregion, userLimit ]);


    // DEALER WISE AVAILABLE INVENTORY API CALLING
    useEffect(() => {
        DashBoardWebSocketsAPIcalling("dealer_vs_warranty_register", {
            "from_date": startDatedaInv, 
            "to_date": endDatedaInv, 
            "search": daInvSearch, 
            "page_no": daInvcurrentPage,
            "limit": userLimit
        });
    }, [ websocket, daInvSearch, userLimit, daInvcurrentPage, endDatedaInv ]);

    // COMMON API CALLING
    const DashBoardWebSocketsAPIcalling = (api_url, request_params) => {
        let param;

        if(Object?.keys(request_params)?.length > 0) {
            const { from_date, to_date, franchise_id, state_id, region_id, product_id, page_no, limit, search } = request_params;

            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };

            // FROM AND TO DATE FILTER
            if((from_date !== null && from_date !== undefined) && (to_date !== null && to_date !== undefined)) {
                param.request.from_date = CommonDateTime(from_date, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(to_date, "YYYY-MM-DD", "hide");
            };

            // FRANCHISE ID FILTER
            if(franchise_id) {
                param.request.franchise_id = franchise_id;
            };

            // STATE ID FILTER
            if(state_id) {
                param.request.state_id = state_id;
            };

            // PRODUCT ID FILTER
            if(product_id) {
                param.request.product_id = product_id;
            };

            // REGION ID FILTER
            if(region_id) {
                param.request.region_id = region_id;
            };

            // SEARCH FILTER 
            if(search) {
                param.request.search = search?.trim();
            };

            // PAGE NO FILTER
            if(page_no) {
                param.request.page_no = page_no;
            };

            // LIMIT FILTER
            if(limit) {
                param.request.limit = limit;
            };
        } else {
            param = {
                "transmit": "broadcast",
                "url": api_url,
                "request": {},
                "DeviceId": device_id
            };
        };
        wsSend_request(websocket, param);
    };

    // State vs Registrations TABLE COLUMNS ARRAY
    const StateVsRegistrationsTableColumns = [
        { key: 'state_name', label: 'State', type: "label", sort: "" },
        { key: 'register_count', label: 'Registrations', type: "label", sort: "" },
        { key: 'mismatch_count', label: 'Mismatched Frames', type: "label", sort: "" },
        { key: 'percentage_difference', label: '% Difference', type: "label_only_percentage", sort: "" },
    ];

    // Product vs Registrations TABLE COLUMNS ARRAY
    const ProductVsRegistrationsTableColumns = [
        { key: 'product_name', label: 'Product Name', type: "label", sort: "" },
        { key: 'register_count', label: 'Registrations', type: "label", sort: "" },
        { key: 'mismatch_count', label: 'Mismatched Frames', type: "label", sort: "" },
        { key: 'percentage_difference', label: '% Difference', type: "label_only_percentage", sort: "" },
    ];

    // Region vs Registrations TABLE COLUMNS ARRAY
    const RegionVsRegistrationsTableColumns = [
        { key: 'region_name', label: 'Region Name', type: "label", sort: "" },
        { key: 'register_count', label: 'Registrations', type: "label", sort: "" },
        { key: 'mismatch_count', label: 'Mismatched Frames', type: "label", sort: "" },
        { key: 'percentage_difference', label: '% Difference', type: "label_only_percentage", sort: "" },
    ];
    
    // Dealer-Wise Available Inventory TABLE COLUMNS ARRAY
    const DealerWiseAvailableInventoryTableColumns = [
        { key: 'franchise_name', label: 'Unique Dealer', type: "label", sort: "" },
        { key: 'primary_sale_qty', label: 'Primary Sale', type: "label", sort: "" },
        { key: 'secondary_sale_qty', label: 'Secondary Sale', type: "label", sort: "" },
        { key: 'available_qty', label: 'Available Qty', type: "label", sort: "" },
    ];

    // AGE GROUPS CLASSIFICATIONS TABLE COLUMNS ARRAY
    const AgeGroupClassificationsTableColumns = [
        { key: 'age_group', label: 'Age', type: "label", sort: "" },
        { key: 'count', label: 'Record Count', type: "label", sort: "" },
    ];

    // CATEGORY WISE CLASSIFICATIONS TABLE COLUMNS ARRAY
    const CategoryWiseClassificationsTableColumns = [
        { key: 'franchise_type_name', label: 'Category', type: "label", sort: "" },
        { key: 'register_count', label: 'Record Count', type: "label", sort: "" },
    ];

    // USE CASE WISE CLASSIFICATIONS TABLE COLUMNS ARRAY
    const UseCaseWiseClassificationsTableColumns = [
        { key: 'use_case', label: 'Use Case', type: "label", sort: "" },
        { key: 'count', label: 'Record Count', type: "label", sort: "" },
    ];

    // WARRANTY REGISTRATIONS REPORT COLUMNS
    const WarrantyRegistrationColumns = ({colmd, header, registrations_count, primary_count, difference_count}) => {
        return(<div className={`col-md-${colmd}`}>
            <div className="warranty_registartions_columns">
                <h4 className="Monthly_heading Monthly">{header}</h4>
                <div className="warranty_reg_cols">
                    <p>Registrations</p>
                    <h4>{registrations_count}</h4>
                </div>
                <div className="warranty_reg_cols">
                    <p>Mismatched Frames</p>
                    <h4>{primary_count}</h4>
                </div>
                <div className="warranty_reg_cols">
                    <p>% Comparison</p>
                    <h4>
                        {IncreDeacrementPercentage(parseFloat(parseFloat(difference_count).toFixed(2)), "No_Badge")}
                    </h4>
                </div>
            </div>
        </div>)
    };

    const MergeDuplicateItem = (data, url) => {
        if(data !== undefined) {
            const mergedData = data?.map((elm) => {
                if(url === "Use-Case-Wise-Classification") {
                    return elm;
                } else if(url === "Category-Group-Wise-Classification") {
                    return {
                        ...elm,
                        use_case: elm?.franchise_type_name,
                        count: elm?.register_count,
                    };
                } else if(url === "Age-Group-Vs-Record-Count") {
                    return {
                        ...elm,
                        use_case: elm?.age_group,
                        count: elm?.count,
                    };
                } else if(url === "Region-Vs-Registrations") {
                    return {
                        ...elm,
                        use_case: elm?.region_name,
                        count: elm?.register_count,
                    };
                } else if(url === "Product-Vs-Registrations") {
                    return {
                        ...elm,
                        use_case: elm?.product_name,
                        count: elm?.register_count,
                    };
                } else if(url === "State-Vs-Registrations") {
                    return {
                        ...elm,
                        use_case: elm?.state_name,
                        count: elm?.register_count,
                    };
                } else {
                    return elm;
                };
            }).reduce((acc, { use_case, count }) => {
                const normalizedUseCase = use_case.trim();
            
                if (acc[normalizedUseCase]) {
                    acc[normalizedUseCase].count += count;
                } else {
                    acc[normalizedUseCase] = { use_case, count };
                };
            
                return acc;
            }, {});
            
            return Object?.values(mergedData);
        } else {
            return [];
        };
    };

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <CommonScrollBar height={{ height: "calc(100vh - 62px)" }}>
                {/* Warranty Registration Report : Table */}
                <div className="dashboard_main_wrapper" >
                    <CommonHeader 
                        heading={"Warranty Registration Report"} 
                        isSearchAllow={false}
                        searchType={"Franchise Code, ERP ID, Customer Name"}
                        isDatePickerAllow={false}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={true}
                        Dealer={franchiseSelect} 
                        setDealer={setFranchiseSelect}
                        isStateAllow={true}
                        stateSelect={stateSelect}
                        setStateSelect={setStateSelect}
                        isRegionAllow={true}
                        regionSelect={regionSelect}
                        setRegionSelect={setRegionSelect}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: false,
                            id: ""
                        }}
                        isBackButtonAllow={{
                            open: false,
                            navigate_url: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper" style={{ margin: "10px 0 0" }}>
                        <div className="row">
                            <WarrantyRegistrationColumns
                                colmd={"3"} 
                                header={"Today"} 
                                registrations_count={DashboardWarrantyRegistarionReport?.today_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.today_count}
                                primary_count={DashboardWarrantyRegistarionReport?.today_mismatch_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.today_mismatch_count} 
                                difference_count={DashboardWarrantyRegistarionReport?.today_vs_yesterday_diff === undefined ? 0 : DashboardWarrantyRegistarionReport?.today_vs_yesterday_diff}
                            />
                            <WarrantyRegistrationColumns
                                colmd={"3"} 
                                header={"Monthly"} 
                                registrations_count={DashboardWarrantyRegistarionReport?.month_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.month_count}
                                primary_count={DashboardWarrantyRegistarionReport?.month_mismatch_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.month_mismatch_count} 
                                difference_count={DashboardWarrantyRegistarionReport?.month_vs_last_month_diff === undefined ? 0 : DashboardWarrantyRegistarionReport?.month_vs_last_month_diff}
                            />
                            <WarrantyRegistrationColumns
                                colmd={"3"} 
                                header={"Yearly"} 
                                registrations_count={DashboardWarrantyRegistarionReport?.year_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.year_count}
                                primary_count={DashboardWarrantyRegistarionReport?.year_mismatch_count === undefined ? 0 : DashboardWarrantyRegistarionReport?.year_mismatch_count} 
                                difference_count={DashboardWarrantyRegistarionReport?.year_vs_last_year_diff === undefined ? 0 : DashboardWarrantyRegistarionReport?.year_vs_last_year_diff}
                            />
                        </div>
                    </div>
                </div>
                
                {/* State Vs Registrations : Table */}
                <div className="dashboard_main_wrapper" >
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-12">
                            <CommonHeader 
                                heading={"State Vs Registrations"} 
                                isSearchAllow={false}
                                searchType={"Franchise Code, ERP ID, Customer Name"}
                                isDatePickerAllow={true}
                                dateRange={svrdateRange} 
                                setDateRange={setsvrDateRange}
                                isSkuTypeAllow={false}
                                isOrderStatusAllow={false}
                                setCurrentPage={setCurrentPage}
                                isDealerAllow={false}
                                isStateAllow={false}
                                isRegionAllow={true}
                                regionSelect={svrregionSelect}
                                setRegionSelect={setsvrRegionSelect}
                                isProductAllow={true}
                                Productselect={svrProductselect}
                                setProductselect={setsvrProductselect}
                                isAllowDownloadPdf={{
                                    open: false,
                                    id: ""
                                }}
                                isAllowDownloadExel={{
                                    open: true,
                                    id: "State-Wise-Report"
                                }}
                                isBackButtonAllow={{
                                    open: false,
                                    navigate_url: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                                <h4 className="Monthly_heading Monthly">State-Wise Report</h4>
                                <DashBoardTables 
                                    isSrNoAllow={false}
                                    Columns={StateVsRegistrationsTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardWarrantyStateVsResistrationsReport} // ORDER LIST
                                    PaginationCurrentPage={currentPage}
                                    setPaginationCurrentPage={setCurrentPage}
                                    PaginationSet={DashboardWarrantyStateVsResistrationsReport?.pagination} // CURRENT TAB PAGINATION
                                    isGrandTotalAllow={{
                                        allow: DashboardWarrantyStateVsResistrationsReport?.list?.length > 0,
                                        table_name: "State-Vs-Registrations",
                                        title: "Grand Total",
                                        colspan: null,
                                        data: DashboardWarrantyStateVsResistrationsReport
                                    }}
                                    height={"570px"}
                                    id={"State-Wise-Report"}
                                    isActionAllow={{
                                        allow: false,
                                        functionType: ""
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <CommonPieChart 
                                title={"State Vs Registrations"}
                                dataPieChart={MergeDuplicateItem(DashboardWarrantyStateVsResistrationsReport?.list, "State-Vs-Registrations")} 
                            />
                        </div>
                    </div>
                </div>

                {/* Product Vs Registrations : Table */}
                <div className="dashboard_main_wrapper" >
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-12">
                            <CommonHeader 
                                heading={"Product Vs Registrations"} 
                                isSearchAllow={false}
                                searchType={""}
                                isDatePickerAllow={true}
                                dateRange={pvrdateRange} 
                                setDateRange={setpvrDateRange}
                                isSkuTypeAllow={false}
                                isOrderStatusAllow={false}
                                setCurrentPage={setPvrCurrentPage}
                                isDealerAllow={false}
                                isStateAllow={true}
                                stateSelect={pvrStateselect}
                                setStateSelect={setPvrStateselect}
                                isRegionAllow={true}
                                regionSelect={pvrregionSelect}
                                setRegionSelect={setPvrRegionSelect}
                                isProductAllow={false}
                                isAllowDownloadPdf={{
                                    open: false,
                                    id: ""
                                }}
                                isAllowDownloadExel={{
                                    open: true,
                                    id: "Product-Wise-Report"
                                }}
                                isBackButtonAllow={{
                                    open: false,
                                    navigate_url: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                                <h4 className="Monthly_heading Monthly">Product-Wise Report</h4>
                                <DashBoardTables 
                                    isSrNoAllow={false}
                                    Columns={ProductVsRegistrationsTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardWarrantyProductVsResistrationsReport} // ORDER LIST
                                    PaginationCurrentPage={pvrcurrentPage}
                                    setPaginationCurrentPage={setPvrCurrentPage}
                                    PaginationSet={DashboardWarrantyProductVsResistrationsReport?.pagination} // CURRENT TAB PAGINATION
                                    isGrandTotalAllow={{
                                        allow: DashboardWarrantyProductVsResistrationsReport?.list?.length > 0,
                                        table_name: "State-Vs-Registrations",
                                        title: "Grand Total",
                                        colspan: null,
                                        data: DashboardWarrantyProductVsResistrationsReport,
                                    }}
                                    height={"570px"}
                                    id={"Product-Wise-Report"}
                                    isActionAllow={{
                                        allow: false,
                                        functionType: ""
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 dashboard-table-center" >
                            <CommonPieChart 
                                title={"Product Vs Registrations"}
                                dataPieChart={MergeDuplicateItem(DashboardWarrantyProductVsResistrationsReport?.list, "Product-Vs-Registrations")} 
                            />
                        </div>
                    </div>
                </div>

                {/* Region Vs Registrations : Table */}
                <div className="dashboard_main_wrapper">
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-12">
                            <CommonHeader 
                                heading={"Region Vs Registrations"} 
                                isSearchAllow={false}
                                searchType={""}
                                isDatePickerAllow={true}
                                dateRange={regiondateRange} 
                                setDateRange={setregionDateRange}
                                isSkuTypeAllow={false}
                                isOrderStatusAllow={false}
                                setCurrentPage={setCurrentPage}
                                isDealerAllow={false}
                                isStateAllow={false}
                                isRegionAllow={false}
                                isProductAllow={false}
                                isAllowDownloadPdf={{
                                    open: false,
                                    id: ""
                                }}
                                isAllowDownloadExel={{
                                    open: true,
                                    id: "Region-Wise-Report"
                                }}
                                isBackButtonAllow={{
                                    open: false,
                                    navigate_url: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>                    
                            <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                                <h4 className="Monthly_heading Monthly">Region-Wise Report</h4>
                                <DashBoardTables 
                                    isSrNoAllow={false}
                                    Columns={RegionVsRegistrationsTableColumns} // TABLE COLLUMNS & ROWS
                                    OrderList={DashboardWarrantyRegionVsResistrationsReport} // ORDER LIST
                                    PaginationCurrentPage={""}
                                    setPaginationCurrentPage={""}
                                    PaginationSet={{
                                        current_page: "1", 
                                        record_limit: "3",
                                    }} // CURRENT TAB PAGINATION
                                    isGrandTotalAllow={{
                                        allow: DashboardWarrantyRegionVsResistrationsReport?.list?.length > 0,
                                        table_name: "State-Vs-Registrations",
                                        title: "Grand Total",
                                        colspan: null,
                                        data: DashboardWarrantyRegionVsResistrationsReport,
                                    }}
                                    height={"320px"}
                                    id={"Region-Wise-Report"}
                                    isActionAllow={{
                                        allow: false,
                                        functionType: ""
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-6 dashboard-table-center" >
                            <CommonPieChart 
                                title={"Region Vs Registrations"}
                                dataPieChart={MergeDuplicateItem(DashboardWarrantyRegionVsResistrationsReport?.list, "Region-Vs-Registrations")} 
                            />
                        </div>
                    </div>
                </div>

                {/* Dealer-Wise Available Inventory : Table */}
                <div className="dashboard_main_wrapper">
                    <CommonHeader 
                        heading={"Dealer-Wise Available Inventory"} 
                        isSearchAllow={true}
                        searchType={"Unique Dealer"}
                        userSearch={daInvSearch}
                        setUserSearch={setdaInvSearch}
                        isDatePickerAllow={true}
                        dateRange={daInvdateRange} 
                        setDateRange={setdaInvDateRange}
                        isSkuTypeAllow={false}
                        isOrderStatusAllow={false}
                        setCurrentPage={setdaInvCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isAllowDownloadPdf={{
                            open: false,
                            id: ""
                        }}
                        isAllowDownloadExel={{
                            open: true,
                            id: "Dealer-Wise-Available-Inventory"
                        }}
                        isBackButtonAllow={{
                            open: false,
                            navigate_url: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper dashboard-table-center" style={{ margin: "10px 0 0" }}>
                        <h4 className="Monthly_heading Monthly">Dealer-Wise Available Inventory</h4>
                        <DashBoardTables 
                            isSrNoAllow={false}
                            Columns={DealerWiseAvailableInventoryTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DealerWiseAvailableInventoryReport} // ORDER LIST
                            PaginationCurrentPage={daInvcurrentPage}
                            setPaginationCurrentPage={setdaInvCurrentPage}
                            PaginationSet={DealerWiseAvailableInventoryReport?.pagination} // CURRENT TAB PAGINATION
                            isGrandTotalAllow={{
                                allow: DealerWiseAvailableInventoryReport?.list?.length > 0,
                                table_name: "Dealer-Wise-Available-Inventory",
                                title: "Grand Total",
                                colspan: null,
                                data: DealerWiseAvailableInventoryReport,
                            }}
                            height={"320px"}
                            id={"Dealer-Wise-Available-Inventory"}
                            isActionAllow={{
                                allow: true,
                                label: "Action",
                                functionType: "Dealer-Wise-Available-Frame-Inventory",
                                franchise_id: ""
                            }}
                        />
                    </div>
                </div>

                <div className="dashboard_main_wrapper">
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-12">
                            <CommonHeader 
                                heading={"Age-Group Wise Classification"} 
                                isSearchAllow={false}
                                searchType={""}
                                isDatePickerAllow={false}
                                isSkuTypeAllow={false}
                                isOrderStatusAllow={false}
                                isDealerAllow={false}
                                isStateAllow={false}
                                isRegionAllow={false}
                                isProductAllow={false}
                                isAllowDownloadPdf={{
                                    open: false,
                                    id: ""
                                }}
                                isAllowDownloadExel={{
                                    open: true,
                                    id: "Age-Group-Wise-Classification"
                                }}
                                isBackButtonAllow={{
                                    open: false,
                                    navigate_url: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <h4 className="Monthly_heading Monthly">Age Group-Wise Classification</h4>
                            <DashBoardTables 
                                isSrNoAllow={false}
                                Columns={AgeGroupClassificationsTableColumns} // TABLE COLLUMNS & ROWS
                                OrderList={{
                                    list: DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts
                                }} // ORDER LIST
                                PaginationCurrentPage={""}
                                setPaginationCurrentPage={""}
                                PaginationSet={{
                                    current_page: "1", 
                                    record_limit: "3",
                                }} // CURRENT TAB PAGINATION
                                isGrandTotalAllow={{
                                    allow: DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts?.length > 0,
                                    table_name: "Use-Case-Wise-Classification",
                                    title: "Grand Total",
                                    colspan: null,
                                    data: {
                                        list:  DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts
                                    },
                                }}
                                height={"340px"}
                                id={"Age-Group-Wise-Classification"}
                                isActionAllow={{
                                    allow: false,
                                    functionType: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" >
                            <CommonPieChart 
                                title={"Age-Group Vs Record Count"}
                                dataPieChart={MergeDuplicateItem(DashboardWarrantyAgeGroupCategoryUseCaseReport?.age_group_counts, "Age-Group-Vs-Record-Count")} 
                            />
                        </div>
                    </div>
                </div>

                <div className="dashboard_main_wrapper">
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-12">
                            <CommonHeader 
                                heading={"Category-Group Wise Classification"} 
                                isSearchAllow={false}
                                searchType={""}
                                isDatePickerAllow={false}
                                isSkuTypeAllow={false}
                                isOrderStatusAllow={false}
                                isDealerAllow={false}
                                isStateAllow={false}
                                isRegionAllow={false}
                                isProductAllow={false}
                                isAllowDownloadPdf={{
                                    open: false,
                                    id: ""
                                }}
                                isAllowDownloadExel={{
                                    open: true,
                                    id: "Category-Group-Wise-Classification"
                                }}
                                isBackButtonAllow={{
                                    open: false,
                                    navigate_url: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" style={{ margin: "0 0 40px" }}>
                            <h4 className="Monthly_heading Monthly">Category-Wise Classification</h4>
                            <DashBoardTables 
                                isSrNoAllow={false}
                                Columns={CategoryWiseClassificationsTableColumns} // TABLE COLLUMNS & ROWS
                                OrderList={CategoryWiseClassificationsReport} // ORDER LIST
                                PaginationCurrentPage={""}
                                setPaginationCurrentPage={""}
                                PaginationSet={{
                                    current_page: "1", 
                                    record_limit: "3",
                                }} // CURRENT TAB PAGINATION
                                isGrandTotalAllow={{
                                    allow: CategoryWiseClassificationsReport?.list?.length > 0,
                                    table_name: "Age-Group-Category-Use-Case-Wise-Classification",
                                    title: "Grand Total",
                                    colspan: null,
                                    data: CategoryWiseClassificationsReport?.total_register_count,
                                }}
                                height={"283px"}
                                id={"Category-Group-Wise-Classification"}
                                isActionAllow={{
                                    allow: false,
                                    functionType: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center" >
                            <CommonPieChart 
                                title={"Category-Group Vs Record Count"}
                                dataPieChart={MergeDuplicateItem(CategoryWiseClassificationsReport?.list, "Category-Group-Wise-Classification")} 
                            />
                        </div>
                    </div>
                </div>
                <div className="dashboard_main_wrapper" style={{ marginBottom: "0px" }}>
                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-12">
                            <CommonHeader 
                                heading={"Use Case-Wise Classification"} 
                                isSearchAllow={false}
                                searchType={""}
                                isDatePickerAllow={false}
                                isSkuTypeAllow={false}
                                isOrderStatusAllow={false}
                                isDealerAllow={false}
                                isStateAllow={false}
                                isRegionAllow={false}
                                isProductAllow={false}
                                isAllowDownloadPdf={{
                                    open: false,
                                    id: ""
                                }}
                                isAllowDownloadExel={{
                                    open: true,
                                    id: "Use-Case-Wise-Classification"
                                }}
                                isBackButtonAllow={{
                                    open: false,
                                    navigate_url: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center">
                            <h4 className="Monthly_heading Monthly">Use Case-Wise Classification</h4>
                            <DashBoardTables 
                                isSrNoAllow={false}
                                Columns={UseCaseWiseClassificationsTableColumns} // TABLE COLLUMNS & ROWS
                                OrderList={{
                                    list: MergeDuplicateItem(DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts, "Use-Case-Wise-Classification")
                                }} // ORDER LIST
                                PaginationCurrentPage={""}
                                setPaginationCurrentPage={""}
                                PaginationSet={{
                                    current_page: "1", 
                                    record_limit: "3",
                                }} // CURRENT TAB PAGINATION
                                isGrandTotalAllow={{
                                    allow: DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts?.length > 0,
                                    table_name: "Use-Case-Wise-Classification",
                                    title: "Grand Total",
                                    colspan: null,
                                    data: {
                                        list:  DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts
                                    },
                                }}
                                height={"420px"}
                                id={"Use-Case-Wise-Classification"}
                                isActionAllow={{
                                    allow: false,
                                    functionType: ""
                                }}
                            />
                        </div>
                        <div className="col-md-6 dashboard-table-center">
                            <CommonPieChart 
                                title={"Use-Case Vs Record Count"}
                                dataPieChart={MergeDuplicateItem(DashboardWarrantyAgeGroupCategoryUseCaseReport?.use_case_counts, "Use-Case-Wise-Classification")} 
                            />
                        </div>
                    </div>
                </div>
            </CommonScrollBar>
        </div>
    </div>)
};

export default WarrantyRegistrationReport;