/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import SidebarMain from "./SidebarMain";
import SidebarChild from "./SidebarChild";
import { Link } from "react-router-dom";
import { DASHBOARD_URL } from "../constant";
import { useDispatch } from "react-redux";
import { setGetMagicCode } from "../../../redux/actions/adminActions";
import { Image, Button } from "react-bootstrap";
import LogoImage from "../../../assets/img/logo.svg";
import MiniLogoImage from "../../../assets/img/EM.png";
import CommonModalLogout from "../../Common/CommonModalLogout";

const Sidebar = (props) => {
    const { setDivWidth, sidebarToggle, setSidebarToggle } = props
    const myDivRef = useRef(null);
    const [menuStates, setMenuStates] = useState({
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
    });
    const [ LogoutModal, setLogoutModal ] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const updateWidth = () => {
          if (myDivRef.current) {
            setDivWidth(myDivRef.current.clientWidth);
          }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
          window.removeEventListener('resize', updateWidth);
        };
    }, []); 

    const callBackErrorHandle = (e) =>{
        if(window.location.origin !== "http://localhost:3000" && window.location.origin !== "http://localhost:3001"){
            dispatch(setGetMagicCode(""));
        }
    };

    // LOGOUT FUNCTION
    const CommonLogoutFct = () => {
      setLogoutModal(!LogoutModal);
    };

    return(<div className={!sidebarToggle ? "sidebarWrapper close" : "sidebarWrapper"} ref={myDivRef}>
        <div className="navLogo">
            <Link to={DASHBOARD_URL} className="nav-logo">
                <Image 
                    src={MiniLogoImage} 
                    className="mini-logo-icons"
                    onErrorCapture={callBackErrorHandle} 
                    onError={callBackErrorHandle} 
                    alt="mini-logo" 
                />
                <Image 
                    src={LogoImage} 
                    className="logo-icons"
                    onErrorCapture={callBackErrorHandle} 
                    onError={callBackErrorHandle} 
                    alt="logo" 
                />
            </Link>
        </div>
        <SidebarMain sidebarToggle={sidebarToggle} setSidebarToggle={setSidebarToggle} menuStates={menuStates} setMenuStates={setMenuStates}/>
        {sidebarToggle && (<SidebarChild menuStates={menuStates} setMenuStates={setMenuStates}/>)}

        {/* COMMON LOGOUT BUTTON */}
        <Button 
          className="cmmn-logut-btn" 
          onClick={() => CommonLogoutFct()}
        > 
          {sidebarToggle ? "Logout" : (<i className="bi bi-box-arrow-right"></i>)}
        </Button>

        {/* COMMON LOGOUT MODAL */}
        {LogoutModal && (<CommonModalLogout 
            LogoutModal={LogoutModal} 
            setLogoutModal={setLogoutModal}
        />)}
    </div>)
};

export default Sidebar;