import React from "react";
import { Badge } from "react-bootstrap";

const CommonToggle = (props) => {
    const { isBadgeAllow, badgeTitle, valueToggle, setValueToggle, name, disabled } = props;

    const BadgeAllowing = () => {
        if(badgeTitle === "active_inactive") {
            if(valueToggle) {
                return(<Badge bg="success">Active</Badge>)
            } else {
                return(<Badge bg="danger">Inactive</Badge>)
            };
        } else if(badgeTitle === "yes_no") {
            if(valueToggle) {
                return(<Badge bg="success">Yes</Badge>)
            } else {
                return(<Badge bg="danger">No</Badge>)
            };
        } else if(badgeTitle === "docket_number_EWB_number") {
            if(valueToggle) {
                return(<Badge bg="success">Docket Number</Badge>)
            } else {
                return(<Badge bg="danger">EWB Number</Badge>)
            };
        };
    };

    const ToggleButtonFunction = () => {
        if(disabled) {
        } else {
            setValueToggle(!valueToggle);
        };
    };

    return(<div className="mb-3">
        {name && (<label className="form-label">{name}</label>)}
        <div className="ToggleMainWrap">
            <div className="comnToggle" onClick={() => ToggleButtonFunction()}>
                {valueToggle ? (<i className="bi bi-toggle2-on"></i>) :
                (<i className="bi bi-toggle2-off"></i>)}
            </div>
            {isBadgeAllow && (
                <BadgeAllowing />
            )}
        </div>
    </div>)
}

export default CommonToggle;