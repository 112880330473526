/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_SCHEMES_URL, EDIT_SCHEMES_URL } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonTable from "../Common/CommonTable";

const Schemes = (props) => {
    const { franchiseSchemeList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // get all product list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "salesperson_incentive_slab",
            "request" : { 
                "status": true,
                "limit" : 5000,
                "search" : "",
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DealerApp[1]?.Edit || PermissionsAccess?.DealerApp[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_scheme_list",
                "request" : { 
                    "status": userType === "active" ? true : userType === "inactive" ? false : "",
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch?.trim(),
                    "start_date": null,
                    "end_date": null,
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.start_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.end_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            }
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, FromDate, ToDate ]);

    // create points
    const cmnFnct = () => {
        Navigate(ADD_SCHEMES_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'scheme_name', label: 'Scheme Name', type: "label_day_price", sort: "", isCollapsable: false },
        { key: 'scheme_code', label: 'Scheme Code', type: "label_day_price", sort: "", isCollapsable: false },
        { key: 'product_name', label: 'Product Name', type: "label_get_from_product_data", sort: "", isCollapsable: false },
        { key: 'incentive_code', label: 'Incentive Code', type: "label", sort: "", isCollapsable: false },
        { key: 'start_date', label: 'Start Date', type: "date", sort: "", isCollapsable: false },
        { key: 'end_date', label: 'End Date', type: "date", sort: "", isCollapsable: false },
    ];

    return(<section className="Main_section schemes_Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group className="mb-3">
                        <div className="datepickField min">
                            <DatePicker
                                selected={FromDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter Start Date"
                                isClearable={FromDate !== "" ? true : false}
                                name="statDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group className="mb-3">
                        <div className="datepickField">
                            <DatePicker
                                selected={ToDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                minDate={FromDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter End Date"
                                isClearable={ToDate !== "" ? true : false}
                                name="ToDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={""}
            searchType={"Scheme Name, Incentive Code"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[1]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Scheme",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(franchiseSchemeList?.pagination?.total_records) === franchiseSchemeList?.list?.length ? "calc(100vh - 193px)" : parseInt(franchiseSchemeList?.pagination?.total_records) > 10 ? "calc(100vh - 229px)" : "calc(100vh - 193px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={"user-list"} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={franchiseSchemeList} // CATEGORY LIST
            Pagination={franchiseSchemeList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[1]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.DealerApp[1]?.Edit),
                label: "edit_delete_scheme",
                naviagte: `${EDIT_SCHEMES_URL}/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default Schemes;