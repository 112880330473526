/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { STATES_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";

const AddStates = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        StateName: "",
        StateCode: "",
        GSTCode: "",
        Status: true
    });
    const [ RegionValue, setRegionValue ] = useState("");
    const roomId = useParams()?.id;
    const { Regionlistall, ClearFormSet, device_id, stateList } = useSelector((state) => state.adminReducers);
    const stateData = stateList?.list?.filter((elm) => elm?.id === roomId)[0];
    const optionsRegion = Regionlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.region_name }));
    // error state
    const [ errorStateName, seterrorStateName ] = useState("");
    const [ errorStateCode, seterrorStateCode ] = useState("");
    const [ errorGSTCode, seterrorGSTCode ] = useState("");
    const [ errorRegionValue, seterrorRegionValue ] = useState("");
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        if(LocationRoute === "edit-state") {
            const data = optionsRegion?.filter((eml)=>stateData?.region_id === eml?.value)[0];
            setApplicationState({
                ...ApplicationState,
                StateName:stateData?.state_name,
                StateCode:stateData?.state_code,
                GSTCode:stateData?.gst_code,
                Status: stateData?.is_active,
            });
            setRegionValue(data);
        };
    }, [ stateData ])

    // create and edit states
    const CreateBrand = () => {
        if(ApplicationState?.StateName?.trim() !== "" && ApplicationState?.StateCode?.trim() !== "" && 
        ApplicationState?.GSTCode?.trim() !== "" && RegionValue !== "") {
            if(LocationRoute === "edit-state") {
                Dispatch(ClearFormSetFutios({
                    url: "state_edit",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "state_edit",
                    "request" : { 
                        "state_id": stateData?.id,
                        "state_name" : ApplicationState?.StateName.trim(), 
                        "state_code" : ApplicationState?.StateCode.trim(),
                        "gst_code" : ApplicationState?.GSTCode.trim(),
                        "region_id": RegionValue?.value,
                        "is_active": ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(ClearFormSetFutios({
                    url: "state_add",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "state_add",
                    "request" : { 
                        "state_name" : ApplicationState?.StateName.trim(), 
                        "state_code" : ApplicationState?.StateCode.trim(),
                        "gst_code" : ApplicationState?.GSTCode.trim(),
                        "region_id": RegionValue?.value,
                        "is_active": ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.StateName?.trim(), {type: 'StateName', error: seterrorStateName});
            CheckValid(ApplicationState?.StateCode?.trim(), {type: 'StateCode', error: seterrorStateCode});
            CheckValid(ApplicationState?.GSTCode?.trim(), {type: 'GSTCode', error: seterrorGSTCode})
            CheckValid(RegionValue, {type: 'RegionSelect', error: seterrorRegionValue});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "state_edit" || ClearFormSet?.url === "state_add")) {
            Navigate(STATES_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={STATES_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>State Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.StateName} 
                                onChange={(e) => setApplicationState({...ApplicationState, StateName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'StateName', error: seterrorStateName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter state name" 
                                disabled={UploadFileLoader}
                            />
                            {errorStateName !== "" &&<span className="error_medotory">{errorStateName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>State Code<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.StateCode} 
                                onChange={(e) => setApplicationState({...ApplicationState, StateCode: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'StateCode', error: seterrorStateCode})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter state code" 
                                disabled={UploadFileLoader}
                            />
                            {errorStateCode !== "" &&<span className="error_medotory">{errorStateCode}</span>}
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>GST Code<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.GSTCode} 
                                onChange={(e) => {
                                    if (/^\d{0,2}$/.test(e.target.value)) {
                                        setApplicationState({...ApplicationState, GSTCode: e.target.value.replace(/^0/, "")})
                                    };
                                }}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'GSTCode', error: seterrorGSTCode})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter GST Code" 
                                disabled={UploadFileLoader}
                                maxLength={2}
                                max={2}
                            />
                            {errorGSTCode !== "" &&<span className="error_medotory">{errorGSTCode}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Select Region<span className="mendatory_star">*</span></Form.Label>
                            <CommonReactSelectMenu 
                                label={"region"}
                                options={optionsRegion} 
                                selectValue={RegionValue} 
                                setSelectValue={setRegionValue} 
                                dependantState={{
                                    allow: false,
                                }} 
                                isMendatory={true}
                                errorState={errorRegionValue} 
                                setErrorState={seterrorRegionValue}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <CommonToggle 
                            isBadgeAllow={true}
                            badgeTitle={"active_inactive"}
                            valueToggle={ApplicationState?.Status}
                            setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                            name={"Status"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "state_edit" || ClearFormSet?.url === "state_add")}
                            onClick={() => CreateBrand()} 
                            variant="primary" 
                        >
                        {LocationRoute === "edit-state" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddStates;