import React from "react";
import { Badge } from "react-bootstrap";

const IncreDeacrementPercentage = (percentage, url) => {
    const CheckNegativeNumber = Math.sign(percentage) > 0;

    return(<div className="IncreDeacrement_Percentage">
        {url === "Badge" ? (<Badge bg={CheckNegativeNumber ? "success" : "danger"}>
            <i className={`bi bi-caret-${CheckNegativeNumber ? "up" : "down"}-fill`}></i>
            {percentage}%
        </Badge>) : (<div className={CheckNegativeNumber ? "IncreDeacrement_Percentage_No_Badge success" : "IncreDeacrement_Percentage_No_Badge danger"}>
            {percentage}% <i className={`bi bi-caret-${CheckNegativeNumber ? "up" : "down"}-fill`}></i>
        </div>)}
    </div>)
}

export default IncreDeacrementPercentage;