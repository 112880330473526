/* eslint-disable */
import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { useSelector } from "react-redux";
import CommonLoader from "../../Common/CommonLoader";
import UnicommerceInvoice from "./UnicommerceInvoice";
import UnicommerceShipment from "./UnicommerceShipment";
import UnicommerceTrackStatus from "./UnicommerceTrackStatus";
import UnicommerceManifest from "./UnicommerceManifest";

const UnicommerceApiFailures = (props) => {
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "",
        Data: {}
    });
    const [ Loader, setLoader ] = useState(false);
    const [ Tab, setTab ] = useState("invoice");
    const { selfInfo } = useSelector((state) => state.adminReducers);

    // error message modal close
    const CloseErrorMessageModal = () => {
        setOrderViewerModal({
            ...OrderViewerModal,
            open: false,
            Title: "",
            Data: {}
        });
    };
    
    return(<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li className={Tab === "invoice" ? "active" : ""} onClick={() => setTab("invoice")}>Invoice</li>
                <li className={Tab === "shipment" ? "active" : ""} onClick={() => setTab("shipment")}>Shipment</li>
                <li className={Tab === "manifest" ? "active" : ""} onClick={() => setTab("manifest")}>Manifest</li>
                <li className={Tab === "track_status" ? "active" : ""} onClick={() => setTab("track_status")}>Mark delivered</li>
            </ul>
        </div>
        <div className="tabletabs_scrolbar">
            {Tab === "invoice" ? (
                <UnicommerceInvoice setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />
            ) : Tab === "shipment" ? (
                <UnicommerceShipment setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />
            ) : Tab === "manifest" ? (
                <UnicommerceManifest setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />
            ) : (
                <UnicommerceTrackStatus setLoader={setLoader} OrderViewerModal={OrderViewerModal} setOrderViewerModal={setOrderViewerModal} />
            )}
        </div>
            
        {OrderViewerModal?.open && (
            <Modal show={OrderViewerModal?.open} className="errorDetailsModal" onHide={()=> CloseErrorMessageModal()}>
                <Modal.Header closeButton>
                <Modal.Title>{OrderViewerModal?.Title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    <p>
                        {OrderViewerModal?.Data?.response === undefined ? "No Error Found" : OrderViewerModal?.Data?.response}
                    </p>
                </Modal.Body>
            </Modal>
        )}

        {Loader && (<CommonLoader />)}
    </section>)
}

export default UnicommerceApiFailures;