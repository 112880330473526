/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { FRANCHISE_DISPATCH_PENDING_URL } from "../../Shared/constant";
import { updateOrderManagementFilter, ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonTable from "../../Common/CommonTable";
import MainOrderDataTableFilter from "../../Common/MainOrderDataTableFilter";

const FranchiseDispatchShipping = (props) => {
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, PendingDispatchList, OrderManagmentFilter, ClearFormSet, sortingfor, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            PendingForDispatch: {
                CurrentPage: page,
            }
        }));
    };

    // get all warehouse list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "warehouse_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get order_list all
    useEffect(() => {
        if (websocket) {
            const { UserLimit, UserSearch, SelectDateType, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage, WarehouseName, is_bundle_orders, is_odi_courier_pin } = OrderManagmentFilter?.PendingForDispatch;
            
            let param = {
                "transmit": "broadcast",
                "url": "order_list",
                "request": {
                    "status": "ST_APPROVED,HALF_DISPATCH",
                    "limit": UserLimit,
                    "page_no": CurrentPage,
                    "search": UserSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "from_inv": null,
                    "to_inv": null,
                    "from_updated_date": null,
                    "to_updated_date": null,
                    "order_type": OrderSource,
                    "region_id": SelectRegion,
                    "warehouse_id": WarehouseName?.value,
                    "order_by": sortingfor,
                    "fetch_location_info": true,
                    "is_bundle_orders": is_bundle_orders,
                    "pin_code_flag": is_odi_courier_pin?.flag,
                },
                "DeviceId": device_id
            };

            if(SelectDateType === "created_at") {
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            } else if(SelectDateType === "invoice_at") {
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_inv = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_inv = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            } else {
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_updated_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_updated_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            };
            
            wsSend_request(websocket, param);
        };
    }, [websocket, OrderManagmentFilter?.PendingForDispatch, sortingfor]);

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'order_code', label: 'Order Id.', type: "navigate_regular_order", navigate_url: FRANCHISE_DISPATCH_PENDING_URL, sort: "order_code", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "customer_identity", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "created_at", isCollapsable: false },
        { key: 'updated_at', label: 'Updated Date', type: "date", sort: "updated_at", isCollapsable: false },
        { key: 'customer_name', label: 'SHIP To Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'pin_code', label: 'SHIP Pincode', type: "label", sort: "pin_code_id", isCollapsable: false },
        { key: 'state', label: 'SHIP State', type: "label", sort: "state_id", isCollapsable: false },
        { key: 'district', label: 'SHIP City', type: "label", sort: "district_id", isCollapsable: false },
        { key: 'billing_id', label: 'BILL To Name', type: "label_get_from_franchise", sort: "billing_id", isCollapsable: false },
        { key: '', label: 'Old Warehouse', type: "label_old_warehouse", sort: "", isCollapsable: false },
        { key: 'warehouse_id', label: 'Ship From Warehouse', type: "label_get_ship_warehouse", sort: "", isCollapsable: false },
        { key: 'invoice_code', label: 'Invoice ID', type: "invoice_regular_modal_open_navigate", sort: "invoice_code", isCollapsable: false },
        { key: 'invoice_at', label: 'Invoice Date', type: "date", sort: "invoice_at", isCollapsable: false },
        { key: 'courier_name', label: 'Transporter', type: "label", sort: "courier_name", isCollapsable: false },
        { key: 'order_priority', label: 'Order Priority', type: "label", sort: "order_priority", isCollapsable: false },
        { key: 'priority', label: 'Criticality', type: "label_Criticality", sort: "priority", isCollapsable: false },
        { key: 'is_automated', label: 'Automated', type: "status_yes_no", sort: "", isCollapsable: false },
        { key: 'erp_id', label: 'ERP Order Code', type: "label", sort: "", isCollapsable: false },
        { key: 'erp_id', label: 'ERP Api Status', type: "label_erp_invoice_delivery_note_api_status", sort: "", isCollapsable: false },
        { key: 'erp_invoice_code', label: 'ERP Invoice Status', type: "label_erp_invoice_delivery_note_api_status", sort: "", isCollapsable: false },
        { key: 'erp_delivery_code', label: 'ERP Delivery Note API status', type: "label_erp_invoice_delivery_note_api_status", sort: "", isCollapsable: false },
        { key: 'updated_at', label: 'Pending Since', type: "pending_since", sort: "updated_at", isCollapsable: false },
        { key: 'warehouse_remark', label: 'Remarks', type: "label", sort: "", isCollapsable: false },
        { key: 'order_source', label: 'Order Source', type: "label", sort: "", isCollapsable: true },
        { key: 'qty', label: 'Quantity', type: "label", sort: "qty", isCollapsable: true },
        { key: 'total', label: 'Order Value', type: "label_day_ammount", sort: "total", isCollapsable: true },
        { key: 'category', label: 'SKU Type', type: "label", sort: "", isCollapsable: true },
        { key: 'order_status', label: 'Status', type: "label_order_status", sort: "", isCollapsable: true },
        { key: '', label: '', type: "Attachments", sort: "", isCollapsable: true },
        { key: 'region_id', label: 'Region', type: "label_get_from_list", filterFrom: { list: "regionlist", label: "region_name" }, sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section">
        <MainOrderDataTableFilter
            filterType={""}
            tabName={"PendingForDispatch"}
            searchType={"Order Id, Name, Invoice ID, Customer Code"}
            FromDateStartDateFlag={true}
            searchdisable={true}
            buttonvisible={false}
            AllOrderStatusAllow={false}
            isAllowWarehouse={true}
            isDateType={true}
            isOdiFilterAllow={true}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(PendingDispatchList?.pagination?.total_records) === PendingDispatchList?.list?.length ? "calc(100vh - 196px)" : parseInt(PendingDispatchList?.pagination?.total_records) > 10 ? "calc(100vh - 235px)" : "calc(100vh - 196px)"
            }}
            srNumber={{
                clickable: true,
                events: "ORDERS_LOG"
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={OrderManagmentFilter["PendingForDispatch"]?.CurrentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={PendingDispatchList} // ORDER LIST
            Pagination={PendingDispatchList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[6]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={true}
        />
    </section>)
}

export default FranchiseDispatchShipping;