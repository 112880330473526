import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchIcon from "../../assets/img/search_icon_new.svg";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";

const SearchCommon = (props) => {
    const { userSearch, setUserSearch, searchType, setCurrentPage } = props;
    const [ CommonSearchUpdate, setCommonSearchUpdate ] = useState("");

    // ON ENTER SEARCH CALLED
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            setCurrentPage(1);
            clickEnterSearch();
        };
    };

    // CLICK SEARCH BUTTON THEN SEARCH
    const clickEnterSearch = () => {
        setUserSearch(CommonSearchUpdate);
    };

    // CLEAR SEARCH FIELD
    const ClearSearchFiled = () => {
        setUserSearch("");
        setCommonSearchUpdate("");
    };

    document.addEventListener('keydown', function(event) {
        if (event.key === "Delete") {
          // Your code to handle the Delete key press goes here
          ClearSearchFiled();
        }
    });

    return(<div className={`searchBoxwithbtn ${userSearch !== ""?"search":""}`}>
        <input 
            type="text" 
            className="form-control" 
            value={CommonSearchUpdate} 
            onKeyDown={(e) => {onEnterSearch(e); EmptySpaceFieldValid(e)}} 
            onChange={(e) => setCommonSearchUpdate(e.target.value)} 
            placeholder={`Search ...`}
        />
        <Button className="searchbtn" onClick={() => clickEnterSearch()}>
            <img src={SearchIcon} alt="search" />
        </Button>
        {userSearch !== "" && (<OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 100 }}
            overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
            Clear the search by using the delete key.
        </Tooltip>}>
            <i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled()}></i>
        </OverlayTrigger>)}
        
        <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 100 }}
            overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
            Search using {searchType ? `(${searchType})`:""}
        </Tooltip>}
        >
            <i className="searchInfo bi bi-info-circle-fill"></i>
        </OverlayTrigger>
    </div>)
};

export default SearchCommon;