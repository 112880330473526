/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { updateAFSOrderManagementFilter } from "../../../redux/actions/adminActions";
import CommonTable from "../../Common/CommonTable";
import AFSQuotationsDataTableFilter from "../../Common/AFSQuotationsDataTableFilter";

const PaymentCancelledQuotationReport = (props) => {
    const { tab_url } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, QuotationsCreatedPaymentCancelledReport, selfInfo, AFSQuotationsManagmentFilter, sortingfor } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        Dispatch(updateAFSOrderManagementFilter({
            [tab_url]: {
                CurrentPage: page,
            }
        }));
    };

    // api calling
    const APICall = () => {
        const { 
            Status = "CANCELLED",
            Type = '',
            UserLimit = 10, 
            UserSearch = '',
            SelectRegion = '', 
            FromDate = null,
            ToDate = null,
            CurrentPage = 1,
    } = AFSQuotationsManagmentFilter[tab_url] || {};
        
        let param = {
            "transmit": "broadcast",
            "url": "quotation_list",
            "request": {
                "quotation_status": Status,
                "quotation_type": Type,
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_updated_date": null,
                "to_updated_date": null,
                "region_id": SelectRegion,
                "order_by": sortingfor,
            },
            "quotation_list_type": false,
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_updated_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_updated_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        };
        wsSend_request(websocket, param);
    };

    // get order_list all
    useEffect(() => {
        APICall();
    }, [ websocket, AFSQuotationsManagmentFilter[tab_url] ]);

    useEffect(() => {
        if (sortingfor !== "" && sortingfor !== "-") { 
            APICall();
        };
    }, [sortingfor]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'quotation_code', label: 'Quotation Id.', type: "navigate_quotations", sort: "quotation_code", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "customer_identity", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "created_at", isCollapsable: false },
        { key: 'customer_name', label: 'SHIP To Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'billing_id', label: 'BILL To Name', type: "label_get_from_franchise", sort: "billing_id", isCollapsable: false },
        { key: 'frame_number', label: 'Frame Number', type: "label", sort: "", isCollapsable: false },
        { key: 'ticket_number', label: 'Tickets Id', type: "label", sort: "", isCollapsable: false },
        { key: 'updated_at', label: 'Pending Since', type: "pending_since", sort: "", isCollapsable: false },
        { key: 'quotation_status', label: 'Status', type: "quotation_status_type", sort: "", isCollapsable: false },
        { key: 'payment_link', label: 'Payment Link', type: "payment_link_label", isDisable: false, sort: "", isCollapsable: true },
        { key: 'payment_id', label: 'Payment ID', type: "label", sort: "", isCollapsable: true },
        { key: 'txn_id', label: 'TXN ID/Ref No', type: "label", sort: "", isCollapsable: true },
        { key: 'payment_date', label: 'Payment Date', type: "date", sort: "", isCollapsable: true },
        { key: '', label: 'Payment Attachment', type: "payment_Attachment_preview_download", sort: "", isCollapsable: true },
        { key: 'payment_mode', label: 'Payment Mode', type: "label", sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section">
        {/* COMMON FILTER */}
        <AFSQuotationsDataTableFilter 
            filterType={""}
            tabName={tab_url}
            searchType={"Quotation Id, Customer code, SHIP To Name, Frame Number, Tickets id"}
            searchdisable={true}
            buttonvisible={false}
            quotationStatusAllow={false}
        />

        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(QuotationsCreatedPaymentCancelledReport?.pagination?.total_records) === QuotationsCreatedPaymentCancelledReport?.list?.length ? "calc(100vh - 245px)" : parseInt(QuotationsCreatedPaymentCancelledReport?.pagination?.total_records) > 10 ? "calc(100vh - 280px)" : "calc(100vh - 245px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={true}
            tabName={tab_url} // TAB URL
            PaginationCurrentPage={AFSQuotationsManagmentFilter[tab_url]?.CurrentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={QuotationsCreatedPaymentCancelledReport} // ORDER LIST
            Pagination={QuotationsCreatedPaymentCancelledReport?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AFSOrderManagement[2]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={APICall}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
        />
    </section>)
}

export default PaymentCancelledQuotationReport;