/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { CREATE_PRODUCT_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonTable from "../../Common/CommonTable";

const Productcreation = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { productList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // get brand all
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "brand_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get category all
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "category_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "subcategory_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_group_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "brand_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get category all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : true,
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : true, 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_group_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[4]?.Edit || PermissionsAccess?.ProductMasters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "fetch_brand" : true
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_PRODUCT_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'product_name', label: 'Product Name', type: "label", sort: "", isCollapsable: false },
        { key: 'sku', label: 'MasterSku', type: "label", sort: "", isCollapsable: false },
        { key: 'product_code', label: 'Product Code', type: "label", sort: "", isCollapsable: false },
        { key: 'erp_id', label: 'ERP ID', type: "label", sort: "", isCollapsable: false },
        { key: 'sub_category_id', label: 'Sub Category Name', type: "label_get_from_list", filterFrom: { list: "sub_category", label: "sub_category_name" }, sort: "", isCollapsable: false },
        { key: 'is_active', label: 'Status', type: "status_active", sort: "", isCollapsable: false },
        { key: 'custom_is_transportation', label: 'Is Transportation', type: "status_yes_no", sort: "", isCollapsable: true },
        { key: 'is_spare', label: 'Is Spare', type: "status_yes_no", sort: "", isCollapsable: true },
        { key: 'sub_category_id', label: 'Application Name', type: "label_get_brand_name", sort: "", isCollapsable: true },
        { key: 'sub_category_id', label: 'Category Name', type: "label_get_category_name", sort: "", isCollapsable: true },
        { key: 'mrp', label: 'Mrp', type: "label", sort: "", isCollapsable: true },
        { key: 'ecom_price', label: 'E-Comm. Price', type: "label", sort: "", isCollapsable: true },
        { key: 'franchise_rate', label: 'Franchise Price', type: "label", sort: "", isCollapsable: true },
        { key: 'created_at', label: 'Created At', type: "date", sort: "", isCollapsable: true },
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Product"}
            searchType={"Product Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={selfInfo?.user?.user_type === 'super_admin' ? true : false}
            ButtonSet={{
                buttontitle: "Create Product",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(productList?.pagination?.total_records) === productList?.list?.length ? "calc(100vh - 140px)" : parseInt(productList?.pagination?.total_records) > 10 ? "calc(100vh - 175px)" : "calc(100vh - 140px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={productList} // PRODUCT LIST
            Pagination={productList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[0]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: true,
                label: "edit",
                naviagte: `/edit-product/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default Productcreation;