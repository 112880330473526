/* eslint-disable */
import React, { useState } from "react";
import { Table, Modal } from 'react-bootstrap';
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import OrderStatusModel from "../OrderStatusModel";
import CommonPagination from "../CommonPagination";
import CommonLoader from "../CommonLoader";
import CommonewModel from "../CommonewModel";
import CommonScrollBar from "../CommonScrollBar";
import DeleteModal from "../DeleteModal";
import OrderDetailsViewer from "../OrderDetailsViewer";
import CommonRemarkAttachment from "../CommonRemarkAttachment";
import { getStringOfErrorValues } from "../TimeSense";

const CommonTable = (props) => {
    const { style, srNumber, tabName, isActionColumnAvailable, isActionTwoColumnAvailable, TableColumns, OrderList, Pagination, EditAccess, PaginationCurrentPage, PaginationFunction, APIcalling, isCollapsable, isTotalAndGrand, isOdiFilterRowColorsAllow, isActionPreviewAvailable } = props;
    const [ Loader, setLoader ] = useState(false);
    const [ OrderNewModel, setOrderNewModel ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        subtitle: "",
        modalType: "",
        button: "",
        Data: {},
    });
    const [ deleteCommonStateModal, setdeleteCommonStateModal ] = useState({
        title: "",
        description: "",
        open: false,
        delete_id: "",
        delete_url: ""
    });
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [ showRemarkAttachment, setShowRemarkAttachment ] = useState({
        open: false,
        title: "",
        Data: {}
    });
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Reason: "",
        Data: {}
    });
    const [ errorShowModal, setErrorShowModal ] = useState({
        open: false,
        title: "",
        Data: {}
    });
    const [ PreviewErrorModal, setPreviewErrorModal ] = useState({
        open: false,
        Title: "",
        Data: {}
    });

    // MODAL CLOSE FUNCTION
    const onClose = () => {
        setShowRemarkAttachment({
            ...showRemarkAttachment,
            open: false,
            title: "",
            Data: {}
        });
        setShowModal({
            ...showModal,
            open: false,
        });
        setErrorShowModal({
            ...errorShowModal,
            open: false,
            title: "",
            Data: {}
        });
    };

    const responseText = (response1) =>{
        if(response1 !== undefined) {
            const response = JSON.parse(response1);
            let text = "";
            if(typeof(response?.errors) == "object"){
                text = text +`</p><b>errors:</b> ${getStringOfErrorValues(response?.errors)}</p>`;
            }
            if(typeof(response?.message) == "object"){
                text = text +`</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
            }
            if(typeof(response?.response) == "object"){
                text = text +`</p><b>response:</b> ${getStringOfErrorValues(response?.response)}</p>`;
            }
            if(typeof(response?.message) == "string"){
                text = text +`</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
            }
            return text;
        } else {
            return "504 Gateway Time-out"
        };
    };

    // error message modal close
    const CloseErrorMessageModal = () => {
        setPreviewErrorModal({
            ...PreviewErrorModal,
            open: false,
            Title: "",
            Data: {}
        });
    };

    return(<div className="tableView gstreporttableadded">
        <CommonScrollBar height={style} className={"ScrollbarsSidebar"}>
            <Table id={tabName === "sales-person-performance" ? "salesPersonPerformance" : tabName}>
                <TableHead 
                    Columns={TableColumns} 
                    ActionColumn={isActionColumnAvailable}
                    isCollapsable={isCollapsable}
                    ActionColumnRead={isActionTwoColumnAvailable}
                    ActionPreviewState={isActionPreviewAvailable}
                />
                <TableBody 
                    srNumber={srNumber}
                    OrderList={OrderList}
                    RowData={TableColumns}
                    EditAccess={EditAccess}
                    Pagination={Pagination}
                    OrderNewModel={OrderNewModel}
                    setOrderNewModel={setOrderNewModel}
                    ActionColumn={isActionColumnAvailable}
                    ActionColumnRead={isActionTwoColumnAvailable}
                    tabName={tabName}
                    setLoader={setLoader}
                    showModalNew={showModalNew}
                    setShowModalNew={setShowModalNew}
                    APIcalling={APIcalling}
                    isCollapsable={isCollapsable}
                    isTotalAndGrand={isTotalAndGrand}
                    deleteCommonStateModal={deleteCommonStateModal}
                    setdeleteCommonStateModal={setdeleteCommonStateModal}
                    OrderViewerModal={OrderViewerModal} 
                    setOrderViewerModal={setOrderViewerModal}
                    showRemarkAttachment={showRemarkAttachment}
                    setShowRemarkAttachment={setShowRemarkAttachment}
                    showModal={showModal} 
                    setShowModal={setShowModal}
                    errorShowModal={errorShowModal} 
                    setErrorShowModal={setErrorShowModal}
                    isOdiRowColorsAllow={isOdiFilterRowColorsAllow}
                    ActionPreviewState={isActionPreviewAvailable}
                    PreviewErrorModal={PreviewErrorModal} 
                    setPreviewErrorModal={setPreviewErrorModal}
                />
            </Table>
        </CommonScrollBar>

        {/* PAGINATION */}
        {Pagination?.total_records > 10 && (<CommonPagination
            currentPage={PaginationCurrentPage}
            paginationLength={Pagination}
            currentFunction={PaginationFunction}
        />)}

        {/* ORDER LOG MODAL */}
        {OrderNewModel?.open && (<OrderStatusModel 
            setOrderNewModel={setOrderNewModel} 
            OrderNewModel={OrderNewModel} 
        />)}

        {/* COMMON MODAL FOR INVOICE CREATIONS */}
        {showModalNew?.open && (<CommonewModel 
            setShowModalNew={setShowModalNew} 
            showModalNew={showModalNew} 
            tabName={tabName}
            APIcalling={APIcalling}
            />
        )}

        {/* COMMON LOADER */}
        {Loader && (<CommonLoader />)}

        {deleteCommonStateModal?.open && (<DeleteModal 
            deleteCommonStateModal={deleteCommonStateModal} 
            setdeleteCommonStateModal={setdeleteCommonStateModal} 
        />)}

        {OrderViewerModal?.open && (<OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />)}

        {showRemarkAttachment?.open && (<CommonRemarkAttachment 
            setShowModalNew={setShowRemarkAttachment}
            showModalNew={showRemarkAttachment}
            onClose={onClose}
        />)}

        {errorShowModal?.open && (<Modal show={errorShowModal?.open} className="errorDetailsModal" onHide={onClose}>
            <Modal.Header closeButton>
            <Modal.Title>{errorShowModal?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow: "auto"}}>
                <p dangerouslySetInnerHTML={{__html: responseText(errorShowModal?.Data?.invoice_response)}}>
                </p>
            </Modal.Body>
        </Modal>)}

        {PreviewErrorModal?.open && (<Modal show={PreviewErrorModal?.open} className="errorDetailsModal" onHide={()=> CloseErrorMessageModal()}>
            <Modal.Header closeButton>
            <Modal.Title>{PreviewErrorModal?.Title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow: "auto"}}>
                <p>
                    {PreviewErrorModal?.Data?.response === undefined ? "No Error Found" : PreviewErrorModal?.Data?.response}
                </p>
            </Modal.Body>
        </Modal>)}
    </div>) 
};

export default CommonTable;