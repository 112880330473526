/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Collapse, Form } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { CREATE_FRANCHISE_USER_REPORT_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonTable from "../../Common/CommonTable";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";

const FranchiseUserReport = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ ParentChildFilter, setParentChildFilter ] = useState(false);
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectState, setSelectState ] = useState("");
    const [ selectCity, setSelectCity ] = useState("");
    const [ FilterOpen, setFilterOpen ] = useState(false);
    const { FranchiseUserTypeList, device_id, selfInfo, citylistall, statelistall, sortingfor } = useSelector((state) => state.adminReducers);
    const optionsState = statelistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.state_name }));
    const optionsCity = citylistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.district_name }));
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select user type
    const SelectFranchiseUserTypeFnct = (e) => {
        setParentChildFilter(e.target.value);
        setCurrentPage(1);
    };

    // update state wise city 
    const optionCityMemo = useMemo(() => {
        return optionsCity?.filter((elm) => {
            if(selectState === "") {
                return elm;
            } else {
                if(elm?.state_id === selectState?.value) {
                    return elm;
                } else {
                    elm
                };
            }
        })
    }, [ selectState ]);

    // get all state
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "state_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all city list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "district_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get franchise_type_list all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[1]?.Edit || PermissionsAccess?.AdminTools[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_type_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools[1]?.Edit || PermissionsAccess?.AdminTools[1]?.View)) {
            let ParamUpdate;

            if(ParentChildFilter === "SubDealer") {
                ParamUpdate = { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "state_id" : selectState?.value,
                    "city_id" : selectCity?.value,
                    "order_by": sortingfor,
                    "is_child" : true
                }
            } else if(ParentChildFilter === "Dealer") {
                ParamUpdate = { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "state_id" : selectState?.value,
                    "city_id" : selectCity?.value,
                    "order_by": sortingfor,
                    "is_parent" : true
                }
            } else {
                ParamUpdate = { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "state_id" : selectState?.value,
                    "city_id" : selectCity?.value,
                    "order_by": sortingfor,
                    "search" : userSearch.trim(),
                }
            };

            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : ParamUpdate,
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, ParentChildFilter, selectState, selectCity, sortingfor ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_FRANCHISE_USER_REPORT_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'customer_name', label: 'Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'email', label: 'Email', type: "label", sort: "", isCollapsable: false },
        { key: 'franchise_code', label: 'Franchise Code', type: "label_franchise_code", sort: "franchise_code", isCollapsable: false },
        { key: 'erp_id', label: 'ERP ID', type: "label", sort: "", isCollapsable: false },
        { key: 'erp_address_id', label: 'ERP Address ID', type: "label", sort: "", isCollapsable: false },
        { key: 'district_id', label: 'City', type: "label_get_from_list", filterFrom: { list: "district", label: "district_name" }, sort: "district_id", isCollapsable: false },
        { key: 'state_id', label: 'State', type: "label_get_from_list", filterFrom: { list: "state", label: "state_name" }, sort: "state_id", isCollapsable: false },
        { key: 'pin_code_id', label: 'PIN Code', type: "label_get_from_list", filterFrom: { list: "pin", label: "pin_code" }, sort: "", isCollapsable: false },
        { key: 'region_id', label: 'Region', type: "label_get_from_list", filterFrom: { list: "region", label: "region_name" }, sort: "region_id", isCollapsable: false },
        { key: 'address', label: 'Address', type: "label", sort: "", isCollapsable: false },
        { key: 'parent_id', label: 'Type', type: "label_parent_id", sort: "", isCollapsable: false },
        { key: 'location_url', label: 'Location', type: "label_redirect_location_url", sort: "", isCollapsable: false },
        { key: 'order_count', label: 'Order Count', type: "label_franchise_last_three_orders", sort: "", isCollapsable: false },
        { key: 'diwali_offer', label: 'Diwali Offers', type: "status_yes_no", sort: "", isCollapsable: false },
        { key: 'is_active', label: 'Status', type: "status_franchise_active", sort: "", isCollapsable: false },
        { key: 'franchise_type_id', label: 'Franchise Type', type: "label_get_from_list", filterFrom: { list: "franchise_type", label: "franchise_type_name" }, sort: "", isCollapsable: true },
        { key: 'is_flexible_pricing', label: 'Is Flexible Pricing', type: "status_yes_no", sort: "", isCollapsable: true },
        { key: 'region_manager_id', label: 'Regional Manager', type: "label_get_from_list", filterFrom: { list: "user", label: "full_name" }, sort: "", isCollapsable: true },
        { key: 'asm_id', label: 'Assistant Sales Manager', type: "label_get_from_list", filterFrom: { list: "asm_list", label: "full_name" }, sort: "", isCollapsable: true },
        { key: 'mobile', label: 'Primary Contact Number', type: "label", sort: "", isCollapsable: true },
        { key: 'secondary_contact', label: 'Secondary Contact Number', type: "label", sort: "", isCollapsable: true },
        { key: 'ifsc_code', label: 'IFSC Code', type: "label", sort: "", isCollapsable: true },
        { key: 'gst_no', label: 'GSTIN', type: "label", sort: "", isCollapsable: true },
        { key: 'created_at', label: 'Created At', type: "date", sort: "", isCollapsable: true },
    ];

    return(<section className="Main_section" id="FranchiseUserReport">
        <DataTableFilter 
            searchType={"Name, Franchise Code"}
            filterType={"Franchise User Report"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[1]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Franchise",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
            ParentChildFilter={ParentChildFilter}
            SelectFranchiseUserTypeFnct={SelectFranchiseUserTypeFnct}
            FilterOpen={FilterOpen}
            setFilterOpen={setFilterOpen}
        />
        <Collapse in={FilterOpen}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <CommonReactSelectMenu 
                                label={"State"}
                                options={optionsState} 
                                selectValue={selectState} 
                                setSelectValue={setSelectState} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <CommonReactSelectMenu 
                                label={"City"}
                                options={optionCityMemo}
                                selectValue={selectCity} 
                                setSelectValue={setSelectCity} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                </div>
            </div>
        </Collapse>
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: FilterOpen === true ? parseInt(FranchiseUserTypeList?.pagination?.total_records) === FranchiseUserTypeList?.list?.length ? "calc(100vh - 195px)" : FranchiseUserTypeList?.pagination?.total_records > 10 ? "calc(100vh - 231px)" : "calc(100vh - 195px)" : parseInt(FranchiseUserTypeList?.pagination?.total_records) === FranchiseUserTypeList?.list?.length ? "calc(100vh - 155px)" : FranchiseUserTypeList?.pagination?.total_records > 10 ? "calc(100vh - 178px)" : "calc(100vh - 142px)"}} 
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={true}
            tabName={"franchiseUserReportData"} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={FranchiseUserTypeList} // CATEGORY LIST
            Pagination={FranchiseUserTypeList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[1]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[1]?.Edit,
                label: "edit",
                naviagte: `/edit-franchise-user-report/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default FranchiseUserReport;