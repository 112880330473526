/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import CommonHeader from "./common/CommonHeader";
import DashBoardTables from "./common/DashBoardTables";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonDateTime from "../Common/CommonDateTime";
import CommonScrollBar from "../Common/CommonScrollBar";

const TransactionHistory = () => {
    const [ DealerskuType, setDealerSkuType ] = useState("");
    const [ OrderDealerStatus, setOrderDealerStatus ] = useState("");
    const { device_id, DashboardAgingerpAccountList, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userLimit, setUserLimit ] = useState(5);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ selectMultiFranchise, setselectMultiFranchise ] = useState([]);
    const MultiSelecteDealers = selectMultiFranchise?.map((elm) => elm?.value)?.toString()
    const [ dateRange, setDateRange ] = useState([null, null]);
    const [ startDate, endDate ] = dateRange;
    const { websocket } = useContext(WebSocketContext);

    // GET ALL FRANCHISE LIST
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        if(selfInfo?.user?.user_type === "regional_sales_team_rm") {
            param.request.rm_id = selfInfo?.user?.id;
        };
        wsSend_request(websocket, param);
    }, [ websocket ]);

    // SALES REVENUE DAILY API CALLING
    useEffect(() => {
        DashBoardAPIcalling("erp_account", userSearch, currentPage, userLimit, startDate, endDate, MultiSelecteDealers);
    }, [ websocket, userSearch, currentPage, userLimit, endDate, MultiSelecteDealers ]);
    
    // COMMON API CALLING
    const DashBoardAPIcalling = (api_url, userSearch, currentPage, userLimit, from_date, to_date, franchise_id) => {
        let param = {
            "transmit": "broadcast",
            "url": api_url,
            "request": {
                "search": userSearch?.trim(),
                "limit": userLimit,
                "page_no": currentPage,
                "from_date": CommonDateTime(from_date, "YYYY-MM-DD", "hide"),
                "to_date": CommonDateTime(to_date, "YYYY-MM-DD", "hide"),
                "franchise_id": franchise_id,
                "order_by": "-updated_at",
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // AGEING TRANSACTION HISTORY TABLE COLUMNS ARRAY
    const AgeingTransactionHistoryTableColumns = [
        { key: 'erp_id', label: 'ERP ID', type: "label", sort: "" },
        { key: 'posting_date', label: 'Posting Date', type: "date", sort: "" },
        { key: 'created_at', label: 'Time', type: "date_time", sort: "" },
        { key: 'franchise_id', label: 'Franchise Code', type: "label_get_from_list_modal_open_summery", filterFrom: { list: "franchise", label: "franchise_code" }, sort: "" },
        { key: 'franchise_id', label: 'Customer Name', type: "label_get_from_list", filterFrom: { list: "franchise", label: "customer_name" }, sort: "" },
        { key: 'region', label: 'Region', type: "label", sort: "" },
        { key: '-', label: 'Sales Person', type: "label", sort: "" },
        { key: 'invoice_amt', label: 'Inv. Amount', type: "label", sort: "" },
        { key: 'range1', label: '0-30', type: "label", sort: "" },
        { key: 'range2', label: '31-60', type: "label", sort: "" },
        { key: 'range3', label: '61-90', type: "label", sort: "" },
        { key: 'range4', label: '91-120', type: "label", sort: "" },
        { key: 'range5', label: '121-Above', type: "label", sort: "" },
        { key: 'outstanding_amt', label: 'Outstanding', type: "label", sort: "" },
        { key: 'paid_amt', label: 'Paid', type: "label", sort: "" },
        { key: 'credit_note', label: 'Credit Note', type: "label", sort: "" },
        { key: 'advance', label: 'Advance', type: "label", sort: "" },
        { key: 'total_due', label: 'Total Due', type: "label", sort: "" },
    ];

    return(<div className="Main_section dashboard_Main_section" style={{ padding: "0px", backgroundColor: "transparent" }}>
        <div className="dashboard_wrapper">
            <CommonScrollBar height={{ height: "calc(100vh - 90px)" }}>
                {/* Ageing : Table */}
                <div style={{ marginBottom: "0px" }}>
                    <CommonHeader 
                        heading={"Ageing"} 
                        isSearchAllow={false}
                        searchType={"Franchise Code, ERP ID, Customer Name"}
                        userSearch={userSearch}
                        setUserSearch={setUserSearch}
                        isDatePickerAllow={true}
                        dateRange={dateRange} 
                        setDateRange={setDateRange}
                        isSkuTypeAllow={false}
                        SkuType={DealerskuType}
                        setSkuType={setDealerSkuType} 
                        isOrderStatusAllow={false}
                        OrderStatus={OrderDealerStatus} 
                        setOrderStatus={setOrderDealerStatus}
                        setCurrentPage={setCurrentPage}
                        isDealerAllow={false}
                        isStateAllow={false}
                        isRegionAllow={false}
                        isProductAllow={false}
                        isFranchiseMultiAllow={true}
                        selectMultiFranchise={selectMultiFranchise} 
                        setselectMultiFranchise={setselectMultiFranchise}
                        isAllowDownloadPdf={{
                            open: true,
                            id: "TransactionHistoryAgeing"
                        }}
                        isAllowDownloadExel={{
                            open: true,
                            id: "TransactionHistoryAgeing"
                        }}
                        isBackButtonAllow={{
                            open: false,
                            navigate_url: ""
                        }}
                    />
                    <div className="iframe_dashboard_wrapper" style={{ overflow: "auto" }}>
                        <DashBoardTables 
                            isSrNoAllow={true}
                            Columns={AgeingTransactionHistoryTableColumns} // TABLE COLLUMNS & ROWS
                            OrderList={DashboardAgingerpAccountList} // ORDER LIST
                            PaginationCurrentPage={currentPage}
                            setPaginationCurrentPage={setCurrentPage}
                            PaginationSet={DashboardAgingerpAccountList?.pagination} // CURRENT TAB PAGINATION
                            height={"283px"}
                            Pagination={""} // CURRENT TAB PAGINATION
                            id={"TransactionHistoryAgeing"}
                            isActionAllow={{
                                allow: false,
                                functionType: ""
                            }}
                        />
                    </div>
                </div>
            </CommonScrollBar>
        </div>
    </div>)
};

export default TransactionHistory;