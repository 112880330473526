/* eslint-disable */
const ExportToCSV = (id) => {
    const table = document.getElementById(id);
    const rows = table.querySelectorAll("tr");
    let csvContent = "data:text/csv;charset=utf-8,";
    let i = 0;
    rows.forEach((row) => {
      const rowData = [];
      if(i == 0) {
        row.querySelectorAll("th").forEach((cell) => {
            rowData.push(cell.innerText.toString().replace(/,/g, ''));
          });
      } else {
        row.querySelectorAll("td").forEach((cell) => {
            rowData.push(cell.innerText.toString().replace(/,/g, ''));
          });
      }
      i++;
      csvContent += rowData.join(",") + "\n";
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", id+".csv");
    document.body.appendChild(link); // Required for FF
  
    link.click(); // This will download the CSV file
};

export default ExportToCSV;