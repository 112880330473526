/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { getTableSorting } from "../../../redux/actions/adminActions";
import CommonExport from "../../Common/CommonExport";
import { FBA_ORDER_DETAILS_LIST } from "../../Shared/constant";
import CommonTable from "../../Common/CommonTable";

const FbaOrderTab = (props) => {
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const today = new Date();
    const { device_id, TbaOrderList, sortingfor, ClearFormSet, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            setToDate(date);
        }
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            SOCancelled();
        }
    }, [websocket, userSearch, userType, currentPage, userLimit, FromDate, ToDate, sortingfor]);

    const SOCancelled = () => {
        let param = {
            "transmit": "broadcast",
            "url": "fba_order_list",
            "request": {
                "status": "",
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
                "from_date": null,
                "to_date": null,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            SOCancelled();
        };
    }, [ ClearFormSet ]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'display_order_code', label: 'Order Id.', type: "navigate_regular_order", navigate_url: FBA_ORDER_DETAILS_LIST, sort: "order_code", isCollapsable: false },
        { key: 'erp_invoice_code', label: 'Invoice No.', type: "label", sort: "", isCollapsable: false },
        { key: 'channel_name', label: 'Channel Name', type: "label", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "", isCollapsable: false },
        { key: 'shipping_pincode', label: 'SHIP To Pincode', type: "label", sort: "", isCollapsable: false },
        { key: 'shipping_city', label: 'SHIP To City', type: "label", sort: "", isCollapsable: false },
        { key: 'shipping_state', label: 'SHIP To State', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_pincode', label: 'Bill To Pincode', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_city', label: 'Bill To City', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_state', label: 'Bill To State', type: "label", sort: "", isCollapsable: false },
        { key: 'marketplace_status', label: 'Marketplace Status', type: "label", sort: "", isCollapsable: false },
        { key: 'shipping_email', label: 'SHIP To Email', type: "label", sort: "", isCollapsable: true },
        { key: 'shipping_name', label: 'SHIP To Name', type: "label", sort: "", isCollapsable: true },
        { key: 'shipping_phone', label: 'SHIP To Phone', type: "label", sort: "", isCollapsable: true },
        { key: 'bill_email', label: 'Bill To Email', type: "label", sort: "", isCollapsable: true },
        { key: 'bill_phone', label: 'Bill To Phone', type: "label", sort: "", isCollapsable: true },
        { key: 'source', label: 'Order Source', type: "label", sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "23%" }}>
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    // maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "auto" }}>
                        <button 
                            className="btn exportbtn" 
                            // disabled={ToDate === "" || FromDate === ""} 
                            style={{ width: "auto" }} 
                            onClick={() => CommonExport("fba_order_list", "")}
                        >
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            searchType={"Order Id, Channel name"}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(TbaOrderList?.pagination?.total_records) === TbaOrderList?.list?.length ? "calc(100vh - 250px)" : parseInt(TbaOrderList?.pagination?.total_records) > 10 ? "calc(100vh - 285px)" : "calc(100vh - 250px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={TbaOrderList} // ORDER LIST
            Pagination={TbaOrderList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[16]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default FbaOrderTab;