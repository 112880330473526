import React from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import { Form, Badge } from "react-bootstrap";

const SettingFields = (props) => {
    const { label, className, type, setting, setSetting, percentageShow } = props;
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    return(<div className={`${className} ${type}`}>
        <Badge bg={type}>{label}</Badge>
        <div className="statusPercentafe">
            <Form.Control 
                type="number"  
                className="inputBoxs"
                value={setting}
                onChange={setSetting}
                onKeyDown={(e) => {
                    EmptySpaceFieldValid(e)
                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                }}
            />
            {percentageShow && (<span>%</span>)}
        </div>
    </div>)
};

export default SettingFields;