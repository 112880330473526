import React, { useState } from "react";
import { Table, Image, Badge, Button } from "react-bootstrap";
import TraingleIcon from "../../../assets/img/traingle_icon.png";
import IncreDeacrementPercentage from "./IncreDeacrementPercentage";
import CommonDateTime from "../../Common/CommonDateTime";
import moment from "moment";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import { Pagination } from "react-pagination-bar";
import 'react-pagination-bar/dist/index.css';
import CommonewModel from "../../Common/CommonewModel";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_SCHEMES_METRICS_URL } from "../../Shared/constant";

const DashBoardTables = (props) => {
    const { isSrNoAllow, Columns, OrderList, PaginationSet, PaginationCurrentPage, setPaginationCurrentPage, height, id, isGrandTotalAllow, isActionAllow } = props;
    const Navigate = useNavigate();
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        last_update_on_date: "",
        Data: {}
    });

    // page change
    const PaginationFunction = (page) => {
        setPaginationCurrentPage(page)
    };

    // get name
    const GetName = (id, url) => {
        return OrderList?.[url?.list]?.filter((elm) => id === elm?.id)[0]?.[url?.label];
    };

    // common date fucntion
    const CommonDateTimeCommon = (date, url) => {
        if(url === "desktop") {
            if (date !== null) {
                const currentTimezone = moment.tz.guess();
                return moment(date).tz(currentTimezone).format('h:mm:ss a');
            } else {
                return "-";
            };
        } else {
            if (date !== null) {
                const currentTimezone = moment.tz.guess();
                return moment(date).tz(currentTimezone).format('MM/DD/YYYY');
            } else {
                return "-";
            };
        };
    };

    // GET SALES PERSON PERFORMANCE STATUS
    function getStatus(data) {
        const currentDate = new Date();
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);

        const resetTime = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const currentDateWithoutTime = resetTime(currentDate);
        const startDateWithoutTime = resetTime(startDate);
        const endDateWithoutTime = resetTime(endDate);
    
        if (currentDateWithoutTime < startDateWithoutTime) {
            return (<Badge bg="info" className="status_mark yet_to_start">Yet to start</Badge>);
        } else if (currentDateWithoutTime >= startDateWithoutTime && currentDateWithoutTime <= endDateWithoutTime) {
            if (parseInt(data.target_achived) >= parseInt(data.target)) {
                return (<Badge bg="success" className="status_mark">Completed</Badge>);
            };
            return (<Badge bg="warning" className="status_mark in_progress">In progress</Badge>);
        } else if (currentDateWithoutTime > endDateWithoutTime) {
            if (parseInt(data.target_achived === null ? 0 : data.target_achived) < parseInt(data.target)) {
                return (<Badge bg="danger" className="status_mark">Expired</Badge>);
            }
        };
    };

    const showTable = (value) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Summary Details",
            subtitle: "Table-ERP-General",
            modalType: "form",
            last_update_on_date: OrderList?.list[0]?.updated_at,
            Data: value
        });
    };

    // COMMON TABLE GRAND TOTAL
    const TotalTableCount = (list, url, type) => {
        if (list?.length > 0) {
            if (url) {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.[url]);
                }
                if(type === "percantage") {
                    return sum + "%";
                };
                return sum;
            }
        } else {
            return 0;
        };
    };

    // COMMON NVAIGATE URL
    const NavigateUrl = (franchise_id) => {
        Navigate(`${DASHBOARD_SCHEMES_METRICS_URL}/${franchise_id}`)
    };

    function displayZeroIfNegative(number) {
        if(!isNaN(number)) {
            if (number < 0) {
                return "-";
            } else {
                return number;
            }
        } else {
            return "-";
        };
    };

    // GET DUE DAYS FUNCTION
    function getDueDays(endDate) {
        const currentDate = new Date(); // Get the current date
        const end = new Date(endDate); // Convert end_date to Date object
    
        // Calculate the difference in milliseconds
        const diffTime = end - currentDate;
    
        // Convert milliseconds to days
        const dueDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
        return displayZeroIfNegative(dueDays);
    };

    // VIEW MORE ACTION ASSOCIATES
    const ViewMoreActiveAssociates = (data, franchise_id, url) => {
        if(url === "Active-Associates-Modal") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Associate's",
                subtitle: "Associate's",
                modalType: "form",
                Data: { data, franchise_id },
                button: ""
            });
        } else if(url === "Dealer-Wise-Available-Frame-Inventory") {
            setShowModalNew({
                ...showModalNew,
                open: !showModalNew?.open,
                title: "Available Frame Inventory",
                subtitle: "Available Frame Inventory",
                modalType: "form",
                Data: franchise_id,
                button: ""
            });
        }
    };

    return(<div className="dashboard_common_tables">
        <div
            style={{
                float: "left",
                width: "100%",
                overflow: "auto",
                maxHeight: height,
            }}
            className="ScrollbarsSidebar_mains"
        >
            <div className="tableView gstreporttableadded">
                <Table id={id} style={{ marginBottom: "0px" }}>
                    <thead>
                        <tr>
                            {isSrNoAllow && (<th>SNo</th>)}
                            {Columns?.map((elm, index) => {
                                if(elm?.type === "label_percentage") {
                                    return(<th key={index}>
                                        <div style={{ display: "flex", alignItems: "center", fontSize: "13px", columnGap: "2px" }}>
                                        % <Image src={TraingleIcon} style={{ width: "13px" }} alt="TRAINGLE" />
                                        </div>
                                    </th>)
                                } else {
                                    if(elm?.key === 'product_name') {
                                        return(<th key={index} style={{ width: "135px" }}>{elm?.label}</th>);
                                    } else {
                                        return(<th key={index}>{elm?.label}</th>);
                                    };
                                };
                            })}
                            {isActionAllow?.allow && (<th>{isActionAllow?.label}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {OrderList?.list?.length === 0 ? (<tr>
                            <td colSpan={Columns?.length + 1} style={{ textAlign: "center" }}>Data not found !</td>
                        </tr>) : OrderList?.list?.map((item, rowIndex) => {
                            return(<tr key={rowIndex}>
                                {isSrNoAllow && (<td>
                                    {GetCurrentTableSRNo(rowIndex, PaginationSet?.current_page, PaginationSet?.record_limit)}
                                </td>)}
                                {Columns?.map((row, ind) => {
                                    if(row?.type === "label") {
                                        if(row?.key === 'product_name') {
                                            return(<td key={ind}>
                                                <p title={item[row?.key]} style={{ width: "130px", textOverflow: "ellipsis", overflow: "hidden", marginBottom: "0px" }}>{item[row?.key]}</p>
                                            </td>)
                                        } else {
                                            return(<td key={ind}>
                                                {item[row?.key]}
                                            </td>)
                                        };
                                    } else if(row?.type === "label_percentage") {
                                        return(<td key={ind}>
                                            {IncreDeacrementPercentage(parseFloat(parseFloat(item[row?.key]).toFixed(2)), "No_Badge")}
                                        </td>)
                                    } else if(row?.type === "label_only_percentage") {
                                        return(<td key={ind} className="label_only_percantage_set">
                                            {IncreDeacrementPercentage(parseFloat(parseFloat(item[row?.key]).toFixed(2)), "No_Badge")}
                                        </td>)
                                    } else if(row?.type === "label_get_from_list_modal_open_summery") {
                                        return(<td key={ind}>
                                            <b className="tableBolanchor" onClick={() => showTable(item)}>{GetName(item[row?.key], row?.filterFrom)}</b>
                                        </td>)
                                    } else if(row?.type === "label_redirect_summery") {
                                        return(<td key={ind}>
                                            <b className="tableBolanchor" onClick={() => NavigateUrl(item?.franchise_id)}>{item[row?.key]}</b>
                                        </td>)
                                    } else if(row?.type === "label_get_from_list") {
                                        return(<td key={ind}>
                                            {GetName(item[row?.key], row?.filterFrom)}
                                        </td>)
                                    } else if(row?.type === "date") {
                                        return(<td key={ind}>
                                            {CommonDateTime(item[row?.key], "LL", "show")}
                                        </td>)
                                    } else if(row?.type === "date_time") {
                                        return(<td key={ind}>
                                            {CommonDateTimeCommon(item[row?.key], "desktop")}
                                        </td>)
                                    } else if(row?.type === "label_k") {
                                        return(<td key={ind}>
                                            {item[row?.key] + "k"}
                                        </td>)
                                    } else if(row?.type === "Status_sales_person_performance") {
                                        return(<td key={ind}>
                                            {getStatus(item)}
                                        </td>)
                                    } else if(row?.type === "label_due_of_end_date") {
                                        return(<td key={ind}>
                                            {getDueDays(item[row?.key])}
                                        </td>)
                                    } else {
                                        return(<td key={ind}>
                                            {item[row?.key]}
                                        </td>)
                                    }
                                })}
                                {isActionAllow?.allow && (<td>
                                    {isActionAllow?.functionType === "Active-Associates-Modal" ? (<React.Fragment>
                                        <Button className="btn btn-link Active-Associates-btn" onClick={() => ViewMoreActiveAssociates(item, isActionAllow?.franchise_id, "Active-Associates-Modal")}>View More</Button>                        
                                    </React.Fragment>) : isActionAllow?.functionType === "Dealer-Wise-Available-Frame-Inventory" ? (<React.Fragment>
                                        <Button className="btn btn-link Active-Associates-btn" onClick={() => ViewMoreActiveAssociates(item, item?.franchise_id, "Dealer-Wise-Available-Frame-Inventory")}>View More</Button>                        
                                    </React.Fragment>) : (<React.Fragment></React.Fragment>)}
                                </td>)}
                            </tr>)
                        })}
                        {isGrandTotalAllow?.allow && (<tr>
                            {(isGrandTotalAllow?.table_name === "Age-Group-Category-Use-Case-Wise-Classification") ? (
                                <React.Fragment>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{isGrandTotalAllow?.data}</td>
                                </React.Fragment>
                            ) : (isGrandTotalAllow?.table_name === "Region-Vs-Registrations") ? (
                                <React.Fragment>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{TotalTableCount(isGrandTotalAllow?.data?.list, "registrations_count", "normal")}</td>
                                    <td>{TotalTableCount(isGrandTotalAllow?.data?.list, "primary_sale_count", "normal")}</td>
                                    <td>{TotalTableCount(isGrandTotalAllow?.data?.list, "Difference_percantage", "percantage")}</td>
                                </React.Fragment>
                            ) : (isGrandTotalAllow?.table_name === "Product-Vs-Registrations") ? (
                                <React.Fragment>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{isGrandTotalAllow?.data?.grand_total?.registrations_count}</td>
                                    <td>{isGrandTotalAllow?.data?.grand_total?.primary_sale_count}</td>
                                    <td className="label_only_percantage_set">
                                        {IncreDeacrementPercentage(parseFloat(parseFloat(isGrandTotalAllow?.data?.grand_total?.Difference_percantage).toFixed(2)), "No_Badge")}
                                    </td>
                                </React.Fragment>
                            ) : (isGrandTotalAllow?.table_name === "Dealer-Wise-Available-Inventory") ? (
                                <React.Fragment>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{isGrandTotalAllow?.data?.grand_total?.primary_sale_total_qty}</td>
                                    <td>{isGrandTotalAllow?.data?.grand_total?.secondary_sale_total_qty}</td>
                                    <td>{isGrandTotalAllow?.data?.grand_total?.available_total_qty}</td>
                                </React.Fragment>
                            ) : (isGrandTotalAllow?.table_name === "Monthly-Sales-Revenue-Product") ? (
                                <React.Fragment>
                                    <th></th>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{isGrandTotalAllow?.data?.grand_total?.grand_total_qty}</td>
                                    <td className="label_only_percantage_set">
                                        {IncreDeacrementPercentage(parseFloat(parseFloat(isGrandTotalAllow?.data?.grand_total?.grand_total_qty_percentage).toFixed(2)), "No_Badge")}
                                    </td>
                                    <td>{AmountNumberFormat(isGrandTotalAllow?.data?.grand_total?.grand_total_amount)}</td>
                                    <td className="label_only_percantage_set">
                                        {IncreDeacrementPercentage(parseFloat(parseFloat(isGrandTotalAllow?.data?.grand_total?.grand_total_amount_percentage).toFixed(2)), "No_Badge")}
                                    </td>
                                </React.Fragment>
                            ) : (isGrandTotalAllow?.table_name === "Use-Case-Wise-Classification") ? (<React.Fragment>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{TotalTableCount(isGrandTotalAllow?.data?.list, "count", "normal")}</td>
                            </React.Fragment>) : (isGrandTotalAllow?.table_name === "State-Vs-Registrations") ? (<React.Fragment>
                                    <th colSpan={isGrandTotalAllow?.colspan}>{isGrandTotalAllow?.title}</th>
                                    <td>{isGrandTotalAllow?.data?.total_register_count}</td>
                                    <td>{isGrandTotalAllow?.data?.total_mismatch_count}</td>
                                    <td className="label_only_percantage_set">
                                        {IncreDeacrementPercentage(parseFloat(parseFloat(isGrandTotalAllow?.data?.total_percentage_difference).toFixed(2)), "No_Badge")}
                                    </td>
                            </React.Fragment>) : (<React.Fragment>
                                
                            </React.Fragment>)}
                        </tr>)}
                    </tbody>
                </Table>
            </div>
        </div>

        {/* PAGINATION */}
        {PaginationSet?.total_records > 5 && (<div className="paginationCustom">
            <Pagination
                currentPage={PaginationCurrentPage}
                itemsPerPage={PaginationSet.record_limit}
                onPageChange={(pageNumber) => PaginationFunction(pageNumber)}
                totalItems={parseInt(PaginationSet.total_records)}
                pageNeighbours={2}
            />
        </div>)}

        {/* COMMON MODAL */}
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </div>)
};


export default DashBoardTables;