/* eslint-disable */
import React, { useState } from "react";
import { Table, Button, Form, Collapse, Image, Spinner, Modal, Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import ImportDataAPI from "../../../Api/ImportDataAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SampleFile from "../../../assets/file/LeadsCreation.xlsx";
import { IMPORT_ORDER_API_URL } from "../../Shared/constant";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonLoader from "../../Common/CommonLoader";
import CommonScrollBar from "../../Common/CommonScrollBar";

const SaleOrderChildTab = (props) => {
    const Dispatch = useDispatch();
    const { accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: {},
        Data: {}
    });

    const [ showModalReplacement, setShowModalReplacement ] = useState({
        open: false,
        Data: []
    });

    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ activeTableDetailsNew, setActiveTableDetailsNew ] = useState("");

    // bike store details state
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const [ StockDetailsList, setStockDetailsList ] = useState({
        excluded_rows: [],
        included_rows: [],
        replacement_rows: [],
    });
    const [ FileGetStore, setFileGetStore ] = useState({});
    const [ FileName, setFileName ] = useState("");
    const [ LoaderOrderAdd, setLoaderOrderAdd ] = useState(false);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // import file
    const ImportFile = async (e, status) => {
        setLoaderStart(true);
        if(e !== undefined) {
            const files = e?.target?.files[0];
            setFileGetStore(files);
            const formData = new FormData();
            formData.append("file", files, files.name);
            // formData.append("warehouse_id ", selectWarehouse?.value);
            formData.append("is_add", status);
            const fileresponse = await ImportDataAPI(IMPORT_ORDER_API_URL, accessToken, formData);
            if(fileresponse?.status === 200) {
                if(fileresponse?.data?.replacement_rows?.length > 0) {
                    setShowModalReplacement({
                        ...showModalReplacement,
                        open: true,
                        Data: fileresponse?.data?.replacement_rows,
                    });
                    toast.info(fileresponse?.message);
                    setFileName(files.name);
                    setStockDetailsList({
                        ...StockDetailsList,
                        excluded_rows: fileresponse?.data?.excluded_rows,
                        included_rows: fileresponse?.data?.included_rows,
                        replacement_rows: []
                    });
                } else {
                    toast.info(fileresponse?.message);
                    setFileName(files.name);
                    setStockDetailsList({
                        ...StockDetailsList,
                        excluded_rows: fileresponse?.data?.excluded_rows,
                        included_rows: fileresponse?.data?.included_rows,
                        replacement_rows: []
                    });
                };
                setLoaderStart(false);
                handleClose();
                document.getElementById('fileInput').value = null;
            } else {
                document.getElementById('fileInput').value = null;
                setLoaderStart(false);
                // setStockDetailsList({
                //     existing: [],
                //     new_added: [],
                //     new_existing: [],
                // });
                // setFileName("");
                setLoaderStart(false);
                toast.error(fileresponse?.message);
                if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                    CommonLogout(accessToken, Dispatch);
                    localStorage?.removeItem("access_token");
                };
            };
        } else {
            const files = FileGetStore;
            const formData = new FormData();
            if(StockDetailsList?.replacement_rows?.length > 0) {
                formData.append("file", files, files.name);
                formData.append("is_add", status);
                formData.append("is_replace", true);
            } else {
                formData.append("file", files, files.name);
                formData.append("is_add", status);
                formData.append("is_replace", false);
            };
            const fileresponse = await ImportDataAPI(IMPORT_ORDER_API_URL, accessToken, formData);
            if(fileresponse?.status === 200) {
                setLoaderOrderAdd(false);
                toast.info(fileresponse?.message);
                setFileName(files.name);
                ResetDetails();
                setLoaderStart(false);
                handleClose();
                // document.getElementById('fileInput').value = null;
            } else {
                setLoaderOrderAdd(false);
                setLoaderStart(false);
                toast.error(fileresponse?.message);

                if(fileresponse?.message === "User is not authorized." && fileresponse?.status === 400) {
                    CommonLogout(accessToken, Dispatch);
                    localStorage?.removeItem("access_token");
                };
            };
        };
    };

    // add store details
    const AddDetails = (e) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
        });
    };

    // add product function
    const AddProductDetails = (e) => {
        setLoaderOrderAdd(true);
        ImportFile(e, 'yes')
    };

    // order replacement function
    const AddReplacmentOrder = () => {
        setStockDetailsList({
            ...StockDetailsList,
            replacement_rows: showModalReplacement?.Data,
        });
        handleCloseReplacement();
    };

    // reset details
    const ResetDetails = () => {
        setStockDetailsList({
            excluded_rows: [],
            included_rows: [],
            replacement_rows: [],
        });
        setFileGetStore({});
        setFileName("");
    };

    // close modal
    const handleClose = () => {
        setShowModalNew({...showModalNew, open: false});
    };

    // close replacement modal
    const handleCloseReplacement = () => {
        setShowModalReplacement({
            ...showModalReplacement,
            open: false,
            Data: [],
        });
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // show more user details
    const ViewMoreDetailsSecond = (id) => {
        setActiveTableDetailsNew(id);
    };

    // hide more user details
    const ElseMoreDetailsSecond = () => {
        setActiveTableDetailsNew("");
    };

    return(<section className="Main_section">
        <div className="card-header-New" >
            <div className="filtr_Form" style={{ marginBottom: "0px" }}>
                <div className="formloginset">
                    <div className="row">
                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[1]?.Edit) && (<div className="col-md-3">
                            <Form.Group  >
                                {FileName === "" ? (<>
                                    <label id="UploadCmnBTN" className="btn head_cmn_btn UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                        <i className="bi bi-upload"></i>
                                        <input type="file" id="fileInput" accept=".xlsx, .xls" onChange={(e) => ImportFile(e, 'no')} />
                                        Upload Sheet
                                    </label>
                                    {LoaderStart && (<div className="UploadLoader">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>)}
                                </>) : (<div className="uplaodedFilename">
                                    <i className="bi bi-filetype-xlsx"></i>
                                    {FileName}
                                    <i className="bi bi-x-circle-fill" onClick={() => ResetDetails()}></i>
                                </div>)}
                            </Form.Group>
                        </div>)}
                        <div className="col-md-3" style={{ width: "auto", float: "right", margin: "auto 0 auto auto" }}>
                            <Form.Group  >
                                {/* <Form.Label>&nbsp;</Form.Label> */}
                                <a href={SampleFile} className="btn exportbtn" style={{ width: "auto", marginTop: "0px" }}>
                                    <i className="bi bi-filetype-xlsx"></i>
                                    Download Format
                                </a>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="tableView">
            <CommonScrollBar 
                className={"ScrollbarsSidebar ScrollbarsSidebarHorizontal"} 
                height={{ height: "calc(100vh - 188px)" }} 
            >
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>ECOM Order ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Billing PINCode</th>
                            <th>BillAddress1</th>
                            <th>BillAddress2</th>
                        </tr>
                    </thead>
                    <tbody>
                        {StockDetailsList?.included_rows?.length === 0 ? (<tr>
                                <td colSpan={17} style={{ textAlign: "center" }}>Store details not found</td>
                            </tr>) : StockDetailsList?.included_rows?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                                <tr key={index}>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm[0] ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm[0] ? ElseMoreDetails() : ViewMoreDetails(elm[0])}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm[0] ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{elm[0]}</td>
                                    <td>{elm[1]}</td>
                                    <td>{elm[2]}</td>
                                    <td>{elm[3]}</td>
                                    <td>{elm[4]}</td>
                                    <td>{elm[5]}</td>
                                    <td>{elm[6]}</td>
                                </tr>
                                <Collapse in={activeTableDetails === elm[0] ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+elm[0]}>
                                        <td colSpan="9" >
                                            <Table className="table">
                                                <tbody>
                                                    <tr>
                                                        <th>ShipAddress1</th>
                                                        <td>{elm[7]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>ShipAddress2</th>
                                                        <td>{elm[8]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "30%" }}>ShipPINCode</th>
                                                        <td>{elm[9]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "30%" }}>EcommType</th>
                                                        <td>{elm[10]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "30%" }}>GSTIN</th>
                                                        <td>{elm[11]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "30%" }}>FullfilledBy</th>
                                                        <td>{elm[12]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Remarks</th>
                                                        <td>{elm[13]}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "30%" }}>Items</th>
                                                        <td>
                                                            {/* {elm[14]} */}
                                                            <Table>
                                                                <thead>
                                                                    <tr>
                                                                        <th>MasterSKU</th>
                                                                        <th>Qty</th>
                                                                        <th>Rate</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {elm[14]?.map((elm, index) => {
                                                                        return(<tr key={index}>
                                                                            <td>{elm?.MasterSKU}</td>
                                                                            <td>{elm?.Qty}</td>
                                                                            <td>{elm?.Rate}</td>
                                                                        </tr>)
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                    </tbody>
                </Table>

                {StockDetailsList?.replacement_rows?.length > 0 && (<div className="exsting_newdetails">
                    <h4 style={{ color: "red" }}>Order Replacement List.</h4>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>SNO</th>
                                <th>Order ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Billing PINCode</th>
                                <th>BillAddress1</th>
                                <th>BillAddress2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {StockDetailsList?.replacement_rows?.length === 0 ? (<tr>
                                    <td colSpan={17} style={{ textAlign: "center" }}>Order replacement not found</td>
                                </tr>) : StockDetailsList?.replacement_rows?.map((elm, index) => {
                                return(<React.Fragment key={index}>
                                    <tr key={index}>
                                        <td style={{ width: '10px' }}>
                                            <button 
                                                className={activeTableDetailsNew === elm[0] ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetailsNew === elm[0] ? ElseMoreDetailsSecond() : ViewMoreDetailsSecond(elm[0])}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetailsNew === elm[0] ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4]}</td>
                                        <td>{elm[5]}</td>
                                        <td>{elm[6]}</td>
                                    </tr>
                                    <Collapse in={activeTableDetailsNew === elm[0] ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase"+elm[0]}>
                                            <td colSpan="9" >
                                                <Table hover className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>ShipAddress1</th>
                                                            <td>{elm[7]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>ShipAddress2</th>
                                                            <td>{elm[8]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>ShipPINCode</th>
                                                            <td>{elm[9]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>EcommType</th>
                                                            <td>{elm[10]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>GSTIN</th>
                                                            <td>{elm[11]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>FullfilledBy</th>
                                                            <td>{elm[12]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Remarks</th>
                                                            <td>{elm[13]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>Items</th>
                                                            <td>
                                                                <Table responsive>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>MasterSKU</th>
                                                                            <th>Qty</th>
                                                                            <th>Rate</th>
                                                                            <th>Error</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {elm[14]?.map((elm, index) => {
                                                                            return(<tr key={index}>
                                                                                <td>{elm?.MasterSKU}</td>
                                                                                <td>{elm?.Qty}</td>
                                                                                <td>{elm?.Rate}</td>
                                                                                <td>
                                                                                    <Badge bg="danger">{elm?.error}</Badge>
                                                                                </td>
                                                                            </tr>)
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>)
                            })}
                        </tbody>
                    </Table>
                </div>)}

                <div className="btnGroupswraps" id="btnGroupswraps">
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[1]?.Edit) && (<Button className="Addbtn" disabled={StockDetailsList?.included_rows?.length === 0 && StockDetailsList?.replacement_rows?.length === 0} onClick={() => AddDetails()}>Add</Button>)}
                    <Button className="Resetbtn" onClick={() => ResetDetails()}>Reset</Button>
                </div>

                {StockDetailsList?.excluded_rows?.length > 0 && (<div className="exsting_newdetails">
                    <h4 style={{ color: "red" }}>Order Rejected List.</h4>
                    <Table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>SNO</th>
                                <th>Order ID</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Billing PINCode</th>
                                <th>BillAddress1</th>
                                <th>BillAddress2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {StockDetailsList?.excluded_rows?.length === 0 ? (<tr>
                                    <td colSpan={17} style={{ textAlign: "center" }}>Store details not found</td>
                                </tr>) : StockDetailsList?.excluded_rows?.map((elm, index) => {
                                return(<React.Fragment key={index}>
                                    <tr key={index}>
                                        <td style={{ width: '10px' }}>
                                            <button 
                                                className={activeTableDetailsNew === elm[0] ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetailsNew === elm[0] ? ElseMoreDetailsSecond() : ViewMoreDetailsSecond(elm[0])}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetailsNew === elm[0] ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>{elm[0]}</td>
                                        <td>{elm[1]}</td>
                                        <td>{elm[2]}</td>
                                        <td>{elm[3]}</td>
                                        <td>{elm[4]}</td>
                                        <td>{elm[5]}</td>
                                        <td>{elm[6]}</td>
                                    </tr>
                                    <Collapse in={activeTableDetailsNew === elm[0] ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase"+elm[0]}>
                                            <td colSpan="9" >
                                                <Table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th>ShipAddress1</th>
                                                            <td>{elm[7]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>ShipAddress2</th>
                                                            <td>{elm[8]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>ShipPINCode</th>
                                                            <td>{elm[9]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>EcommType</th>
                                                            <td>{elm[10]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>GSTIN</th>
                                                            <td>{elm[11]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>FullfilledBy</th>
                                                            <td>{elm[12]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Remarks</th>
                                                            <td>{elm[13]}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "30%" }}>Items</th>
                                                            <td>
                                                                <Table>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>MasterSKU</th>
                                                                            <th>Qty</th>
                                                                            <th>Rate</th>
                                                                            <th>Error</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {elm[14]?.map((elm, index) => {
                                                                            return(<tr key={index}>
                                                                                <td>{elm?.MasterSKU}</td>
                                                                                <td>{elm?.Qty}</td>
                                                                                <td>{elm?.Rate}</td>
                                                                                <td>
                                                                                    <Badge bg="danger">{elm?.error}</Badge>
                                                                                </td>
                                                                            </tr>)
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>)
                            })}
                        </tbody>
                    </Table>
                </div>)}
            </CommonScrollBar>
        </div>
        
        {/* add order permission modal */}
        <Modal className="commonModal" show={showModalNew?.open} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%"}}>
                    Are you sure?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="notformsection">
                    <p>Would you like to add the <b>{parseFloat(StockDetailsList?.included_rows?.length + StockDetailsList?.replacement_rows?.length)} order</b>?</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleClose()}>
                    No
                </Button>
                <Button variant="primary" className="addbtncmn" onClick={() => AddProductDetails()}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>

        {/* order replacement permission modal */}
        <Modal className="commonModal" show={showModalReplacement?.open} onHide={handleCloseReplacement}>
            <Modal.Header closeButton>
                <Modal.Title style={{ width: "100%"}}>
                    Replacement Order
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="notformsection">
                    <p>Are you sure, you want replace <b>{showModalReplacement?.Data?.length} order ?</b></p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleCloseReplacement()}>
                    No
                </Button>
                <Button variant="primary" className="addbtncmn" onClick={() => AddReplacmentOrder()}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>

        {LoaderOrderAdd && (<CommonLoader />)}
    </section>)
}

export default SaleOrderChildTab;