/* eslint-disable */
import React, { useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { REACT_APP_VERIFY_ADHAAR_CARD_VERIFY_URL } from "../Shared/constant";
import BankDetailVerify from "../../Api/BankDetailVerify";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VerifyModal = (props) => {
    const { VerifyModalState, setVerifyModalState, setAadharVerifiedStatus, ApplicationState, setApplicationState } = props;
    const [ otp, setOtp ] = useState(['', '', '', '', '', '']);
    const [ adharCaradDetails, setadharCaradDetails ] = useState({});
    const [ errorotp, seterrorOtp ] = useState("");
    const [ loader, setloader ] = useState(false);

    // close Modal
    const handleClose = () => {
        setVerifyModalState({
            ...VerifyModalState,
            open: false,
            title: "",
            modalType: "",
            data: {} 
        });
    };
    
    const onSubmit = async () => {
        if(otp?.filter(elm => elm !== "")?.length === 6) {
            setloader(true);
            const payload = {
                client_id: VerifyModalState?.data?.client_id,
                otp: otp?.toString()?.replaceAll(",",""),
            };
            const response = await BankDetailVerify(REACT_APP_VERIFY_ADHAAR_CARD_VERIFY_URL, payload, "aadhaar_card_details");
            if(response?.status === 200) {
                let address = [
                    response?.data.address.house ?? '',
                    response?.data.address.street ?? '',
                    response?.data.address.landmark ?? '',
                    response?.data.address.vtc ?? '',
                    response?.data.address.subdist ?? '',
                    response?.data.address.dist ?? '',
                    response?.data.address.po ?? '',
                    response?.data.address.state ?? '',
                    response?.data.zip ?? '',
                    response?.data.address.country ?? ''
                ];
                
                let formattedAddress = address.filter(part => part !== '').join(', ');
                setloader(false);
                setadharCaradDetails(response?.data);
                setApplicationState({
                    ...ApplicationState,
                    FullName: response?.data?.full_name,
                    Address: formattedAddress,
                });
                toast.info("Aadhar card verified successfully.");
                setAadharVerifiedStatus("Verified");
            } else {
                setloader(false);
                setAadharVerifiedStatus("Unverified");
                if(response?.message) {
                    toast.error(response?.message);
                } else {
                    toast.error(response?.otp);
                };
            };
            seterrorOtp("");
        } else {
            setloader(false);
            seterrorOtp("Please enter OTP.");
        };
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !otp[index] && index !== 0) {
            // Move to the previous input field
            event.target.previousSibling.focus();
        }
    };

    const handleChange = (element, index) => {
        const value = element.value.replace(/[^0-9]/g, ''); // Allow only digits
        if (value) {
          const newOtp = [...otp];
          newOtp[index] = value;
          setOtp(newOtp);
    
          // Move to the next input field
          if (element.nextSibling) {
            element.nextSibling.focus();
          }
        } else {
        const newOtp = [...otp];
          newOtp[index] = "";
          setOtp(newOtp);
        }
    };

    return(<Modal className="commonModal verifySuccessErrorModal" show={VerifyModalState?.open} onHide={handleClose}>
        <Modal.Header closeButton>
            <h4>{Object?.keys(adharCaradDetails)?.length === 0 ? VerifyModalState?.title : "Aadhar card verified successfully."}</h4>
        </Modal.Header>
        <Modal.Body>
            {VerifyModalState?.modalType === "aadhaar_card_details" ? (<React.Fragment>
                {Object?.keys(adharCaradDetails)?.length === 0 ? (<div className="formloginset">
                    <Form.Group  >
                        <div className="otp_wrappper" >
                            {otp.map((digit, index) => (
                                <Form.Control
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleChange(e.target, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                            ))}
                        </div>
                        {errorotp !== "" && (<span className="error_medotory">{errorotp}</span>)}
                    </Form.Group>
                </div>) : (<div className="banks_details_list">
                    <ul>
                        <li><label>Name: </label>  {adharCaradDetails?.full_name}</li>
                        <li><label>Date of birth: </label>  {adharCaradDetails?.dob}</li>
                        <li><label>Address: </label>  {adharCaradDetails?.address?.loc},{adharCaradDetails?.address?.landmark},{adharCaradDetails?.address?.dist},{adharCaradDetails?.address?.state},{adharCaradDetails?.address?.country}</li>
                    </ul>
                </div>)}
            </React.Fragment>) : (<div className="banks_details_list">
                <ul>
                    <li><label>Name: </label>  {VerifyModalState?.data?.full_name}</li>
                    <li><label>Bank Name: </label>  {VerifyModalState?.data?.ifsc_details?.bank_name}</li>
                    <li><label>Branch: </label>  {VerifyModalState?.data?.ifsc_details?.branch}</li>
                    <li><label>City: </label>  {VerifyModalState?.data?.ifsc_details?.city}</li>
                </ul>
            </div>)} 
        </Modal.Body>
        <Modal.Footer>
            {VerifyModalState?.modalType === "aadhaar_card_details" ? (<React.Fragment>
                {Object?.keys(adharCaradDetails)?.length === 0 ? (<Button className="proceed_btn" onClick={() => onSubmit()} disabled={loader}>
                    <i className="bi bi-check-all"></i>
                    Proceed
                </Button>) : (<Button className="proceed_btn" onClick={() => handleClose()}>
                    Close
                </Button>)}
            </React.Fragment>) : (<Button className="proceed_btn" onClick={() => handleClose()}>
                Close
            </Button>)}
        </Modal.Footer>
    </Modal>)
}

export default VerifyModal;