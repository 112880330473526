/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { Link, useNavigate } from "react-router-dom";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonewModel from "../../Common/CommonewModel";
import CommonExport from "../../Common/CommonExport";
import moment from "moment";

const ERPoutstandingReport = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [show, setShow] = useState(null)
    const today = new Date();
    const { device_id, erpAccountList, selfInfo } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    const showTable = (value) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Summary Details",
            subtitle: "Table-ERP",
            // description: `Are you sure! You want to confirm this transaction?</h5>`,
            modalType: "form",
            // button: "Yes",
            Data: value
        });
    }

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };



    // get order_list all
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "erp_account",
            "request": {
                "search": userSearch?.trim(),
                "limit": userLimit,
                "page_no": currentPage,
                "franchise_id":"",
                "order_by": "-updated_at",
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, [websocket, currentPage, userLimit, userSearch]);

    // get frachise data
    const GetFranchiseData = (id) => {
        const franchise = erpAccountList?.franchise?.filter((elm) => elm?.id === id)[0];
        return {
            FranchiseCode: franchise?.franchise_code,
            FranchiseName: franchise?.customer_name,
        };
    };

     // common date fucntion
     const CommonDateTimeCommon = (date) => {
        if (date !== null) {
            const currentTimezone = moment.tz.guess();
            return moment(date).tz(currentTimezone).format('MMMM Do YYYY, h:mm:ss a');
        } else {
            return "-";
        };
    };

    return (<section className="Main_section">
        <div className="outstanding_filters_datetime">
            <DataTableFilter
                searchType={"Franchise code, ERP ID, Customer Name"}
                filterType={"ErpOutstandingReport"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userType={userType}
                setUserType={setUserType}
                userLimit={userLimit}
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                SelectUserTypeFnct={SelectUserTypeFnct}
                buttonvisible={false}
                searchdisable={true}
            />
            {erpAccountList?.list?.length > 0 && (<div className="date_time_stamp">
                <label><i className="bi bi-alarm"></i> Last Updated On:</label>
                {CommonDateTimeCommon(erpAccountList?.list[0]?.updated_at)}
            </div>)}
        </div>
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{ height: erpAccountList?.pagination?.total_records > 0 ? "calc(100vh - 188px)" : "calc(100vh - 153px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table id="ErpOutstandingReport" bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>ERP ID</th>
                            <th>Posting Date</th>
                            <th>Date & Time</th>
                            <th>Franchise Code</th>
                            <th>Customer Name</th>
                            <th>Region</th>
                            <th>Sales Person</th>
                            <th>Inv. Amount</th>
                            <th>0-30</th>
                            <th>31-60</th>
                            <th>61-90</th>
                            <th>91-120</th>
                            <th>121-Above</th>
                            <th>Outstanding</th>
                            <th>Paid</th>
                            <th>Credit Note</th>
                            <th>Advance</th>
                            <th>Total Due</th>
                        </tr>
                    </thead>
                    <tbody>
                        {erpAccountList?.list?.map((item, index) => {
                            return (<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, erpAccountList?.pagination?.current_page, erpAccountList?.pagination?.record_limit)}
                                </td>
                                <td>{item?.erp_id}</td>
                                <td>{item?.posting_date === null ? "-" : CommonDateTime(item.posting_date, "LL", "show")}</td>
                                <td>{CommonDateTimeCommon(item?.created_at)}</td>
                                <td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.PaymentManager[5]?.Edit) ? (<Link className="btn-link btn-link-color text-decoration-none" onClick={() => showTable(item)}>
                                        <b className="tableBolanchor">{GetFranchiseData(item?.franchise_id)?.FranchiseCode}</b>
                                    </Link>) : (GetFranchiseData(item?.franchise_id)?.FranchiseCode)}
                                </td>
                                <td>{GetFranchiseData(item?.franchise_id)?.FranchiseName}</td>
                                <td>{item?.region}</td>
                                <td>-</td>
                                <td>{AmountNumberFormat(item?.invoice_amt)}</td>
                                <td>{item?.range1}</td>
                                <td>{item?.range2}</td>
                                <td>{item?.range3}</td>
                                <td>{item?.range4}</td>
                                <td>{item?.range5}</td>
                                <td>{AmountNumberFormat(item?.outstanding_amt)}</td>
                                <td>{item?.paid_amt === null ? "-" : AmountNumberFormat(item?.paid_amt)}</td>
                                <td>{AmountNumberFormat(item?.credit_note)}</td>
                                <td>{AmountNumberFormat(item?.advance)}</td>
                                <td>{AmountNumberFormat(item?.total_due)}</td>
                            </tr>)
                        })}
                        {erpAccountList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="18">Erp outstanding report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {erpAccountList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={erpAccountList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setShowModalNew={setShowModalNew} showModalNew={showModalNew} />)}
    </section>)
}

export default ERPoutstandingReport;

