/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import { Button, OverlayTrigger, Tooltip, Form, Image, DropdownButton, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateOrderManagementFilter } from "../../redux/actions/adminActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import SearchIcon from "../../assets/img/table_search_box.png";
import Select from 'react-select';
import CommonExport from "./CommonExport";

const MainOrderDataTableFilter = ({ tabName, filterType, searchType, ButtonSet, searchdisable, buttonvisible, FromDateStartDateFlag, AllOrderStatusAllow, isAllowWarehouse, isDateType, isOdiFilterAllow, ...props }) => {
    const { websocket } = useContext(WebSocketContext);
    const [ CommonSearchUpdate, setCommonSearchUpdate ] = useState("");
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ FilterOpen, setFilterOpen ] = useState(false);
    const today = new Date();
    const { OrderManagmentFilter, Regionlistall, device_id, saleTypeAllList, wareHouselistall, selfInfo } = useSelector((state) => state.adminReducers);
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const optionSaleTypeAllList = saleTypeAllList?.map((item, index)=>({...item, key: item.id, value: item?.id , label: item?.sale_type }));
    const Dispatch = useDispatch();

    const optionODI_Courier_Pin_Order = [
        {label:"All Orders", flag: "all", color: "#fff"},
        {label:"ODA Pincode", flag: "is_odi", color: "rgba(245, 133, 133, 0.32)"},
        {label:"Normal Pincode", flag: "is_normal", color: "#eee"},
        {label:"Serviceability missing", flag: "is_courier_pin_code", color: "#f3b70285"},
    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select limit
    const SelectLimitFct = (e, url) => {
        const filterKey = url;
        if (filterKey) {
            Dispatch(updateOrderManagementFilter({
                [filterKey]: {
                    UserLimit: e.target.value,
                    CurrentPage: 1,
                }
            }));
        };
    };

    // clear search box
    const ClearSearchFiled = (url) => {
        const filterKey = url;

        if (filterKey) {
            Dispatch(updateOrderManagementFilter({
                [filterKey]: {
                    UserSearch: "",
                }
            }));
        };
    };

    // clear after delete button hit
    document.addEventListener('keydown', function(event) {
        var keyPressed = event.key;
        if (keyPressed === 'Delete') {
            ClearSearchFiled(tabName);
        }
    });

    // common search update state
    const SearchMainOnchange = async (value, url) => {
        if(url === tabName) {
            await setCommonSearchUpdate(value);
        };
    };

    // on enter search 
    const onEnterSearch = (e, url) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            const filterKey = tabName;

            if (filterKey) {
                OnEnterSearch(url);
            };
        };
    };

    // search box
    const OnEnterSearch = (url) => {
        const filterKey = url;

        if (filterKey) {
            Dispatch(updateOrderManagementFilter({
                [filterKey]: {
                    UserSearch: CommonSearchUpdate,
                    CurrentPage: 1,
                }
            }));
        };
    };    

    useEffect(() => {
        const CurrentSearch = OrderManagmentFilter[tabName]?.UserSearch === undefined ? "" : OrderManagmentFilter[tabName]?.UserSearch;
        setCommonSearchUpdate(CurrentSearch);
    }, [ OrderManagmentFilter[tabName] ]);


    const selectRegionFunction = (e, url) => {
        Dispatch(updateOrderManagementFilter({
            [url]: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date, tabName) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                [tabName]: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                [tabName]: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date, tabName) => {
        Dispatch(updateOrderManagementFilter({
            [tabName]: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            setEndDate("")
            let selectedDate = new Date(date);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                [tabName]: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                [tabName]: {
                    ToDate: date,
                }
            }));
        }
    };

    // order Source update
    const OnChangeOrderSource = (value, url) => {
        if (url) {
            Dispatch(updateOrderManagementFilter({
                [url]: {
                    OrderSource: value,
                    CurrentPage: 1,
                }
            }));
        };
    };

    // select date type
    const selectDateTypeFunction = (e, url) => {
        Dispatch(updateOrderManagementFilter({
            [url]: {
                SelectDateType: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // get page limit from redux
    const GetuserLimit = () => {
        if(OrderManagmentFilter[tabName]?.UserLimit === undefined) {
            return 10;
        } else {
            return OrderManagmentFilter[tabName].UserLimit;
        };
    };

    // order Source update
    const OnChangeBundleOrder = (event, url) => {
        const filterKey = url;

        if (filterKey) {
            Dispatch(updateOrderManagementFilter({
                [filterKey]: {
                    is_bundle_orders: event?.target?.checked,
                    CurrentPage: 1,
                }
            }));
        };
    };

    // wareehouse select
    const WareHouseSelect = (value, url) => {
        if(value !== null) {
            Dispatch(updateOrderManagementFilter({
                [url]: {
                    WarehouseName: value,
                    CurrentPage: 1,
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                [url]: {
                    WarehouseName: "",
                    CurrentPage: 1,
                }
            }));
        };
    };

    // ODI courier pincode select
    const OnChangeIsODICourierPincode = (value, url) => {
        if(url) {
            Dispatch(updateOrderManagementFilter({
                [url]: {
                    is_odi_courier_pin: value,
                    CurrentPage: 1,
                }
            }));
        };
    };

    const selectOrderTypeFunction = (e, url) => {
        Dispatch(updateOrderManagementFilter({
            [url]: {
                SelectOrderType: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    return(<div className="card-header-Order-Filter-New" style={{ width: "100%" }}>
            <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee", marginBottom: "10px" }}>
                <div className="formloginset">
                    <div className="row">
                        {/* DATE TYPE SELECT DROPDOWN */}
                        {isDateType && (<div className="col-md-2" style={{ width: "14%", paddingRight: "0px" }}>
                            <div className="order_type_select" >
                                <small>Date Type:</small>
                                <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter[tabName]?.SelectDateType} onChange={(e) => selectDateTypeFunction(e, tabName)}>
                                    <option value="created_at">Order Date</option>
                                    <option value="updated_at">Updated Date</option>
                                </select>
                            </div>
                        </div>)}

                        {/* FROM DATE AND TO DATE FILTERS */}
                        {FromDateStartDateFlag && (<React.Fragment>
                            <div className="col-md-3" style={{ width: "16%", paddingRight: "0px" }}>
                                <Form.Group>
                                    <div className="datepickField min">
                                        <DatePicker
                                            selected={OrderManagmentFilter[tabName]?.FromDate === "" ? "" : new Date(OrderManagmentFilter[tabName]?.FromDate)}
                                            startDate={OrderManagmentFilter[tabName]?.FromDate === "" ? "" : new Date(OrderManagmentFilter[tabName]?.FromDate)}
                                            endDate={OrderManagmentFilter[tabName]?.ToDate === "" ? "" : new Date(OrderManagmentFilter[tabName]?.ToDate)}
                                            onChange={(date) => DateStartGet(date, tabName)}
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={today}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Enter From Date"
                                            isClearable={OrderManagmentFilter[tabName]?.FromDate !== "" ? true : false}
                                            name="statDate"
                                            autoComplete="off"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-3" style={{ width: "16%", paddingRight: "0px" }}>
                                <Form.Group>
                                    <div className="datepickField">
                                        <DatePicker
                                            selected={OrderManagmentFilter[tabName]?.ToDate === "" ? "" : new Date(OrderManagmentFilter[tabName]?.ToDate)}
                                            startDate={OrderManagmentFilter[tabName]?.FromDate === "" ? "" : new Date(OrderManagmentFilter[tabName]?.FromDate)}
                                            endDate={OrderManagmentFilter[tabName]?.ToDate === "" ? "" : new Date(OrderManagmentFilter[tabName]?.ToDate)}
                                            minDate={startDate}
                                            maxDate={endDate}
                                            onChange={(date) => DateEndGet(date, tabName)}
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Enter To Date"
                                            isClearable={OrderManagmentFilter[tabName]?.ToDate !== "" ? true : false}
                                            name="ToDate"
                                            autoComplete="off"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </React.Fragment>)}

                        {/* ORDER STEPS FILTERS  */}
                        {AllOrderStatusAllow && (<div className="col-md-3" style={{ width: "17%", paddingRight: "0px" }}>
                            <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter[tabName]?.SelectOrderType} onChange={(e) => selectOrderTypeFunction(e, tabName)}>
                                <option value="OPEN,SO_HALF_APPROVAL,SO_APPROVED,WH_PENDING,WH_APPROVED,AC_APPROVED,ST_APPROVED,HALF_DISPATCH,DISPATCHED,DELIVERED,CANCELLED,REJECTED,RETURN,REPLACE,RETURN_REQUEST,CANCELLED_REQUEST,REPLACE_REQUEST">All</option>
                                <option value="OPEN,SO_HALF_APPROVAL">So Approval</option>
                                <option value="SO_APPROVED">Warehouse Approval</option>
                                <option value="WH_PENDING">Warehouse Pending</option>
                                <option value="AC_APPROVED,WH_APPROVED">Pending For Store</option>
                                <option value="ST_APPROVED,HALF_DISPATCH">Pending For Dispatch</option>
                                <option value="DISPATCHED">Pending For Delivery</option>
                                <option value="DELIVERED">Order Delivered</option>
                                <option value="REJECTED">Rejected</option>
                                <option value="CANCELLED,CANCELLED_REQUEST">Cancellation</option>
                                <option value="RETURN,REPLACE,RETURN_REQUEST,RETURN_IN_TRANSIT,REPLACE_REQUEST">Return And Replace</option>
                            </select>
                        </div>)}

                        {/* WAREHOUSE FILTER */}
                        {isAllowWarehouse &&(<div className="col-md-3" style={{ width: "19%", paddingRight: "0px" }}>
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    value={OrderManagmentFilter[tabName]?.WarehouseName}
                                    onChange={(e) => WareHouseSelect(e, tabName)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </div>)}
                        
                        {/* SELECT REGION */}
                        <div className="col-md-3" style={{ width: "13%",paddingRight: "0px" }}>
                            <select 
                                className="form-control activestatusselet pe-4" 
                                id="columnfullwith" 
                                value={OrderManagmentFilter[tabName]?.SelectRegion} 
                                onChange={(e) => selectRegionFunction(e, tabName)}
                            >
                                <option value="">Select Region</option>
                                {Regionlistall?.filter((elz) => elz?.is_active)?.map((elm, index) => {
                                    return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                                })}
                            </select>
                        </div>

                        {/* EXPORT BUTTON */}
                        <div className="col-md-3" style={{ width: "auto", paddingRight: "0px" }}>
                            <button 
                                className="btn exportbtn" 
                                id="exportbtn" 
                                disabled={OrderManagmentFilter[tabName]?.ToDate === "" || OrderManagmentFilter[tabName]?.FromDate === ""} 
                                style={{ width: "auto", marginRight: "0px" }} 
                                onClick={() => CommonExport("order_list", { "FromDate": OrderManagmentFilter[tabName]?.FromDate, "ToDate": OrderManagmentFilter[tabName]?.ToDate, "FromInv": null, "ToInv": null, "status": OrderManagmentFilter[tabName]?.SelectOrderType, "region_id": OrderManagmentFilter[tabName]?.SelectRegion ,"search": OrderManagmentFilter[tabName]?.UserSearch.trim(), "order_type": OrderManagmentFilter[tabName]?.orderSource })}
                            >
                                <i className="bi bi-filetype-xlsx"></i>
                                Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-header-New">
                {/* TABLE LISTING SEARCH */}
                {searchdisable && (<div className={`searchBoxwithbtn ${CommonSearchUpdate !== ""?"search":""}`}>
                    <input 
                        type="text" 
                        className="form-control" 
                        value={CommonSearchUpdate} 
                        onKeyDown={(e) => {onEnterSearch(e, tabName); EmptySpaceFieldValid(e)}} 
                        onChange={(e) => SearchMainOnchange(e.target.value, tabName)} 
                        placeholder={`Search ...`}
                    />
                    <Button className="searchbtn" onClick={() => OnEnterSearch(tabName)}>
                        <Image src={SearchIcon} alt="search_btn" />
                    </Button>
                    {CommonSearchUpdate !== "" && (
                        <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                        Clear the search by using the delete key.
                    </Tooltip>}
                    >
                        <i className="bi bi-x-circle-fill searchclear" onClick={() => ClearSearchFiled(tabName)}></i>
                    </OverlayTrigger>)}
                    
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 100 }}
                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                        Search using {searchType ? `(${searchType})`:""}
                    </Tooltip>}
                    >
                        <i className="searchInfo bi bi-info-circle-fill"></i>
                    </OverlayTrigger>
                </div>)}
                <div className="card-header-right"> 
                    {/* BUNDLE ORDER CHECKBOX */}
                    <div className="order_bunddle_checkboxes">
                        <Form.Label>
                            <Form.Check
                                type="checkbox"
                                checked={OrderManagmentFilter[tabName]?.is_bundle_orders}
                                onChange={(e) => OnChangeBundleOrder(e, tabName)}
                            />
                            Bundle Orders
                        </Form.Label>
                    </div>

                    {/* ODI COURIER PIN ORDER SELECT */}
                    {isOdiFilterAllow && (<div className="order_bunddle_seelct order_ODA_bunddle_seelct">
                        <DropdownButton 
                            id="dropdown-basic-button" 
                            className={"order_bunlde_active_ ODA_order_active_" + (OrderManagmentFilter[tabName]?.is_odi_courier_pin === undefined ? optionOrdersBundles[0]?.label?.replaceAll(" ", "_") : OrderManagmentFilter[tabName]?.is_odi_courier_pin?.label?.replaceAll(" ", "_"))}
                            title={OrderManagmentFilter[tabName]?.is_odi_courier_pin === undefined ? optionOrdersBundles[0]?.label : OrderManagmentFilter[tabName]?.is_odi_courier_pin?.label}
                        >
                            {optionODI_Courier_Pin_Order?.map((elm, index) => {
                                return(<Dropdown.Item className={elm.label?.replaceAll(" ", "_")} key={index} onClick={() => OnChangeIsODICourierPincode(elm, tabName)}>
                                    <span style={{ backgroundColor: elm?.label === "All Orders" ? "#eee" : `${elm?.color}` }}></span>
                                    {elm?.label}
                                </Dropdown.Item>)
                            })}
                        </DropdownButton>
                    </div>)}

                    {/* ORDER SOURCE SELECT */}
                    <select 
                        className="form-control activestatusselet pe-4" 
                        id="FranchiseUserReportWidthNew" 
                        value={OrderManagmentFilter[tabName]?.OrderSource} 
                        onChange={(e) => OnChangeOrderSource(e.target.value, tabName)}
                    >
                        <option value="">All</option>
                        <option value="FO">Dealer</option>
                        <option value="CO">Sale</option>
                        <option value="WO"> Internal Warehouse to Warehouse</option>
                        <option value="AO">Amazon, Website, Flipkart</option>
                    </select>

                    {/* SELECT TABLE LIMIT */}
                    <select 
                        className="form-control userLimitselect" 
                        value={GetuserLimit()} 
                        onChange={(e) => SelectLimitFct(e, tabName)}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                    </select>

                    {/* COMMON BUTTON */}
                    {buttonvisible && (<button className="btn head_cmn_btn" style={{ marginLeft: filterType === "Users" ? "10px" : "0px" }} onClick={ButtonSet?.function}>
                        <i className={ButtonSet?.buttontitle === "Return & Replace" ? "bi bi-arrow-return-right" : "bi bi-plus-circle"}></i>
                        {ButtonSet?.buttontitle}
                    </button>)}
                </div>
            </div>
        </div>)
}

export default MainOrderDataTableFilter;