/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SPARES_CAMPATIBILITY_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios, errorCreateSparesCampatibilityList } from "../../../redux/actions/adminActions";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import CommonScrollBar from "../../Common/CommonScrollBar";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";
import DeleteIcon from "../../../assets/img/delete_icon.svg";

const CreateSparesCompatibility = (props) => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ PrimaryItemCode, setPrimaryItemCode ] = useState("");
    const [ CompatibleItemCode, setCompatibleItemCode ] = useState("");
    const [ CompatibleItemName, setCompatibleItemName ] = useState("");
    const [ CategoryState, setCategoryState ] = useState({
        CategoryType: ""
    });

    const { productlistall, ClearFormSet, sparescampatibiityList, errorSparesCampatibiityList,device_id } = useSelector((state) => state.adminReducers);
    const PrimaryItemCodeOptions = productlistall?.filter((elm) => elm?.is_spare === true && (elm?.erp_id !== null && elm?.erp_id !== ""))?.map((item, index)=>({...item, key:item.id, value:item?.id , label: `${item?.product_name} - (${item?.erp_id})` }));
    const CompatibleItemCodeOptions = productlistall?.filter((elm) => elm?.is_bike === true && (elm?.erp_id !== null && elm?.erp_id !== ""))?.map((item, index)=>({...item, key:item.id, value:item?.id , label: `${item?.product_name} - (${item?.erp_id})` }));

    // ERROR STATE
    const [ errorPrimaryItemCode, seterrorPrimaryItemCode ] = useState("");
    const [ errorCompatibleItemCode, seterrorCompatibleItemCode ] = useState("");
    const [ errorCompatibleItemName, seterrorCompatibleItemName ] = useState("");
    const [ errorCategoryType, seterrorCategoryType ] = useState("");
    const { roomId } = useParams();
    const CurrentData_ = sparescampatibiityList?.list?.filter((elm) => elm?.id === roomId)[0];

    // GET ALL PRODUCT LIST
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "product_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    const UpdatePrimaryItemCodeOptions = PrimaryItemCodeOptions?.filter((elm) => elm?.erp_id === CurrentData_?.primary_item_code)[0];
    const UpdateCompatibleItemCodeOptions = CompatibleItemCodeOptions?.filter((elm) => elm?.erp_id === CurrentData_?.compatible_item_code)[0];

    useEffect(() => {
        if (CurrentData_ && UpdatePrimaryItemCodeOptions !== undefined && UpdateCompatibleItemCodeOptions !== undefined) {
            if(LocationRoute === "edit-spares-compatibility") {
                setTimeout(() => {
                    setPrimaryItemCode(UpdatePrimaryItemCodeOptions);
                    setCompatibleItemCode(UpdateCompatibleItemCodeOptions);
                
                    setCategoryState({
                        ...CategoryState,
                        CategoryType: CurrentData_?.type,
                    });
                }, 500);
            };
        };
    }, [ CurrentData_, (UpdatePrimaryItemCodeOptions !== undefined, UpdateCompatibleItemCodeOptions !== undefined) ]);

    // SELECT CATEGORY TYPE
    const SelectCategoryType = (value) => {
        if(value !== "") {
            setCategoryState({...CategoryState, CategoryType: value});
            CheckValid(value === null ? "" : value, {type: 'CategoryType', error: seterrorCategoryType});
        } else {
            setCategoryState({...CategoryState, CategoryType: value});
            CheckValid(value === null ? "" : value, {type: 'CategoryType', error: seterrorCategoryType});
        };
    };

    useEffect(() => {
        if(PrimaryItemCode !== "" && CompatibleItemCode !== "") {
            let FinalCompatibleItemName;
            if(CompatibleItemCode?.product_name?.includes("-")) {
                let CurrentCompatibaleName = CompatibleItemCode?.product_name?.split("-");
                CurrentCompatibaleName?.pop();
                CurrentCompatibaleName[CurrentCompatibaleName.length - 1] = CurrentCompatibaleName[CurrentCompatibaleName.length - 1].trim();
                FinalCompatibleItemName = CurrentCompatibaleName?.toString()?.replaceAll(" ,","") + "-" + PrimaryItemCode?.product_name; 
            } else {
                FinalCompatibleItemName = CompatibleItemCode?.product_name + "-" + PrimaryItemCode?.product_name; 
            };
            setCompatibleItemName(FinalCompatibleItemName);
            seterrorCompatibleItemName("");
        };
    }, [ PrimaryItemCode, CompatibleItemCode ]);

    const [ InitialSpareItems, setInitialSpareItems ] = useState([]);
    let varInitialSpareItems = [...InitialSpareItems];

    useEffect(() => {
        if(errorSparesCampatibiityList?.length > 0) {
            setInitialSpareItems(errorSparesCampatibiityList);
        }
    }, [ errorSparesCampatibiityList ]);

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    // CREATE COMAPTIBILITY
    const createCompatibility = () => {
        if(PrimaryItemCode !== "" &&  CompatibleItemCode !== "" && 
            CompatibleItemName.trim() !== "" && CategoryState?.CategoryType !== ""
        ) {
            if(LocationRoute === "edit-spares-compatibility") {
                CallAPISparesCompatibilty("edit");
            } else {
                let obj = {
                    ui_id: generateRandomId(),
                    product_id: PrimaryItemCode?.id,
                    primary_item_code : PrimaryItemCode?.erp_id, 
                    primary_item_name : PrimaryItemCode?.product_name,
                    primary_item_id : PrimaryItemCode?.id,
                    compatible_item_code : CompatibleItemCode?.erp_id,
                    compatible_item_name : CompatibleItemName,
                    compatible_item_id : CompatibleItemCode?.id, 
                    category_type : CategoryState?.CategoryType
                };
                varInitialSpareItems?.push(obj);
                setInitialSpareItems(varInitialSpareItems);
                FormClear();
            };
        } else {
            CheckValid(PrimaryItemCode, {type: 'PrimaryItemCode', error: seterrorPrimaryItemCode});
            CheckValid(CompatibleItemCode, {type: 'CompatibleItemCode', error: seterrorCompatibleItemCode});
            CheckValid(CompatibleItemName.trim(), {type: 'CompatibleItemName', error: seterrorCompatibleItemName});
            CheckValid(CategoryState?.CategoryType, {type: 'CategoryType', error: seterrorCategoryType});
        };
    };

    // MERGE WITH PRIMARY ITEM CODE
    const mergeSpareItems = InitialSpareItems?.reduce((acc, item) => {
        const key = item.primary_item_code;
        const existing = acc.find(obj => obj.primary_item_code === key);
        
        if (existing) {
            existing.items.push(item);
        } else {
            acc.push({
                primary_item_code: key,
                items: [item]
            });
        }
        return acc;
    }, []);

    // FORM CLEAR FUNCTION
    const FormClear = () => {
        setCategoryState({
            ...CategoryState,
            CategoryType: ""
        });
        setPrimaryItemCode("");
        setCompatibleItemCode("");
        setCompatibleItemName("");
    };
        
    // CALLING ADD & UPDATE API
    const CallAPISparesCompatibilty = (url) => {
        if(url === "edit") {
            Dispatch(ClearFormSetFutios({
                url: "spare_item_edit",
                action: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_edit",
                "request" : { 
                    "spare_id": CurrentData_?.id,
                    "product_id": PrimaryItemCode?.id,
                    "primary_item_code" : PrimaryItemCode?.erp_id, 
                    "primary_item_name" : PrimaryItemCode?.product_name,
                    "compatible_item_code" : CompatibleItemCode?.erp_id,
                    "compatible_item_name" : CompatibleItemName, 
                    "category_type" : CategoryState?.CategoryType
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            let mergedItems = mergeSpareItems.flatMap(obj => obj.items);
            const UpdatemergedItems = mergedItems?.map((elm) => {
                return{
                    ui_id: elm?.ui_id,
                    product_id: elm?.product_id,
                    primary_item_code: elm?.primary_item_code,
                    primary_item_name: elm?.primary_item_name,
                    compatible_item_code: elm?.compatible_item_code,
                    compatible_item_name: elm?.compatible_item_name,
                    compatible_item_id: elm?.compatible_item_id,
                    category_type: elm?.category_type,
                }
            });
            

            Dispatch(ClearFormSetFutios({
                url: "spare_item_add",
                action: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_add",
                "request" : { 
                    "spare_items": UpdatemergedItems,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "spare_item_add" || ClearFormSet?.url === "spare_item_edit")) {
            Navigate(SPARES_CAMPATIBILITY_URL);
        } else {
            // setInitialSpareItems([]);
        };
    }, [ ClearFormSet ]);

    // OPTIONS PRIMARY ITEM CODE & COMPATABLE ITEM CODE UPDATE WHEN SELECTED
    const optionSchemeListUpdate = (data, url) => {
        const schemeCodeExit = InitialSpareItems?.map((elm) => elm?.[url]);
        const schemeList = data?.filter((elmz) => !schemeCodeExit.includes(elmz?.id));
        return schemeList;
    };

    // DELETE FUNCTION
    const DeleteCompatible = (delete_id) => {
        const DeleteCompList = InitialSpareItems?.map((elm) => {
            if(elm?.ui_id !== delete_id) {
                return elm;
            }
        })?.filter((elm) => elm !== undefined);
        setInitialSpareItems(DeleteCompList);
    };

    // DELETE ALL SPARES COMPATIBILITY
    const delete_existed_items = () => {
        Dispatch(errorCreateSparesCampatibilityList([]));
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={SPARES_CAMPATIBILITY_URL} className="btn btn-link backbtn" onClick={() => delete_existed_items()}><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <CommonScrollBar className={"ScrollbarsSidebarHide"} height={{ height: "calc(100vh - 135px)"}}>
            <div className={LocationRoute === "edit-spares-compatibility" ? "formloginset tabsWrappers" : "formloginset"}>
                <div className="row">
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <div className="mb-3" >
                            <label className="form-label">Primary Item Code<span className="mendatory_star">*</span></label>
                            <CommonReactSelectMenu 
                                label={"primary item code"}
                                options={PrimaryItemCodeOptions}
                                selectValue={PrimaryItemCode} 
                                setSelectValue={setPrimaryItemCode} 
                                dependantState={{
                                    allow: true,
                                    statType: "clear_state",
                                    stateFunction: setCompatibleItemName
                                }} 
                                isMendatory={true}
                                errorState={errorPrimaryItemCode} 
                                setErrorState={seterrorPrimaryItemCode}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </div>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <div className="mb-3" >
                            <label className="form-label">Compatible Item Code<span className="mendatory_star">*</span></label>
                            <CommonReactSelectMenu 
                                label={"compatible item code"}
                                options={optionSchemeListUpdate(CompatibleItemCodeOptions, "compatible_item_id")}
                                selectValue={CompatibleItemCode} 
                                setSelectValue={setCompatibleItemCode} 
                                dependantState={{
                                    allow: true,
                                    statType: "clear_state",
                                    stateFunction: setCompatibleItemName
                                }} 
                                isMendatory={true}
                                errorState={errorCompatibleItemCode} 
                                setErrorState={seterrorCompatibleItemCode}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </div>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <div className="mb-3" >
                            <label className="form-label">Compatible Item Name<span className="mendatory_star">*</span></label>
                            <input 
                                type="text" 
                                className="form-control"
                                value={CompatibleItemName} 
                                onChange={(e) => setCompatibleItemName(e.target.value)}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CompatibleItemName', error: seterrorCompatibleItemName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Compatible Item Name"
                                disabled={true}
                            />
                            {errorCompatibleItemName !== "" && <span className="error_medotory">{errorCompatibleItemName}</span>}
                        </div>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <div className="mb-3" >
                            <label className="form-label">Category Type<span className="mendatory_star">*</span></label>
                            <select 
                                className="form-control"
                                value={CategoryState?.CategoryType}
                                onChange={(e) => SelectCategoryType(e.target.value)}
                            >
                                <option value={""}>Select Category Type</option>
                                <option value={"A"}>A</option>
                                <option value={"B"}>B</option>
                                <option value={"C"}>C</option>
                                <option value={"D"}>D</option>
                            </select>
                            {errorCategoryType !== "" && <span className="error_medotory">{errorCategoryType}</span>}
                        </div>
                    </div>
                    <div className={LocationRoute === "edit-spares-compatibility" ? "col-md-6" : "col-md-4"}>
                        <div className="mb-3" >
                            {LocationRoute === "create-spares-compatibility" && (<label className="form-label">&nbsp;</label>)}
                            <button 
                                id="createSpares"
                                className="btn formbtn" 
                                disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "spare_item_add" || ClearFormSet?.url === "spare_item_edit")}
                                onClick={() => createCompatibility()} 
                                variant="primary" 
                            >
                                {LocationRoute === "edit-spares-compatibility" ? "Update" : "Add"}
                            </button>
                            <button 
                                id="createSparesClear"
                                className="btn formbtn" 
                                onClick={() => FormClear()} 
                                variant="primary" 
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    {mergeSpareItems?.length > 0 && (<div className="col-md-4">
                        <div className="mb-3" style={{ float: "right", width: "auto" }}>
                            <label className="form-label">&nbsp;</label>
                            <ul className="statusof_spare_Items">
                                <li><span className="error"></span>Already Exits</li>
                                <li><span className="success"></span>New</li>
                            </ul>
                        </div>
                    </div>)}
                </div>
            </div>
            {errorSparesCampatibiityList?.length > 0 && (<div className="reassign_info" style={{ marginBottom: "15px" }}>
                <i className="bi bi-info-circle-fill"></i>&nbsp; If you want to add that spare, you should delete the existing spare item data row from the table below, then click the create button.
            </div>)}
            <div className="tableView gstreporttableadded">
                {mergeSpareItems?.length > 0  && (<React.Fragment>
                    <Table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>SNO.</th>
                                <th rowSpan={2}>Primary Item Code</th>
                                <th colSpan={4} style={{ textAlign: "center" }}>Compatible Items</th>
                                <th rowSpan={2}>Action</th>
                            </tr>
                            <tr>
                                <th style={{ borderLeft: "1px solid #dee2e6", borderTop: "1px solid #dee2e6" }}>Compatible Item Code</th>
                                <th style={{ borderTop: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }}>Compatible Item Name</th>
                                <th style={{ borderTop: "1px solid #dee2e6", borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }}>Category</th>
                                <th style={{ borderRight: "1px solid #dee2e6", borderTop: "1px solid #dee2e6" }}>Error</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mergeSpareItems?.map((elm, index) => {
                                return (<React.Fragment key={index}>
                                    {elm?.items?.map((elz, subIndex) => {
                                        return(<tr key={`${index}-${subIndex}`}>
                                            {subIndex === 0 ? (
                                                <>
                                                    <td rowSpan={elm?.items?.length}>{index + 1}</td>
                                                    <td rowSpan={elm?.items?.length}>{elm?.primary_item_code}</td>
                                                </>
                                            ) : null}
                                            <td className={elz?.error ? "error_spare_item" : ""}>{elz?.compatible_item_code}</td>
                                            <td className={elz?.error ? "error_spare_item" : ""}>{elz?.compatible_item_name}</td>
                                            <td className={elz?.error ? "error_spare_item" : ""}>{elz?.category_type}</td>
                                            <td className={elz?.error ? "error_spare_item" : ""}>
                                                {elz?.error !== undefined ? (<OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 100 }}
                                                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                                    {elz?.error}
                                                </Tooltip>}>
                                                    <i className="bi bi-eye" style={{ fontSize: "15px" }}></i>
                                                </OverlayTrigger>) : ("-")}
                                            </td>
                                            <td>
                                                <Button className="btn edittable" onClick={() => DeleteCompatible(elz?.ui_id)} style={{ marginLeft: "7px" }}>
                                                    <img src={DeleteIcon} alt="delete_icon" />
                                                </Button>
                                            </td>
                                        </tr>)
                                    })}
                                </React.Fragment>);
                            })}
                        </tbody>
                    </Table>
                    <div className="bottom_button_design">
                        <button 
                            className="btn formbtn" 
                            onClick={() => CallAPISparesCompatibilty("add")} 
                            variant="primary" 
                        >
                            Create
                        </button>
                    </div>
                </React.Fragment>)}
            </div>
        </CommonScrollBar>
    </section>)
}

export default CreateSparesCompatibility;