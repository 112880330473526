/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { CITIES_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";

const AddCities = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        CityName: "",
        Status: true
    });
    const [ State, setState ] = useState("");
    const roomId = useParams()?.id;
    const { ClearFormSet, stateList, device_id, cityList } = useSelector((state) => state.adminReducers);
    const CurrentData_ = cityList?.list?.filter((elm) => elm?.id === roomId )[0];
    const optionsState = stateList?.list?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.state_name }));
    // error state
    const [ errorCityName, seterrorCityName ] = useState("");
    const [ errorState, seterrorState ] = useState("");


    useEffect(() => { 
        if(LocationRoute === "edit-cities") {
            const data = optionsState?.filter((eml)=>CurrentData_?.state_id === eml?.value)
            setApplicationState({
                ...ApplicationState,
                CityName: CurrentData_?.district_name,
                State: data[0],
                Status: CurrentData_?.is_active,
            });
            setState(data[0]);
        };
    }, [ CurrentData_ ])

    // create and edit city
    const CreateBrand = () => {
        if(ApplicationState?.CityName?.trim() !== "" && State !== "") {
            if(LocationRoute === "edit-cities") {
                Dispatch(ClearFormSetFutios({
                    url: "district_edit",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "district_edit",
                    "request" : { 
                        "district_id" : CurrentData_?.id,
                        "district_name" : ApplicationState?.CityName?.trim(), 
                        "state_id": State?.value,
                        "is_active" : ApplicationState?.Status
                    },
                "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(ClearFormSetFutios({
                    url: "district_add",
                    action: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "district_add",
                    "request" : { 
                        "district_name" : ApplicationState?.CityName.trim(),
                        "state_id": State?.value,
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };

                wsSend_request(websocket, param);
            };
        } else {
            CheckValid(ApplicationState?.CityName?.trim(), {type: 'CityName', error: seterrorCityName});
            CheckValid(State?.trim(), {type: 'StateName', error: seterrorState});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "district_add" || ClearFormSet?.url === "district_edit")) {
            Navigate(CITIES_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={CITIES_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>City Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.CityName} 
                                onChange={(e) => setApplicationState({...ApplicationState, CityName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CityName', error: seterrorCityName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter City Name" 
                                disabled={UploadFileLoader}
                            />
                            {errorCityName !== "" &&<span className="error_medotory">{errorCityName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Select State<span className="mendatory_star">*</span></Form.Label>
                            <CommonReactSelectMenu 
                                label={"state"}
                                options={optionsState} 
                                selectValue={State} 
                                setSelectValue={setState} 
                                dependantState={{
                                    allow: false,
                                }} 
                                isMendatory={true}
                                errorState={errorState} 
                                setErrorState={seterrorState}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <CommonToggle 
                            isBadgeAllow={true}
                            badgeTitle={"active_inactive"}
                            valueToggle={ApplicationState?.Status}
                            setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                            name={"Status"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button 
                            className="formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "district_add" || ClearFormSet?.url === "district_edit")}
                            onClick={() => CreateBrand()} 
                            variant="primary" 
                        >
                            {LocationRoute == "edit-cities"?"Update":"Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddCities;