const CancelStageStatus = (status) => {
    if(status === "SO_APPROVED") {
        return "Warehouse Approval";
    } else if(status === "OPEN") {
        return "SO Approvals";
    } else if(status === "WH_APPROVED") {
        return "Pending For Accounts";
    } else if(status === "AC_APPROVED") {
        return "Pending For Stores";
    } else if(status === "DISPATCHED") {
        return "Pending For Delivery";
    } else if(status === "ST_APPROVED" || status === "HALF_DISPATCH") {
        return "Pending For Dispatch";
    } else {
        return "-";
    }
}
 
export default CancelStageStatus;