import React from "react";
import LoadingImage from "../../assets/img/modified-bicycle-unscreen.gif";
import { Image } from "react-bootstrap";

const CommonLoader = () => {
    return(<div className="commonLoaderwarap">
        <div className="commonLoaderwarapBox">
            <Image src={LoadingImage} />
            <span>Please Wait....</span>
        </div>
    </div>)
}

export default CommonLoader;