

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonTable from "../../Common/CommonTable";
import PaymentDateTableFilter from "../../Common/PaymentDateTableFilter";

const DebitCreditNoteReport = (props) => {
    const Dispatch = useDispatch();
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const { device_id, ClearFormSet, DebitCreditNoteReportList } = useSelector((state) => state.adminReducers);
    const [Franchise, setFranchise] = useState("");

    // get all franchise list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "franchise_list",
            "request": {
                "status": true,
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            callFilterData()
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

    useEffect(() => {
        callFilterData();
    }, [currentPage, userLimit, FromDate, ToDate, Franchise]);

    const callFilterData = () => {
        let param = {
            "transmit": "broadcast",
            "url": "dncn_list",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "from_date": null,
                "to_date": null,
                "franchise_id": Franchise?.value
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'franchise_id', label: 'Franchise Code', type: "label_get_from_list_summary_details", filterFrom: { list: "franchise", label: "franchise_code" }, sort: "", isCollapsable: false },
        { key: 'franchise_id', label: 'Name', type: "label_get_from_list", filterFrom: { list: "franchise", label: "customer_name" }, sort: "", isCollapsable: false },
        { key: 'credit_amt', label: 'Credit Amount', type: "label_price", sort: "", isCollapsable: false },
        { key: 'debit_amt', label: 'Debit Amount', type: "label_price", sort: "", isCollapsable: false },
        { key: 'deposit_date', label: 'Credit/Debit Date', type: "date", sort: "", isCollapsable: false },
        { key: 'author', label: 'Adjusted By', type: "label_get_from_list", filterFrom: { list: "user", label: "full_name" }, sort: "", isCollapsable: true },
        { key: 'reason', label: 'Reasons', type: "label", sort: "", isCollapsable: true },
        { key: 'remarks', label: 'Remarks', type: "label", sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section">
        <PaymentDateTableFilter 
            MainColumns={{
                firstCol: 9,
                secondCol: 3,
            }}
            searchAllow={false}
            FromDate={FromDate}
            setFromDate={setFromDate}
            ToDate={ToDate}
            setToDate={setToDate}
            setCurrentPage={setCurrentPage}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            filterType={"Debit-credit-note-report"}
            exportBtn={{
                isAllow: true,
                api_url: "export_debit_credit_note_report"
            }}
            exportSmallBtn={{
                isAllow: false,
                api_url: ""
            }}
            FromDateStartDateFlag={true}
            franchiseFilter={true}
            Franchise={Franchise}
            setFranchise={setFranchise}
            ButtonSet={{
                buttontitle: "",
                type: "",
                navigate_url: "",
            }}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: parseInt(DebitCreditNoteReportList?.pagination?.total_records) === DebitCreditNoteReportList?.list?.length ? "calc(100vh - 140px)" : DebitCreditNoteReportList?.pagination?.total_records > 10 ? "calc(100vh - 178px)" : "calc(100vh - 140px)" }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={DebitCreditNoteReportList} // ACCOUNT LEDGER REPORT LIST
            Pagination={DebitCreditNoteReportList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: false,
                label: "edit",
                naviagte: ``
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isTotalAndGrand={{
                isShow: true,
                filterType: "Debit-credit-note-report",
                colSpan: 4,
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default DebitCreditNoteReport;

