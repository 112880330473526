/* eslint-disable */
import React, { useEffect, useState } from 'react'
import wsSend_request from '../../Api/ws/ws_request';
import GetCurrentTableSRNo from './GetCurrentTableSRNo';
import CommonDateTime from './CommonDateTime';
import MoreIcon from './../../assets/img/more_options.png';
import { Badge, Button, Collapse, FormControl, Image, Table } from 'react-bootstrap';
import { TimeSense, getColor } from './TimeSense';
import AmountNumberFormat from './AmountNumberFormat';
import CommonewModel from './CommonewModel';
import { useSelector } from 'react-redux';
import BrowserUniqueTabID from './BrowserUniqueTabID';

export default function ItemInvoiceCancel(props) {
    const [cancel_reason, setCancel_reason] = useState("");
    const { websocket, elm, device_id, activeTableDetails, setActiveTableDetails, ClearFormSet, handleShow, index, EInvoiceFailure } = props;
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const get_color = getColor(elm);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        cancel_reason:"",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    var tabID = BrowserUniqueTabID();
    
    // open order details modal
    const OrderDetailsModal = (code) => {
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request" : {
                "order_id" : code,
            },
            "DeviceId" : device_id,
            modal:true
        };
        wsSend_request(websocket, param);
    };
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            setShowModalNew({
                open: false,
                title: "",
                modalType: "",
                description: "",
                cancel_reason:"",
                Data: {}
            })
        };
    }, [ ClearFormSet ]);
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };
    const callInvoiceModal = (e, code) => {
        e.preventDefault();
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    }
    const onChange = (e) => {
        if (e.target.value === " ") {
            e.target.value = "";
        }
        setCancel_reason(e.target.value);
    }
    const setCallCancel = () => {
        if (cancel_reason != "") {
            const param = {
                "transmit": "broadcast",
                "url": "invoice_cancel",
                "request": {
                    "cancel_reason": cancel_reason,
                    cancel_type: "invoice",
                    invoice_no: elm?.invoice_no
                },
                "DeviceId": device_id,
            };
            wsSend_request(websocket, param);
        }
    }
    const callCancelOrder = (e) =>{
        e.preventDefault();
        if (cancel_reason != "") {
            setShowModalNew({
                ...showModalNew,
                open: true,
                title: "Confirmation",
                subtitle: "Order-Cancel",
                modalType: "Order-Cancel",
                description: "Are you sure to proceed for Cancel Invoice?",
                modalType: "not form",
                button: "Yes",
                Data: {}
            });
        }else{
        }
    }
    return (
        <React.Fragment>
            <tr >
                <td hidden style={{ width: '10px' }}>
                    <button
                        className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                        onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                        aria-controls="example-collapse-text"
                        aria-expanded={activeTableDetails === elm?.id ? true : false}
                    >
                        <Image src={MoreIcon} alt="more icon" />
                    </button>
                </td>
                <td>{GetCurrentTableSRNo(index, EInvoiceFailure?.pagination?.current_page, EInvoiceFailure?.pagination?.record_limit)}</td>
                <td>
                    {elm?.order_code ? elm?.order_code : "-"}
                </td>
                <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                <td>
                    <b className="tableBolanchor" onClick={() => OrderDetailsModal(elm?.order_id)} >{!elm?.invoice_no ? "-" : <span className="" onClick={(e) => callCancelOrder(e, elm?.id)}>{elm?.invoice_no}</span>}</b>
                </td>
                <td>{!elm?.invoice_no ? "-" : CommonDateTime(elm?.updated_at, "LL", "show")}</td>
                <td>
                    {elm?.status ? elm?.status : "-"}
                </td>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[12]?.Edit) && (<td>
                    <FormControl
                        as={"textarea"}
                        onChange={onChange}
                        value={cancel_reason}
                    />
                </td>)}
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[12]?.Edit) && (<td>
                    <button onClick={callCancelOrder} disabled={cancel_reason == "" ? true : false} className="btn exportbtn exportbtnCancel">Cancel</button>
                </td>)}
                {/* <td>-</td> */}

                {elm?.status === "CAN" ? (
                    <React.Fragment>
                        <td>
                            <Button className="btn edittable btn btn-primary" onClick={()=>handleShow(elm)}>
                                <i className="bi bi-eye"></i>
                            </Button>
                        </td>
                    </React.Fragment>
                ):(
                    <td>-</td>
                )}
                <td hidden>
                    {elm?.order_status === "DELIVERED" && (<Badge bg="danger">Inward completed</Badge>)}
                </td>
            </tr>
            <Collapse hidden in={activeTableDetails === elm?.id ? true : false}>
                <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                    <td colSpan="12" >
                        <Table bordered  className="table">
                            <tbody>
                                <tr>
                                    <th style={{ width: "20%" }}>Quantity</th>
                                    <td>{elm?.qty}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "20%" }}>Order Value</th>
                                    <td>{AmountNumberFormat(elm?.total)}</td>

                                </tr>
                                <tr>
                                    <th style={{ width: "20%" }}>SKU Type</th>
                                    <td>{elm?.category}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "20%" }}>Pending Since</th>
                                    {/* <td>{elm?.updated_at?.split(" ")[0] === elm?.created_at?.split(" ")[0] ? TimeSense(elm?.updated_at) : TimeSense(elm?.created_at)}</td> */}
                                    <td>{TimeSense(elm?.updated_at)}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "20%" }}>Invoice Date</th>
                                    <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "20%" }}>Criticality</th>
                                    <td>
                                        <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </td>
                </tr>
            </Collapse>
            {showModalNew?.open && (<CommonewModel callBack={setCallCancel} setShowModalNew={setShowModalNew} showModalNew={{...showModalNew, cancel_reason:""}}/>)}
        </React.Fragment>
    )
}
