/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import MainOrderTableFilterWithoutRedux from "../../Common/MainOrderTableFilterWithoutRedux";
import CommonTable from "../../Common/CommonTable";

const SOCancelled = (props) => {
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ orderSource, setOrderSource ] = useState("");
    const [ selectRegion, setSelectRegion ] = useState("");
    const [ selectWarehouse, setSelectWarehouse ] = useState("");
    const { device_id, CancelledOrderList, sortingfor, ClearFormSet, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // get all warehouse list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "warehouse_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get order_list all
    useEffect(() => {
        if (websocket) {
            SOCancelled();
        }
    }, [ websocket, userSearch, orderSource, selectWarehouse, selectRegion, userType, currentPage, userLimit, FromDate, ToDate, sortingfor ]);

    const SOCancelled = () => {
        let param = {
            "transmit": "broadcast",
            "url": "order_list",
            "request": {
                "status": "CANCELLED,CANCELLED_REQUEST",
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
                "from_date": null,
                "to_date": null,
                "order_type": orderSource,
                "region_id": selectRegion,
                "warehouse_id": selectWarehouse?.value,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            SOCancelled();
        };
    }, [ ClearFormSet ]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'order_code', label: 'Order Id.', type: "label", sort: "order_code", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "customer_identity", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "created_at", isCollapsable: false },
        { key: 'order_source', label: 'Order Source', type: "label", sort: "", isCollapsable: false },
        { key: 'customer_name', label: 'SHIP To Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'billing_id', label: 'BILL To Name', type: "label_get_from_franchise", sort: "billing_id", isCollapsable: false },
        { key: 'mobile', label: 'Contact', type: "label", sort: "mobile", isCollapsable: false },
        { key: 'qty', label: 'Quantity', type: "label", sort: "qty", isCollapsable: false },
        { key: 'total', label: 'Order Value', type: "label_day_ammount", sort: "total", isCollapsable: false },
        { key: 'cancel_stage', label: 'Rejection Stage', type: "label_cancel_status", sort: "cancel_stage", isCollapsable: false },
        { key: 'updated_at', label: 'Pending Since', type: "pending_since", sort: "updated_at", isCollapsable: false },
        { key: 'order_status', label: 'Status', type: "label_order_status", sort: "order_status", isCollapsable: true },
        { key: 'order_status', label: 'Add. Status', type: "label_Additional_Status", sort: "order_status", isCollapsable: true },
        { key: '', label: '', type: "Attachments_SoApprovals", sort: "", isCollapsable: true },
        { key: 'region_id', label: 'Region', type: "label_get_from_list", filterFrom: { list: "regionlist", label: "region_name" }, sort: "", isCollapsable: true },
    ];

    return (<section className="Main_section">
        <MainOrderTableFilterWithoutRedux 
            searchType={"Order Id, Name, Customer Code"}
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            callSelectSource={setOrderSource}
            orderSource={orderSource}
            isRegionAllow={true}
            selectRegion={selectRegion} 
            setSelectRegion={setSelectRegion}
            isWarehouseAllow={true}
            selectWarehouse={selectWarehouse} 
            setSelectWarehouse={setSelectWarehouse}
            isDateAllow={true}
            FromDate={FromDate} 
            setFromDate={setFromDate}
            ToDate={ToDate} 
            setToDate={setToDate}
            startDate={startDate} 
            setStartDate={setStartDate}
            endDate={endDate} 
            setEndDate={setEndDate}
            isExport={{
                isExportAllow: true,
                export_url: "order_list",
                isDisabled: ToDate === "" || FromDate === "",
            }}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(CancelledOrderList?.pagination?.total_records) === CancelledOrderList?.list?.length ? "calc(100vh - 196px)" : parseInt(CancelledOrderList?.pagination?.total_records) > 10 ? "calc(100vh - 235px)" : "calc(100vh - 196px)"
            }}
            srNumber={{
                clickable: true,
                events: "ORDERS_LOG"
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={CancelledOrderList} // ORDER LIST
            Pagination={CancelledOrderList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[10]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[10]?.Edit),
                label: "order_accept_reject",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default SOCancelled;