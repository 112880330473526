/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import Select from 'react-select';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import { SearchBillingPincodeList, SearchShippingPincodeList, ErrorMessageFromRedux, StartMainCommonLoader, TicketsVerfiyAPI } from "../../../redux/actions/adminActions";
import CheckValid from "../../Common/CheckValid";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import CommonewModel from "../../Common/CommonewModel";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import TicketsVerify from "../../Common/TicketsVerify";
import { toast } from "react-toastify";
import CommonLoader from "../../Common/CommonLoader";
import CommonScrollBar from "../../Common/CommonScrollBar";
import CommonAddressTextField from "../../Common/CommonAddressTextField";
import DeleteIcon from "../../../assets/img/delete_icon.svg";

const QuotationCreations = (props) => {
    const { RouteName, PermissionStockInwardModule } = props;
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ClearFormSet, selfInfo, AllDealerFranchiseTypeListAll, subCategorylistall, productlistall, sparescampatibiityAllList, ErrorMessageFromReddux, searchBillingPincodeList, searchShippingPincodeList, commonMainLoader, tickets_response, franchiseTypeListAll } = useSelector((state) => state.adminReducers);
    const [ sparesCampatProduct, setSparesCampatProduct ] = useState("");
    const [ selectProduct, setselectProduct ] = useState("");
    const [ selectSubCategory, setselectSubCategory ] = useState("");
    const [ selectCategory, setselectCategory ] = useState("");
    const [ AddQuantity, setAddQuantity ] = useState("");
    const [ AddPrice, setAddPrice ] = useState("");
    const [ optionsAllDealerFranchise, setoptionsAllDealerFranchise ] = useState([]);
    const optionsSparesListproductlistall = productlistall?.filter((elm) => elm?.is_active && elm?.is_spare).reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
    }, {});
    const optionsparescampatibiityAllList = sparescampatibiityAllList?.filter((elm) => elm?.product_id !== "None")?.filter((elm) => optionsSparesListproductlistall[elm?.product_id]?.is_spare)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.compatible_item_name }));
    const optionsProductsTransportation = productlistall?.filter((elm) => elm?.custom_is_transportation)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.product_name}));
    const [ ApplicationBillingState, setApplicationBillingState ] = useState({
        AddressType: "",
        Franchise: "",
        Name: "",
        Mobile: "",
        Email: "",
        PANCard: "",
        PINCode: "",
        GSTIN: "",
        Address: "",
        address2: "",
    });
    const [ ApplicationState, setApplicationState ] = useState({
        AddressType: "",
        MainFranchise: "",
        Franchise: "",
        Name: "",
        Mobile: "",
        Email: "",
        PINCode: "",
        PANCard: "",
        GSTIN: "",
        Address: "",
        address2: "",
    }); 
    const [ NewApplicationState, setNewApplicationState ] = useState({
        frame_number: "",
        ticket_number: "",
    });
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        Data: {}
    });
    const [ PreviewOrder, setPreviewOrder ] = useState(false);
    const [ selectFranchise, setselectFranchise ] = useState("");
    const [ SubParentDealerFranchiseList, setSubParentDealerFranchiseList ] = useState([]);
    const [ selectSaleType, setselectSaleType ] = useState("After Sales");
    const ParentDealerFranchiseList = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.customer_name}));
    let GetCurrentDealerFranchiseType = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === selectFranchise)[0];

    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const exceptThisCostSymbolsBox = ["e", "E", "+", "-"];
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const pinRegex = /^\d{6}$/; // eslint-disable-line
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // BILLING ADDRESS ERROR STATE
    const [ errorBillingFranchise, seterrorBillingFranchise ] = useState("");
    const [ errorBillingPINCode, seterrorBillingPINCode ] = useState("");
    const [ errorBillingName, seterrorBillingName ] = useState("");
    const [ errorBillingMobile, seterrorBillingMobile ] = useState("");
    const [ errorBillingEmail, seterrorBillingEmail ] = useState("");
    const [ errorBillingAddress, seterrorBillingAddress ] = useState("");
    const [ errorBillingAddress2, seterrorBillingAddress2 ] = useState("");
    const [ errorBillingGSTIN, seterrorBillingGSTIN ] = useState("");

    // DELIVERY ADDRESS ERROR STATE
    const [ errorFranchise, seterrorFranchise ] = useState("");
    const [ errorPINCode, seterrorPINCode ] = useState("");
    const [ errorName, seterrorName ] = useState("");
    const [ errorMobile, seterrorMobile ] = useState("");
    const [ errorEmail, seterrorEmail ] = useState("");
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorAddress2, seterrorAddress2 ] = useState("");
    const [ errorGSTIN, seterrorGSTIN ] = useState("");

    // NEW FILEDS ERROR STATE
    const [ errorticketNumber, seterrorticketNumber ] = useState("");

    // SAME ADDRESS CHECKBOXES STATES
    const [ SameAsBillingAddress, setSameAsBillingAddress ] = useState(false);

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // SELECT ADDRESS TYPE FUNCTION
    const SelectAddressTypeOnChange = (value, url) => {
        if(url === "billing") {
            setApplicationBillingState({
                ...ApplicationBillingState, 
                AddressType: value, 
                Franchise: "",
                Name: "",
                Mobile: "",
                Email: "",
                PINCode: "",
                PANCard: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            setApplicationState({
                ...ApplicationState,
                AddressType: "customer",
                MainFranchise: "",
                Franchise: "",
                Name: "",
                Mobile: "",
                Email: "",
                PINCode: "",
                PANCard: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            Dispatch(SearchBillingPincodeList({}));
            Dispatch(SearchShippingPincodeList({}));
        } else {
            setApplicationState({
                ...ApplicationState, 
                AddressType: value, 
                MainFranchise: "",
                Franchise: "",
                Name: "",
                Mobile: "",
                Email: "",
                PINCode: "",
                PANCard: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            Dispatch(SearchShippingPincodeList({}));
        };
        setSameAsBillingAddress(false);
        Dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
    };
    
    // pin code error get from redux
    useEffect(() => {
        if (ErrorMessageFromReddux?.API === "pin_code_search_billing" && ErrorMessageFromReddux?.Error !== "") {
            seterrorBillingPINCode(ErrorMessageFromReddux?.Error);
        };
        if (ErrorMessageFromReddux?.API === "pin_code_search_customer" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
        };
    }, [ErrorMessageFromReddux]);

    useEffect(() => {
        const SubParentDealerFranchiseList = AllDealerFranchiseTypeListAll?.list && AllDealerFranchiseTypeListAll?.list?.filter((elm) => {
            if(ApplicationBillingState?.AddressType === "customer") {
                if(elm?.parent_id === ApplicationState?.MainFranchise?.value || elm?.id === ApplicationState?.MainFranchise?.value) {
                    return elm;
                }; 
            } else {
                if(elm?.parent_id === ApplicationBillingState?.Franchise?.value) {
                    return elm;
                }; 
            };
        })?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.customer_name}));
        setSubParentDealerFranchiseList(SubParentDealerFranchiseList);
    }, [ AllDealerFranchiseTypeListAll, ApplicationBillingState, ApplicationState ]);

    const GetAvailableBalance = () => {
        const CurrentSelectedFranchaise = ParentDealerFranchiseList?.filter((elm) => elm?.id === selectFranchise?.id)[0];
        const franchiseTypeListAllCurrent = franchiseTypeListAll?.filter((elm) => elm?.id === CurrentSelectedFranchaise?.franchise_type_id)[0];
        let wallet = 0
        
        if(franchiseTypeListAllCurrent?.franchise_type_credit && franchiseTypeListAllCurrent?.franchise_type_credit !== "") {
            wallet = parseInt(franchiseTypeListAllCurrent?.franchise_type_credit);
        } 
        if(CurrentSelectedFranchaise?.credit_addon && CurrentSelectedFranchaise?.credit_addon !== "") {
            wallet = parseInt(CurrentSelectedFranchaise?.credit_addon) + wallet;
        } 
        if(CurrentSelectedFranchaise?.advance_amount && CurrentSelectedFranchaise?.advance_amount !== "") {
            wallet = wallet + parseInt(CurrentSelectedFranchaise?.advance_amount);

        };
        return AmountNumberFormat(wallet);
    };

    // Franchise select
    const FranchiseSelect = (value) => {
        if(value !== null) {
            setselectFranchise(value);
        } else {
            setselectFranchise("");
        }
    };

    useEffect(() => {
        if(selectFranchise) {
            setApplicationBillingState({
                ...ApplicationBillingState,
                Franchise: selectFranchise,
            });
        };
    }, [ selectFranchise ]);

    // BILLING & DELIVERY FRANCHISE SELECTIONS
    const FrameSelectOnChange = (value, url) => {
        if(url === "billing") {
            if(value !== null) {
                setApplicationBillingState({
                    ...ApplicationBillingState,
                    Franchise: value,
                });
                setApplicationState({
                    ...ApplicationState,
                    MainFranchise: "",
                    Name: "",
                    Mobile: "",
                    Franchise: "",
                    Email: "",
                    PINCode: "",
                    GSTIN: "",
                    Address: "",
                    address2: "",
                });
                Dispatch(SearchShippingPincodeList({}));
                Dispatch(ErrorMessageFromRedux({
                    API: "",
                    Error: "",
                }));
            } else {
                setSameAsBillingAddress(false);
                ClearFranchiseForm("billing");
            };
            CheckValid(value === null ? "" : value, {type: 'dealer_franchise', error: seterrorBillingFranchise});
        } else if(url === "dealer_main_delivery") {
            if(value !== null) {
                setApplicationState({
                    ...ApplicationState,
                    MainFranchise: value,
                    Name: "",
                    Mobile: "",
                    Franchise: "",
                    Email: "",
                    PINCode: "",
                    GSTIN: "",
                    Address: "",
                    address2: "",
                });
                Dispatch(SearchShippingPincodeList({}));
                Dispatch(ErrorMessageFromRedux({
                    API: "",
                    Error: "",
                }));
            } else {
                ClearFranchiseForm("delivery");
            };
            CheckValid(value === null ? "" : value, {type: 'dealer_franchise', error: seterrorBillingFranchise});
        } else {
            if(value !== null) {
                setApplicationState({
                    ...ApplicationState,
                    Franchise: value,
                });
            } else {
                ClearFranchiseForm("delivery");
            };
            CheckValid(value === null ? "" : value, {type: 'dealer_franchise', error: seterrorFranchise});
        };
    };

    useEffect(() => {
        if(AllDealerFranchiseTypeListAll?.list?.length > 0) {
            if(selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm') {
                setoptionsAllDealerFranchise(AllDealerFranchiseTypeListAll?.list?.filter((elm) => (elm?.parent_id === null && elm?.region_manager_id === selfInfo?.user?.id))?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.customer_name })));
            } else if(selfInfo?.user?.user_type === 'regional_sales_team_asm') {
                setoptionsAllDealerFranchise(AllDealerFranchiseTypeListAll?.list?.filter((elm) => (elm?.parent_id === null && elm?.asm_id === selfInfo?.user?.id))?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.customer_name })));
            } else {
                setoptionsAllDealerFranchise(AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null)?.map((item, index)=>({...item, key:item.id, value:item?.id , label:item?.customer_name })));
            };
        }
    }, [ AllDealerFranchiseTypeListAll ]);
    
    // BILLING ADDRESS DATA POPULATED
    useEffect(() => {
        if(ApplicationBillingState?.AddressType === "dealer") {
            if(ApplicationBillingState?.Franchise) {
                const { customer_name, mobile, email, gst_no, address, address2, pin_code_id } = ApplicationBillingState?.Franchise;
                const GetCurrentPincode = AllDealerFranchiseTypeListAll?.pin?.filter((elm) => elm?.id === pin_code_id)[0]?.pin_code;

                setApplicationBillingState({
                    ...ApplicationBillingState,
                    Name: customer_name,
                    Mobile: mobile,
                    Email: email,
                    PINCode: GetCurrentPincode,
                    GSTIN: gst_no === null ? "" : gst_no,
                    Address: (address === null && address === "") ? "" : address,
                    address2: (address2 === null && address2 === "") ? "" : address2,
                }); 

                if(GetCurrentPincode?.length === 6) {
                    Dispatch(StartMainCommonLoader({
                        url: "pin_code_search_billing",
                        loader: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "pin_code_search",
                        "request" : { 
                            "pin_code" : GetCurrentPincode, 
                        },
                        "request_of_diffrent": "pin_code_search_billing",
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(StartMainCommonLoader({
                        url: "pin_code_search_billing",
                        loader: false
                    }));
                };
                CheckValid(customer_name === null ? "" : customer_name, {type: 'Customername', error: seterrorBillingName});
                CheckValid(mobile === null ? "" : mobile, {type: 'phone', error: seterrorBillingMobile});
                CheckValid(GetCurrentPincode, {type: 'PINCode', error: seterrorBillingPINCode});
                CheckValid(email === null ? "" : email, {type: 'email', error: seterrorBillingEmail});
                CheckValid(address === null ? "" : address, {type: 'Address', error: seterrorBillingAddress});
                CheckValid(address2 === null ? "" : address2, {type: 'address2', error: seterrorBillingAddress2});
            };
        } else {
            ClearFranchiseForm("billing");
        };
    }, [ ApplicationBillingState?.AddressType, ApplicationBillingState?.Franchise ]);
    
    // DELIVERY ADDRESS DATA POPULATED
    useEffect(() => {
        if(ApplicationState?.AddressType === "dealer") {
            if(ApplicationState?.Franchise) {
                const { customer_name, mobile, email, gst_no, address, address2, pin_code_id } = ApplicationState?.Franchise;
                const GetCurrentPincode = AllDealerFranchiseTypeListAll?.pin?.filter((elm) => elm?.id === pin_code_id)[0]?.pin_code;

                setApplicationState({
                    ...ApplicationState,
                    Name: customer_name,
                    Mobile: mobile,
                    Email: email,
                    PINCode: GetCurrentPincode,
                    GSTIN: gst_no === null ? "" : gst_no,
                    Address: (address === null && address === "") ? "" : address,
                    address2: (address2 === null && address2 === "") ? "" : address2,
                }); 

                if(GetCurrentPincode?.length === 6) {
                    Dispatch(StartMainCommonLoader({
                        url: "pin_code_search_customer",
                        loader: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "pin_code_search",
                        "request" : { 
                            "pin_code" : GetCurrentPincode, 
                        },
                        "request_of_diffrent": "pin_code_search_customer",
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(StartMainCommonLoader({
                        url: "pin_code_search_customer",
                        loader: false
                    }));
                };

                CheckValid(customer_name === null ? "" : customer_name, {type: 'Customername', error: seterrorName});
                CheckValid(mobile === null ? "" : mobile, {type: 'phone', error: seterrorMobile});
                CheckValid(GetCurrentPincode, {type: 'PINCode', error: seterrorPINCode});
                CheckValid(email === null ? "" : email, {type: 'email', error: seterrorEmail});
                CheckValid(address === null ? "" : address, {type: 'Address', error: seterrorAddress});
                CheckValid(address2 === null ? "" : address2, {type: 'address2', error: seterrorAddress2});
            };
        } else {
            ClearFranchiseForm("delivery");
        };
    }, [ ApplicationState?.AddressType, ApplicationState?.Franchise ]);


    // BILLING ADDRESS ADDED TO THE DELIVERY ADDRESS
    useEffect(() => {
        if(SameAsBillingAddress) {
            if(ApplicationState?.AddressType === "customer") {
                const { Name, Mobile, Email, PINCode, GSTIN, Address, address2 } = ApplicationBillingState;
                setApplicationState({
                    ...ApplicationState,
                    Name: Name,
                    MainFranchise: "",
                    Mobile: Mobile,
                    Franchise: "",
                    Email: Email,
                    PINCode: PINCode,
                    GSTIN: GSTIN,
                    Address: Address,
                    address2: address2,
                });
                if(PINCode?.length === 6) {
                    Dispatch(StartMainCommonLoader({
                        url: "pin_code_search_customer",
                        loader: true
                    }));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "pin_code_search",
                        "request" : { 
                            "pin_code" : PINCode, 
                        },
                        "request_of_diffrent": "pin_code_search_customer",
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    Dispatch(StartMainCommonLoader({
                        url: "pin_code_search_customer",
                        loader: false
                    }));
                };

                CheckValid(Name, {type: 'Customername', error: seterrorName});
                CheckValid(Mobile, {type: 'phone', error: seterrorMobile});
                CheckValid(PINCode, {type: 'PINCode', error: seterrorPINCode});
                CheckValid(Email, {type: 'email', error: seterrorEmail});
                CheckValid(Address, {type: 'Address', error: seterrorAddress});
                CheckValid(address2 === null ? "" : address2, {type: 'address2', error: seterrorAddress2});
            };
        } else {
            setApplicationState({
                ...ApplicationState,
                Name: "",
                MainFranchise: "",
                Mobile: "",
                Franchise: "",
                Email: "",
                PINCode: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            Dispatch(SearchShippingPincodeList({}));
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    }, [ SameAsBillingAddress ])

    // CLEAR FORM WHEN FRANCHISE REMOVE COMMON FUNCTION
    const ClearFranchiseForm = (url) => {
        if(url === "billing") {
            setApplicationBillingState({
                ...ApplicationBillingState,
                Name: "",
                Mobile: "",
                Franchise: "",
                Email: "",
                PINCode: "",
                GSTIN: "",
                Address: "",
                address2: "",
            }); 
            Dispatch(SearchBillingPincodeList({}));
        } else {
            setApplicationState({
                ...ApplicationState,
                Name: "",
                MainFranchise: "",
                Mobile: "",
                Franchise: "",
                Email: "",
                PINCode: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            Dispatch(SearchShippingPincodeList({}));
        };
    };

    // get franchise_type_list all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit || PermissionsAccess?.AFSOrderManagement[4]?.View))  {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_type_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // GET ALL CATEGORY 
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit || PermissionsAccess?.AFSOrderManagement[4]?.View))  {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : true,
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // GET ALL SUB CATEGORY 
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit || PermissionsAccess?.AFSOrderManagement[4]?.View))  {
            let param = { 
                "transmit": "broadcast", 
                "url": "subcategory_list",
                "request" : { 
                    "status" : true, 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // GET ALL PRODUCT LIST
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit || PermissionsAccess?.AFSOrderManagement[4]?.View))  {
            let param = { 
                "transmit": "broadcast", 
                "url": "product_list",
                "request" : { 
                    "status" : true, 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit || PermissionsAccess?.AFSOrderManagement[4]?.View))  {
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_list",
                "request" : { 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : null,
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all franchise list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit || PermissionsAccess?.AFSOrderManagement[4]?.View))  {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : { 
                    "status" : true, 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);
    
    // Product select
    const ProductSparesSelect = (value) => {
        setSparesCampatProduct(value);
        if(value !== null) {
            const ProductCurrent = productlistall?.filter((elm) => elm?.id === value?.product_id)[0];
            const GetCurrentSubCategory = subCategorylistall?.filter((elm) => elm?.id === ProductCurrent?.sub_category_id)[0];
            setselectProduct(ProductCurrent);
            setselectSubCategory(GetCurrentSubCategory?.id);
            setselectCategory(GetCurrentSubCategory?.category_id);
        } else {
            setSparesCampatProduct("");
            setselectProduct("");
        };
    };

    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchPincode();
        };
    };

    // search pincode
    const SearchPincode = (url) => {
        if(url === "billing") {
            if(ApplicationState?.PINCode?.length === 6) {
                Dispatch(StartMainCommonLoader({
                    url: "pin_code_search_"+url,
                    loader: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "pin_code_search",
                    "request" : { 
                        "pin_code" : ApplicationState?.PINCode, 
                    },
                    "request_of_diffrent": "pin_code_search_"+url,
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(StartMainCommonLoader({
                    url: "pin_code_search_"+url,
                    loader: false
                }));
            };
        } else {
            if(ApplicationState?.PINCode?.length === 6) {
                Dispatch(StartMainCommonLoader({
                    url: "pin_code_search_"+url,
                    loader: true
                }));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "pin_code_search",
                    "request" : { 
                        "pin_code" : ApplicationState?.PINCode, 
                    },
                    "request_of_diffrent": "pin_code_search_"+url,
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                Dispatch(StartMainCommonLoader({
                    url: "pin_code_search_"+url,
                    loader: false
                }));
            };
        }
    };

    const PinCOdeSearchFunction = (e, url) => {
        if(url === "billing") {
            setApplicationBillingState({...ApplicationBillingState, PINCode: e.target.value});
        } else {
            setApplicationState({...ApplicationState, PINCode: e.target.value});
        };
        
        if (e.target.value.length === 6) {
            Dispatch(StartMainCommonLoader({
                url: "pin_code_search_"+url,
                loader: true
            }));
            let param = {
                "transmit": "broadcast",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
                "request_of_diffrent": "pin_code_search_"+url,
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        }; 
        if (e.target.value === "") {
            if(url === "billing") {
                Dispatch(SearchBillingPincodeList({}));
            } else {
                Dispatch(SearchShippingPincodeList({}));
            };
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        } else {
            Dispatch(StartMainCommonLoader({
                url: "pin_code_search_"+url,
                loader: false
            }));
        };
    };

    const [ ProductListOrder, setProductListOrder ] = useState([]);
    var ProductMergAarray = [...ProductListOrder];

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    const [ sparesProductWiseQtyMain, setsparesProductWiseQtyMain ] = useState([]);
    let sparesProductWiseQty = [...sparesProductWiseQtyMain];
    // add purchase order
    const PurchaseOrderAdd = () => {
        if(sparesCampatProduct !== "" && selectProduct !== "" && AddQuantity > 0) {
            let Obj = {
                id: generateRandomId(),
                spares_campat_item_code: sparesCampatProduct,
                compatible_item_name: sparesCampatProduct?.compatible_item_name,
                franchise: selectFranchise,
                product: selectProduct,
                sub_category: selectSubCategory,
                category: selectCategory,
                price: GetCurrentOrderRate({ quantity: parseInt(AddQuantity) }, selectProduct)?.price,
                sub_total: GetCurrentOrderRate({ quantity: parseInt(AddQuantity) }, selectProduct)?.sub_total,
                quantity: parseInt(AddQuantity),
            };

            let sparesProducts = {
                id: sparesCampatProduct.id,
                compatible_item_name: sparesCampatProduct.compatible_item_name,
                quantity: parseInt(AddQuantity),
            };
            sparesProductWiseQty.push(sparesProducts);

            let mergesparesItems = mergeAndUpdateSpares(sparesProductWiseQty)
            setsparesProductWiseQtyMain(mergesparesItems);

            if (GetCurrentDealerFranchiseType?.is_flexible_pricing) {
                Obj.amount = parseInt(AddRate);
            };

            ProductMergAarray?.push(Obj);
        
            let mergedArray = mergeAndUpdate(ProductMergAarray);
            // unique array convert

            const UpdateProductListOrder = mergedArray?.map((elm) => {
                const compatible_items = elm?.compatible_item_name?.split(",");
                
                const getSparesQty = (spare_qty) => {
                    const getCurrentSPres = mergesparesItems?.filter((elm) => elm?.compatible_item_name === spare_qty?.trim())[0];
                    return getCurrentSPres;
                };
        
                return {
                    ...elm,
                    compatible_items: compatible_items?.map((elmx, index) => {
                        return{
                            id: getSparesQty(elmx)?.id,
                            item_id: elm?.id,
                            compatible_item_name: elmx,
                            quantity: getSparesQty(elmx)?.quantity,
                        }
                    })
                }
            });

            setProductListOrder(UpdateProductListOrder);
            PurchaseOrderClear();
        } else {

        };
    };

    // merge array and add quantity
    function mergeAndUpdate(arr) {
        let mergedArray = [];
        arr.forEach(item => {
            // Check if the item already exists in the merged array
            const existingSparesItemIndex = mergedArray.findIndex(
                mergedItem => mergedItem?.spares_campat_item_code?.primary_item_code === item?.spares_campat_item_code?.primary_item_code
            );

            if (existingSparesItemIndex !== -1) {
                // If the item exists, update its quantity
                mergedArray[existingSparesItemIndex].quantity += item.quantity;

                const compatible_item_name_array = mergedArray[existingSparesItemIndex].compatible_item_name?.split(",")?.map((elm) => {
                    return elm?.trim();
                });

                if(!compatible_item_name_array.includes(item?.spares_campat_item_code?.compatible_item_name)) {
                    mergedArray[existingSparesItemIndex].compatible_item_name += ", "+item?.spares_campat_item_code?.compatible_item_name;
                };
            } else {
                // If the item doesn't exist, add it to the merged array
                mergedArray.push({...item});
            }
        });
    
        return mergedArray;
    };

    // merge spares array
    // merge spares array
    function mergeAndUpdateSpares(arr) {
        let mergedArray = [];
        arr.forEach(item => {
            // Check if the item already exists in the merged array
            const existingItemIndex = mergedArray.findIndex(
                mergedItem => mergedItem?.id === item?.id
            );
            
            if (existingItemIndex !== -1) {
                // If the item exists, update its quantity
                mergedArray[existingItemIndex].quantity += item.quantity;
            } else {
                // If the item doesn't exist, add it to the merged array
                mergedArray.push({...item});
            }
        });
    
        return mergedArray;
    };

    const GetPcCodeWiseItems = (data) => {
        const mergedData = data.reduce((acc, current) => {
            const existing = acc.find(item => item?.product?.product_code === current?.product?.product_code);
            
            if (existing) {
                // Merge quantities
                existing.quantity += current.quantity;
        
                // Merge compatible items
                current?.compatible_items?.forEach(newItem => {
                    const existingItem = existing.compatible_items.find(item => item.id === newItem.id);
                    if (existingItem) {
                        existingItem.quantity += newItem.quantity;
                    } else {
                        existing.compatible_items.push(newItem);
                    }
                });
            } else {
                // Add new item if not already present
                acc.push({ ...current });
            }
        
            return acc;
        }, []);

        return mergedData;
    };

    const PrviewOrderCarts = () => {
        let sparesItems = GetPcCodeWiseItems(ProductListOrder);
        let greaterRateOfproduct = sparesItems.reduce((max, current) => parseFloat(max?.product?.igst_rate) > parseFloat(current?.product?.igst_rate) ? max : current);
        let GreaterGstTransporationChargesProduct = optionsProductsTransportation?.filter((elm) => parseInt(elm?.igst_rate) === parseInt(greaterRateOfproduct?.product?.igst_rate))[0];

        if(GreaterGstTransporationChargesProduct !== undefined) {
            let Obj = {
                id: generateRandomId(),
                spares_campat_item_code: [],
                compatible_item_name: {},
                product: GreaterGstTransporationChargesProduct,
                sub_category: selectSubCategory,
                category: selectCategory,
                quantity: 1,
                price: GetCurrentOrderRate({ quantity: 1 }, GreaterGstTransporationChargesProduct)?.price,
                sub_total: GetCurrentOrderRate({ quantity: 1 }, GreaterGstTransporationChargesProduct)?.sub_total,
            };
            sparesItems?.push(Obj);
            setProductListOrder(sparesItems);
        };
        
        setPreviewOrder(!PreviewOrder);
    };

    // CONFIRM ORDER FUNCTION API INTEGRATIONS
    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });
    const CheckBillingMobileNumber = parsePhoneNumber(ApplicationBillingState?.Mobile?.toString(), { regionCode: CountryStates });

    const ConfirmOrder = () => {
        const ProductListOrderStateArray = [];
        const sparesItems = GetPcCodeWiseItems(ProductListOrder);
        sparesItems?.filter((elm, index) => {
            return  ProductListOrderStateArray?.push({
                product_id: elm?.product?.id,
                product_qty: elm?.quantity,
                is_demo: false,
                rate: elm?.price,
                amount: elm?.sub_total,
                idx: index + 1,
            });
        });

        if(ApplicationState?.Name.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.Address.trim() !== "" &&
           ApplicationBillingState?.Name.trim() !== "" && ApplicationBillingState?.Mobile.trim() !== "" && ApplicationBillingState?.Email.trim() !== "" && ApplicationBillingState?.PINCode.trim() !== "" && ApplicationBillingState?.Address.trim() !== ""
        )  {
            if(EmailRegex.test(ApplicationState?.Email.trim()) === true && CheckMobileNumber?.valid === true && pinRegex?.test(ApplicationState?.PINCode.trim()) === true &&
               EmailRegex.test(ApplicationBillingState?.Email.trim()) === true && CheckBillingMobileNumber?.valid === true && pinRegex?.test(ApplicationBillingState?.PINCode.trim()) === true
            ) {
                Dispatch(StartMainCommonLoader({
                    url: "quotation_add",
                    loader: true
                }));

                let order_type = "";
                if(ApplicationBillingState?.AddressType === "dealer") {
                    order_type = "FO";
                } else if(ApplicationBillingState?.AddressType === "customer") {
                    order_type = "CO";
                };

                let param = { 
                    "transmit": "broadcast", 
                    "url": "quotation_add",
                    "request" : { 
                        "quotation_type" : order_type, 
                        "ship_customer_name": ApplicationState?.Name.trim(),
                        "ship_mobile": ApplicationState?.Mobile.trim(),
                        "ship_email": ApplicationState?.Email.trim(),
                        "ship_pin_code_id": searchShippingPincodeList?.pin?.id,
                        "ship_pan_no": ApplicationState?.PANCard.trim(),
                        "ship_gstin_no": ApplicationState?.GSTIN.trim(),
                        "ship_address": ApplicationState?.Address.trim(),
                        "ship_address2": ApplicationState?.address2,
                        "items": ProductListOrderStateArray,
                    },
                    "DeviceId" : device_id
                };

                if(NewApplicationState?.frame_number !== "") {
                    param.request.frame_number = NewApplicationState?.frame_number;
                };

                if(NewApplicationState?.ticket_number !== "") {
                    param.request.ticket_number = NewApplicationState?.ticket_number
                };

                if(tickets_response?.status === "SUCCESS") {
                    param.request.zoho_mobile = tickets_response?.data?.cf?.cf_customer_contact_no === null ? (tickets_response?.data?.contact?.mobile || tickets_response?.data?.contact?.phone) : tickets_response?.data?.cf?.cf_customer_contact_no,
                    param.request.zoho_issue = tickets_response?.data?.subject,
                    param.request.zoho_customer_name = tickets_response?.data?.cf?.cf_customer_name === null ? ((tickets_response?.data?.contact?.firstName === null ? "" : tickets_response?.data?.contact?.firstName) + " " + (tickets_response?.data?.contact?.lastName === null ? "" : tickets_response?.data?.contact?.lastName)) : tickets_response?.data?.cf?.cf_customer_name
                    param.request.zoho_ticket_id = tickets_response?.data?.id,
                    param.request.zoho_error = null,
                    param.request.zoho_status = tickets_response?.status
                } else {
                    param.request.zoho_error = tickets_response?.data?.message,
                    param.request.zoho_status = tickets_response?.status
                };

                const CommonPrames = () => {
                    if(ApplicationBillingState?.AddressType === "customer" && ApplicationState?.AddressType === "dealer") {
                        param.request.shipping_id = ApplicationState?.Franchise?.value
                    };
                    param.request.bill_customer_name = ApplicationBillingState?.Name?.trim(),
                    param.request.bill_mobile = ApplicationBillingState?.Mobile?.trim(),
                    param.request.bill_email = ApplicationBillingState?.Email?.trim(),
                    param.request.bill_pin_code_id = searchBillingPincodeList?.pin?.id,
                    param.request.bill_gstin_no = ApplicationBillingState?.GSTIN.trim(),
                    param.request.bill_address = ApplicationBillingState?.Address?.trim(),
                    param.request.bill_address2 = ApplicationBillingState?.address2
                };

                if(ApplicationBillingState?.AddressType === "dealer" && ApplicationState?.AddressType === "dealer") {
                    param.request.billing_id = ApplicationBillingState?.Franchise?.value,
                    param.request.shipping_id = ApplicationState?.Franchise?.value
                } else if(ApplicationBillingState?.AddressType === "dealer" && ApplicationState?.AddressType === "customer") {
                    param.request.billing_id = ApplicationBillingState?.Franchise?.value
                } else if(ApplicationBillingState?.AddressType === "customer" && ApplicationState?.AddressType === "dealer") {
                    CommonPrames();
                } else if (ApplicationBillingState?.AddressType === "customer" && ApplicationState?.AddressType === "customer") {
                    CommonPrames();
                };

                wsSend_request(websocket, param);
            } else {
                // BILLING VALIDATIONS
                CheckValid(ApplicationBillingState?.Mobile.trim(), {type: 'phone', error: seterrorBillingMobile});
                CheckValid(ApplicationBillingState?.Email.trim(), {type: 'email', error: seterrorEmail });
                CheckValid(ApplicationBillingState?.PINCode.trim(), {type: 'PINCode', error: seterrorBillingPINCode});

                // DELIVERY VALIDATIONS
                CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
                CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});                
            };
        } else {
            // BILLING VALIDATIONS
            CheckValid(ApplicationBillingState?.Name.trim(), {type: 'Customername', error: seterrorBillingName});
            CheckValid(ApplicationBillingState?.Mobile.trim(), {type: 'phone', error: seterrorBillingMobile});
            CheckValid(ApplicationBillingState?.PINCode.trim(), {type: 'PINCode', error: seterrorBillingPINCode});
            CheckValid(ApplicationBillingState?.Email.trim(), {type: 'email', error: seterrorBillingEmail});
            CheckValid(ApplicationBillingState?.Address.trim(), {type: 'Address', error: seterrorBillingAddress});
            CheckValid(ApplicationBillingState?.address2 === null ? "" : ApplicationBillingState?.address2?.trim(), {type: 'address2', error: seterrorBillingAddress2});

            // DELIVERY VALIDATIONS
            CheckValid(ApplicationState?.Name.trim(), {type: 'Customername', error: seterrorName});
            CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
            CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
            CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail});
            CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
            CheckValid(ApplicationState?.address2 === null ? "" : ApplicationState?.address2?.trim(), {type: 'address2', error: seterrorAddress2});
        };
    };


    const OrderPurchaseFunct = () => {
        let transportationChargesRatting = ProductListOrder?.filter((elm) => elm?.product?.custom_is_transportation)[0]?.price;
        
        if(transportationChargesRatting !== "") {
            CommonFunctionOrderCreations();
        } else {
            toast.error("Please add the transportation charge rate.");
        };
    };

    // ORDER PURCHASE MODAL OPEN WHEN VALID FORM
    const CommonFunctionOrderCreations = () => {        
        const FinalCheckValid = (url) => {
            if(selectSaleType === "After Sales") {
                if(FieldsShippingValid && FieldsBillingValid)  {
                    if(FieldsMobileEmailPinShippingValid && FieldsMobileEmailPinBillingValid && CheckShippingBillingGST === "" ? true : CheckShippingBillingGST) {
                        if(Object?.keys(tickets_response)?.length > 0) {
                            seterrorticketNumber("");
                        };

                        if(NewApplicationState?.ticket_number !== "") {
                            if(Object?.keys(tickets_response)?.length > 0) {
                                setShowModalNew({
                                    ...showModalNew,
                                    open: open,
                                    title: "Confirmation",
                                    subtitle: "Order-Purchase",
                                    modalType: "Order-Purchase",
                                    description: "Are you sure you want to create a quotation?",
                                    modalType: "not form",
                                    button: "Yes",
                                    Data: {}
                                });
                            } else {
                                toast.error("Please verify the ticket number.");
                            };
                        } else {
                            setShowModalNew({
                                ...showModalNew,
                                open: open,
                                title: "Confirmation",
                                subtitle: "Order-Purchase",
                                modalType: "Order-Purchase",
                                description: "Are you sure you want to create a quotation?",
                                modalType: "not form",
                                button: "Yes",
                                Data: {}
                            });
                        };
                    } else {
                        // BILLING VALIDATIONS
                        CheckValid(ApplicationBillingState?.Mobile.trim(), {type: 'phone', error: seterrorBillingMobile});
                        CheckValid(ApplicationBillingState?.Email.trim(), {type: 'email', error: seterrorEmail });
                        CheckValid(ApplicationBillingState?.PINCode.trim(), {type: 'PINCode', error: seterrorBillingPINCode});
        
                        // DELIVERY VALIDATIONS
                        CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                        CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail });
                        CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
    
                        if(url === "billing_shipping_gst") {
                            if(GSTvalidations(ApplicationBillingState?.GSTIN, searchBillingPincodeList) && ApplicationBillingState?.GSTIN?.length === 15) {
                                seterrorBillingGSTIN("");
                            } else {
                                seterrorBillingGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchBillingPincodeList?.state?.gst_code?.length === 1 ? String(searchBillingPincodeList?.state?.gst_code).padStart(2, '0') : searchBillingPincodeList?.state?.gst_code}'.`)
                            };
                            if(GSTvalidations(ApplicationState?.GSTIN, searchShippingPincodeList) && ApplicationState?.GSTIN?.length === 15) {
                                seterrorGSTIN("");
                            } else {
                                seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchShippingPincodeList?.state?.gst_code?.length === 1 ? String(searchShippingPincodeList?.state?.gst_code).padStart(2, '0') : searchShippingPincodeList?.state?.gst_code}'.`)
                            };
                        } else if(url === "billing_gst") {
                            if(GSTvalidations(ApplicationBillingState?.GSTIN, searchBillingPincodeList) && ApplicationBillingState?.GSTIN?.length === 15) {
                                seterrorBillingGSTIN("");
                            } else {
                                seterrorBillingGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchBillingPincodeList?.state?.gst_code?.length === 1 ? String(searchBillingPincodeList?.state?.gst_code).padStart(2, '0') : searchBillingPincodeList?.state?.gst_code}'.`)
                            };
                        } else if(url === "shipping_gst") {
                            if(GSTvalidations(ApplicationState?.GSTIN, searchShippingPincodeList) && ApplicationState?.GSTIN?.length === 15) {
                                seterrorGSTIN("");
                            } else {
                                seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchShippingPincodeList?.state?.gst_code?.length === 1 ? String(searchShippingPincodeList?.state?.gst_code).padStart(2, '0') : searchShippingPincodeList?.state?.gst_code}'.`)
                            };
                        } else {
    
                        };
                    };
                } else {
                    // BILLING VALIDATIONS
                    CheckValid(ApplicationBillingState?.Name.trim(), {type: 'Customername', error: seterrorBillingName});
                    CheckValid(ApplicationBillingState?.Mobile.trim(), {type: 'phone', error: seterrorBillingMobile});
                    CheckValid(ApplicationBillingState?.PINCode.trim(), {type: 'PINCode', error: seterrorBillingPINCode});
                    CheckValid(ApplicationBillingState?.Email.trim(), {type: 'email', error: seterrorBillingEmail});
                    CheckValid(ApplicationBillingState?.Address.trim(), {type: 'Address', error: seterrorBillingAddress});
                    CheckValid(ApplicationBillingState?.address2 === null ? "" : ApplicationBillingState?.address2?.trim(), {type: 'address2', error: seterrorBillingAddress2});
                    
                    // DELIVERY VALIDATIONS
                    CheckValid(ApplicationState?.Name.trim(), {type: 'Customername', error: seterrorName});
                    CheckValid(ApplicationState?.Mobile.trim(), {type: 'phone', error: seterrorMobile});
                    CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                    CheckValid(ApplicationState?.Email.trim(), {type: 'email', error: seterrorEmail});
                    CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                    CheckValid(ApplicationState?.address2 === null ? "" : ApplicationState?.address2?.trim(), {type: 'address2', error: seterrorAddress2});
    
                    if(NewApplicationState?.ticket_number !== "" && Object?.keys(tickets_response)?.length === 0) {
                        seterrorticketNumber("Please verify the ticket number.");
                    };
    
                    if(url === "billing_shipping_gst") {
                        if(ApplicationBillingState?.GSTIN?.trim() !== "") {
                            if(searchBillingPincodeList?.state?.gst_code === undefined) {
                                seterrorBillingGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                            } else {
                                if(GSTvalidations(ApplicationBillingState?.GSTIN, searchBillingPincodeList) && ApplicationBillingState?.GSTIN?.length === 15) {
                                    seterrorBillingGSTIN("");
                                } else {
                                    seterrorBillingGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchBillingPincodeList?.state?.gst_code?.length === 1 ? String(searchBillingPincodeList?.state?.gst_code).padStart(2, '0') : searchBillingPincodeList?.state?.gst_code}'.`)
                                };
                            };
                        } else {
                            // seterrorBillingGSTIN("GSTIN cannot be empty");
                        };
                        if(ApplicationState?.GSTIN?.trim() !== "") {
                            if(searchShippingPincodeList?.state?.gst_code === undefined) {
                                seterrorGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                            } else {
                                if(GSTvalidations(ApplicationState?.GSTIN, searchShippingPincodeList) && ApplicationState?.GSTIN?.length === 15) {
                                    seterrorGSTIN("");
                                } else {
                                    seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchShippingPincodeList?.state?.gst_code?.length === 1 ? String(searchShippingPincodeList?.state?.gst_code).padStart(2, '0') : searchShippingPincodeList?.state?.gst_code}'.`)
                                };
                            };
                        } else {
                            // seterrorGSTIN("GSTIN cannot be empty");
                        };
                    } else if(url === "billing_gst") {
                        if(ApplicationBillingState?.GSTIN?.trim() !== "") {
                            if(searchBillingPincodeList?.state?.gst_code === undefined) {
                                seterrorBillingGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                            } else {
                                if(GSTvalidations(ApplicationBillingState?.GSTIN, searchBillingPincodeList) && ApplicationBillingState?.GSTIN?.length === 15) {
                                    seterrorBillingGSTIN("");
                                } else {
                                    seterrorBillingGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchBillingPincodeList?.state?.gst_code?.length === 1 ? String(searchBillingPincodeList?.state?.gst_code).padStart(2, '0') : searchBillingPincodeList?.state?.gst_code}'.`)
                                };
                            };
                        } else {
                            // seterrorBillingGSTIN("GSTIN cannot be empty");
                        };
                    } else if(url === "shipping_gst") {
                        if(ApplicationState?.GSTIN?.trim() !== "") {
                            if(searchShippingPincodeList?.state?.gst_code === undefined) {
                                seterrorGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                            } else {
                                if(GSTvalidations(ApplicationState?.GSTIN, searchShippingPincodeList) && ApplicationState?.GSTIN?.length === 15) {
                                    seterrorGSTIN("");
                                } else {
                                    seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchShippingPincodeList?.state?.gst_code?.length === 1 ? String(searchShippingPincodeList?.state?.gst_code).padStart(2, '0') : searchShippingPincodeList?.state?.gst_code}'.`)
                                };
                            };
                        } else {
                            // seterrorGSTIN("GSTIN cannot be empty");
                        };
                    } else {
    
                    };
                };
            } else { };
        };

        const GSTBillingShippingValid = ApplicationBillingState?.GSTIN?.trim() !== "" && ApplicationState?.GSTIN?.trim() !== "";
        const GSTBillingValid = ApplicationBillingState?.GSTIN?.trim() !== "" && ApplicationState?.GSTIN?.trim() === "";
        const GSTShippingValid = ApplicationBillingState?.GSTIN?.trim() === "" && ApplicationState?.GSTIN?.trim() !== "";

        // CHECK SHIPPING & BILLING NORMAL FIELDS VALIDATIONS
        const FieldsShippingValid = ApplicationState?.Name.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.PINCode.trim() !== "" && ApplicationState?.Address.trim() !== "" 

        let FieldsBillingValid;

        if(ApplicationBillingState?.AddressType === "dealer") {
            FieldsBillingValid = ApplicationBillingState?.Franchise !== "" && ApplicationBillingState?.Name.trim() !== "" && ApplicationBillingState?.Mobile.trim() !== "" && ApplicationBillingState?.Email.trim() !== "" && ApplicationBillingState?.PINCode.trim() !== "" && ApplicationBillingState?.Address.trim() !== "";
        } else {
            FieldsBillingValid = ApplicationBillingState?.Name.trim() !== "" && ApplicationBillingState?.Mobile.trim() !== "" && ApplicationBillingState?.Email.trim() !== "" && ApplicationBillingState?.PINCode.trim() !== "" && ApplicationBillingState?.Address.trim() !== "";
        };

        // CHECK SHIPPING & BILLING GST FIELDS VALIDATIONS
        let CheckShippingBillingGST = "";
        if(GSTBillingShippingValid) {
            CheckShippingBillingGST = (GSTvalidations(ApplicationState?.GSTIN, searchShippingPincodeList) && ApplicationState?.GSTIN?.length === 15) && (GSTvalidations(ApplicationBillingState?.GSTIN, searchBillingPincodeList) && ApplicationBillingState?.GSTIN?.length === 15);
        } else if(GSTBillingValid) {
            CheckShippingBillingGST = (GSTvalidations(ApplicationBillingState?.GSTIN, searchBillingPincodeList) && ApplicationBillingState?.GSTIN?.length === 15);
        } else if(GSTShippingValid) {
            CheckShippingBillingGST = (GSTvalidations(ApplicationState?.GSTIN, searchShippingPincodeList) && ApplicationState?.GSTIN?.length === 15);
        } else {
            CheckShippingBillingGST = ""
        };

        // CHECK FINAL EMAIL & MOBILE NUMBER & PINCODE FIELDS VALIDATION
        const FieldsMobileEmailPinShippingValid = EmailRegex.test(ApplicationState?.Email.trim()) === true && CheckMobileNumber?.valid === true && pinRegex?.test(ApplicationState?.PINCode.trim()) === true;
        const FieldsMobileEmailPinBillingValid = EmailRegex.test(ApplicationBillingState?.Email.trim()) === true && CheckBillingMobileNumber?.valid === true && pinRegex?.test(ApplicationBillingState?.PINCode.trim()) === true;

        const CommonCheckFranchise = () => {
            if(GSTBillingShippingValid) {
                FinalCheckValid("billing_shipping_gst");
            } else if(GSTBillingValid) {
                FinalCheckValid("billing_gst");
            } else if(GSTShippingValid) {
                FinalCheckValid("shipping_gst");
            } else {
                FinalCheckValid("no_gst");
            };
        };
        
        if(ApplicationState?.AddressType === "dealer" && ApplicationBillingState?.AddressType === "dealer") {
            if(ApplicationState?.Franchise !== "" && ApplicationBillingState?.Franchise !== "") {
                CommonCheckFranchise();
            } else {
                if(ApplicationState?.Franchise === "" && ApplicationBillingState?.Franchise === "") {
                    CommonCheckFranchise();
                };
                
                CheckValid(ApplicationBillingState?.Franchise, {type: 'dealer_franchise', error: seterrorBillingFranchise});
                CheckValid(ApplicationState?.Franchise, {type: 'dealer_franchise', error: seterrorFranchise});
            };
        } else if(ApplicationBillingState?.AddressType === "customer" && ApplicationState?.AddressType === "dealer") {
            if(ApplicationState?.Franchise !== "") {
                CommonCheckFranchise();
            } else {
                CommonCheckFranchise();
                CheckValid(ApplicationState?.Franchise, {type: 'dealer_franchise', error: seterrorFranchise});
            };
        } else if(ApplicationBillingState?.AddressType === "dealer" && ApplicationState?.AddressType === "customer") {
            if(ApplicationBillingState?.Franchise !== "") {
                CommonCheckFranchise();
            } else {
                CommonCheckFranchise();
                CheckValid(ApplicationBillingState?.Franchise, {type: 'dealer_franchise', error: seterrorBillingFranchise});
            };
        } else if(ApplicationBillingState?.AddressType === "customer" && ApplicationState?.AddressType === "customer") {
            CommonCheckFranchise();
        } else {
            CommonCheckFranchise();
        };
    };

    // clear purchase order
    const PurchaseOrderClear = () => {
        setSparesCampatProduct("");
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        setAddQuantity("");
        setAddPrice("");
    };

    // all clears data
    const PurchaseOrderMainClear = () => {
        setsparesProductWiseQtyMain("");
        setSparesCampatProduct("");
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        setAddQuantity("");
        setAddPrice("");
        setProductListOrder([]);
        setApplicationBillingState({
            ...ApplicationBillingState,
            AddressType: "",
            Franchise: "",
            Name: "",
            Mobile: "",
            Email: "",
            PANCard: "",
            PINCode: "",
            GSTIN: "",
            Address: "",
            address2: "",
        });
        setApplicationState({
            ...ApplicationState,
            AddressType: "",
            MainFranchise: "",
            Franchise: "",
            Name: "",
            Mobile: "",
            Email: "",
            PINCode: "",
            PANCard: "",
            GSTIN: "",
            Address: "",
            address2: "",
        }); 
        setNewApplicationState({
            ...NewApplicationState,
            frame_number: "",
            ticket_number: "",
        });
        seterrorticketNumber("");
    };

    useEffect(() => {
        if(ClearFormSet?.url === "quotation_add" && ClearFormSet?.action === 200) {
            PurchaseOrderClear();
            PurchaseOrderMainClear();
            setselectFranchise("");
            setApplicationState({
                ...ApplicationState,
                AddressType: "",
                Name: "",
                Mobile: "",
                Email: "",
                PINCode: "",
                PANCard: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            setApplicationBillingState({
                ...ApplicationBillingState,
                AddressType: "",
                Franchise: "",
                Name: "",
                Mobile: "",
                Email: "",
                PANCard: "",
                PINCode: "",
                GSTIN: "",
                Address: "",
                address2: "",
            });
            setShowModalNew({
                ...showModalNew,
                open: false,
                Data: {}
            });
            setSameAsBillingAddress(false);
            setPreviewOrder(false);
            setSparesCampatProduct("");
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
            Dispatch(SearchBillingPincodeList({}));
            Dispatch(SearchShippingPincodeList({}));
            setNewApplicationState({
                ...NewApplicationState,
                frame_number: "",
                ticket_number: "",
            });
            Dispatch(TicketsVerfiyAPI({}));
        };
    }, [ ClearFormSet ]);

    function getGstAmt(price, gstRate) {
        return (parseFloat(price)  - ( parseFloat(price) / (1 + (parseFloat(gstRate) / 100))));
    };

    // gst get
    const GetGSTAmount = () => {
        let total = 0;
        let total_gst = 0;
        let price = 0;
        let sub_total = 0;
        ProductListOrder?.filter((elm) => {
            const ProductGet = productlistall?.filter((elms) => {
                if(PermissionStockInwardModule?.permissionStockInward === "Bundles" && PermissionStockInwardModule?.permissionStockInward !== "Spares") {
                    return elms?.id === elm?.product?.value;
                } else {
                    return elms?.id === elm?.product?.id;
                };
            })[0];

            if(elm?.is_demo) {
                price = ProductGet?.demo_rate;
            } else {
                price = ProductGet?.franchise_rate;
            };

            let gst_rate = ProductGet?.igst_rate
            
            sub_total = parseFloat(parseFloat(price) * parseInt(elm?.quantity) * 100);
            total = total + sub_total;
            // total_gst = parseInt(total_gst + (parseInt((parseInt(gst_rate) / 100) * sub_total)));
            total_gst = total_gst + (getGstAmt(price, gst_rate) * parseInt(elm?.quantity))

        });
        
        return {
            total: total/100,
            total_gst: total_gst,
        };
    };

    const GetCurrentOrderRate = (current, product) => {
        let price = 0;
        let sub_total = 0;
        // if(current?.is_demo) {
        //     price = parseFloat(product?.demo_rate === null ? 0 : product?.demo_rate);
        // } else {
        //     price = parseFloat(product?.franchise_rate === null ? 0 : product?.franchise_rate);
        // };
        if(ApplicationBillingState?.AddressType === "customer") {
            price = parseFloat(product?.ecom_price === null ? 0 : product?.ecom_price);
        } else {
            price = parseFloat(product?.franchise_rate === null ? 0 : product?.franchise_rate);
        };
        
        sub_total = parseFloat(parseFloat(price) * parseFloat(current?.quantity) * 100);
        
        return {
            price: price,
            sub_total: sub_total/100,
        };
    };

    const GSTvalidations = (value, pincodeList) => {
        if(value !== "" && value !== null) {
            const GSTDigit = pincodeList?.state?.gst_code?.length === 1 ? 2 : pincodeList?.state?.gst_code?.length
            const GSTDigitValid = pincodeList?.state?.gst_code?.length === 1 ? String(pincodeList?.state?.gst_code).padStart(2, '0') : pincodeList?.state?.gst_code;

            const Substring = value?.trim()?.substring(0, GSTDigit);

            if(Substring.toString() === GSTDigitValid.toString()) {
                return true;
            } else {
                return false;
            }
        };
    };

    const GSTINvalidKeyUp = (value, url) => {
        if(url === "billing") {
            if(value !== "") {
                if(searchBillingPincodeList?.state?.gst_code === undefined) {
                    seterrorBillingGSTIN(`Before you enter the GST number, please enter the PIN code.`);
                } else {
                    if(GSTvalidations(value, searchBillingPincodeList) && value?.length === 15) {
                        seterrorBillingGSTIN("");
                    } else {
                        seterrorBillingGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchBillingPincodeList?.state?.gst_code?.length === 1 ? String(searchBillingPincodeList?.state?.gst_code).padStart(2, '0') : searchBillingPincodeList?.state?.gst_code}'.`)
                    };
                };
            } else {
                // seterrorBillingGSTIN("GSTIN cannot be empty");
            };
        } else {
            if(value !== "") {
                if(searchShippingPincodeList?.state?.gst_code === undefined) {
                    seterrorGSTIN(`Before you enter the GST number, please enter the PIN code.`);
                } else {
                    if(GSTvalidations(value, searchShippingPincodeList) && value?.length === 15) {
                        seterrorGSTIN("");
                    } else {
                        seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchShippingPincodeList?.state?.gst_code?.length === 1 ? String(searchShippingPincodeList?.state?.gst_code).padStart(2, '0') : searchShippingPincodeList?.state?.gst_code}'.`)
                    };
                };
            } else {
                // seterrorGSTIN("GSTIN cannot be empty");
            };
        }
    };

    useEffect(() => {
        Dispatch(TicketsVerfiyAPI({}));
    }, []);

    const onChange = (e) => {
        let value = e.target.value;
    
        if (value?.length < 16) {
            // Remove leading zeros
            value = value.replace(/^0+/g, "");
        
            // Remove non-numeric characters
            value = value.replace(/\D/g, "");
        
            // Ensure the value is more than 1 (not negative number)
            if (value === "" || parseInt(value) < 0) {
                value = ""; // Set default value to 1
            }
        
            // Update the state with the modified value
            setAddQuantity(parseInt(value));
        };
    }

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // get spares product wise quantity
    const GetSparesProductList = (spares) => {
        return(<Table style={{ margin: "0px" }} bordered>
            <thead>
                <tr>
                    <th>Compatible Item Name</th>
                    <th>Quantity</th>
                </tr>
            </thead>
            <tbody>
                {spares?.filter((elm) => {
                    if(elm?.quantity !== undefined) {
                        return elm;
                    };
                }).map((elm, index, spares_array) => {
                    return(<tr key={index}>
                        <td>{elm?.compatible_item_name}</td>
                        <td>
                            {PreviewOrder ? elm?.quantity : (<div className="QauntityManage">
                                <button className="btn remove" onClick={() => (spares_array?.length === 1 && elm?.quantity === 1) ? DeleteOrder(elm?.item_id) : removeSparesQuantity(elm?.id, elm?.quantity, elm?.item_id)}>
                                    <i className="bi bi-dash-circle-fill"></i>
                                </button>
                                <h2>{elm?.quantity}</h2>
                                <button className="btn add" onClick={() => addMainSparesQuantity(elm?.id, elm?.item_id)}>
                                    <i className="bi bi-plus-circle-fill"></i>
                                </button>
                            </div>)}
                        </td>
                    </tr>)
                })}
            </tbody>
        </Table>)
    };

    // Function to handle adding quantity
    const addMainSparesQuantity = (id, item_id) => {
        const AddQauntArray = ProductListOrder?.map((elm) => {
            if(elm?.id === item_id) {
                return {
                    ...elm,
                    compatible_items: elm?.compatible_items?.map((el) => {
                        if(el?.id === id) {
                            return {
                                ...el,
                                quantity: parseInt(el?.quantity) + 1,
                            };
                        } else {
                            return el;
                        };
                    }),
                    quantity: parseInt(elm?.quantity) + 1,
                };
            } else {
                return elm;
            };
        });
        setProductListOrder(AddQauntArray);
        BackSpareQty(AddQauntArray);
    };

    // Function to handle removing quantity
    const removeSparesQuantity = (id, value, item_id) => {
        const RemovedQunty = ProductMergAarray?.map((elm) => {
            if(value === 1) {
                if(elm?.id === item_id) {
                    return {
                        ...elm,
                        compatible_items: elm?.compatible_items?.map((el) => {
                            if(el?.id !== id) {
                                return el;
                            };
                        })?.filter((elm) => elm !== undefined),
                        quantity: parseInt(elm?.quantity) - 1,
                    }
                } else {
                    return elm;
                }
            } else {
                if(elm?.id === item_id) {
                    return {
                        ...elm,
                        compatible_items: elm?.compatible_items?.map((el) => {
                            if(el?.id === id) {
                                return {
                                    ...el,
                                    quantity: parseInt(el?.quantity) - 1,
                                };
                            } else {
                                return el;
                            };
                        }),
                        quantity: parseInt(elm?.quantity) - 1,
                    }
                } else {
                    return elm;
                }
            }
        })?.filter((elm) => elm !== undefined);
        setProductListOrder(RemovedQunty);
        BackSpareQty(RemovedQunty);
    };

    // remove order
    const DeleteOrder = (id) => {
        const RemoveOrder = [];
        ProductMergAarray?.map((elm) => {
            if(elm?.id !== id) {
                return RemoveOrder.push(elm);
            }
        });
        setProductListOrder(RemoveOrder);

        if(PermissionStockInwardModule?.permissionStockInward === "Spares") {
            BackSpareQty(RemoveOrder);
        };
    };

    const BackSpareQty = (spares_array_deletations) => {
        spares_array_deletations.forEach(spare => {
            const currentSparesArray = spare.compatible_items;

            if (Array.isArray(currentSparesArray)) {
                currentSparesArray.forEach(item1 => {
                    // Find the matching item in array2 by id
                    const item2 = sparesProductWiseQty.find(item => item.id === item1.id);
                    
                    // If a matching item is found, update its quantity with the one from array1
                    if (item2) {
                        item2.quantity = item1.quantity;
                    }
                });
            }
        });

        // Remove items in sparesProductWiseQty that do not have a matching id in spares_array_deletations
        sparesProductWiseQty = sparesProductWiseQty.filter(item2 => 
            spares_array_deletations.some(spare => 
                spare.compatible_items.some(item1 => item1.id === item2.id)
            )
        );
       
        setsparesProductWiseQtyMain(sparesProductWiseQty);
    };

    // CLEAR BILLING & DELIVERY PINCODES
    const ClearPincodes = (url) => {
        if(url === "billing") {
            setApplicationBillingState({...ApplicationBillingState, PINCode: ""});
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        } else {
            setApplicationState({...ApplicationState, PINCode: ""});
            Dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    };

    // CUSTOME TRANSPORTATION CHARGES RATING CHANGES
    const TransportationRatingCustom = (value, data) => {
        let inputValue = value;
        let ProductListOrderArray = ProductListOrder?.map((elm) => {
            if(elm?.id === data?.id) {
                // Check if the input is a valid number with up to 2 decimal places
                if (/^\d*\.?\d*$/.test(inputValue)) {
                    // Limit to 2 decimal places if there's a decimal point
                    const [integerPart, decimalPart] = inputValue.split(".");
                    if (decimalPart?.length > 2) {
                        inputValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }
                    return {
                        ...elm,
                        price: inputValue,
                        sub_total: parseFloat(parseFloat(inputValue) * parseFloat(data?.quantity)),
                    };
                };
            } else {
                return elm;
            };
        });
        setProductListOrder(ProductListOrderArray);
    };

    const BackFunction = () => {
        let ProductListOrderArray = ProductListOrder?.map((elm) => {
            if(!elm?.product?.custom_is_transportation) {
                return elm;
            };
        })?.filter((elm) => elm !== undefined);

        setProductListOrder(ProductListOrderArray);
        setPreviewOrder(false); 
        Dispatch(TicketsVerfiyAPI({}));
    };
    
    var IsCheckMainPreiew = false;
    var IsFranchiseAllow = false;
    if(selectSaleType !== "") {
        if(ApplicationBillingState?.AddressType === "dealer") {
            if(selectFranchise) {
                IsCheckMainPreiew = true;
            };
            IsFranchiseAllow = true;
        } else if(ApplicationBillingState?.AddressType === "customer") {
            IsCheckMainPreiew = true;
            IsFranchiseAllow = false;
        } else {
            // IsCheckMainPreiew = false;
            // IsFranchiseAllow = true;
        };

    } else {
        IsCheckMainPreiew = false;
        IsFranchiseAllow = false;
    };

    const PurchaseOrderBuyerClear = () => {
        IsCheckMainPreiew = true;
        IsFranchiseAllow = false;
        CommonClearSet();
        ErrorCommonSet();
    };

    const PurchaseOrderFranchiseClear = () => {
        IsCheckMainPreiew = true;
        IsFranchiseAllow = false;
        CommonClearSet();
        ErrorCommonSet();
    };

    const CommonClearSet = () => {
        setselectFranchise();
        setsparesProductWiseQtyMain("");
        setSparesCampatProduct("");
        setselectProduct("");
        setselectSubCategory("");
        setselectCategory("");
        setAddQuantity("");
        setAddPrice("");
        setProductListOrder([]);
        setApplicationBillingState({
            ...ApplicationBillingState,
            AddressType: "",
            Franchise: "",
            Name: "",
            Mobile: "",
            Email: "",
            PANCard: "",
            PINCode: "",
            GSTIN: "",
            Address: "",
            address2: "",
        });
        setApplicationState({
            ...ApplicationState,
            AddressType: "",
            MainFranchise: "",
            Franchise: "",
            Name: "",
            Mobile: "",
            Email: "",
            PINCode: "",
            PANCard: "",
            GSTIN: "",
            Address: "",
            address2: "",
        }); 
        setNewApplicationState({
            ...NewApplicationState,
            frame_number: "",
            ticket_number: "",
        });
        ErrorCommonSet();
    };

    const ErrorCommonSet = () => {
        seterrorticketNumber("");
        seterrorBillingFranchise("");
        seterrorBillingPINCode("");
        seterrorBillingName("");
        seterrorBillingMobile("");
        seterrorBillingEmail("");
        seterrorBillingAddress("");
        seterrorBillingAddress2("");
        seterrorBillingGSTIN("");
        seterrorFranchise("");
        seterrorPINCode("");
        seterrorName("");
        seterrorMobile("");
        seterrorEmail("");
        seterrorAddress("");
        seterrorAddress2("");
        seterrorGSTIN("");
    };

    // show last three orders
    const CheckStockLevelOrders = (orders) => {
        setShowModalNew({
            ...showModalNew,
            title: orders?.product?.product_name,
            subtitle: "Check Stock Level",
            modalType: "form",
            open: !showModalNew?.open,
            button: "",
            Data: orders?.product?.id,
        });
        Dispatch(StartMainCommonLoader({
            url: "store_level_list",
            loader: true
        }));
    };

    return(<section className="Main_section Main_section_Quotations" style={{ position: "relative" }}>
        {PreviewOrder && (<div className="FranchiseWallet" style={{ padding: "0px 0px 7px", marginBottom: "7px", justifyContent: "flex-end" }}>
            {ApplicationBillingState?.AddressType === "dealer" ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", float: "left", width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", float: "left", width: "50%", columnGap: "15px" }}>
                    <div className="Franchise_name">
                        <label>Order Buyer :</label>
                        Dealer
                    </div>
                    <div className="Franchise_name">
                        <label>Franchise Name:</label>
                        {selectFranchise?.customer_name}
                    </div>
                </div>
                <div className="FranchiseBlance_name">
                    <label><i className="bi bi-wallet-fill"></i> Available Balance:</label>
                    ₹ {GetAvailableBalance()}
                </div>
            </div>) : (<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", float: "left", width: "100%" }}>
                <div className="Franchise_name">
                    <label>Order Buyer :</label>
                    Customer
                </div>
            </div>)}
            <button 
                className="btn btn-link backbtn" 
                onClick={() => BackFunction()} 
                style={{ display: "flex" }}
            >
                <i className="bi bi-arrow-left-short"></i> Back
            </button>
        </div>)}
        {!PreviewOrder && (<div className="filtr_Form Purchase_Order_Mendetory" style={{ borderBottom: "1px solid #eee" }}>
            {!IsCheckMainPreiew ? (<div className="formloginset">
                <div className="row">
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <Form.Group className="mb-3">
                            <select 
                                className="form-control"
                                value={ApplicationBillingState?.AddressType}
                                onChange={(e) => {
                                    setApplicationBillingState({...ApplicationBillingState, AddressType: e.target.value})
                                    setApplicationState({...ApplicationState, AddressType: e.target.value})
                                }}
                            >
                                <option value={""}>Select Buyer</option>
                                <option value={"customer"}>Customer</option>
                                <option value={"dealer"}>Dealer</option>
                            </select>
                        </Form.Group>
                    </div>
                    {IsFranchiseAllow && (<div className="col-md-3">
                        <Form.Group className="mb-3">
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select 
                                    value={selectFranchise}
                                    onChange={(e) => FranchiseSelect(e)} 
                                    isClearable 
                                    options={optionsAllDealerFranchise}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    placeholder="Select Franchise"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>)}
                </div>
            </div>) : (<div className="formloginset">
                <div className="row">
                    <div className="col-md-2" style={{ width: "15%" }}>
                        <Form.Group className="mb-3">
                            <div className="smallLabelData">
                                <small>Selected Order Buyer</small>
                                <div>{ApplicationBillingState?.AddressType}</div>
                                <span className="clearbtn" onClick={() => PurchaseOrderBuyerClear()}>
                                    <i className="bi bi-x-circle-fill"></i>
                                </span>
                            </div>
                        </Form.Group>
                    </div>
                    {IsFranchiseAllow && (<div className="col-md-3">
                        <Form.Group className="mb-3">
                            <div className="smallLabelData">
                                <small>Selected Franchise</small>
                                <div>{selectFranchise?.label}</div>
                                <span className="clearbtn" onClick={() => PurchaseOrderFranchiseClear()}>
                                    <i className="bi bi-x-circle-fill"></i>
                                </span>
                            </div>
                        </Form.Group>
                    </div>)}
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select 
                                    onChange={(e) => ProductSparesSelect(e)} 
                                    value={sparesCampatProduct}
                                    isClearable 
                                    options={optionsparescampatibiityAllList}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    placeholder="Select Products"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-2">
                        <Form.Group className="mb-3" >
                            <Form.Control 
                                type="number"
                                value={AddQuantity} 
                                className="inputBoxs"
                                onChange={onChange}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Quantity"
                                maxLength={3}
                                min="1" max="10"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-2" style={{ paddingRight: "0px" }}>
                        <Form.Group className="mb-3" >
                            <button className="btn exportbtn" disabled={!sparesCampatProduct || !AddQuantity} id="PurchaseOrderbtn" onClick={() => PurchaseOrderAdd()}>
                                Add
                            </button>
                            <button 
                                className="btn exportbtn" 
                                style={{ marginLeft: "8px" }} 
                                id="PurchaseOrderClearbtn" 
                                onClick={() => PurchaseOrderClear()}
                            >
                                Clear
                            </button>
                        </Form.Group>
                    </div>
                    {ApplicationBillingState?.AddressType === "dealer" && (<div className="FranchiseWallet" style={{ paddingTop: "0px" }}> 
                        <div className="FranchiseBlance_name">
                            <label><i className="bi bi-wallet-fill"></i> Available Balance:</label>
                            ₹ {GetAvailableBalance()}
                        </div>
                    </div>)}
                </div>
            </div>
            )}
        </div>)}
            <div className="tableView">
                <CommonScrollBar 
                    height={{ 
                        height: ApplicationBillingState?.AddressType === "dealer" ? (PreviewOrder ? "calc(100vh - 148px)" : "calc(100vh - 225px)") : (PreviewOrder ? "calc(100vh - 140px)" : "calc(100vh - 170px)")
                    }} 
                    className="ScrollbarsSidebar"
                >
                    {ProductListOrder?.length > 0 && (<>
                        <div className="tableView">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>SNO</th>
                                        <th>Product Code</th>
                                        <th style={{ width: "30%" }}>Compatible Items</th>
                                        <th>Product Name</th>
                                        <th>Rate</th>
                                        <th>Quantity</th>
                                        <th>Amount</th>
                                        {(!PreviewOrder && PermissionStockInwardModule?.permissionStockInward !== "Bundles") && (<th>Action</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ProductListOrder?.map((elm, index) => {
                                        const GetProductDetails = productlistall?.filter((elms) => elms?.id === elm?.product?.id)[0];
                                        return(<tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{GetProductDetails?.erp_id}</td>
                                            <td>{elm?.product?.custom_is_transportation ? "Transportation Charges" : GetSparesProductList(elm?.compatible_items)}</td>
                                            <td>
                                                {GetProductDetails?.product_name} <br/>
                                                {!PreviewOrder && (<button className="btn btn-link" onClick={() => CheckStockLevelOrders(elm)}>Check Stock</button>)}
                                            </td>
                                            <td>
                                                {elm?.product?.custom_is_transportation ? (<div className="spares_input_box">₹ 
                                                    <input 
                                                        type="number"
                                                        className="form-control inputBoxs" 
                                                        value={elm.price} 
                                                        onChange={(e) => {
                                                            TransportationRatingCustom(e.target.value.replace(/^0/g, ""), elm)
                                                        }}
                                                        onKeyDown={(e) => {
                                                            EmptySpaceFieldValid(e)
                                                            exceptThisCostSymbolsBox.includes(e.key) && e.preventDefault()
                                                            // Prevent only the up and down arrow keys
                                                            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={2}
                                                        max={2}
                                                        pattern="/^-?\d+\.?\d*$/"
                                                    />
                                                </div>) : 
                                                "₹" + AmountNumberFormat(GetCurrentOrderRate(elm, GetProductDetails)?.price)}
                                            </td>
                                            <td>{elm?.quantity}</td>
                                            <td>₹{AmountNumberFormat(isNaN(elm?.sub_total) ? 0 : elm?.sub_total)}</td>
                                            {(!PreviewOrder && PermissionStockInwardModule?.permissionStockInward !== "Bundles") && (<td>
                                                <Button className="btn edittable" onClick={() => DeleteOrder(elm?.id)} style={{ marginLeft: "7px" }}>
                                                    <img src={DeleteIcon} alt="delete_icon" /> 
                                                </Button>
                                            </td>)}
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        {!PreviewOrder && (<div className="btnGroupswraps" id="btnGroupswraps">
                            <Button className="Addbtn" onClick={() => PrviewOrderCarts()}>Preview</Button>
                        </div>)}
                        {PreviewOrder && (<>
                            <ul className="GSTAmounttotal">
                                <li className="totaltd">
                                    <label>GST: &nbsp;</label>
                                    ₹ {AmountNumberFormat(GetGSTAmount()?.total_gst)}
                                </li>
                                <li className="totaltd">
                                    <label>Net Amount: &nbsp;</label>
                                    ₹ {AmountNumberFormat(GetGSTAmount()?.total)}
                                </li>
                            </ul>

                            <div className="formloginset">
                                <div className="HeaderDivider" >
                                    <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Billing Address
                                    </h6>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Select Address Type</Form.Label>
                                                {selectSaleType === "After Sales" ? (<h4 className="defaultvalueField" style={{ textTransform: "capitalize" }}>{ApplicationBillingState?.AddressType}</h4>) : (<select 
                                                    className="form-control"
                                                    value={ApplicationBillingState?.AddressType}
                                                    onChange={(e) => SelectAddressTypeOnChange(e.target.value, "billing")}
                                                >
                                                    <option value={"customer"}>Customer</option>
                                                    <option value={"dealer"}>Dealer</option>
                                                </select>)}
                                            </Form.Group>
                                        </div>
                                        {ApplicationBillingState?.AddressType === "dealer" && (<div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Select Dealer<span className="mendatory_star">*</span></Form.Label>
                                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                                    <Select 
                                                        options={ParentDealerFranchiseList}
                                                        value={ApplicationBillingState?.Franchise}
                                                        onChange={(e) => FrameSelectOnChange(e, "billing")} 
                                                        isClearable 
                                                        className="godown-select-container" 
                                                        classNamePrefix="godown-select"
                                                        placeholder="Select Dealer"
                                                        styles={customStyles}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                                {errorBillingFranchise !== "" &&<span className="error_medotory">{errorBillingFranchise}</span>}
                                            </Form.Group>
                                        </div>)}
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Customer Name<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationBillingState?.Name} 
                                                    onChange={(e) => setApplicationBillingState({...ApplicationBillingState, Name: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Customername', error: seterrorBillingName})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Customer Name" 
                                                />
                                                {errorBillingName !== "" &&<span className="error_medotory">{errorBillingName}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Mobile Number<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="number" 
                                                    className="inputBoxs"
                                                    value={ApplicationBillingState?.Mobile} 
                                                    onChange={(e) => setApplicationBillingState({...ApplicationBillingState, Mobile: e.target.value.replace(/^0/, "")})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'phone', error: seterrorBillingMobile})}
                                                    onKeyDown={(e) => {
                                                        EmptySpaceFieldValid(e)
                                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                    }}
                                                    placeholder="Enter Mobile Number" 
                                                />
                                                {errorBillingMobile !== "" &&<span className="error_medotory">{errorBillingMobile}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Email Address<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationBillingState?.Email} 
                                                    onChange={(e) => setApplicationBillingState({...ApplicationBillingState, Email: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: seterrorBillingEmail})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Email Address" 
                                                />
                                                {errorBillingEmail !== "" &&<span className="error_medotory">{errorBillingEmail}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>
                                                    PIN Code
                                                    <span className="mendatory_star">*</span>
                                                    <OverlayTrigger
                                                        placement="left"
                                                        delay={{ show: 250, hide: 100 }}
                                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                                        Search by either hitting the enter button or clicking on the search icon.
                                                    </Tooltip>}
                                                    >
                                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div className="serachBoxWIth">
                                                    <Form.Control 
                                                        type="text" 
                                                        value={ApplicationBillingState?.PINCode} 
                                                        onChange={(e) => PinCOdeSearchFunction(e, "billing")}
                                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'PINCode', error: seterrorBillingPINCode})}
                                                        onKeyDown={(e) => {
                                                            EmptySpaceFieldValid(e);
                                                            onEnterPincodeSearch(e)
                                                        }}
                                                        disabled={(commonMainLoader?.url === "pin_code_search_billing" && commonMainLoader?.loader)}
                                                        maxLength={6}
                                                        placeholder="Enter PIN Code" 
                                                    />
                                                    <Button className="searchbtn" onClick={() => SearchPincode("billing")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                            <path d="M21 21l-6 -6"></path>
                                                        </svg>
                                                    </Button>
                                                    
                                                    {ErrorMessageFromReddux?.API === "pin_code_search_billing" && (<>
                                                        <div className="pincode_error">
                                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                                <i className="bi bi-x-circle-fill" onClick={() => ClearPincodes("billing")} style={{ color: "red" }}></i>
                                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                                        </div>
                                                    </>)}
                                                    {(commonMainLoader?.url === "pin_code_search_billing" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>)}
                                                </div>
                                                {errorBillingPINCode !== "" &&<span className="error_medotory">{errorBillingPINCode}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>City</Form.Label>
                                                <div className="defaultvalueField">
                                                    {ErrorMessageFromReddux?.Error === "" ? searchBillingPincodeList?.city?.district_name : ""}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>State</Form.Label>
                                                <div className="defaultvalueField">
                                                    {ErrorMessageFromReddux?.Error === "" ? searchBillingPincodeList?.state?.state_name : ""}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>PAN(Optional)</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationBillingState?.PANCard} 
                                                    onChange={(e) => setApplicationBillingState({...ApplicationBillingState, PANCard: e.target.value})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter PAN" 
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>GSTIN(Optional)</Form.Label>
                                                <Form.Control
                                                    type="text" 
                                                    value={ApplicationBillingState?.GSTIN} 
                                                    onChange={(e) => setApplicationBillingState({...ApplicationBillingState, GSTIN: e.target.value})}
                                                    onKeyUp={(e) => GSTINvalidKeyUp(e?.target?.value, "billing")}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter GSTIN"
                                                />
                                                {(ApplicationBillingState?.GSTIN !== "" && errorBillingGSTIN !== "") &&<span className="error_medotory">{errorBillingGSTIN}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <CommonAddressTextField 
                                                Label={"Address Line 1"}
                                                MainState={ApplicationBillingState}
                                                setMainState={setApplicationBillingState} 
                                                Address={"Address"}
                                                isMendatory={true}
                                                ErrorAddress={errorBillingAddress} 
                                                setErrorAddress={seterrorBillingAddress}
                                                MaxLength={100}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CommonAddressTextField 
                                                Label={"Address Line 2(Optional)"}
                                                MainState={ApplicationBillingState}
                                                setMainState={setApplicationBillingState} 
                                                Address={"address2"}
                                                isMendatory={false}
                                                MaxLength={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="HeaderDivider" >
                                    <h6 className="subtitleForm PurchaseOrderCheckbox">
                                        Delivery Address
                                    </h6>
                                    <div className="row">
                                        {ApplicationState?.AddressType === "customer" && (<div className="col-md-12">
                                            <Form.Group className="mb-3 warranty_order_cols">
                                                <Form.Label>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={SameAsBillingAddress}
                                                        onChange={(e) => setSameAsBillingAddress(e.target.checked)}
                                                    />
                                                    Same as billing address
                                                </Form.Label>
                                            </Form.Group>
                                        </div>)}
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Select Address Type</Form.Label>
                                                <select 
                                                    className="form-control"
                                                    value={ApplicationState?.AddressType}
                                                    onChange={(e) => SelectAddressTypeOnChange(e.target.value, "delivery")}
                                                    disabled={SameAsBillingAddress}
                                                >
                                                    <option value={"customer"}>Customer</option>
                                                    <option value={"dealer"}>Dealer</option>
                                                </select>
                                            </Form.Group>
                                        </div>
                                        {(ApplicationBillingState?.AddressType === "customer" && ApplicationState?.AddressType === "dealer") && (<div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Select Dealer<span className="mendatory_star">*</span></Form.Label>
                                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                                    <Select 
                                                        options={ParentDealerFranchiseList}
                                                        value={ApplicationState?.MainFranchise}
                                                        onChange={(e) => FrameSelectOnChange(e, "dealer_main_delivery")} 
                                                        isClearable 
                                                        className="godown-select-container" 
                                                        classNamePrefix="godown-select"
                                                        placeholder="Select Dealer"
                                                        styles={customStyles}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                                <small className="informative_text"><i className="bi bi-info-circle-fill"></i> Before selecting a sub-dealer, you first need to select a dealer.</small>
                                            </Form.Group>
                                        </div>)}
                                        {ApplicationState?.AddressType === "dealer" && (<div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Select Sub Dealer<span className="mendatory_star">*</span></Form.Label>
                                                <div className="selectMultiselect selectMultiselectCategory" id="selectMultiselectBrand">
                                                    <Select 
                                                        options={SubParentDealerFranchiseList}
                                                        value={ApplicationState?.Franchise}
                                                        onChange={(e) => FrameSelectOnChange(e, "delivery")} 
                                                        isClearable 
                                                        className="godown-select-container" 
                                                        classNamePrefix="godown-select"
                                                        placeholder="Select Sub Dealer"
                                                        styles={customStyles}
                                                        menuPlacement="auto"
                                                        menuPortalTarget={document.body}
                                                    />
                                                </div>
                                                {errorFranchise !== "" &&<span className="error_medotory">{errorFranchise}</span>}
                                            </Form.Group>
                                        </div>)}
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Customer name<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.Name} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Name: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Customername', error: seterrorName})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Customer name" 
                                                />
                                                {errorName !== "" &&<span className="error_medotory">{errorName}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Mobile Number<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="number" 
                                                    className="inputBoxs"
                                                    value={ApplicationState?.Mobile} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Mobile: e.target.value.replace(/^0/, "")})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'phone', error: seterrorMobile})}
                                                    onKeyDown={(e) => {
                                                        EmptySpaceFieldValid(e)
                                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                                    }}
                                                    placeholder="Enter Mobile Number" 
                                                />
                                                {errorMobile !== "" &&<span className="error_medotory">{errorMobile}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>Email Address<span className="mendatory_star">*</span></Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.Email} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, Email: e.target.value})}
                                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: seterrorEmail})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter Email Address" 
                                                />
                                                {errorEmail !== "" &&<span className="error_medotory">{errorEmail}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>
                                                    PIN Code
                                                    <span className="mendatory_star">*</span>
                                                    <OverlayTrigger
                                                        placement="left"
                                                        delay={{ show: 250, hide: 100 }}
                                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                                        Search by either hitting the enter button or clicking on the search icon.
                                                    </Tooltip>}
                                                    >
                                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <div className="serachBoxWIth">
                                                    <Form.Control 
                                                        type="text" 
                                                        value={ApplicationState?.PINCode} 
                                                        onChange={(e) => PinCOdeSearchFunction(e, "customer")}
                                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'PINCode', error: seterrorPINCode})}
                                                        onKeyDown={(e) => {
                                                            EmptySpaceFieldValid(e);
                                                            onEnterPincodeSearch(e)
                                                        }}
                                                        disabled={(commonMainLoader?.url === "pin_code_search_customer" && commonMainLoader?.loader)}
                                                        maxLength={6}
                                                        placeholder="Enter PIN Code" 
                                                    />
                                                    <Button className="searchbtn" onClick={() => SearchPincode("customer")}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                            <path d="M21 21l-6 -6"></path>
                                                        </svg>
                                                    </Button>
                                                    
                                                    {ErrorMessageFromReddux?.API === "pin_code_search_customer" && (<>
                                                        <div className="pincode_error">
                                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                                <i className="bi bi-x-circle-fill" onClick={() => ClearPincodes("customer")} style={{ color: "red" }}></i>
                                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                                        </div>
                                                    </>)}
                                                    {(commonMainLoader?.url === "pin_code_search_customer" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>)}
                                                </div>
                                                {errorPINCode !== "" &&<span className="error_medotory">{errorPINCode}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>City</Form.Label>
                                                <div className="defaultvalueField">
                                                    {ErrorMessageFromReddux?.Error === "" ? searchShippingPincodeList?.city?.district_name : ""}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>State</Form.Label>
                                                <div className="defaultvalueField">
                                                    {ErrorMessageFromReddux?.Error === "" ? searchShippingPincodeList?.state?.state_name : ""}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>PAN(Optional)</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.PANCard} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, PANCard: e.target.value})}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter PAN" 
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <Form.Group className="mb-3 " >
                                                <Form.Label>GSTIN(Optional)</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    value={ApplicationState?.GSTIN} 
                                                    onChange={(e) => setApplicationState({...ApplicationState, GSTIN: e.target.value})}
                                                    onKeyUp={(e) => GSTINvalidKeyUp(e?.target?.value, "delivery")}
                                                    onKeyDown={EmptySpaceFieldValid}
                                                    placeholder="Enter GSTIN"
                                                />
                                                {(ApplicationState?.GSTIN !== "" && errorGSTIN !== "") &&<span className="error_medotory">{errorGSTIN}</span>}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-4">
                                            <CommonAddressTextField 
                                                Label={"Address Line 1"}
                                                MainState={ApplicationState}
                                                setMainState={setApplicationState} 
                                                Address={"Address"}
                                                isMendatory={true}
                                                ErrorAddress={errorAddress} 
                                                setErrorAddress={seterrorAddress}
                                                MaxLength={100}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <CommonAddressTextField 
                                                Label={"Address Line 2(Optional)"}
                                                MainState={ApplicationState}
                                                setMainState={setApplicationState} 
                                                Address={"address2"}
                                                isMendatory={false}
                                                MaxLength={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3 " >
                                            <Form.Label>Frame Number</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                value={NewApplicationState?.frame_number} 
                                                onChange={(e) => setNewApplicationState({...NewApplicationState, frame_number: e.target.value})}
                                                onKeyDown={EmptySpaceFieldValid}
                                                placeholder="Enter Frame Number" 
                                            />
                                        </Form.Group>
                                    </div>
                                    <TicketsVerify 
                                        ticket_number={NewApplicationState?.ticket_number} 
                                        MainState={NewApplicationState}
                                        setMainState={setNewApplicationState} 
                                        error_tickets_number={errorticketNumber}
                                        seterror_tickets_number={seterrorticketNumber}
                                        showModalNew={showModalNew} 
                                        setShowModalNew={setShowModalNew}
                                        isMendatory={false}
                                        // isDisabled={}
                                    />
                                </div>
                            </div>
                            <div className="formloginset">
                                <div className="row">
                                    <div className="col-md-12" style={{ textAlign: "right" }}>
                                        {selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AFSOrderManagement[4]?.Edit) ? (<Button className="formbtn" id="confimr_order_main" onClick={() => OrderPurchaseFunct()} style={{ width: "auto", marginTop: "0px" }} variant="primary" >
                                        Confirm Quotation
                                        </Button>) : <span style={{ color: "red" }}>You don't have permission to create an order.</span>}
                                        <Button className="formbtnback" id="backbtbackgt" style={{ width: "auto", marginLeft: "10px" }} onClick={() => BackFunction()}>Back</Button>
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </>)}
                </CommonScrollBar>
            </div>

            {showModalNew?.open && (<CommonewModel ConfirmOrder={ConfirmOrder} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
                                                        
            {(commonMainLoader?.url === "quotation_add" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default QuotationCreations;