/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonDateTime from "../../Common/CommonDateTime";
import { updateOrderManagementFilter } from "../../../redux/actions/adminActions";
import MainOrderDataTableFilter from "../../Common/MainOrderDataTableFilter";
import CommonTable from "../../Common/CommonTable";

const FranchiseOrdersReport = (props) => {
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, OrderReportList, OrderManagmentFilter, sortingfor } = useSelector((state) => state.adminReducers);

    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            AllOrdersReport: {
                CurrentPage: page,
            }
        }));
    };

    // get all warehouse list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "warehouse_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get all franchise list
    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "franchise_list",
            "request": {
                "status": true,
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get order_list all
    useEffect(() => {
        const { SelectOrderType, UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage, WarehouseName, is_bundle_orders } = OrderManagmentFilter?.AllOrdersReport;

        let param = {
            "transmit": "broadcast",
            "url": "order_list",
            "request": {
                "status": SelectOrderType,
                "limit": UserLimit,
                "page_no": CurrentPage,
                "search": UserSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "order_type": OrderSource,
                "order_data": "list",
                "region_id": SelectRegion,
                "warehouse_id": WarehouseName?.value,
                "order_by": sortingfor,
                "fetch_location_info": true,
                "is_bundle_orders": is_bundle_orders,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    }, [ websocket, sortingfor, OrderManagmentFilter?.AllOrdersReport ]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'customer_name', label: 'SHIP To Name', type: "label", sort: "customer_name", isCollapsable: false },
        { key: 'pin_code', label: 'SHIP Pincode', type: "label", sort: "pin_code_id", isCollapsable: false },
        { key: 'state', label: 'SHIP State', type: "label", sort: "state_id", isCollapsable: false },
        { key: 'district', label: 'SHIP City', type: "label", sort: "district_id", isCollapsable: false },
        { key: 'billing_id', label: 'BILL To Name', type: "label_get_from_franchise", sort: "billing_id", isCollapsable: false },
        { key: 'order_code', label: 'Order Id.', type: "order_details", sort: "order_code", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "customer_identity", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "created_at", isCollapsable: false },
        { key: 'invoice_code', label: 'Invoice No.', type: "invoice_details", sort: "invoice_code", isCollapsable: false },
        { key: 'invoice_at', label: 'Invoice Date', type: "date", sort: "invoice_at", isCollapsable: false },
        { key: 'courier_name', label: 'Transporter', type: "label", sort: "courier_name", isCollapsable: false },
        { key: 'order_priority', label: 'Order Priority', type: "label", sort: "order_priority", isCollapsable: false },
        { key: 'qty', label: 'Qty', type: "label", sort: "qty", isCollapsable: false },
        { key: 'docket_number', label: 'Docket Number', type: "label", sort: "docket_number", isCollapsable: false },
        { key: 'total', label: 'Amount', type: "label_day_ammount", sort: "total", isCollapsable: false },
        { key: 'order_status', label: 'Status', type: "label_order_status", sort: "", isCollapsable: false },
        { key: 'invoice_at', label: 'Invoice Date', type: "date", sort: "", isCollapsable: true },
        { key: 'remark', label: 'Remarks', type: "label", sort: "", isCollapsable: true },
        { key: '', label: 'Attachments', type: "label", sort: "", isCollapsable: true },
        { key: 'region_id', label: 'Region', type: "label_get_from_list", filterFrom: { list: "regionlist", label: "region_name" }, sort: "", isCollapsable: true },
    ];
 
    return (<section className="Main_section">
        <MainOrderDataTableFilter 
            filterType={""}
            tabName={"AllOrdersReport"}
            searchType={"Order Id, Customer Code"}
            FromDateStartDateFlag={true}
            searchdisable={true}
            buttonvisible={false}
            AllOrderStatusAllow={true}
            isAllowWarehouse={true}
            isDateType={false}
            isOdiFilterAllow={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(OrderReportList?.pagination?.total_records) === OrderReportList?.list?.length ? "calc(100vh - 196px)" : parseInt(OrderReportList?.pagination?.total_records) > 10 ? "calc(100vh - 235px)" : "calc(100vh - 196px)"
            }}
            srNumber={{
                clickable: true,
                events: "ORDERS_LOG"
            }}
            isCollapsable={true}
            tabName={""} // TAB URL
            PaginationCurrentPage={OrderManagmentFilter["AllOrdersReport"]?.CurrentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={OrderReportList} // ORDER LIST
            Pagination={OrderReportList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            APIcalling={""}
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default FranchiseOrdersReport;