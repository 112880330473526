/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { PINE_CODES_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import Select from 'react-select'

const AddPincodes = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [UploadFileLoader, setUploadFileLoader] = useState(false);
    const [errorCityName, seterrorCityName] = useState("");
    const [ApplicationState, setApplicationState] = useState({
        StateName: "",
        CityName: "",
        GSTCode: "",
        Pincode: "",
        Status: true
    });
    const roomId = useParams()?.id;
    const { ClearFormSet, device_id, stateList, pincodeList, statelistall, cityList, citylistall } = useSelector((state) => state.adminReducers);
    const stateData = pincodeList?.list?.filter((elm) => elm?.id === roomId)[0];
    const [optionsState, SetOptionsState] = useState(() => statelistall?.filter((elm) => elm?.is_active)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.state_name })));
    // error state
    const [errorStateName, seterrorStateName] = useState("");
    const [errorPinCode, seterrorPinCode] = useState("");
    const [stateId, setStateId] = useState("")
    const [optionsCities, SetOptionsCities] = useState([]);
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
        }),
    };

    useEffect(() => {
        if (stateId && citylistall) {
            const filteredCities = citylistall.filter(city => city.state_id === stateId && city?.is_active === true);
            const mappedCities = filteredCities.map(city => ({
                key: city.id,
                value: city.id,
                label: city.district_name
            }));
            SetOptionsCities(mappedCities);
        }
    }, [stateId, citylistall]);

    // get current state, city,  starting digits
    const GetCurrentDetails = (district_id) => {
        const CurrentCity = pincodeList?.district?.filter((elm) => district_id === elm?.id)[0];
        const GetCurrentStateName = statelistall?.filter((elm) => CurrentCity?.state_id === elm?.id)[0];
        return {
            city: CurrentCity?.district_name,
            state: GetCurrentStateName?.state_name,
            city_id: CurrentCity?.state_id
        }
    };

    //get city
    useEffect(() => {
        if (stateId && optionsCities.length > 0) {
            var citi = optionsCities.filter((ele) => ele?.label === GetCurrentDetails(stateData?.district_id)?.city)
            setApplicationState({
                ...ApplicationState,
                CityName: citi,
            });
        }
    }, [stateId, optionsCities])

    useEffect(() => {
        if (LocationRoute === "edit-pincode") {
            const GetCurrentStateName = statelistall?.filter((elm) => GetCurrentDetails(stateData?.district_id)?.city_id === elm?.id)[0];
            const stateName = optionsState?.filter((ele) => GetCurrentStateName?.state_name === ele?.label)
            setStateId(stateName[0]?.value)
            setApplicationState({
                ...ApplicationState,
                StateName: stateName,
                Pincode: stateData?.pin_code,
                Status: stateData?.is_active,
            });
        };
    }, [stateData])

    const StateValueSelect = (value) => {
        if (value !== null) {
            setStateId(value?.value)
            setApplicationState({ ...ApplicationState, StateName: value });
            CheckValid(value, { type: 'StateName', error: seterrorStateName });
        } else {
            setApplicationState({ ...ApplicationState, StateName: "", CityName: "" });
            CheckValid(value === null ? "" : value, { type: 'StateName', error: seterrorStateName });
        }
    };

    //select city
    const CityValueSelect = (value) => {
        if (value !== null) {
            setApplicationState({ ...ApplicationState, CityName: value });
            CheckValid(value, { type: 'CityName', error: seterrorCityName });
        } else {
            setApplicationState({ ...ApplicationState, CityName: "" });
            CheckValid(value === null ? "" : value, { type: 'CityName', error: seterrorCityName });
        }
    };

    // create and edit brand
    const CreatePincode = () => {
        if (ApplicationState?.StateName !== "" && ApplicationState?.CityName !== "" &&
            ApplicationState?.GSTCode !== "") {

        } else {
            CheckValid(ApplicationState?.StateName, { type: 'StateName', error: seterrorStateName });
            CheckValid(ApplicationState?.CityName, { type: 'CityName', error: seterrorCityName });
            CheckValid(ApplicationState?.Pincode, { type: 'PINCode', error: seterrorPinCode });
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            Navigate(PINE_CODES_URL);
        };
    }, [ClearFormSet]);

    return (<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={PINE_CODES_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.Pincode}
                                onChange={(e) => setApplicationState({ ...ApplicationState, Pincode: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'PINCode', error: seterrorPinCode })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Pincode"
                            />
                            {errorPinCode !== "" && <span className="error_medotory">{errorPinCode}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Select State</Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select
                                    value={ApplicationState?.StateName}
                                    onChange={(e) => StateValueSelect(e)}
                                    // defaultValue={defaultCategoryOption} 
                                    isClearable
                                    options={optionsState}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select State"
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                />
                                {errorStateName !== "" && <span className="error_medotory">{errorStateName}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Select City</Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select
                                    value={ApplicationState?.CityName}
                                    onChange={(e) => CityValueSelect(e)}
                                    // defaultValue={defaultCategoryOption} 
                                    isClearable
                                    options={optionsCities}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    isDisabled={!ApplicationState?.StateName}
                                    placeholder="Select City"
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                />
                                {errorCityName !== "" && <span className="error_medotory">{errorCityName}</span>}
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle
                                    valueToggle={ApplicationState?.Status}
                                    setValueToggle={() => setApplicationState({ ...ApplicationState, Status: !ApplicationState?.Status })}
                                    name={""}
                                />
                                {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreatePincode()} variant="primary" >
                            {LocationRoute === "edit-pincode" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section >)
}

export default AddPincodes;