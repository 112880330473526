/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App.js";
import wsSend_request from "../../../Api/ws/ws_request.js";
import  {setGetMagicCode} from "../../../redux/actions/adminActions.js"
import getMagiccodeAPI from '../../../Api/getMagiccodeAPI.jsx';
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu.jsx";

const RmQuickLogin = () => {
    const { websocket } = useContext(WebSocketContext);
    const { userlistAll, device_id, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const [ RmUser, setRmUser ] = useState("");
    const optionsUserListState = userlistAll?.filter((elm) => elm?.is_active === true && (elm?.user_type === "regional_manager" || elm?.user_type === "regional_sales_team_rm" || elm?.user_type === "regional_sales_team_asm") )?.map((item, index)=>({key:item.id, value:item?.id , label:`${item?.user_code} - ${item?.full_name} - (${item?.user_type === "regional_sales_team_asm" ? "ASM" : "RM"})` }));
    const dispatch = useDispatch();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "user_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    const getMagicToken = async (value)=>{
        const rmUserCode = value.split(" - ")[0];
        rmUserCode.trim()
        if(value !== undefined ){
            const payload = {
                device_type:"web",
                device_id: device_id
            }
            const response = await getMagiccodeAPI(accessToken, payload);
            if(response?.status === 200){
                dispatch(setGetMagicCode(response?.data));
                setTimeout(() => {
                    const url = `${process.env.REACT_APP_RM_URL}/rm-login/${rmUserCode}/${response?.data}/${response?.device_id}/web`;
                    window.open(url, '_blank');
                }, 1000);
            }
        }
    };

    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3 " >
                        <Form.Label>RM Code<span className="mendatory_star">*</span></Form.Label>
                        <CommonReactSelectMenu 
                            label={"RM User"}
                            options={optionsUserListState} 
                            selectValue={RmUser} 
                            setSelectValue={setRmUser}
                            dependantState={{
                                allow: false,
                            }} 
                            isMendatory={false}
                            isAllowClearable={true}
                            customWidth={"100%"}
                            customeHeight={"38px"}
                            isMulti={false}
                        />
                    </Form.Group>
                </div>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[3]?.Edit) && (<div className="col-md-1">
                    <Form.Group className="mb-3 " >
                        <Form.Label>&nbsp;</Form.Label>
                        <Button id="whloginhbtn" className="whloginhbtn" onClick={() => getMagicToken(RmUser?.label)} disabled={!RmUser}>
                            Login
                        </Button>
                    </Form.Group>
                </div>)}
            </div>
        </div>
    </section>)
}

export default RmQuickLogin;