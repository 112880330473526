/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonDateTime from "../Common/CommonDateTime";
import { ADD_SALES_PERSON_PERFORMANCE_URL } from "../Shared/constant";
import { useNavigate } from "react-router-dom";
import CommonTable from "../Common/CommonTable";

const SalesPersonPerformance = (props) => {
    const { device_id, selfInfo, salesPersonPerformanceList, AllDealerFranchiseTypeListAll, franchiseSchemeAllList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ Franchise, setFranchise ] = useState("");
    const [ scheme_code, setscheme_code ] = useState("");
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const OptionFranchiseArray = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.customer_name }));
    const optionSchemeList = franchiseSchemeAllList?.map((item) => ({...item, key: item.scheme_code, value: item.scheme_code, label: item.scheme_code}))
    const today = new Date();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select franchise name
    const FranchiseName = (value) => {
        setFranchise(value);
        setCurrentPage(1);
    };

    // select schemecode
    const SelectSchemeOnchange = (value) => {
        setscheme_code(value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // get franchise_type_list all
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_scheme_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "start_date": "",
                "end_date": "",
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // api calling
    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "admin_scheme_report",
            "request" : { 
                "search" : userSearch.trim(),
                "franchise_id" : Franchise?.value,
                "scheme_code": scheme_code?.value,
                "from_date": null,
                "to_date": null,
                "limit" : userLimit,
                "page_no" : currentPage, 
                "order_by" :"-updated_at"
            },
            "module_name": "sales-person-performance",
            "DeviceId" : device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, currentPage, userLimit, Franchise, scheme_code, FromDate, ToDate ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_SALES_PERSON_PERFORMANCE_URL);
    };

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'full_name', label: 'User', type: "label", sort: "", isCollapsable: false },
        { key: 'sales_email', label: 'Email', type: "label", sort: "", isCollapsable: false },
        { key: 'product_value', label: 'Dealer', type: "label_get_from_franchise_data", sort: "", isCollapsable: false },
        { key: 'scheme_name', label: 'Scheme Name', type: "label", sort: "", isCollapsable: false },
        { key: 'scheme_date', label: 'Scheme Month', type: "months_show", sort: "", isCollapsable: false },
        { key: 'start_date', label: 'Start Date', type: "date", sort: "", isCollapsable: false },
        { key: 'end_date', label: 'End Date', type: "date", sort: "", isCollapsable: false },
        { key: 'target', label: 'Target', type: "label", sort: "", isCollapsable: false },
        { key: 'target_achived', label: 'Achvmnt', type: "label", sort: "", isCollapsable: false },
        { key: 'incentive_earned', label: 'Inc. Earned', type: "label", sort: "", isCollapsable: false },
        { key: 'device', label: 'Status', type: "Status_sales_person_performance", sort: "", isCollapsable: false },
    ];
    
    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group>
                        <div className="datepickField min">
                            <DatePicker
                                selected={FromDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                onChange={(date) => DateStartGet(date)}
                                dateFormat="dd/MM/yyyy"
                                maxDate={today}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter From Date"
                                isClearable={FromDate !== "" ? true : false}
                                name="statDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3" style={{ width: "23%" }}>
                    <Form.Group>
                        <div className="datepickField">
                            <DatePicker
                                selected={ToDate}
                                startDate={FromDate}
                                endDate={ToDate}
                                minDate={startDate}
                                maxDate={endDate}
                                onChange={(date) => DateEndGet(date)}
                                dateFormat="dd/MM/yyyy"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText="Enter To Date"
                                isClearable={ToDate !== "" ? true : false}
                                name="ToDate"
                                autoComplete="off"
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <Form.Group className="mb-3">
                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                            <Select
                                value={Franchise}
                                onChange={(e) => FranchiseName(e)}
                                isClearable
                                options={OptionFranchiseArray}
                                className="godown-select-container"
                                classNamePrefix="godown-select"
                                placeholder="Select Franchise"
                                styles={customStyles}
                            />
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-3">
                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                        <Select 
                            value={scheme_code}
                            onChange={(e) => SelectSchemeOnchange(e)} 
                            isClearable 
                            options={optionSchemeList}
                            className="godown-select-container" 
                            classNamePrefix="godown-select"
                            placeholder="Select scheme code"
                            styles={customStyles}
                        />
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter 
            filterType={"salesPersonPerformance"}
            searchType={"Email"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[2]?.Edit) ? true : false}
            searchdisable={true}
            ActiveInactiveFilter={false}
            ButtonSet={{
                buttontitle: "Create Monthly Target",
                function: cmnFnct
            }}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{ height: parseInt(salesPersonPerformanceList?.pagination?.total_records) === salesPersonPerformanceList?.list?.length ? "calc(100vh - 192px)" : salesPersonPerformanceList?.pagination?.total_records > 10 ? "calc(100vh - 230px)" : "calc(100vh - 192px)" }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={"sales-person-performance"} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={salesPersonPerformanceList} // ACCOUNT LEDGER REPORT LIST
            Pagination={salesPersonPerformanceList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={false} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: false,
                label: "",
                naviagte: ``
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isTotalAndGrand={{
                isShow: false,
                filterType: "",
                colSpan: 0
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default SalesPersonPerformance;