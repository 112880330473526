/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GetOrderReportDetails } from "../../../redux/actions/adminActions";
import { Link, useParams } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { FBA_ORDER_LIST } from "../../Shared/constant";
import PurchaseOrderReport from "../../Common/PurchaseOrderReport";
import CommonScrollBar from "../../Common/CommonScrollBar";

const OrderDetailsViewer = (props) => {
    const { OrderViewerModal } = props;
    const { OrderReportDetails, device_id } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const roomId = useParams();

    useEffect(() => {
        if(roomId?.roomId) {
            let param = { 
                "transmit": "broadcast", 
                "url": "fba_order_detail",
                "request" : { 
                    "order_id" : roomId?.roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId?.roomId, websocket ]);

    // common function
    const CommonFunction = async (id) => {
        const printContent = document.getElementById(id);
        const newWindow = window.open('', '_blank', "width=1200,height=600");
        newWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Window</title>
            <!-- Add Bootstrap CSS link -->
            <style>
                table.printTable {
                    border-collapse: collapse;
                }
                .printTable th, .printTable td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                }
                .printTable th {
                    background-color: #f2f2f2;
                }
                .sotklevelbtn {
                    display: none;
                }
            </style>
        </head>
        <body>
            ${printContent.innerHTML}
        </body>
        </html>`);
        newWindow.document.close();
        newWindow.print();

        // Listen for the print dialog close event
        const printDialogClosed = () => {
            newWindow.close();
            window.removeEventListener('focus', printDialogClosed);
        };
    
        // Event listener to handle print dialog close
        window.addEventListener('focus', printDialogClosed);
    };

    return(
        <React.Fragment>
            <section className="Main_section">
                <div className="MainHeader">
                    <button  className='btn btn-icon p-1' id="print_btn_function" onClick={() => CommonFunction("contentToPrint")}>
                        <i className="bi bi-printer"></i>
                    </button>
                    <Link className="btn btn-link backbtn" to={FBA_ORDER_LIST} onClick={() => Dispatch(GetOrderReportDetails({}))}><i className="bi bi-arrow-left-short"></i> Back</Link>
                </div>
                <CommonScrollBar height={{ height: "calc(100vh - 135px)"}} className="ScrollbarsSidebar" >
                    <div className="OrderDetailsModal" id="OrderDetailsModalNew">
                        <div >
                            <h5>{OrderViewerModal?.Title}</h5>
                        </div>
                        {/* print content */}
                        <div className="orderDetailsViewr" id="contentToPrint" style={{ display: "none" }}>
                            {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                <PurchaseOrderReport  OrderReportDetails={OrderReportDetails}/>
                            </div>) : (<>
                                Please Wait...
                            </>)}
                        </div>

                        <div className="orderDetailsViewr">
                            {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                <PurchaseOrderReport OrderReportDetails={OrderReportDetails}/>
                            </div>) : (<>
                                Please Wait...
                            </>)}
                        </div>
                    </div>
                </CommonScrollBar>
            </section>
        </React.Fragment>
    )
}

export default OrderDetailsViewer;