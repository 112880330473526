/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { useNavigate } from "react-router-dom";
import { ADD_SPARES_CAMPATIBILITY_URL, EDIT_SPARES_CAMPATIBILITY_URL } from "../../Shared/constant";
import { WebSocketContext } from "../../../App";
import { ClearFormSetFutios, errorCreateSparesCampatibilityList } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonTable from "../../Common/CommonTable";

const SparesCampatibiity = (props) => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { sparescampatibiityList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters[5]?.Edit || PermissionsAccess?.ProductMasters[5]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "spare_item_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "order_by": "-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_SPARES_CAMPATIBILITY_URL);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
        Dispatch(errorCreateSparesCampatibilityList([]));
    }, []);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'primary_item_code', label: 'Primary Item Code', type: "label", sort: "", isCollapsable: false },
        { key: 'primary_item_name', label: 'Primary Item Name', type: "label", sort: "", isCollapsable: false },
        { key: 'compatible_item_code', label: 'Compatible Item Code', type: "label", sort: "", isCollapsable: false },
        { key: 'compatible_item_name', label: 'Compatible Item Name', type: "label", sort: "", isCollapsable: false },
        { key: 'type', label: 'Category', type: "label", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Created At', type: "date", sort: "", isCollapsable: false },
        { key: 'primary_item_status', label: 'Primary Item Status', type: "status_active", sort: "", isCollapsable: false },
        { key: 'compatible_item_status', label: 'Compatible Item Status', type: "status_active", sort: "", isCollapsable: false },
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Primary Item Code, Primary Item Name, Campatible Item Code, Campatible Item Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[5]?.Edit)}
            ButtonSet={{
                buttontitle: "Create Spares Compatibility",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(sparescampatibiityList?.pagination?.total_records) === sparescampatibiityList?.list?.length ? "calc(100vh - 140px)" : parseInt(sparescampatibiityList?.pagination?.total_records) > 10 ? "calc(100vh - 175px)" : "calc(100vh - 140px)"
            }}
            srNumber={{
                clickable: false,
                events: ""
            }}
            isCollapsable={false}
            tabName={""} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={sparescampatibiityList} // SPARES COMPATIBILITY LIST
            Pagination={sparescampatibiityList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ProductMasters[0]?.Edit)} // EDITABLE ACCESS STATE
            isActionColumnAvailable={{
                isShow: true,
                label: "edit",
                naviagte: `${EDIT_SPARES_CAMPATIBILITY_URL}/`
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default SparesCampatibiity;