/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import CommonTable from "../../Common/CommonTable";

const ErpApiError = (props) => {
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ IsSpares, setIsSpares ] = useState(false);
    const { websocket } = useContext(WebSocketContext);
    const { device_id, ErpApiFailureList, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        let param = {
            "transmit": "broadcast",
            "url": "erp_order_log_list",
            "request": {
                "limit": userLimit,
                "page_no": currentPage,
                "order_status":"FAILURE",
                "is_invoice": false,
                "is_spares": IsSpares,
                "search": userSearch.trim(),
                "order_by": "-updated_at"
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [websocket, userSearch, userType, currentPage, userLimit, IsSpares]);

    // TABLE COLUMNS ARRAY
    const TableColumns = [
        { key: 'order_code', label: 'Order Id.', type: "label", sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Order Date', type: "date", sort: "", isCollapsable: false },
        { key: 'invoice_code', label: 'Invoice No.', type: "label", sort: "", isCollapsable: false },
        { key: 'invoice_at', label: 'Invoice Date', type: "date", sort: "invoice_at", isCollapsable: false },
        { key: 'order_source', label: 'Order Source', type: "label", sort: "order_source", isCollapsable: false },
        { key: 'customer_identity', label: 'Customer Code', type: "label", sort: "", isCollapsable: false },
        { key: 'order_status', label: 'Order Stage', type: "label_order_status", sort: "", isCollapsable: false },
    ];

    return(<section className="Main_section">
        <DataTableFilter 
            searchType={"Order Id."}
            filterType={"ERP_sales_order"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            IsSpares={IsSpares} 
            setIsSpares={setIsSpares}
        />
        {/* COMMON TABLE */}
        <CommonTable 
            style={{
                height: parseInt(ErpApiFailureList?.pagination?.total_records) === ErpApiFailureList?.list?.length ? "calc(100vh - 185px)" : parseInt(ErpApiFailureList?.pagination?.total_records) > 10 ? "calc(100vh - 221px)" : "calc(100vh - 185px)"
            }}
            srNumber={{
                clickable: false,
            }}
            isCollapsable={false}
            tabName={"erp-api-failures-Sales-Order"} // TAB URL
            PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
            PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
            TableColumns={TableColumns} // TABLE COLLUMNS & ROWS
            OrderList={ErpApiFailureList} // ORDER LIST
            Pagination={ErpApiFailureList?.pagination} // CURRENT TAB PAGINATION
            EditAccess={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[15]?.Edit)} // EDITABLE ACCESS STATE
            APIcalling={APICall}
            isActionColumnAvailable={{
                isShow: (selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[15]?.Edit),
                label: "Erp-Api-Failure-Retry",
                naviagte: null
            }}
            isActionTwoColumnAvailable={{
                isShow: false,
                url: "",
            }}
            isActionPreviewAvailable={{
                isShow: true,
                url: "erp_api_failures_error_view"
            }}
            isOdiFilterRowColorsAllow={false}
        />
    </section>)
}

export default ErpApiError;