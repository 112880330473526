/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button } from 'react-bootstrap';
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import SettingFields from "../Common/SettingFields";
import CommonScrollBar from "../Common/CommonScrollBar";

const Settings = (props) => {
    const { device_id, RefurbishedSettings, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ InvoiceGeared, setInvoiceGeared ] = useState(({
        Invoice: {
            Yes: "",
            No: "",
            YesScore: "",
            NoScore: ""
        },
        Geared: {
            Yes: "",
            No: "",
            YesScore: "",
            NoScore: ""
        },
        Suspension: {
            Yes: "",
            No: "",
            YesScore: "",
            NoScore: ""
        },
    }));
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // error state
    const [ errorInvoice, setErrorInvoice ] = useState("");
    const [ errorGeared, setErrorGeared ] = useState("");
    const [ errorSuspension, setErrorSuspension ] = useState("");

    // get current settings
    useEffect(() => {
        setInvoiceGeared(({
            ...InvoiceGeared,
            Invoice: {
                Yes: RefurbishedSettings?.invoice_yes,
                No: RefurbishedSettings?.invoice_no,
                YesScore: RefurbishedSettings?.invoice_yes_score,
                NoScore: RefurbishedSettings?.invoice_no_score,
            },
            Geared: {
                Yes: RefurbishedSettings?.geared_yes,
                No: RefurbishedSettings?.geared_no,
                YesScore: RefurbishedSettings?.geared_yes_score,
                NoScore: RefurbishedSettings?.geared_no_score,
            },
            Suspension: {
                Yes: RefurbishedSettings?.suspension_yes,
                No: RefurbishedSettings?.suspension_no,
                YesScore: RefurbishedSettings?.suspension_yes_score,
                NoScore: RefurbishedSettings?.suspension_no_score,
            }
        }));

        if(RefurbishedSettings?.invoice_yes?.trim() === "" || RefurbishedSettings?.invoice_no?.trim() === "" || RefurbishedSettings?.suspension_score?.trim() === "") {
            setErrorInvoice("Invoice percentage cannot be empty");
        } else {
            setErrorInvoice("");
        };
        if(RefurbishedSettings?.geared_yes?.trim() === "" || RefurbishedSettings?.geared_no?.trim() === "" || RefurbishedSettings?.geared_score?.trim() === "") {
            setErrorGeared("Geared percentage cannot be empty");
        } else {
            setErrorGeared("");
        };
        if(RefurbishedSettings?.suspension_yes?.trim() === "" || RefurbishedSettings?.suspension_no?.trim() === "") {
            setErrorSuspension("Suspension percentage cannot be empty");
        } else {
            setErrorSuspension("");
        };
    }, [ RefurbishedSettings ]);

    const NumberValidHundredValue = (e, type, method) => {
        if (e.target.value <= 100) {
            setInvoiceGeared({
                ...InvoiceGeared, 
                [type]: {
                    ...InvoiceGeared?.[type],
                    [method] : e.target.value.replace(/^0/, ""),
                } 
            })
        };
    };

    // save percentage
    const save_percentage = () => {
        if((InvoiceGeared?.Invoice?.Yes?.trim() !== "" && InvoiceGeared?.Invoice?.No?.trim() !== "" && InvoiceGeared?.Invoice?.YesScore !== "" && InvoiceGeared?.Invoice?.NoScore !== "") && (InvoiceGeared?.Geared?.Yes?.trim() !== "" && InvoiceGeared?.Geared?.No?.trim() !== "" && InvoiceGeared?.Geared?.YesScore !== "" && InvoiceGeared?.Geared?.NoScore !== "") && (InvoiceGeared?.Suspension?.Yes?.trim() !== "" && InvoiceGeared?.Suspension?.No?.trim() !== "" && InvoiceGeared?.Suspension?.YesScore !== "" && InvoiceGeared?.Suspension?.NoScore !== "")) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_settings_update",
                "request" : { 
                    "invoice_yes": InvoiceGeared?.Invoice?.Yes, 
                    "invoice_no": InvoiceGeared?.Invoice?.No,
                    "invoice_yes_score": InvoiceGeared?.Invoice?.YesScore,
                    "invoice_no_score": InvoiceGeared?.Invoice?.NoScore,
                    "geared_yes": InvoiceGeared?.Geared?.Yes, 
                    "geared_no": InvoiceGeared?.Geared?.No,
                    "geared_yes_score": InvoiceGeared?.Geared?.YesScore,
                    "geared_no_score": InvoiceGeared?.Geared?.NoScore,
                    "suspension_yes": InvoiceGeared?.Suspension?.Yes,
                    "suspension_no": InvoiceGeared?.Suspension?.No,
                    "suspension_yes_score": InvoiceGeared?.Suspension?.YesScore,
                    "suspension_no_score": InvoiceGeared?.Suspension?.NoScore,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            if(InvoiceGeared?.Invoice?.Yes?.trim() === "" || InvoiceGeared?.Invoice?.No?.trim() === "" || InvoiceGeared?.Invoice?.YesScore === "" || InvoiceGeared?.Invoice?.NoScore === "") {
                setErrorInvoice("Invoice percentage cannot be empty");
            } else {
                setErrorInvoice("");
            };
            if(InvoiceGeared?.Geared?.Yes?.trim() === "" || InvoiceGeared?.Geared?.No?.trim() === "" || InvoiceGeared?.Geared?.YesScore === "" || InvoiceGeared?.Geared?.NoScore === "") {
                setErrorGeared("Geared percentage cannot be empty");
            } else {
                setErrorGeared("");
            };
            if(InvoiceGeared?.Suspension?.Yes?.trim() === "" || InvoiceGeared?.Suspension?.No?.trim() === "") {
                setErrorSuspension("Suspension percentage cannot be empty");
            } else {
                setErrorSuspension("");
            };
        };
    };

    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "refurbished_settings_get", 
            "request" : {},
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket ]);

    const disablebtn = parseInt(InvoiceGeared?.Invoice?.Yes) === parseInt(RefurbishedSettings?.invoice_yes) && parseInt(InvoiceGeared?.Invoice?.No) === parseInt(RefurbishedSettings?.invoice_no) && parseInt(InvoiceGeared?.Invoice?.YesScore) === parseInt(RefurbishedSettings?.invoice_yes_score) && parseInt(InvoiceGeared?.Invoice?.NoScore) === parseInt(RefurbishedSettings?.invoice_no_score) && parseInt(InvoiceGeared?.Geared?.Yes) === parseInt(RefurbishedSettings?.geared_yes) && parseInt(InvoiceGeared?.Geared?.No) === parseInt(RefurbishedSettings?.geared_no) && parseInt(InvoiceGeared?.Geared?.YesScore) === parseInt(RefurbishedSettings?.geared_yes_score) && parseInt(InvoiceGeared?.Geared?.NoScore) === parseInt(RefurbishedSettings?.geared_no_score) && parseInt(InvoiceGeared?.Suspension.Yes) === parseInt(RefurbishedSettings?.suspension_yes) && parseInt(InvoiceGeared?.Suspension?.No) === parseInt(RefurbishedSettings?.suspension_no) && parseInt(InvoiceGeared?.Suspension?.YesScore) === parseInt(RefurbishedSettings?.suspension_yes_score) && parseInt(InvoiceGeared?.Suspension?.NoScore) === parseInt(RefurbishedSettings?.suspension_no_score);

    return(<section className="Main_section">
        <div className="tableView">
            <CommonScrollBar height={{ height: "calc(100vh - 90px)"}}>
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">Invoice</h6>
                    <div className="settting_Staus_wrapper">
                        <SettingFields 
                            label={"Yes"} 
                            className={"settting_Staus"}
                            type={"success"}
                            setting={InvoiceGeared?.Invoice?.Yes} 
                            setSetting={(e) => NumberValidHundredValue(e, "Invoice", "Yes")}
                            percentageShow={true}
                        />
                        <SettingFields 
                            label={"Yes Score"} 
                            className={"settting_Staus settting_Staus_score"}
                            type={"success"}
                            setting={InvoiceGeared?.Invoice?.YesScore} 
                            setSetting={(e) => NumberValidHundredValue(e, "Invoice", "YesScore")}
                            percentageShow={false}
                        />
                        <SettingFields 
                            label={"No"} 
                            className={"settting_Staus"}
                            type={"danger"}
                            setting={InvoiceGeared?.Invoice?.No} 
                            setSetting={(e) => NumberValidHundredValue(e, "Invoice", "No")}
                            percentageShow={true}
                        />
                        <SettingFields 
                            label={"No Score"} 
                            className={"settting_Staus settting_Staus_score"}
                            type={"danger"}
                            setting={InvoiceGeared?.Invoice?.NoScore} 
                            setSetting={(e) => NumberValidHundredValue(e, "Invoice", "NoScore")}
                            percentageShow={false}
                        />
                    </div>
                    {errorInvoice !== "" && <span className="error_medotory">{errorInvoice}</span>}
                </div>
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">Geared</h6>
                    <div className="settting_Staus_wrapper">
                        <SettingFields 
                            label={"Yes"} 
                            className={"settting_Staus"}
                            type={"success"}
                            setting={InvoiceGeared?.Geared?.Yes} 
                            setSetting={(e) => NumberValidHundredValue(e, "Geared", "Yes")}
                            percentageShow={true}
                        />
                        <SettingFields 
                            label={"Yes Score"} 
                            className={"settting_Staus settting_Staus_score"}
                            type={"success"}
                            setting={InvoiceGeared?.Geared?.YesScore} 
                            setSetting={(e) => NumberValidHundredValue(e, "Geared", "YesScore")}
                            percentageShow={false}
                        />
                        <SettingFields 
                            label={"No"} 
                            className={"settting_Staus"}
                            type={"danger"}
                            setting={InvoiceGeared?.Geared?.No} 
                            setSetting={(e) => NumberValidHundredValue(e, "Geared", "No")}
                            percentageShow={false}
                        />
                        <SettingFields 
                            label={"No Score"} 
                            className={"settting_Staus settting_Staus_score"}
                            type={"danger"}
                            setting={InvoiceGeared?.Geared?.NoScore} 
                            setSetting={(e) => NumberValidHundredValue(e, "Geared", "NoScore")}
                            percentageShow={false}
                        />
                    </div>
                    {errorGeared !== "" && <span className="error_medotory">{errorGeared}</span>}
                </div>
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">Suspension</h6>
                    <div className="settting_Staus_wrapper">
                        <SettingFields 
                            label={"Yes"} 
                            className={"settting_Staus"}
                            type={"success"}
                            setting={InvoiceGeared?.Suspension?.Yes} 
                            setSetting={(e) => NumberValidHundredValue(e, "Suspension", "Yes")}
                            percentageShow={true}
                        />
                        <SettingFields 
                            label={"Yes Score"} 
                            className={"settting_Staus settting_Staus_score"}
                            type={"success"}
                            setting={InvoiceGeared?.Suspension?.YesScore} 
                            setSetting={(e) => NumberValidHundredValue(e, "Suspension", "YesScore")}
                            percentageShow={false}
                        />
                        <SettingFields 
                            label={"No"} 
                            className={"settting_Staus"}
                            type={"danger"}
                            setting={InvoiceGeared?.Suspension?.No} 
                            setSetting={(e) => NumberValidHundredValue(e, "Suspension", "No")}
                            percentageShow={false}
                        />
                        <SettingFields 
                            label={"No Score"} 
                            className={"settting_Staus settting_Staus_score"}
                            type={"danger"}
                            setting={InvoiceGeared?.Suspension?.NoScore} 
                            setSetting={(e) => NumberValidHundredValue(e, "Suspension", "NoScore")}
                            percentageShow={false}
                        />
                    </div>
                    {errorSuspension !== "" && <span className="error_medotory">{errorSuspension}</span>}
                </div>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[2]?.Edit) && (<Button className="save_percentage" disabled={disablebtn} onClick={() => save_percentage()}>Save</Button>)}
            </CommonScrollBar>
        </div>
    </section>)
}

export default Settings;