/* eslint-disable */
import React, { useState } from "react";
import FbaOrderTab from "./FbaOrderTab";
import FbaOrderLogsTab from "./FbaOrderLogsTab";

const FbaOrdersList = (props) => {
    const [ Tab, setTab ] = useState("orders");

    return (<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li className={Tab === "orders" ? "active" : ""} onClick={() => setTab("orders")}>Orders</li>
                <li className={Tab === "logs" ? "active" : ""} onClick={() => setTab("logs")}>Logs</li>
            </ul>
        </div>
        <React.Fragment>
            {Tab === "orders" ? (<>
                <FbaOrderTab />
            </>) : (<>
                <FbaOrderLogsTab />
            </>)}
        </React.Fragment>
    </section>)
}

export default FbaOrdersList;