/* eslint-disable */
import jsPDF from "jspdf";
import "jspdf-autotable";

const generatePDF = (filterType, fileName) => {
    const table = document.getElementById(filterType);
    const rows = table.querySelectorAll("tr");

    // Extract table data
    const csvData = [];
    rows.forEach((row, index) => {
        const rowData = [];
        const cells = index === 0 ? row.querySelectorAll("th") : row.querySelectorAll("td");
        cells.forEach((cell) => rowData.push(cell.innerText));
        csvData.push(rowData);
    });

    // Use jsPDF to generate PDF
    const doc = new jsPDF((fileName === "Erp General Outstanding Report" || fileName === "Transaction History Ageing") ? {
        unit: 'mm',
        format: [400, 297], // Custom width, height (mm)
    } : "");
    const headers = [csvData[0]]; // First row as headers
    const data = csvData.slice(1); // Remaining rows as data

    // Add table heading/title
    const tableHeading = table.getAttribute("data-heading") || fileName; // Get heading from a custom attribute or use filterType as fallback
    doc.setFontSize(14);

    // Calculate the center position for the text
    const pageWidth = doc.internal.pageSize.getWidth();
    const textWidth = doc.getTextWidth(tableHeading);
    const centerX = (pageWidth - textWidth) / 2;

    doc.text(tableHeading, centerX, 7);

    let updatedData;
    
    if(fileName === "Erp Summary General Details") {
        updatedData = data.map((row) => {
            if (row[0] === "Page total" || row[0] === "Grand total") {
                return [
                    { content: row[0], colSpan: 4, styles: { halign: "right" } },
                    ...row.slice(1), // Keep the remaining columns as is
                ];
            }
            return row; // Leave other rows unchanged
        });
    } else {
        updatedData = data;
    };

    // Add table to PDF
    doc.autoTable({
        head: headers,
        body: updatedData,
        startY: 10, // Start position
        theme: 'striped', // Adds borders
        styles: { fontSize: 8 }, // Adjust font size
        margin: { top: 10, left: 10, right: 10 },
        didDrawPage: (updatedData) => {
            // Add page number at the bottom
            const pageCount = doc.internal.getNumberOfPages();
            const pageSize = doc.internal.pageSize;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Page ${updatedData.pageNumber} of ${pageCount}`, updatedData.settings.margin.left, pageHeight - 10);
        },
    });

    // Save the PDF
    doc.save(filterType + ".pdf");
};

export default generatePDF;