import React from "react";
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import CycleIcon from "../../../assets/img/cycle_icons.png";
import RupeesIcon from "../../../assets/img/rupee_icons.png";
import IncreDeacrementPercentage from "./IncreDeacrementPercentage";
import AmountNumberFormat from "../../Common/AmountNumberFormat";

const DashBoardCards = (props) => {
    const { columns, icons, label, total_value, compaared, style, compaaredPercantage, type } = props;
    const CheckTotalAmount = total_value === undefined ? 0 : total_value?.toString().length + 2;

    return(<div className={`col-md-${columns}`} style={style}>
        <div className="dashboard_cards">
            <div className="dashboard_cards_Image">
                <Image src={icons === "cycle" ? CycleIcon : RupeesIcon} alt={icons} />
            </div>
            <h5>{label}</h5>
            {CheckTotalAmount > 15 ? (<OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                {label} : {type === "Qty" ? total_value : "₹" + AmountNumberFormat(total_value)}
            </Tooltip>}>
                <h2>{type === "Qty" ? total_value : "₹" + AmountNumberFormat(total_value)}</h2>
            </OverlayTrigger>) : (<h2>{type === "Qty" ? total_value : "₹" + AmountNumberFormat(total_value)}</h2>)}
            <p>Compared to {compaared} {IncreDeacrementPercentage(parseFloat(parseFloat(compaaredPercantage).toFixed(2)), "Badge")}</p>
        </div>
    </div>)
};

export default DashBoardCards;