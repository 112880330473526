/* eslint-disable */
import React, { useEffect } from "react";
import { Nav, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import DashboardIcon from "../../../assets/img/dashboard_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { SelectMainNavigation } from "../../../redux/actions/adminActions";
import MainMenuIcon1 from "../../../assets/img/Inventory.svg";
import MainMenuIcon2 from "../../../assets/img/admin-tools.svg";
import MainMenuIcon3 from "../../../assets/img/order-management.svg";
import MainMenuIcon4 from "../../../assets/img/crm-tools.svg";

const SidebarMain = (props) => {
    const { selfInfo, darkmode } = useSelector((state) => state.adminReducers);
    const { sidebarToggle,setSidebarToggle, menuStates, setMenuStates } = props;
    const Dispatch = useDispatch();
    const { MainNavUrl } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // clear menu selection and menu select
    const ClearMenuSelection = (url) => {
        Dispatch(SelectMainNavigation(url));
        if(!sidebarToggle) {
            setSidebarToggle(true);
        };
        if(url === "Dashboard") {
            setMenuStates({
                ...menuStates,
                menu1: false,
                menu2: false,
                menu3: false,
                menu4: false,
                menu5: true,
            });
        } else {
            setMenuStates({
                ...menuStates,
                menu1: false,
                menu2: false,
                menu3: false,
                menu4: false,
                menu5: false,
            });
        };
    };

    useEffect(() => {
        let body = document.getElementById("main_body");

        body.classList.add(darkmode);
    }, [ darkmode ]);

    return(<div className="sidebarMain">
        <Nav className="flex-column">
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.DashboardMain?.filter((elm) => elm?.API !== "")?.length > 0 || (PermissionsAccess?.Dashboard?.DailyOrdersRGLBangalore || PermissionsAccess?.Dashboard?.DailyOrdersRGLDelhi || PermissionsAccess?.Dashboard?.DailyOrdersRGLKolkata || PermissionsAccess?.Dashboard?.DealerPerformance || PermissionsAccess?.Dashboard?.OrderFulfillment || PermissionsAccess?.Dashboard?.SalesRevenueReport || PermissionsAccess?.Dashboard?.AllOrderSummery ))) &&
                <OverlayTrigger
                    placement={sidebarToggle ? "top" : "right"}
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>Dashboard</Tooltip>}
                >
                    <a className={MainNavUrl === "Dashboard" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Dashboard")}>
                        <Image src={DashboardIcon} className="dashboard_icon_main" alt="dashboard" />
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0)) &&
                <OverlayTrigger
                    placement={sidebarToggle ? "top" : "right"}
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>Inventory</Tooltip>}
                >
                    <a className={MainNavUrl === "Inventory" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Inventory")}>
                        <Image src={MainMenuIcon1} alt="menu_icon" />                        
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0)) &&
                <OverlayTrigger
                    placement={sidebarToggle ? "top" : "right"}
                    delay={{ show: 250, hide: 100 }}
                    overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                    Admin Tools
                </Tooltip>}
                >
                    <a className={MainNavUrl === "Admin-Tools" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Admin-Tools")}>
                        <Image src={MainMenuIcon2} alt="menu_icon" />
                    </a>
                </OverlayTrigger>
            }
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0)) &&
            <OverlayTrigger
                placement={sidebarToggle ? "top" : "right"}
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Order Management
              </Tooltip>}
            >
                <a className={MainNavUrl === "Franchise" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("Franchise")}>
                    <Image src={MainMenuIcon3} alt="menu_icon" />
                </a>
            </OverlayTrigger>}
            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.ReSells?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.DealerApp?.filter((elm) => elm?.API !== "")?.length > 0) &&
            <OverlayTrigger
                placement={sidebarToggle ? "top" : "right"}
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                CRM Tools
              </Tooltip>}
            >
                <a className={MainNavUrl === "CRM-Tools" ? "nav-link active" : "nav-link"} onClick={() => ClearMenuSelection("CRM-Tools")}>
                    <Image src={MainMenuIcon4} alt="menu_icon" />
                </a>
            </OverlayTrigger>}
        </Nav>
    </div>)
};

export default SidebarMain;