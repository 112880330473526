/* eslint-disable */
import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import "./assets/css/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { LOGIN_URL, DASHBOARD_URL, SET_RM_LOGIN_URL, SET_WM_LOGIN_URL, SET_LOGIN_URL } from './components/Shared/constant';
import AuthLayout from './components/Shared/AuthLayout';
import Login from './components/Login';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import useWebSocket from './Api/UseWebsocket';
import SetLogin from './components/SetLogin';
import { getAccessToken, SelectMainNavigation, clearRedux } from './redux/actions/adminActions';
import PageLoad from './PageLoad';
import ShowInvoiceModal from './components/Common/ShowInvoiceModal';
import WMLogin from './components/WMLogin';
import RmLogin from './components/RmLogin';
import { AppModules } from './AppModules';
import WebSocketDisconnectModal from './components/Common/WebSocketDisconnectModal';
import Privacypolicy from './components/Privacypolicy';
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  const Dispatch = useDispatch();
  const { accessToken, MainNavUrl, selfInfo, OrderManagmentFilter } = useSelector((state) => state.adminReducers);
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");
  const PermissionsAccess = selfInfo?.user?.permissions_access;
  const [ isOnline, setIsOnline ] = useState(navigator.onLine);
  const [ WebsocketCheck, setWebsocketCheck ] = useState("");

  // websocket connection
  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}/${localStorage.getItem("access_token")}/`, setGetWEBSocket, localStorage.getItem("access_token"), setWebsocketCheck);

  useEffect(() => {
    if(localStorage.getItem("access_token") === null) {
       Dispatch(getAccessToken(""));
    } else {
      Dispatch(getAccessToken(localStorage.getItem("access_token")));
    };
  }, []);

  useEffect(() => {
    if(Object?.keys(selfInfo)?.length === 0) {
       Dispatch(getAccessToken(""));
    };
  }, [ selfInfo ]);

  useEffect(() => {
    if(MainNavUrl === "") {
        if(PermissionsAccess === null) {
          Dispatch(SelectMainNavigation("Dashboard"));
        } else if(PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("Inventory"));
        } else if(PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("Admin-Tools"));
        } else if(PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("Franchise"));
        } else if(PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("CRM-Tools"));
        } else {
          // Dispatch(SelectMainNavigation("Inventory"));
        }
    } else {
      
    };
  }, [  ]);

  // internet check
  useEffect(() => {
      const handleOnline = () => setIsOnline(true);
      const handleOffline = () => setIsOnline(false);

      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);

      return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
      };
  }, []);

  function checkStyleSheet() {
    var link = document.querySelector('link[rel="stylesheet"]');
    if (link) {
        fetch(link.href).then(response => {
            if (response.headers.get("Content-Type") !== "text/css") {
                console.error("Incorrect MIME type for stylesheet.");
                location.reload();
            }
        }).catch(error => {
            console.error("Error fetching stylesheet:", error);
            location.reload();
        });
    }
  };

  window.onload = function() {
    checkStyleSheet();
  };

  useEffect(() => {
    if(OrderManagmentFilter === undefined) {
        Dispatch(clearRedux());
        localStorage?.removeItem("access_token");
    };
  }, [ OrderManagmentFilter ]);

  function getOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Find all elements with the class name 'system'
      const elements = document.querySelectorAll('.ScrollbarsSidebar');

      if (/linux/i.test(userAgent)) {
          elements.forEach(element => {
            element.classList.add('ScrollbarsSidebar-active');
          });
      };
  };

  useEffect(() => {
    getOperatingSystem();
  }, []);

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket}}>
    <div className="wrapper">
        <BrowserRouter>
          <PageLoad state={false}/>
          <Routes>
            <Route path={`${SET_RM_LOGIN_URL}`} element={<RmLogin />} />
            <Route path={`${SET_WM_LOGIN_URL}`} element={<WMLogin />} />
            <Route path={SET_LOGIN_URL} element={accessToken === "" ? <Navigate  replace to={LOGIN_URL}/> : <SetLogin />} />
            <Route path="/" element={accessToken === "" ? <Login /> : <Navigate  replace to={DASHBOARD_URL}/>} >
                <Route path={LOGIN_URL} element={accessToken === "" ? <Login /> : <Navigate  replace to={DASHBOARD_URL}/>} />
            </Route>
            {/* after login all modules */}
            {AppModules?.map((elm, index) => {
              return(<Route 
                key={index}
                path={elm?.path} 
                element={accessToken !== "" ? (<AuthLayout>{elm?.element}</AuthLayout>) : (<Navigate replace to={LOGIN_URL}/>)} 
              />)
            })}
            <Route path="*" element={<Navigate replace to={accessToken === "" ? LOGIN_URL : SET_LOGIN_URL}/>} />
            <Route path="privacy-policy" element={<Privacypolicy />} />
          </Routes>
          <ShowInvoiceModal/>
        </BrowserRouter>
        <ToastContainer />
    </div>

    {/* websocket disconnect modal  */}
    {(accessToken !== "" && (!isOnline || WebsocketCheck === "websocket close")) && (<WebSocketDisconnectModal WebsocketCheck={WebsocketCheck} />)}
  </WebSocketContext.Provider>);
}

export default App;