import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';

const CommonScrollBar = (props) => {
    const { className, height, children } = props;

    const renderThumbVertical = ({ style, ...props }) => {
        const thumbStyle = {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
        };
        return <div className="custom-thumb-vertical" style={{ ...style, ...thumbStyle }} {...props} />;
    };
    
    const renderThumbHorizontal = ({ style, ...props }) => {
        const thumbStyle = {
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: '4px',
        };
        return <div className="custom-thumb-horizontal" style={{ ...style, ...thumbStyle }} {...props} />;
    };

    return(<Scrollbars 
        style={{ height: height?.height}}   
        renderView={props => <div {...props} className="view"/>}
        renderThumbVertical={renderThumbVertical}
        renderThumbHorizontal={renderThumbHorizontal}
        className={className !== "" ? className : "ScrollbarsSidebar"}
    >
        {children}
    </Scrollbars>)
};

export default CommonScrollBar;