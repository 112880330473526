/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Button , Collapse, Image, Modal, FormControl, Spinner } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { RETURN_AND_REPLACE_REPORT } from "../../Shared/constant";
import { Link, useNavigate } from "react-router-dom";
import MoreIcon from '../../../assets/img/more_options.png';
import { TimeSense, getColor } from "../../Common/TimeSense";
import { ClearFormSetFutios, getTableSorting, GetReturnReplaceInvoiceOrderDetails, StartMainCommonLoader } from "../../../redux/actions/adminActions";
import CommonStatus from "../../Common/CommonStatus";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";
import ReactDatePicker from "react-datepicker";
import SelectReact from "../../Common/SelectReact";
import moment from "moment";
import BrowserUniqueTabID from "../../Common/BrowserUniqueTabID";
import CommonLoader from "../../Common/CommonLoader";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import OrderCancelModal from "../../Common/OrderCancelModal";
import CommonScrollBar from "../../Common/CommonScrollBar";

const ReturnAndReplace = (props) => {
    const navigate = useNavigate();
    const { RouteName } = props;
    const [orderSource, setOrderSource] = useState("")
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const today = new Date();
    const [ selectRegion, setSelectRegion ] = useState("");
    const { device_id, ReturnReplaceOrderList, OptionCourierList, invoiceDetails, Regionlistall, ClearFormSet, sortingfor, selfInfo, commonMainLoader, accessToken } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    var tabID = BrowserUniqueTabID();

    //order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const [showModal, setShowModal] = useState({
        open: false,
        title: "",
        modalType: "",
        Reason: "",
        Data: {}
    });
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        Data: {}
    });

    const [formData, setFormData] = useState({
        dispatch_date:"",
        distance:0,
        est_delivered_date:"",
        docket_number:"",
        courier_id:"",
        docket_slip: "",
        delivered_date: "",
        is_dispatch:"",
        order_id: "",
    });
    const [ errorReason, setErrorReason ] = useState("");
    const [ Loader, setLoader ] = useState(false);

    // error state
    const [ docket_slip, setError_docket_slip ] = useState("")

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };

    // close modal
    const onClose = () => {
        setShowModal({
            ...showModal,
            open: false,
            title: "",
            modalType: "",
            Reason: "",
            Data: {}
        });
        setShowModalNew({
            ...showModalNew,
            open: false,
            title: "",
            Data: {}
        });
        setFormData({
            ...formData,
            dispatch_date:"",
            distance:0,
            est_delivered_date:"",
            docket_number:"",
            courier_id:"",
            docket_slip: "",
            delivered_date: "",
            is_dispatch:"",
            order_id: "",
        });
        Dispatch(ClearFormSetFutios({
            url: "",
            action: false
        }));
        setErrorReason("");
        setLoader(false);
        Dispatch(GetReturnReplaceInvoiceOrderDetails({}));
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        Dispatch(getTableSorting(""))
    }, [])

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "courier_list",
            "request" : { 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : "",
                "status": true,
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    const selectRegionFunction = (e) => {
        setSelectRegion(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };


    // get order_list all
    useEffect(() => {
        if (websocket) {
            ReturnReplaceOrder();
        }
    }, [websocket, userSearch, userType, selectRegion, currentPage, userLimit, FromDate, ToDate, orderSource, sortingfor]);

    const ReturnReplaceOrder = () => {
        let param = {
            "transmit": "broadcast",
            "url": "order_list",
            "request": {
                "status": "RETURN,REPLACE,RETURN_REQUEST,RETURN_IN_TRANSIT,REPLACE_REQUEST",
                "limit": userLimit,
                "page_no": currentPage,
                "search": userSearch.trim(),
                "from_date": null,
                "to_date": null,
                "from_inv": null,
                "to_inv": null,
                "order_type": orderSource,
                "region_id": selectRegion,
                "order_by": sortingfor,
            },
            "DeviceId": device_id
        };
        if (FromDate !== "" && ToDate !== "") {
            param.request.from_inv = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
            param.request.to_inv = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
        }
        wsSend_request(websocket, param);
    };

    const callInvoiceModal = (e, code) => {
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request": {
                "order_id": code,
            },
            "DeviceId": device_id,
            "TabId": tabID,
            modal: true
        };
        wsSend_request(websocket, param);
    };

    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "order_rr") {
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
        if (ClearFormSet?.action === 200 && ClearFormSet?.url === "order_replace") {
            onClose();
        };
    }, [ClearFormSet]);

    const cmnFnct = () => {
        navigate(RETURN_AND_REPLACE_REPORT);
    };

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = ReturnReplaceOrderList?.regionlist?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name
        }
    };

    // accept modal open
    const ModalAcceptOpen = (data , url) => {
        setShowModal({
            ...showModal,
            open: !showModal?.open,
            title: `Order ${url}`,
            modalType: data?.order_status,
            Data: data
        });
        OrderDetailsModal(data?.invoice_code);
    };

    // order details api calling
    const OrderDetailsModal = (id) => {
        let param = { 
            "transmit": "broadcast", 
            "url": "order_detail",
            "request" : { 
                "order_id" : id,
                "order_by" : "invoice"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // unicommorce return request cancel order
    const ReturnRquestAcceptOpen = (data) => {
        const param = {
            "transmit": "broadcast",
            "url": "order_cancelled",
            "request" :{
                "order_id" : data?.id,
                "cancel_reason" : data?.cancel_reason,
                "order_status" : "CANCELLED",
            },
            'DeviceId':device_id,
        }
        wsSend_request(websocket, param)
    };

    // order success reject flow
    const OrderSuccesReject = (url) => {
        if(showModal?.Reason?.trim() !== "") {
            setErrorReason("");
            let param;
            
            if(url === "Order REJECT_RETURN_REQUEST") {
                param = {
                    "transmit": "broadcast",
                    "url": "order_cancelled",
                    "request" :{
                        "order_id" : showModal?.Data?.id,
                        "cancel_reason" : showModal?.Reason?.trim(),
                        "order_status" : "CANCELLED",
                    },
                    'DeviceId':device_id,
                }
            } else {
                param = {
                    "transmit": "broadcast",
                    "url": "order_cancelled",
                    "request" :{
                        "order_id" : showModal?.Data?.id,
                        "cancel_reason" : showModal?.Reason?.trim(),
                        "order_status" : url === "Order Accept" ? "RETURN" : showModal?.Data?.cancel_stage,
                    },
                    'DeviceId':device_id,
                }
            };
            wsSend_request(websocket, param);
        } else {
            if(showModal?.Reason?.trim() !== "") {
                setErrorReason("");
                const param = {
                    "transmit": "broadcast",
                    "url": "order_cancelled",
                    "request" :{
                        "order_id" : showModal?.Data?.id,
                        "cancel_reason" : showModal?.Reason?.trim(),
                        "order_status" : url === "Order Accept" ? "RETURN" : showModal?.Data?.cancel_stage,
                    },
                    'DeviceId':device_id,
                }
                wsSend_request(websocket, param);
            } else {
                setErrorReason("Please enter reason")
            };
        };
    };

    const onChange = (e) => {
        if (e.target.name === "distance" && e.target.value !== "") {
            e.target.value = e.target.value.replace(/^0/g, "");
        }
       // Allow only numbers
        if (e.target.name === "docket_number") {
            if(parseInt(e.target.value)<=0){
                e.target.value = "";
            }
            e.target.value = e.target.value; // Remove non-numeric characters
        }
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }));
    };
    
    const onSelect = (e, name) =>{
        setFormData((data)=>({
            ...data,
            [name]:e
        }))
    };

    const callSubmitForm = (e, state) =>{
        e.preventDefault();
        if(invoiceDetails?.order?.is_pin_same === true && formData?.distance<=0){
            return toast.info("Distance is required greater than 0");
        }
        
        if(formData?.courier_id ){
            let param = { 
                "transmit": "broadcast", 
                "url": "order_dispatch",
                "request" : {
                    "order_id" : showModal?.Data?.id,
                    is_dispatch: state?true:false,
                    courier_id:formData?.courier_id,
                    distance:  formData?.distance,
                    est_delivered_date: moment(formData?.est_delivered_date).format("YYYY-MM-DD"),
                    is_reverse: true,
                },
                "DeviceId" : device_id
            };
            if(state){
                param.request.distance = formData?.distance
            }
            if(state && formData?.docket_number){
                param.request.docket_number = formData?.docket_number
            }
            if(state && formData?.est_delivered_date){
                param.request.est_delivered_date = moment(formData?.est_delivered_date).format("YYYY-MM-DD");
            }
            if(state && formData?.dispatch_date){
                param.request.dispatch_date = moment(formData?.dispatch_date).format("YYYY-MM-DD");
            }
            wsSend_request(websocket, param);
        }
    };

    useEffect(() => {
        if((ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") || (ClearFormSet?.action === 200 && ClearFormSet?.url == "order_replace") || (ClearFormSet?.action === 200 && ClearFormSet?.url == "ao_order_return") || (ClearFormSet?.action === 200 && ClearFormSet?.url == "order_dispatch_reverse")) {
            setShowModal({
                ...showModal,
                open: false,
                title: "",
                modalType: "",
                Reason: "",
                Data: {}
            });
            setShowModalNew({
                ...showModalNew,
                open: false,
                title: "",
                Data: {}
            });
            ReturnReplaceOrder();
            onClose();
            setCurrentPage(1);
        };
    }, [ ClearFormSet ]);

    // ao order cance modal open function
    const OrderCncelFunct = (data) => {
        setShowModalNew({
            ...showModalNew,
            open: !showModal?.open,
            title: `Order Return`,
            Data: data
        });

        setFormData({
            ...formData,
            dispatch_date: data?.dispatch_date === null ? "" : new Date(data?.dispatch_date),
            docket_number: data?.docket_number === null ? "" : data?.docket_number,
        });
    };

    // upload Docket Slip file
    const UploadImage = async (e) => {
        setLoader(true);
        const files = e.target.files[0];
        if(files){
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if(fileresponse?.status === 200) {
                setLoader(true);
                setFormData((data)=>({
                    ...data,
                    [e.target.name]:fileresponse?.id,
                    fileName: files.name,
                }))
                setError_docket_slip("")
            } else {
                setLoader(false);
                // setUploadFileLoader(false);
            };
        } else{
            setLoader(false);
            setFormData((data)=>({
                ...data,
                [e.target.name]:"",
                fileName: "",
            }))
            setError_docket_slip("Docket Slip Is Required")
        }
    };

    // ao order return 
    const AoOrderReturn = (url) => {
        Dispatch(StartMainCommonLoader({
            url: "ao_order_return",
            loader: true,
        }));

        let ParamUpdate;
        if(url === "from_eship_auto") {
            ParamUpdate = {
                "order_id" : showModal?.Data?.id,
                "docket_slip": null,
                "docket_number": null,
                "dispatch_date": null,
                "delivered_date": null,
                "is_forcibly_accepted": true,
            };
        } else {
            ParamUpdate = {
                "order_id" : showModalNew?.Data?.id,
                "docket_slip": formData?.docket_slip,
                "docket_number": formData?.docket_number,
                "dispatch_date": moment(formData?.est_delivered_date).format("YYYY-MM-DD"),
                "delivered_date": moment(formData?.est_delivered_date).format("YYYY-MM-DD"),
                "is_forcibly_accepted": false,
            };
        };
        let param = { 
            "transmit": "broadcast", 
            "url": "ao_order_return",
            "request" : ParamUpdate,
            "DeviceId" : device_id
        };

        wsSend_request(websocket, param);
    };

    const RemoveDocketSlip = () => {
        setFormData({...formData, docket_slip:""})
        setLoader(false);
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={selectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": null, "ToDate": null, "FromInv": FromDate, "ToInv": ToDate, "status": "RETURN,REPLACE", "region_id": selectRegion,"search": userSearch.trim(), "order_type": orderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            searchType={"Order Id, Name, Invoice ID, Customer Code"}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[14]?.Edit) ? true : false}
            searchdisable={true}
            orderSource={orderSource}
            callSelectSource={setOrderSource}
            ButtonSet={{
                buttontitle: "Return & Replace",
                function: cmnFnct
            }}
        />
        <div className="tableView orderlistTable gstreporttableadded returnAndReplaceTableModules">
            <CommonScrollBar 
                height={{ height: parseInt(ReturnReplaceOrderList?.pagination?.total_records) === ReturnReplaceOrderList?.list?.length ? "calc(100vh - 200px)" : ReturnReplaceOrderList?.pagination?.total_records > 10 ? "calc(100vh - 240px)" : "calc(100vh - 200px)"}}
                className="ScrollbarsSidebar"
            >
                <Table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>Name</th>
                            <th className={sortingfor === "invoice_code" ? "active" : sortingfor === "-invoice_code" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_code")}>Invoice ID</th>
                            <th className={sortingfor === "invoice_at" ? "active" : sortingfor === "-invoice_at" ? "inactive" : ""} onClick={() => CommonSortingFct("invoice_at")}>Invoice Date</th>
                            <th className={sortingfor === "courier_name" ? "active" : sortingfor === "-courier_name" ? "inactive" : ""} onClick={() => CommonSortingFct("courier_name")}>Transporter</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                            <th>Criticality</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ReturnReplaceOrderList?.list?.map((elm, index) => {
                            const get_color = getColor(elm);
                            return (
                                <React.Fragment key={index}>
                                    <tr key={index}>
                                        <td style={{ width: '10px' }}>
                                            <button
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                                {GetCurrentTableSRNo(index, ReturnReplaceOrderList?.pagination?.current_page, ReturnReplaceOrderList?.pagination?.record_limit)}
                                            </Link>
                                        </td>
                                        <td>
                                            <b style={{ cursor: "auto" }} className="tableBolanchor">{elm?.order_code}</b>
                                        </td>
                                        <td>
                                            {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                        </td>
                                        <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                        <td>{elm?.customer_name}</td>
                                        <td>
                                            {elm?.invoice_code === "OPEN" ? "-" : <b className="tableBolanchor" onClick={(e) => callInvoiceModal(e, elm?.id)}>{elm?.invoice_code}</b>}
                                        </td>
                                        <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                        <td>{elm?.courier_name ? elm?.courier_name : "-"}</td>
                                        <td>{TimeSense(elm?.updated_at)}</td>
                                        <td>
                                            <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                        </td>
                                        <td>
                                            {CommonStatus(elm?.order_status)}
                                        </td>
                                        <td>
                                            {(elm?.order_type === "AO" && elm?.order_status === "RETURN_IN_TRANSIT") && (
                                                <Button variant="primary" size="sm" onClick={() => OrderCncelFunct(elm)}>Order Return</Button>
                                            )}
                                            {(elm?.order_type === "AO" && (elm?.order_status === "RETURN_REQUEST" || elm?.order_status === "REPLACE_REQUEST")) ? (<div style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                                                {(elm?.order_type === "AO" && elm?.order_status === "RETURN_REQUEST" && elm?.cancel_stage === "ST_APPROVED") ? (
                                                    <Button variant="primary" size="sm" onClick={() => elm?.cancel_reason === null ? ModalAcceptOpen(elm, "REJECT_RETURN_REQUEST") : ReturnRquestAcceptOpen(elm)}>Accept</Button>
                                                ) : (
                                                    <Button variant="primary" size="sm" onClick={() => ModalAcceptOpen(elm, "Accept")}>Accept</Button>
                                                )}
                                                <Button variant="danger" size="sm"  className="btn" onClick={() => ModalAcceptOpen(elm, "Reject")}>Reject</Button>
                                            </div>) : ("")}
                                        </td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                            <td colSpan="12" >
                                                <Table bordered  className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Source</th>
                                                            <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Quantity</th>
                                                            <td>{elm?.qty}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Order Value</th>
                                                            <td>{AmountNumberFormat(elm?.total)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>SKU Type</th>
                                                            <td>{elm?.category}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Attachments</th>
                                                            <td>
                                                                {elm?.attachments === null ? "-" : (<ul className="fileuploadListytable">
                                                                    {elm?.attachments?.map((elm, index) => {
                                                                        return (<li key={index}>
                                                                            <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                        </li>)
                                                                    })}
                                                                </ul>)}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Region</th>
                                                            <td>{GetRegionName(elm?.region_id)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                        {ReturnReplaceOrderList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="11">Return replace order list not found</td></tr>}
                    </tbody>
                </Table>
            </CommonScrollBar>
            {ReturnReplaceOrderList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={ReturnReplaceOrderList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />

        {/* order eship and manual */}
        {showModal?.open && (<Modal 
            show={showModal?.open} 
            onHide={onClose} 
            id={showModal?.title === "Order Reject" ? "order_return_replacment_accept" : "order_return_replacment_accept_new"}
            className={(showModal?.modalType === "REPLACE_REQUEST" && showModal?.title !== "Order Reject") ? 'commonModal order_return_replacment_accept_new' : 'commonModal order_return_replacment_accept'} 
            centered
        >
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>{showModal?.title === "Order REJECT_RETURN_REQUEST" ? "Order Accept" : showModal?.title} <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3'>
                {((showModal?.modalType === "REPLACE_REQUEST" || showModal?.modalType === "RETURN_REQUEST") && showModal?.title !== "Order Reject") ? (<div className="return_request_wrapper">
                    <div className="formloginset">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Courier<span className="mendatory_star">*</span></Form.Label>
                                    <SelectReact
                                        options={OptionCourierList?.data}
                                        name={"courier_id"}
                                        value={formData?.courier_id}
                                        onChange={onChange}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Docket Number<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        name={"docket_number"}
                                        placeholder="Enter Docket Number"
                                        onChange={onChange}
                                        value={formData?.docket_number}
                                        autoComplete="off"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Dispatch Date<span className="mendatory_star">*</span></Form.Label>
                                    <div className="datepickField min">
                                        <ReactDatePicker
                                            selected={formData?.dispatch_date} 
                                            startDate={formData?.dispatch_date}
                                            onChange={(e)=>onSelect(e, "dispatch_date")} 
                                            // minDate={subDate(new Date(), 34)}
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth 
                                            showMonthDropdown
                                            name="dispatch_date"
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Enter From Date"
                                            isClearable={formData?.dispatch_date !== "" ? true : false}
                                            // maxDate={subDate(new Date(invoiceDetails?.order?.invoice_at), -1)}
                                            autoComplete="off"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Estimated Delivered Date<span className="mendatory_star">*</span></Form.Label>
                                    <div className="datepickField min">
                                        <ReactDatePicker
                                            selected={formData?.est_delivered_date} 
                                            startDate={formData?.est_delivered_date}
                                            minDate={new Date(formData?.dispatch_date)}
                                            // minDate={new Date()}
                                            onChange={(e)=>onSelect(e, "est_delivered_date")} 
                                            dateFormat="dd/MM/yyyy"
                                            peekNextMonth 
                                            showMonthDropdown
                                            showYearDropdown
                                            name="est_delivered_date"
                                            dropdownMode="select"
                                            placeholderText="Enter From Date"
                                            isClearable={formData?.est_delivered_date !== "" ? true : false}
                                            autoComplete="off"
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Distance<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        name={"distance"}
                                        onChange={onChange}
                                        className="inputBoxs"
                                        value={formData?.distance}
                                        type="number"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className='Order_Accept_btn_group'>
                        <Button 
                            disabled={formData?.courier_id === "" || (formData?.est_delivered_date === "" || formData?.est_delivered_date === null) ? true:false} 
                            className="btn exportbtn" 
                            variant={"exportbtn"} 
                            onClick={callSubmitForm}
                        >
                            Eship Reverse
                        </Button>
                        <Button 
                            disabled={
                                (formData?.est_delivered_date &&
                                formData?.dispatch_date &&
                                formData?.distance!="" &&
                                formData?.courier_id && formData?.docket_number)? false:true
                            } 
                            className="btn exportbtn" 
                            variant={"exportbtn"} 
                            onClick={(e)=>callSubmitForm(e, true)}
                        >
                            Manual Reverse
                        </Button>
                        <Button 
                            className="btn skipbtn" 
                            variant={"skipbtn"} 
                            onClick={(e)=> AoOrderReturn("from_eship_auto")}
                        >
                            Skip
                        </Button>
                    </div>
                </div>) : (<div className="return_request_wrapper">
                    <Form.Group className="mb-3" >
                        <FormControl 
                            value={showModal?.Reason} 
                            onChange={(e) => {
                                setShowModal({
                                    ...showModal,
                                    Reason: e?.target?.value,
                                });
                            }}
                            placeholder='Enter a reason' as='textarea' 
                        />
                        {errorReason !== "" &&(<small className='text-danger' >{errorReason}</small>)}
                    </Form.Group>
                    <div className='d-block'>
                        <button 
                            className='btn button-1 w-auto ms-auto' 
                            id="permissionbtn"
                            style={{ float: "right" }} 
                            onClick={() => OrderSuccesReject(showModal?.title)}
                        >Submit</button>
                    </div>
                </div>)}
            </div>
        </Modal>)}

        {/* ao order manual return modal  */}
        {showModalNew?.open && (<Modal show={showModalNew?.open} onHide={onClose} id="order_return_replacment_accept_new" className="commonModal" centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>{showModalNew?.title} <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3'>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Docket Number<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    name={"docket_number"}
                                    placeholder="Enter Docket Number"
                                    onChange={onChange}
                                    value={formData?.docket_number}
                                    autoComplete="off"
                                />
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Dispatch Date<span className="mendatory_star">*</span></Form.Label>
                                <div className="datepickField min">
                                    <ReactDatePicker
                                        selected={formData?.dispatch_date} 
                                        startDate={formData?.dispatch_date}
                                        onChange={(e)=>onSelect(e, "dispatch_date")} 
                                        // minDate={subDate(new Date(), 34)}
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth 
                                        showMonthDropdown
                                        name="dispatch_date"
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholderText="Enter From Date"
                                        isClearable={formData?.dispatch_date !== "" ? true : false}
                                        // maxDate={subDate(new Date(invoiceDetails?.order?.invoice_at), -1)}
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Actual Delivered Date<span className="mendatory_star">*</span></Form.Label>
                                <div className="datepickField min">
                                    <ReactDatePicker
                                        selected={formData?.est_delivered_date} 
                                        startDate={formData?.est_delivered_date}
                                        minDate={new Date(formData?.dispatch_date)}
                                        onChange={(e)=>onSelect(e, "est_delivered_date")} 
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth 
                                        showMonthDropdown
                                        showYearDropdown
                                        name="est_delivered_date"
                                        dropdownMode="select"
                                        placeholderText="Enter Actual Delivered Date"
                                        isClearable={formData?.est_delivered_date !== "" ? true : false}
                                        autoComplete="off"
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-6 ">
                            <Form.Group className="mb-3" >
                                <Form.Label className="d-block">Docket Slip <span className="mendatory_star">*</span></Form.Label>
                                {formData?.docket_slip ?(
                                    <div className="uplaodedFilename w-100">
                                        <i className="bi bi-file-earmark-text"></i>
                                        {formData?.fileName}
                                        <i className="bi bi-x-circle-fill" onClick={() => RemoveDocketSlip()}></i>
                                    </div>
                                ):(
                                    <label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                        <i className="bi bi-upload"></i>
                                        <input type="file" name={"docket_slip"} id="fileInput" onChange={UploadImage} disabled={Loader} accept=".png, .jpg, .pdf" />
                                        Upload Slip

                                        {Loader && (<Spinner />)}
                                    </label>
                                )}
                                {docket_slip !== "" &&<span className="error_medotory">{docket_slip}</span>}
                            </Form.Group>
                        </div>
                    </div>
                </div>
                <div className='Order_Accept_btn_group'>
                    <Button 
                        className="btn exportbtn" 
                        variant={"exportbtn"} 
                        disabled={
                            (formData?.est_delivered_date &&
                            formData?.dispatch_date &&
                            formData?.docket_slip && 
                            formData?.docket_number)? false:true
                        } 
                        onClick={(e)=> AoOrderReturn("from_eship_manual")}
                    >
                        Yes, Return
                    </Button>
                    <Button 
                        className="btn skipbtn" 
                        onClick={() => onClose()}
                    >
                        No
                    </Button>
                </div>
            </div>
        </Modal>)}

        <OrderCancelModal />

        {(commonMainLoader?.url === "ao_order_return" && commonMainLoader?.loader) && (<CommonLoader />)}
    </section>)
}

export default ReturnAndReplace;