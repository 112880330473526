import React from "react";
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';

const CommonPieChart = (props) => {
    const { title, dataPieChart } = props;

    const ColorCodes = [
      { color: "#004DAC", textColor: "#FFFFFF" },
      { color: "#3282E4", textColor: "#FFFFFF" },
      { color: "#64A9FF", textColor: "#FFFFFF" },
      { color: "#ABD0FC", textColor: "#004DAC" },
      { color: "#D7E9FF", textColor: "#004DAC" }
    ];

    const dataMain = dataPieChart?.sort((a, b) => b.count - a.count)?.map((elm, index) => {
        return {
          ...elm,
          label: elm?.use_case,
          value: elm?.count,
          color: ColorCodes[index]?.color,
          textColor: ColorCodes[index]?.textColor,
        }
    });

    const data = dataPieChart?.sort((a, b) => b.count - a.count)?.map((elm, index) => {
      return {
        ...elm,
        label: elm?.use_case,
        value: elm?.count,
        color: ColorCodes[index]?.color,
        textColor: ColorCodes[index]?.textColor,
      }
    })?.filter((elm) => elm?.value > 0);

    const getArcLabel = (params) => {
      return (<tspan fill={params.value === 0 ? "#004DAC" : params.textColor} fontSize="13px" >
          {params.value}
      </tspan>)
    };

    
    const allZero = dataMain.every(item => item.value === 0);
    
    // ✅ Custom Legend Component (Creates Circular Markers)
    const CustomLegend = () => {
      const AvailableData = dataMain?.filter((elm) => elm?.value > 0);
      const ZeroAvailableData = dataMain?.filter((elm) => elm?.value === 0);

      return (
        <div style={{ display: "flex", flexDirection: "column", gap: "5px", marginRight: "20px", height: "100%", width: "40%" }}>
            <div style={{ marginBottom: "10px" }}>
              <h4 style={{ fontSize: "15px", marginBottom: "8px" }}>{title}</h4>
              {AvailableData.map((entry, index) => (<div key={index} style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "2px" }}>
                  {/* Circular Color Marker */}
                  <div
                    style={{
                      width: "12px",
                      height: "12px",
                      borderRadius: "50%", // Makes it circular
                      backgroundColor: entry.color
                    }}
                  />
                  {/* Legend Text */}
                  <span style={{ fontSize: "13px", color: "#333", width: "90%" }}>{entry.label}</span>
              </div>))}
            </div>

            {ZeroAvailableData?.length > 0 && (<div>
                <h4 style={{ fontSize: "15px", marginBottom: "5px" }}>Registrations Unavailable.</h4>
                {ZeroAvailableData.map((entry, index) => (<div key={index} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {/* Circular Color Marker */}
                    <div
                      style={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%", // Makes it circular
                        backgroundColor: entry.color
                      }}
                    />
                    {/* Legend Text */}
                    <span style={{ fontSize: "13px", color: "#333", width: "90%" }}>{entry.label}</span>
                </div>))}
            </div>)}
        </div>
      );
    };

    return(<div className="common_piechart_wrapper">
        <Stack direction="row" style={{ alignItems: "center" }}>
          {allZero ? (<div className="piechart_not_found"><i className="bi bi-pie-chart"></i> No Data Found</div>) : (<React.Fragment>
            <PieChart
                series={[
                    {
                      paddingAngle: 5,
                      innerRadius: 65,
                      outerRadius: 100,
                      data,
                      arcLabel: getArcLabel,
                      cornerRadius: 5,
                    },
                ]}
                margin={{ right: 0 }}
                width={200}
                height={200}
                legend={{ 
                  hidden: true,
                }}
            />
            <CustomLegend />
          </React.Fragment>)}
        </Stack>
    </div>)
};

export default CommonPieChart;