/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SUB_CATEGORY_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";

const SubCreateCategory = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ BrandName, setBrandName ] = useState("");
    const [ CategoryName, setCategoryName ] = useState("");
    const [ subCategoryState, setSubCategoryState ] = useState({
        SubCategoryName: "",
        Status: true
    });
    const { brandlistall, ClearFormSet, subcategoryList, categorylistall, device_id } = useSelector((state) => state.adminReducers);
    const optionsBrands = brandlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.brand_name }));
    const optionsCategory = categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.category_name, brand_id: item?.brand_id }));

    // ERROR STATE
    const [ errorSubCategoryName, seterrorSubCategoryName ] = useState("");
    const [ errorCategoryName, seterrorCategoryName ] = useState("");
    const [ errorSelectBrandName, seterrorSelectBrandName ] = useState("");
    const { roomId } = useParams();

    // CURRENT DATA FROM THE REDUX
    const CurrentData_ = subcategoryList?.list?.filter((elm) => elm?.id === roomId)[0];

    useEffect(() => {
        if(LocationRoute === "edit-subcategory") {
            const CurrentCategory = optionsCategory?.filter((elm) => elm?.value === CurrentData_?.category_id)[0];
            const CurrentBrandName = optionsBrands?.filter((elm) => elm?.value === CurrentCategory?.brand_id)[0];

            setBrandName(CurrentBrandName);
            setCategoryName(CurrentCategory);
            setSubCategoryState({
                ...subCategoryState,
                SubCategoryName: CurrentData_?.sub_category_name,
                Status: CurrentData_?.is_active
            });
        };
    }, [ CurrentData_ ]);

    // CREATE SUB CATEGORY
    const CreateCategory = () => {
        if(BrandName !== "" && CategoryName !== "" && subCategoryState?.SubCategoryName.trim() !== "") {
            Dispatch(ClearFormSetFutios({
                url: LocationRoute === "edit-subcategory" ? "subcategory_edit" : "subcategory_add",
                action: true
            }));

            let param = { 
                "transmit": "broadcast", 
                "request" : { 
                    "sub_category_name" : subCategoryState?.SubCategoryName.trim(),
                    "category_id" : CategoryName?.value,
                    "is_active" : subCategoryState?.Status
                },
                "DeviceId" : device_id
            };

            if(LocationRoute === "edit-subcategory") {
                param.url = "subcategory_edit";
                param.request.sub_category_id = CurrentData_?.id;
            } else {
                param.url = "subcategory_add";
            };

            wsSend_request(websocket, param);
        } else {
            CheckValid(subCategoryState?.SubCategoryName.trim(), {type: 'SubCategoryName', error: seterrorSubCategoryName})
            CheckValid(CategoryName, {type: 'SelectCategory', error: seterrorCategoryName});
            CheckValid(BrandName, {type: 'SelectBrand', error: seterrorSelectBrandName});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "subcategory_add" || ClearFormSet?.url === "subcategory_edit")) {
            Navigate(SUB_CATEGORY_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={SUB_CATEGORY_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3" >
                            <label className="form-label">Brands<span className="mendatory_star">*</span></label>
                            <CommonReactSelectMenu 
                                label={"brand"}
                                options={optionsBrands} 
                                selectValue={BrandName} 
                                setSelectValue={setBrandName} 
                                dependantState={{
                                    allow: true,
                                    statType: "clear_state",
                                    stateFunction: setCategoryName
                                }} 
                                isMendatory={true}
                                errorState={errorSelectBrandName} 
                                setErrorState={seterrorSelectBrandName}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-3" >
                            <label className="form-label">Category Name<span className="mendatory_star">*</span></label>
                            <CommonReactSelectMenu 
                                label={"Category"}
                                options={optionsCategory?.filter((elm) => {
                                    if(elm?.brand_id === BrandName?.value) {
                                        return elm;
                                    };
                                })}
                                selectValue={CategoryName}
                                setSelectValue={setCategoryName}
                                dependantState={{
                                    allow: false,
                                }} 
                                isMendatory={true}
                                errorState={errorCategoryName} 
                                setErrorState={seterrorCategoryName}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="mb-3" >
                            <label className="form-label">Sub Category Name<span className="mendatory_star">*</span></label>
                            <input 
                                type="text" 
                                className="form-control"
                                value={subCategoryState?.SubCategoryName} 
                                onChange={(e) => setSubCategoryState({...subCategoryState, SubCategoryName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'SubCategoryName', error: seterrorSubCategoryName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Sub Category Name" 
                            />
                            {errorSubCategoryName !== "" && <span className="error_medotory">{errorSubCategoryName}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <CommonToggle 
                            isBadgeAllow={true}
                            badgeTitle={"active_inactive"}
                            valueToggle={subCategoryState?.Status}
                            setValueToggle={() => setSubCategoryState({...subCategoryState, Status: !subCategoryState?.Status})}
                            name={"Status"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <button 
                            className="btn formbtn widthfullbtn" 
                            disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "subcategory_add" || ClearFormSet?.url === "subcategory_edit")}
                            onClick={() => CreateCategory()} 
                            variant="primary" 
                        >
                            {LocationRoute === "edit-subcategory" ? "Update" : "Add"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default SubCreateCategory;