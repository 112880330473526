import React from "react";
import { Button, Modal } from "react-bootstrap";
import CommonLogout from "./CommonLogout";
import { useSelector, useDispatch } from "react-redux";

const CommonModalLogout = (props) => {
    const { LogoutModal, setLogoutModal } = props;
    const Dispatch = useDispatch();
    const { accessToken } = useSelector((state) => state.adminReducers);

    const handleLogout = () => {
        CommonLogout(accessToken, Dispatch);
    };

    const handleClose = () => {
        setLogoutModal(false);
    };

    return(<Modal className="logout_modal_main" show={LogoutModal} onHide={handleClose}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <div className="logout_icons">
                <i className="bi bi-exclamation-triangle-fill"></i>
            </div>
            <h4>Logout</h4>
            <p>Are you sure you would like to logout of your account ?</p>
        </Modal.Body>
        <Modal.Footer>
            <Button className="yes_btn" onClick={handleLogout}>Yes</Button>
            <Button className="no_btn" onClick={handleClose}>No</Button>
        </Modal.Footer>
    </Modal>)
};

export default CommonModalLogout;