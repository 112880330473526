/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Badge, Spinner, Image, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FRANCHISE_USER_REPORT_URL, FILE_AUTH_URL, REACT_APP_ADHAAR_CARD_VERIFY_URL, REACT_APP_BANK_ACCOUNT_VERIFY_URL } from "../../Shared/constant";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector, useDispatch } from "react-redux";
import { StartCommonLoader, ErrorMessageFromRedux, SearchPincodeList, ClearFormSetFutios } from "../../../redux/actions/adminActions";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import Select from 'react-select';
import CheckValid from "../../Common/CheckValid";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import ProductDefaultImage from "../../../assets/img/DefaultProduct.jpg";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import FileAuthConvert from "../../../Api/FileAuthConvert";
import CommonToggle from "../../Common/CommonToggle";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BankDetailVerify from "../../../Api/BankDetailVerify";
import VerifyModal from "../../Common/VerifyModal";
import CommonLoader from "../../Common/CommonLoader";
import CommonAddressTextField from "../../Common/CommonAddressTextField";

const CreateFranchiseUserReport = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { roomId } = useParams();    
    const { websocket } = useContext(WebSocketContext);
    const [ Tab, setTab ] = useState("dealer");
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { accessToken, ClearFormSet, franchiseTypeListAll, searchPincodeList, device_id, commonLoader, ErrorMessageFromReddux, AllDealerFranchiseTypeListAll, FranchiseUserTypeList, Regionlistall, userlistAll } = useSelector((state) => state.adminReducers);
    const CurrentData_ = FranchiseUserTypeList?.list?.filter((elm) => elm?.id === roomId)[0];

    const [ optionsFranchiseTyp, SetOptionsfranchiseTyp ] = useState(()=> franchiseTypeListAll?.map((item, index)=>({key:item.id, value:item?.id , label:item?.franchise_type_name })));
    let defaultFranchiseTypeOption = optionsFranchiseTyp?.filter((elm) => elm?.key === CurrentData_?.franchise_type_id)[0];

    const DealerFranchiseType = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null);
    const [ optionsParentDealerType, SetOptionsParentDealerType ] = useState([]);
    let defaultParentDealerType = optionsParentDealerType?.filter((elm) => elm?.key === CurrentData_?.parent_id)[0];  
    
    const OptionFranchiseArray = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.parent_id === null)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.customer_name }));

    const [ optionsRegion, SetOptionsRegion ] = useState(()=>Regionlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.region_name })));
    let defaultRegionOption = optionsRegion?.filter((elm) => elm?.key === CurrentData_?.region_id)[0];
    
    const [ optionsUserList, setOptionsUserList ] = useState([]);

    const [ optionsASMUserList, setOptionsASMUserList ] = useState([]);

    const [ subCategoryState, setSubCategoryState ] = useState({
        FranchiseTypeid: "",
        RegisteredBusinessName: "",
        ParentId: "",
        POCName: "",
        PrimaryContact: "",
        SecondaryContact: "",
        Email: "",
        PINCode: "",
        Address: "",
        address2: "",
        PANCard: "",
        PANImage: "",
        GSTNumber: "",
        GSTINImage: "",
        AdvanceAmount: "",
        AccountHolderName: "", 
        address2:"",
        AccountNumber: "", 
        IFSCCode: "", 
        BranchName: "",
        RelatedID: "",
        RMuser: "",
        ASMuser: "",
        LocationUrl: "",
        AadhaarNumber: "",
        is_flexible_pricing: false,
        Status: true,
    });
    const [ VerifyModalState, setVerifyModalState ] = useState({
        open: false,
        title: "",
        modalType: "",
        data: {},
    });
    const [ ImageDisplay, setImageDisplay ] = useState("");
    const [ GSTINImageDisplay, setGSTINImageDisplay ] = useState("");
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ Registered, setRegistered ] = useState(true);
    const [ BankVerifiedStatus, setBankVerifiedStatus ] = useState("");
    const [ AadharVerifiedStatus, setAadharVerifiedStatus ] = useState("");
    const [ commonVerifyLoader, setcommonVerifyLoader ] = useState({
        adhar_loader: false,
        bank_details_loader: false,
    });

    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    // error state
    const [ errorPOCName, seterrorPOCName ] = useState("");
    const [ errorEmail, seterrorEmail ] = useState("");
    const [ errorPINCode, seterrorPINCode ] = useState("");
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorAddress2, seterrorAddress2 ] = useState("");
    const [ errorFranchiseType, seterrorFranchiseType ] = useState("");
    const [ errorRegisteredBusinessName, seterrorRegisteredBusinessName ] = useState("");
    const [ errorPrimaryContact, seterrorPrimaryContact ] = useState("");
    const [ errorPANCard, seterrorPANCard ] = useState("");
    const [ errorBrandImage, seterrorBrandImage ] = useState("");
    const [ errorGSTImage, seterrorGSTImage ] = useState("");
    const [ errorGSTNumber, seterrorGSTNumber ] = useState("");
    const [ errorParentTypeId, seterrorParentTypeId ] = useState("");
    const [ errorParentSubParentStateSame, seterrorParentSubParentStateSame ] = useState("");
    const [ errorRelatedID, seterrorRelatedID ] = useState("");
    const [ errorRMuser, seterrorRMuser ] = useState("");
    const [ errorAccountNumber, setErrorAccountNumber ] = useState("");
    const [ errorIFSCcodeNumber, setErrorIFSCcodeNumber ] = useState("");
    const [ errorAadhaarNumber, setErrorAadhaarNumber ] = useState("");

    let ParentFranchiseDetails = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === subCategoryState?.ParentId?.value)[0];
    
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // get region list
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "region_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "user_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // get franchise_type_list all
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "franchise_list",
            "request" : { 
                "status" : true, 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, [ ]);

    // select related type
    const SelectRelatedType = (value, url) => {
        if(value !== "" && value !== null) {
            setSubCategoryState({...subCategoryState, RelatedID: value, RMuser: ""});
            seterrorRelatedID("");
        } else {
            setSubCategoryState({...subCategoryState, RelatedID: "", RMuser: ""});
            seterrorRelatedID(`Please select ${url?.replace("_", " ")}`);
        };
    };

    useEffect(() => {
        if(userlistAll !== undefined) {
            const UserlistAllX = userlistAll?.filter((elm) => elm?.is_active === true && (elm?.user_type === "regional_manager" || elm?.user_type === "regional_sales_team_rm") )?.map((item, index)=>({...item, key:item.id, value:item?.id , label:`${item?.user_code} - ${item?.full_name}` }));
            setOptionsUserList(UserlistAllX?.filter((elm) => elm?.related_id === subCategoryState?.RelatedID?.value));
        };
    }, [ userlistAll, subCategoryState?.RelatedID, LocationRoute, defaultRegionOption ]);


    useEffect(() => {
        if(userlistAll !== undefined) {
            const UserlistAllX = userlistAll?.filter((elm) => elm?.is_active === true && elm?.user_type === "regional_sales_team_asm" )?.map((item, index)=>({...item, key:item.id, value:item?.id , label:`${item?.user_code} - ${item?.full_name}` }));
            setOptionsASMUserList(UserlistAllX?.filter((elm) => elm?.related_id === subCategoryState?.RMuser?.id))
        };
    }, [ userlistAll, subCategoryState?.RMuser ]);

    // rm user function
    const RmValueSelect = (value) => {
        if(value !== "" && value !== null) {
            setSubCategoryState({...subCategoryState, RMuser: value, ASMuser: ""});
            CheckValid((value === null || value === "") ? "" : value, {type: 'RMuser', error: seterrorRMuser});
        } else {
            setSubCategoryState({...subCategoryState, RMuser: "", ASMuser: ""});
            CheckValid((value === null || value === "") ? "" : value, {type: 'RMuser', error: seterrorRMuser});
        };
    };

    // asm user function
    const ASMValueSelect = (value) => {
        if(value !== "" && value !== null) {
            setSubCategoryState({...subCategoryState, ASMuser: value});
        } else {
            setSubCategoryState({...subCategoryState, ASMuser: ""});
        };
    };
    
    useEffect(() => {
        if(LocationRoute === "edit-franchise-user-report") {
            const CurrentPincode = FranchiseUserTypeList?.pin.filter((elm) => elm?.id === CurrentData_?.pin_code_id)[0]?.pin_code;
            const CurrentParentid = OptionFranchiseArray?.filter((elm) => elm?.value === CurrentData_?.parent_id)[0];
            const UserlistAllMain = userlistAll?.filter((elm) => elm?.is_active === true && (elm?.user_type === "regional_manager" || elm?.user_type === "regional_sales_team_rm") )?.map((item, index)=>({...item, key:item.id, value:item?.id , label:`${item?.user_code} - ${item?.full_name}` }));
            const UserlistAllX = userlistAll?.filter((elm) => elm?.is_active === true && elm?.user_type === "regional_sales_team_asm" )?.map((item, index)=>({...item, key:item.id, value:item?.id , label:`${item?.user_code} - ${item?.full_name}` }));

            if(CurrentPincode?.length === 6) {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "pin_code_search",
                    "request" : { 
                        "pin_code" : CurrentPincode, 
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                dispatch(StartCommonLoader(false));
            };
           
            setTimeout(() => {
                setSubCategoryState({
                    ...subCategoryState,
                    FranchiseTypeid: defaultFranchiseTypeOption?.value,
                    RegisteredBusinessName: CurrentData_?.customer_name === null ? "" : CurrentData_?.customer_name,
                    POCName: CurrentData_?.poc_name === null ? "" : CurrentData_?.poc_name,
                    PrimaryContact:  CurrentData_?.mobile === null ? "" : CurrentData_?.mobile,
                    ParentId: CurrentParentid,
                    SecondaryContact: CurrentData_?.secondary_contact === null ? "" : CurrentData_?.secondary_contact,
                    Email: CurrentData_?.email === null ? "" : CurrentData_?.email,
                    PINCode: CurrentPincode,
                    Address: CurrentData_?.address === null ? "" : CurrentData_?.address,
                    address2: CurrentData_?.address2 || "",
                    PANCard: CurrentData_?.pan_no === null ? "" : CurrentData_?.pan_no,
                    PANImage: CurrentData_?.pan_image === null ? "" : CurrentData_?.pan_image,
                    GSTNumber: CurrentData_?.gst_no === null ? "" : CurrentData_?.gst_no,
                    GSTINImage: CurrentData_?.gst_image === null ? "" : CurrentData_?.gst_image,
                    AdvanceAmount: CurrentData_?.deposit === null ? 0 : CurrentData_?.deposit,
                    AccountHolderName: CurrentData_?.account_holder_name === null ? "" : CurrentData_?.account_holder_name, 
                    AccountNumber: CurrentData_?.bank_account_number === null ? "" : CurrentData_?.bank_account_number, 
                    AadhaarNumber: CurrentData_?.aadhar_number === null ? "" : CurrentData_?.aadhar_number, 
                    IFSCCode: CurrentData_?.ifsc_code === null ? "" : CurrentData_?.ifsc_code, 
                    BranchName: CurrentData_?.branch_name === null ? "" : CurrentData_?.branch_name,
                    RelatedID: defaultRegionOption,
                    RMuser: UserlistAllMain?.filter((elm) => elm?.id === CurrentData_?.region_manager_id)[0],
                    ASMuser: UserlistAllX?.filter((elm) => elm?.id === CurrentData_?.asm_id)[0],
                    is_flexible_pricing: CurrentData_?.is_flexible_pricing,
                    Status: CurrentData_?.is_active,
                    LocationUrl: CurrentData_?.location_url,
                });
                setBankVerifiedStatus(CurrentData_?.is_bank_verified === null ? "" : CurrentData_?.is_bank_verified ? "Verified" : "Unverified");
                setAadharVerifiedStatus(CurrentData_?.is_aadhar_verified === null ? "" : CurrentData_?.is_aadhar_verified ? "Verified" : "Unverified");
                if(CurrentData_?.view_thumbnail_url_pan === null) {
                    setImageDisplay("");
                } else {
                    setImageDisplay({
                        url: FILE_AUTH_URL + CurrentData_?.view_thumbnail_url_pan,
                        name: CurrentData_?.pan_image_name
                    });
                };
                if(CurrentData_?.view_thumbnail_url_gst === null) {
                    setGSTINImageDisplay("");
                } else {
                    setGSTINImageDisplay({
                        url: FILE_AUTH_URL + CurrentData_?.view_thumbnail_url_gst,
                        name: CurrentData_?.gst_image_name
                    });
                };
                setRegistered(CurrentData_?.is_registered);
            }, 1000);
            if(CurrentData_?.parent_id === null) {
                setTab("dealer");
            } else {
                setTab("sub_dealer");
            };
        };
    }, [ CurrentData_ ]);


    useEffect(() => {
        if(searchPincodeList) {
            setSubCategoryState({
                ...subCategoryState,
                PINCode: searchPincodeList?.pin?.pin_code === undefined ? "" : searchPincodeList?.pin?.pin_code,
            })
        };
    }, [ searchPincodeList ]);

    // select brand name
    const FranchiseTypeSelectFCT = (value) => {
        if(value !== null) {
            setSubCategoryState({...subCategoryState, FranchiseTypeid: value?.value});
            CheckValid(value, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
        } else {
            setSubCategoryState({...subCategoryState, FranchiseTypeid: ""});
            CheckValid(value === null ? "" : value, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
        }
    };

    const FranchiseTypeCreditLimit = () => {
        const getCreditlimit = franchiseTypeListAll?.filter((elm) => elm?.id === subCategoryState?.FranchiseTypeid)[0];
        return getCreditlimit === undefined ? "-" : AmountNumberFormat(parseInt(getCreditlimit?.franchise_type_credit));
    }

     // select parent dealer type
    const ParentDealerTypeSelectFCT = (value) => {
        if(value !== null) {
            setSubCategoryState({...subCategoryState, ParentId: value});
            CheckValid(value, {type: 'ParentTypeId', error: seterrorParentTypeId});
        } else {
            setSubCategoryState({...subCategoryState, ParentId: ""});
            CheckValid(value === null ? "" : value, {type: 'ParentTypeId', error: seterrorParentTypeId});
        }
    };

    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchPincode();
        };
    };

    // search pincode
    const SearchPincode = () => {
        if(subCategoryState?.PINCode?.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = { 
                "transmit": "broadcast", 
                "url": "pin_code_search",
                "request" : { 
                    "pin_code" : subCategoryState?.PINCode, 
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            dispatch(StartCommonLoader(false));
        }
    };

    // check valid
    const GSTINvalid = (value) => {
        if(value !== "") {
            setSubCategoryState({...subCategoryState, GSTNumber: value});
        } else {
            setSubCategoryState({...subCategoryState, GSTNumber: ""});
            seterrorGSTNumber("GSTIN cannot be empty");
        };
    };

    const GSTvalidations = (value) => {
        if(value !== "") {
            const GSTDigit = searchPincodeList?.state?.gst_code?.length === 1 ? 2 : searchPincodeList?.state?.gst_code?.length
            const GSTDigitValid = searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code;

            const Substring = value.trim().substring(0, GSTDigit);

            if(Substring.toString() === GSTDigitValid.toString()) {
                return true;
            } else {
                return false;
            }
        };
    };

    useEffect(() => {
        if(searchPincodeList?.state?.gst_code !== undefined) {
            seterrorGSTNumber(``);
        };
    }, [ searchPincodeList ]);

    const GSTINvalidKeyUp = (value) => {
        if(value !== "") {
            if(searchPincodeList?.state?.gst_code === undefined) {
                seterrorGSTNumber(`Before you enter the GST number, please enter the PIN code.`);
            } else {
                if(GSTvalidations(value) && value?.length === 15) {
                    seterrorGSTNumber("");
                } else {
                    seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                };
            };
        } else {
            seterrorGSTNumber("GSTIN cannot be empty");
        };
    };

    // clear data
    const ClearData = () => {
        dispatch(SearchPincodeList({}));
        dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
    };

    const PinCOdeSearchFunction = (e) => {
        setSubCategoryState({...subCategoryState, PINCode: e.target.value});
        if (e.target.value.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "broadcast",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);

        } if (e.target.value === "") {
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        } else {
            dispatch(StartCommonLoader(false))
        }
    };

    // pin code error get from redux
    useEffect(() => {
        if(ErrorMessageFromReddux?.API === "pin_code_search" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
        };
    }, [ ErrorMessageFromReddux ]);

    // upload pancard image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            setSubCategoryState({...subCategoryState, PANImage: fileresponse?.id});
            const Authfileresponse = await FileAuthConvert(accessToken, FILE_AUTH_URL + fileresponse?.view_thumbnail_url);
            if(Authfileresponse?.status === 200) {
                setUploadFileLoader(false);
                setImageDisplay({
                    url: Authfileresponse?.authImage,
                    name: files.name
                });
                CheckValid(fileresponse?.id, {type: 'BrandImage', error: seterrorBrandImage});
            } else {
                setUploadFileLoader(false);
            };
        } else {
            setUploadFileLoader(false);
        };
    };

    // remove image uploaded
    const RemoveUploadImage = () => {
        setSubCategoryState({...subCategoryState, PANImage: ""});
        setImageDisplay("");
    };

    // upload GSTIN image 
    const UploadGSTINImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            setSubCategoryState({...subCategoryState, GSTINImage: fileresponse?.id});
            const Authfileresponse = await FileAuthConvert(accessToken, FILE_AUTH_URL + fileresponse?.view_thumbnail_url);
            if(Authfileresponse?.status === 200) {
                setUploadFileLoader(false);
                setGSTINImageDisplay({
                    url: Authfileresponse?.authImage,
                    name: files.name
                });
                CheckValid(fileresponse?.id, {type: 'BrandImage', error: seterrorGSTImage});
            } else {
                setUploadFileLoader(false);
            };
        } else {
            setUploadFileLoader(false);
        };
    };

    // remove GSTIN image uploaded
    const RemoveGSTINImage = () => {
        setSubCategoryState({...subCategoryState, GSTINImage: ""});
        setGSTINImageDisplay("");
    };

    // parent state and sub state valid
    const ParentSubdelarStateSameValid = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === subCategoryState?.ParentId?.value)[0]?.state_id === searchPincodeList?.state?.id;

    useEffect(() => {
        if(Tab === "sub_dealer") {
            const CurrentStateSame = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === subCategoryState?.ParentId?.value)[0];
            if(CurrentStateSame?.state_id !== undefined && searchPincodeList?.state?.id !== undefined) {
                if(CurrentStateSame?.state_id === searchPincodeList?.state?.id) {
                    seterrorParentSubParentStateSame("");
                } else {
                    seterrorParentSubParentStateSame("The sub-dealer's state and the parent state should be the same. Please enter the pincode according to the parent state.");
                };
            };
        };
    }, [ ParentSubdelarStateSameValid, Tab, searchPincodeList ]);

    const [ errorSecondaryContact, setErrorSecondaryContact ] = useState("");

    // create franchise
    const CreateFranchiseFct = () => {
        if(Registered) {
            if(LocationRoute === "edit-franchise-user-report") {
                if(Tab === "sub_dealer") {
                    if(subCategoryState?.SecondaryContact?.length > 0) {
                        if(subCategoryState?.SecondaryContact?.length === 10) {
                            if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                subCategoryState?.address2?.length <= 100
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                                ) {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_edit",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_edit",
                                        "request" : { 
                                            "franchise_id": CurrentData_?.id,
                                            "erp_id": CurrentData_?.erp_id,
                                            "erp_address_id": CurrentData_?.erp_address_id,
                                            "franchise_type": Tab, 
                                            "parent_id": subCategoryState?.ParentId?.value,
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status, 
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                                    CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                                }
                            } else {
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
    
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                
                            };
                            setErrorSecondaryContact("");
                        } else {
                            setErrorSecondaryContact("Please enter valid secondary contact");
                        }
                    } else {
                        if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                            subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                            subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                            subCategoryState?.address2?.length <= 100
                        ) {
                            if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                            ) {
                                dispatch(ClearFormSetFutios({
                                    url: "franchise_edit",
                                    action: true
                                }));
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "franchise_edit",
                                    "request" : { 
                                        "franchise_id": CurrentData_?.id,
                                        "erp_id": CurrentData_?.erp_id,
                                        "erp_address_id": CurrentData_?.erp_address_id,
                                        "franchise_type": Tab, 
                                        "parent_id": subCategoryState?.ParentId?.value,
                                        "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                        "customer_name": subCategoryState?.RegisteredBusinessName,
                                        "poc_name": subCategoryState?.POCName,
                                        "mobile": subCategoryState?.PrimaryContact,
                                        "secondary_contact": subCategoryState?.SecondaryContact,
                                        "email": subCategoryState?.Email, 
                                        "pin_code_id": searchPincodeList?.pin?.id,
                                        "address": subCategoryState?.Address,
                                        "address2": subCategoryState?.address2,
                                        "region_id": subCategoryState?.RelatedID?.value,
                                        "region_manager_id": subCategoryState?.RMuser?.value,
                                        "asm_id": subCategoryState?.ASMuser?.value,
                                        "location_url": subCategoryState?.LocationUrl,
                                        "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                        "is_active": subCategoryState?.Status, 
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                                CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                            }
                        } else {
                            CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                            CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                            CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                            CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                            CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                            CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                            CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                            
                            if(subCategoryState?.RelatedID === "") {
                                seterrorRelatedID(`Please select regional manager`);
                            } else {
                                seterrorRelatedID("");
                            };
                            CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                        };
                    };
                } else {
                    if(subCategoryState?.SecondaryContact?.length > 0) {
                        if(subCategoryState?.SecondaryContact?.length === 10) {
                            if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&  
                                subCategoryState?.PANCard.trim() !== "" &&  subCategoryState?.PANImage !== "" && subCategoryState?.GSTNumber.trim() !== "" && 
                                subCategoryState?.GSTINImage !== "" && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified" &&
                                subCategoryState?.address2?.length <= 100
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && panRegex.test(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim()) === true && 
                                ErrorMessageFromReddux?.Error === "" && (GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15)
                                ) {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_edit",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_edit",
                                        "request" : { 
                                            "franchise_id": CurrentData_?.id,
                                            "erp_id": CurrentData_?.erp_id,
                                            "erp_address_id": CurrentData_?.erp_address_id,
                                            "franchise_type": Tab, 
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "pan_no": subCategoryState?.PANCard,
                                            "pan_image" : subCategoryState?.PANImage, 
                                            "gst_no" : subCategoryState?.GSTNumber,
                                            "gst_image": subCategoryState?.GSTINImage,
                                            "advance_amount": subCategoryState?.AdvanceAmount,
                                            "bank_account_number": subCategoryState?.AccountNumber,
                                            "ifsc_code": subCategoryState?.IFSCCode,
                                            "branch_name": subCategoryState?.BranchName,
                                            "account_holder_name": subCategoryState?.AccountHolderName,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status,
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_registered": Registered,
                                            "aadhar_number": subCategoryState?.AadhaarNumber,
                                            "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                            "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                    if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                        seterrorGSTNumber("");
                                    } else {
                                        seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                    };
                                }
                            } else {
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                CheckValid(subCategoryState?.PANImage.trim(), {type: 'BrandImage', error: seterrorBrandImage});
                                CheckValid(subCategoryState?.GSTINImage.trim(), {type: 'BrandImage', error: seterrorGSTImage});
                                if(subCategoryState?.GSTNumber.trim() !== "") {
                                    if(searchPincodeList?.state?.gst_code === undefined) {
                                        seterrorGSTNumber(`Before you enter the GST number, please enter the PIN code.`);
                                    } else {
                                        if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                            seterrorGSTNumber("");
                                        } else {
                                            seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                        };
                                    };
                                } else {
                                    seterrorGSTNumber("GSTIN cannot be empty");
                                };
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                if(BankVerifiedStatus !== "Verified") {
                                    setBankVerifiedStatus("Unverified");
                                };
                            };
                            setErrorSecondaryContact("");
                        } else {
                            setErrorSecondaryContact("Please enter valid secondary contact");
                        };
                    } else {
                        if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                            subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                            subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&  
                            subCategoryState?.PANCard.trim() !== "" &&  subCategoryState?.PANImage !== "" && subCategoryState?.GSTNumber.trim() !== "" && 
                            subCategoryState?.GSTINImage !== "" && subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified" 
                        ) {
                            if(EmailRegex.test(subCategoryState?.Email) === true && panRegex.test(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim()) === true && 
                            ErrorMessageFromReddux?.Error === "" && (GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15)
                            ) {
                                dispatch(ClearFormSetFutios({
                                    url: "franchise_edit",
                                    action: true
                                }));
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "franchise_edit",
                                    "request" : { 
                                        "franchise_id": CurrentData_?.id,
                                        "erp_id": CurrentData_?.erp_id,
                                        "erp_address_id": CurrentData_?.erp_address_id,
                                        "franchise_type": Tab, 
                                        "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                        "customer_name": subCategoryState?.RegisteredBusinessName,
                                        "poc_name": subCategoryState?.POCName,
                                        "mobile": subCategoryState?.PrimaryContact,
                                        "secondary_contact": subCategoryState?.SecondaryContact,
                                        "email": subCategoryState?.Email, 
                                        "pin_code_id": searchPincodeList?.pin?.id,
                                        "address": subCategoryState?.Address,
                                        "address2": subCategoryState?.address2,
                                        "pan_no": subCategoryState?.PANCard,
                                        "pan_image" : subCategoryState?.PANImage, 
                                        "gst_no" : subCategoryState?.GSTNumber,
                                        "gst_image": subCategoryState?.GSTINImage,
                                        "advance_amount": subCategoryState?.AdvanceAmount,
                                        "bank_account_number": subCategoryState?.AccountNumber,
                                        "ifsc_code": subCategoryState?.IFSCCode,
                                        "branch_name": subCategoryState?.BranchName,
                                        "account_holder_name": subCategoryState?.AccountHolderName,
                                        "region_id": subCategoryState?.RelatedID?.value,
                                        "region_manager_id": subCategoryState?.RMuser?.value,
                                        "asm_id": subCategoryState?.ASMuser?.value,
                                        "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                        "is_active": subCategoryState?.Status,
                                        "location_url": subCategoryState?.LocationUrl,
                                        "is_registered": Registered,
                                        "aadhar_number": subCategoryState?.AadhaarNumber,
                                        "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                        "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                    seterrorGSTNumber("");
                                } else {
                                    seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                };
                            }
                        } else {
                            CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                            CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                            CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                            CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                            CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                            CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                            CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                            CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                            CheckValid(subCategoryState?.PANImage.trim(), {type: 'BrandImage', error: seterrorBrandImage});
                            CheckValid(subCategoryState?.GSTINImage.trim(), {type: 'BrandImage', error: seterrorGSTImage});
                            if(subCategoryState?.GSTNumber.trim() !== "") {
                                if(searchPincodeList?.state?.gst_code === undefined) {
                                    seterrorGSTNumber(`Before you enter the GST number, please enter the PIN code.`);
                                } else {
                                    if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                        seterrorGSTNumber("");
                                    } else {
                                        seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                    };
                                };
                            } else {
                                seterrorGSTNumber("GSTIN cannot be empty");
                            };
                            if(subCategoryState?.RelatedID === "") {
                                seterrorRelatedID(`Please select regional manager`);
                            } else {
                                seterrorRelatedID("");
                            };
                            CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                            CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                            CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                            CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                            if(BankVerifiedStatus !== "Verified") {
                                setBankVerifiedStatus("Unverified");
                            };
                        };
                    };
                };
            } else {
                if(Tab === "sub_dealer") {
                    if(subCategoryState?.SecondaryContact?.length > 0) {
                        if(subCategoryState?.SecondaryContact?.length === 10) {
                            if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                subCategoryState?.address2?.length <= 100
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                                ) {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_add",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_add",
                                        "request" : { 
                                            "franchise_type": Tab, 
                                            "parent_id": subCategoryState?.ParentId?.value,
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status,
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_registered": Registered
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                                }
                            } else {
                                CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                            };
                            setErrorSecondaryContact("");
                        } else {
                            setErrorSecondaryContact("Please enter valid secondary contact");
                        };
                    } else {
                        if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                            subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                            subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                            subCategoryState?.address2?.length <= 100
                        ) {
                            if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                            ) {
                                dispatch(ClearFormSetFutios({
                                    url: "franchise_add",
                                    action: true
                                }));
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "franchise_add",
                                    "request" : { 
                                        "franchise_type": Tab, 
                                        "parent_id": subCategoryState?.ParentId?.value,
                                        "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                        "customer_name": subCategoryState?.RegisteredBusinessName,
                                        "poc_name": subCategoryState?.POCName,
                                        "mobile": subCategoryState?.PrimaryContact,
                                        "secondary_contact": subCategoryState?.SecondaryContact,
                                        "email": subCategoryState?.Email, 
                                        "pin_code_id": searchPincodeList?.pin?.id,
                                        "address": subCategoryState?.Address,
                                        "address2": subCategoryState?.address2,
                                        "region_id": subCategoryState?.RelatedID?.value,
                                        "region_manager_id": subCategoryState?.RMuser?.value,
                                        "asm_id": subCategoryState?.ASMuser?.value,
                                        "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                        "is_active": subCategoryState?.Status,
                                        "location_url": subCategoryState?.LocationUrl,
                                        "is_registered": Registered
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                            }
                        } else {
                            CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                            CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                            CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                            CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                            CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                            CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                            CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                            CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
    
                            if(subCategoryState?.RelatedID === "") {
                                seterrorRelatedID(`Please select regional manager`);
                            } else {
                                seterrorRelatedID("");
                            };
                            CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                        };
                    }
                } else {
                    if(subCategoryState?.SecondaryContact?.length > 0) {
                        if(subCategoryState?.SecondaryContact?.length === 10) {
                            if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                subCategoryState?.PANCard.trim() !== "" &&  subCategoryState?.PANImage !== "" && subCategoryState?.GSTNumber.trim() !== "" && 
                                subCategoryState?.GSTINImage !== "" && subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && panRegex.test(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim()) === true && ErrorMessageFromReddux?.Error === "" && 
                                (GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15)
                                ) {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_add",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_add",
                                        "request" : { 
                                            "franchise_type": Tab, 
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "pan_no": subCategoryState?.PANCard,
                                            "pan_image" : subCategoryState?.PANImage, 
                                            "gst_no" : subCategoryState?.GSTNumber,
                                            "gst_image": subCategoryState?.GSTINImage,
                                            "advance_amount": subCategoryState?.AdvanceAmount,
                                            "bank_account_number": subCategoryState?.AccountNumber,
                                            "ifsc_code": subCategoryState?.IFSCCode,
                                            "branch_name": subCategoryState?.BranchName,
                                            "account_holder_name": subCategoryState?.AccountHolderName,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status, 
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_registered": Registered,
                                            "aadhar_number": subCategoryState?.AadhaarNumber,
                                            "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                            "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                    if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                        seterrorGSTNumber("");
                                    } else {
                                        seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                    };
                                }
                            } else {
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                CheckValid(subCategoryState?.PANImage.trim(), {type: 'BrandImage', error: seterrorBrandImage});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.GSTINImage.trim(), {type: 'BrandImage', error: seterrorGSTImage});
                                if(subCategoryState?.GSTNumber.trim() !== "") {
                                    if(searchPincodeList?.state?.gst_code === undefined) {
                                        seterrorGSTNumber(`Before you enter the GST number, please enter the PIN code.`);
                                    } else {
                                        if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                            seterrorGSTNumber("");
                                        } else {
                                            seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                        };
                                    };
                                } else {
                                    seterrorGSTNumber("GSTIN cannot be empty");
                                };
    
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                if(BankVerifiedStatus !== "Verified") {
                                    setBankVerifiedStatus("Unverified");
                                };
                            };
                            setErrorSecondaryContact("");
                        } else {
                            setErrorSecondaryContact("Please enter valid secondary contact");
                        };
                    } else {
                        if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                            subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                            subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                            subCategoryState?.PANCard.trim() !== "" &&  subCategoryState?.PANImage !== "" && subCategoryState?.GSTNumber.trim() !== "" && 
                            subCategoryState?.GSTINImage !== "" && subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                        ) {
                            if(EmailRegex.test(subCategoryState?.Email) === true && panRegex.test(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim()) === true && ErrorMessageFromReddux?.Error === "" && 
                            (GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15)
                            ) {
                                dispatch(ClearFormSetFutios({
                                    url: "franchise_add",
                                    action: true
                                }));
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "franchise_add",
                                    "request" : { 
                                        "franchise_type": Tab, 
                                        "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                        "customer_name": subCategoryState?.RegisteredBusinessName,
                                        "poc_name": subCategoryState?.POCName,
                                        "mobile": subCategoryState?.PrimaryContact,
                                        "secondary_contact": subCategoryState?.SecondaryContact,
                                        "email": subCategoryState?.Email, 
                                        "pin_code_id": searchPincodeList?.pin?.id,
                                        "address": subCategoryState?.Address,
                                        "address2": subCategoryState?.address2,
                                        "pan_no": subCategoryState?.PANCard,
                                        "pan_image" : subCategoryState?.PANImage, 
                                        "gst_no" : subCategoryState?.GSTNumber,
                                        "gst_image": subCategoryState?.GSTINImage,
                                        "advance_amount": subCategoryState?.AdvanceAmount,
                                        "bank_account_number": subCategoryState?.AccountNumber,
                                        "ifsc_code": subCategoryState?.IFSCCode,
                                        "branch_name": subCategoryState?.BranchName,
                                        "account_holder_name": subCategoryState?.AccountHolderName,
                                        "region_id": subCategoryState?.RelatedID?.value,
                                        "region_manager_id": subCategoryState?.RMuser?.value,
                                        "asm_id": subCategoryState?.ASMuser?.value,
                                        "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                        "is_active": subCategoryState?.Status, 
                                        "location_url": subCategoryState?.LocationUrl,
                                        "is_registered": Registered,
                                        "aadhar_number": subCategoryState?.AadhaarNumber,
                                        "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                        "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                    seterrorGSTNumber("");
                                } else {
                                    seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                };
                            }
                        } else {
                            CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                            CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                            CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                            CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                            CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                            CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                            CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                            CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                            CheckValid(subCategoryState?.PANImage.trim(), {type: 'BrandImage', error: seterrorBrandImage});
                            CheckValid(subCategoryState?.GSTINImage.trim(), {type: 'BrandImage', error: seterrorGSTImage});
                            if(subCategoryState?.GSTNumber.trim() !== "") {
                                if(searchPincodeList?.state?.gst_code === undefined) {
                                    seterrorGSTNumber(`Before you enter the GST number, please enter the PIN code.`);
                                } else {
                                    if(GSTvalidations(subCategoryState?.GSTNumber) && subCategoryState?.GSTNumber?.length === 15) {
                                        seterrorGSTNumber("");
                                    } else {
                                        seterrorGSTNumber(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                                    };
                                };
                            } else {
                                seterrorGSTNumber("GSTIN cannot be empty");
                            };
    
                            if(subCategoryState?.RelatedID === "") {
                                seterrorRelatedID(`Please select regional manager`);
                            } else {
                                seterrorRelatedID("");
                            };
                            CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                            CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                            CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                            CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                            if(BankVerifiedStatus !== "Verified") {
                                setBankVerifiedStatus("Unverified");
                            };
                        };
                    };
                };
            };
        } else {
            if(LocationRoute === "edit-franchise-user-report") {
                if(Tab === "sub_dealer") {
                    if(subCategoryState?.SecondaryContact?.length > 0) {
                        if(subCategoryState?.SecondaryContact?.length === 10) {
                            if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                subCategoryState?.address2?.length <= 100
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                                ) {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_edit",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_edit",
                                        "request" : { 
                                            "franchise_id": CurrentData_?.id,
                                            "erp_id": CurrentData_?.erp_id,
                                            "erp_address_id": CurrentData_?.erp_address_id,
                                            "franchise_type": Tab, 
                                            "parent_id": subCategoryState?.ParentId?.value,
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status, 
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                                    CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                                }
                            } else {
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
    
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                
                            };
                            setErrorSecondaryContact("");
                        } else {
                            setErrorSecondaryContact("Please enter valid secondary contact");
                        }
                    } else {
                        if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                            subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                            subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                            subCategoryState?.address2?.length <= 100
                        ) {
                            if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                            ) {
                                dispatch(ClearFormSetFutios({
                                    url: "franchise_edit",
                                    action: true
                                }));
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "franchise_edit",
                                    "request" : { 
                                        "franchise_id": CurrentData_?.id,
                                        "erp_id": CurrentData_?.erp_id,
                                        "erp_address_id": CurrentData_?.erp_address_id,
                                        "franchise_type": Tab, 
                                        "parent_id": subCategoryState?.ParentId?.value,
                                        "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                        "customer_name": subCategoryState?.RegisteredBusinessName,
                                        "poc_name": subCategoryState?.POCName,
                                        "mobile": subCategoryState?.PrimaryContact,
                                        "secondary_contact": subCategoryState?.SecondaryContact,
                                        "email": subCategoryState?.Email, 
                                        "pin_code_id": searchPincodeList?.pin?.id,
                                        "address": subCategoryState?.Address,
                                        "address2": subCategoryState?.address2,
                                        "region_id": subCategoryState?.RelatedID?.value,
                                        "region_manager_id": subCategoryState?.RMuser?.value,
                                        "asm_id": subCategoryState?.ASMuser?.value,
                                        "location_url": subCategoryState?.LocationUrl,
                                        "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                        "is_active": subCategoryState?.Status, 
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                                CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                            }
                        } else {
                            CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                            CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                            CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                            CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                            CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                            CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                            CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                            
                            if(subCategoryState?.RelatedID === "") {
                                seterrorRelatedID(`Please select regional manager`);
                            } else {
                                seterrorRelatedID("");
                            };
                            CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                        };
                    };
                } else {
                    if(subCategoryState?.PANCard.trim() !== "") {
                        if(panRegex.test(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim()) === true) {
                            if(subCategoryState?.SecondaryContact?.length > 0) {
                                if(subCategoryState?.SecondaryContact?.length === 10) {
                                    if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                        subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                        subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 && 
                                        subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                                    ) {
                                        if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "") {
                                            dispatch(ClearFormSetFutios({
                                                url: "franchise_edit",
                                                action: true
                                            }));
                                            let param = { 
                                                "transmit": "broadcast", 
                                                "url": "franchise_edit",
                                                "request" : { 
                                                    "franchise_id": CurrentData_?.id,
                                                    "erp_id": CurrentData_?.erp_id,
                                                    "erp_address_id": CurrentData_?.erp_address_id,
                                                    "franchise_type": Tab, 
                                                    "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                                    "customer_name": subCategoryState?.RegisteredBusinessName,
                                                    "poc_name": subCategoryState?.POCName,
                                                    "mobile": subCategoryState?.PrimaryContact,
                                                    "secondary_contact": subCategoryState?.SecondaryContact,
                                                    "email": subCategoryState?.Email, 
                                                    "pin_code_id": searchPincodeList?.pin?.id,
                                                    "address": subCategoryState?.Address,
                                                    "address2": subCategoryState?.address2,
                                                    "region_id": subCategoryState?.RelatedID?.value,
                                                    "region_manager_id": subCategoryState?.RMuser?.value,
                                                    "asm_id": subCategoryState?.ASMuser?.value,
                                                    "pan_no": subCategoryState?.PANCard,
                                                    "pan_image" : subCategoryState?.PANImage, 
                                                    "advance_amount": subCategoryState?.AdvanceAmount,
                                                    "bank_account_number": subCategoryState?.AccountNumber,
                                                    "ifsc_code": subCategoryState?.IFSCCode,
                                                    "branch_name": subCategoryState?.BranchName,
                                                    "account_holder_name": subCategoryState?.AccountHolderName,
                                                    "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                                    "is_active": subCategoryState?.Status,
                                                    "location_url": subCategoryState?.LocationUrl,
                                                    "is_registered": Registered,
                                                    "aadhar_number": subCategoryState?.AadhaarNumber,
                                                    "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                                    "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                                },
                                                "DeviceId" : device_id
                                            };
                                            wsSend_request(websocket, param);
                                        } else {
                                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                        }
                                    } else {
                                        CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                        CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                        CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                        CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                        CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                        CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                        CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                        CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                        if(subCategoryState?.RelatedID === "") {
                                            seterrorRelatedID(`Please select regional manager`);
                                        } else {
                                            seterrorRelatedID("");
                                        };
                                        CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                        CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                        CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                        CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                        if(BankVerifiedStatus !== "Verified") {
                                            setBankVerifiedStatus("Unverified");
                                        };
                                    };
                                    setErrorSecondaryContact("");
                                } else {
                                    setErrorSecondaryContact("Please enter valid secondary contact");
                                };
                            } else {
                                if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                    subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                    subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&  
                                    subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                                ) {
                                    if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "") {
                                        dispatch(ClearFormSetFutios({
                                            url: "franchise_edit",
                                            action: true
                                        }));
                                        let param = { 
                                            "transmit": "broadcast", 
                                            "url": "franchise_edit",
                                            "request" : { 
                                                "franchise_id": CurrentData_?.id,
                                                "erp_id": CurrentData_?.erp_id,
                                                "erp_address_id": CurrentData_?.erp_address_id,
                                                "franchise_type": Tab, 
                                                "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                                "customer_name": subCategoryState?.RegisteredBusinessName,
                                                "poc_name": subCategoryState?.POCName,
                                                "mobile": subCategoryState?.PrimaryContact,
                                                "secondary_contact": subCategoryState?.SecondaryContact,
                                                "email": subCategoryState?.Email, 
                                                "pin_code_id": searchPincodeList?.pin?.id,
                                                "address": subCategoryState?.Address,
                                                "address2": subCategoryState?.address2,
                                                "pan_no": subCategoryState?.PANCard,
                                                "pan_image" : subCategoryState?.PANImage, 
                                                "advance_amount": subCategoryState?.AdvanceAmount,
                                                "bank_account_number": subCategoryState?.AccountNumber,
                                                "ifsc_code": subCategoryState?.IFSCCode,
                                                "branch_name": subCategoryState?.BranchName,
                                                "account_holder_name": subCategoryState?.AccountHolderName,
                                                "region_id": subCategoryState?.RelatedID?.value,
                                                "region_manager_id": subCategoryState?.RMuser?.value,
                                                "asm_id": subCategoryState?.ASMuser?.value,
                                                "is_active": subCategoryState?.Status,
                                                "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                                "location_url": subCategoryState?.LocationUrl,
                                                "is_registered": Registered,
                                                "aadhar_number": subCategoryState?.AadhaarNumber,
                                                "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                                "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                            },
                                            "DeviceId" : device_id
                                        };
                                        wsSend_request(websocket, param);
                                    } else {
                                        CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    }
                                } else {
                                    CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                    CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                    CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                    CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                    CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                    CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                    CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                    if(subCategoryState?.RelatedID === "") {
                                        seterrorRelatedID(`Please select regional manager`);
                                    } else {
                                        seterrorRelatedID("");
                                    };
                                    CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                    CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                    CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                    CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                    if(BankVerifiedStatus !== "Verified") {
                                        setBankVerifiedStatus("Unverified");
                                    };
                                };
                            };
                        } else {
                            CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                        };
                    } else {
                        if(subCategoryState?.SecondaryContact?.length > 0) {
                            if(subCategoryState?.SecondaryContact?.length === 10) {
                                if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                    subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                    subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified" && 
                                    subCategoryState?.address2?.length <= 100
                                ) {
                                    if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "") {
                                        dispatch(ClearFormSetFutios({
                                            url: "franchise_edit",
                                            action: true
                                        }));
                                        let param = { 
                                            "transmit": "broadcast", 
                                            "url": "franchise_edit",
                                            "request" : { 
                                                "franchise_id": CurrentData_?.id,
                                                "erp_id": CurrentData_?.erp_id,
                                                "erp_address_id": CurrentData_?.erp_address_id,
                                                "franchise_type": Tab, 
                                                "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                                "customer_name": subCategoryState?.RegisteredBusinessName,
                                                "poc_name": subCategoryState?.POCName,
                                                "mobile": subCategoryState?.PrimaryContact,
                                                "secondary_contact": subCategoryState?.SecondaryContact,
                                                "email": subCategoryState?.Email, 
                                                "pin_code_id": searchPincodeList?.pin?.id,
                                                "address": subCategoryState?.Address,
                                                "address2": subCategoryState?.address2,
                                                "region_id": subCategoryState?.RelatedID?.value,
                                                "region_manager_id": subCategoryState?.RMuser?.value,
                                                "asm_id": subCategoryState?.ASMuser?.value,
                                                "pan_no": subCategoryState?.PANCard,
                                                "pan_image" : subCategoryState?.PANImage,
                                                "advance_amount": subCategoryState?.AdvanceAmount,
                                                "bank_account_number": subCategoryState?.AccountNumber,
                                                "ifsc_code": subCategoryState?.IFSCCode,
                                                "branch_name": subCategoryState?.BranchName,
                                                "account_holder_name": subCategoryState?.AccountHolderName,
                                                "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                                "is_active": subCategoryState?.Status,
                                                "location_url": subCategoryState?.LocationUrl,
                                                "is_registered": Registered,
                                                "aadhar_number": subCategoryState?.AadhaarNumber,
                                                "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                                "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                            },
                                            "DeviceId" : device_id
                                        };
                                        wsSend_request(websocket, param);
                                    } else {
                                        CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    }
                                } else {
                                    CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                    CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                    CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                    CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                    CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                    CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                    CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                    if(subCategoryState?.RelatedID === "") {
                                        seterrorRelatedID(`Please select regional manager`);
                                    } else {
                                        seterrorRelatedID("");
                                    };
                                    CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                    CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                    CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                    CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                    if(BankVerifiedStatus !== "Verified") {
                                        setBankVerifiedStatus("Unverified");
                                    };
                                };
                                setErrorSecondaryContact("");
                            } else {
                                setErrorSecondaryContact("Please enter valid secondary contact");
                            };
                        } else {
                            if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&  subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified" &&
                                subCategoryState?.address2?.length <= 100
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "") {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_edit",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_edit",
                                        "request" : { 
                                            "franchise_id": CurrentData_?.id,
                                            "erp_id": CurrentData_?.erp_id,
                                            "erp_address_id": CurrentData_?.erp_address_id,
                                            "franchise_type": Tab, 
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "pan_no": subCategoryState?.PANCard,
                                            "pan_image" : subCategoryState?.PANImage, 
                                            "advance_amount": subCategoryState?.AdvanceAmount,
                                            "bank_account_number": subCategoryState?.AccountNumber,
                                            "ifsc_code": subCategoryState?.IFSCCode,
                                            "branch_name": subCategoryState?.BranchName,
                                            "account_holder_name": subCategoryState?.AccountHolderName,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "is_active": subCategoryState?.Status,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_registered": Registered,
                                            "aadhar_number": subCategoryState?.AadhaarNumber,
                                            "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                            "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                }
                            } else {
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                if(BankVerifiedStatus !== "Verified") {
                                    setBankVerifiedStatus("Unverified");
                                };
                            };
                        };
                    };
                };
            } else {
                if(Tab === "sub_dealer") {
                    if(subCategoryState?.SecondaryContact?.length > 0) {
                        if(subCategoryState?.SecondaryContact?.length === 10) {
                            if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                subCategoryState?.address2?.length <= 100
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                                ) {
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_add",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_add",
                                        "request" : { 
                                            "franchise_type": Tab, 
                                            "parent_id": subCategoryState?.ParentId?.value,
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status,
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                                }
                            } else {
                                CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                            };
                            setErrorSecondaryContact("");
                        } else {
                            setErrorSecondaryContact("Please enter valid secondary contact");
                        };
                    } else {
                        if(subCategoryState?.ParentId !== "" && subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                            subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                            subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                            subCategoryState?.address2?.length <= 100
                        ) {
                            if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "" && ParentSubdelarStateSameValid === true 
                            ) {
                                dispatch(ClearFormSetFutios({
                                    url: "franchise_add",
                                    action: true
                                }));
                                let param = { 
                                    "transmit": "broadcast", 
                                    "url": "franchise_add",
                                    "request" : { 
                                        "franchise_type": Tab, 
                                        "parent_id": subCategoryState?.ParentId?.value,
                                        "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                        "customer_name": subCategoryState?.RegisteredBusinessName,
                                        "poc_name": subCategoryState?.POCName,
                                        "mobile": subCategoryState?.PrimaryContact,
                                        "secondary_contact": subCategoryState?.SecondaryContact,
                                        "email": subCategoryState?.Email, 
                                        "pin_code_id": searchPincodeList?.pin?.id,
                                        "address": subCategoryState?.Address,
                                        "address2": subCategoryState?.address2,
                                        "region_id": subCategoryState?.RelatedID?.value,
                                        "region_manager_id": subCategoryState?.RMuser?.value,
                                        "asm_id": subCategoryState?.ASMuser?.value,
                                        "location_url": subCategoryState?.LocationUrl,
                                        "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                        "is_active": subCategoryState?.Status,
                                    },
                                    "DeviceId" : device_id
                                };
                                wsSend_request(websocket, param);
                            } else {
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                CheckValid(ParentSubdelarStateSameValid, {type: 'CheckParentSubFrnchState', error: seterrorParentSubParentStateSame});
                            }
                        } else {
                            CheckValid(subCategoryState?.ParentId, {type: 'ParentTypeId', error: seterrorParentTypeId});
                            CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                            CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                            CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                            CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                            CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                            CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                            CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
    
                            if(subCategoryState?.RelatedID === "") {
                                seterrorRelatedID(`Please select regional manager`);
                            } else {
                                seterrorRelatedID("");
                            };
                            CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                        };
                    }
                } else {
                    if(subCategoryState?.PANCard.trim() !== "") {
                        if(panRegex.test(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim()) === true) {
                            if(subCategoryState?.SecondaryContact?.length > 0) {
                                if(subCategoryState?.SecondaryContact?.length === 10) {
                                    if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                        subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                        subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                        subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                                    ) {
                                        if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "") {
                                            dispatch(ClearFormSetFutios({
                                                url: "franchise_add",
                                                action: true
                                            }));
                                            let param = { 
                                                "transmit": "broadcast", 
                                                "url": "franchise_add",
                                                "request" : { 
                                                    "franchise_type": Tab, 
                                                    "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                                    "customer_name": subCategoryState?.RegisteredBusinessName,
                                                    "poc_name": subCategoryState?.POCName,
                                                    "mobile": subCategoryState?.PrimaryContact,
                                                    "secondary_contact": subCategoryState?.SecondaryContact,
                                                    "email": subCategoryState?.Email, 
                                                    "pin_code_id": searchPincodeList?.pin?.id,
                                                    "address": subCategoryState?.Address,
                                                    "address2": subCategoryState?.address2,
                                                    "pan_image" : subCategoryState?.PANImage, 
                                                    "pan_no": subCategoryState?.PANCard,
                                                    "pan_image" : subCategoryState?.PANImage,
                                                    "advance_amount": subCategoryState?.AdvanceAmount,
                                                    "bank_account_number": subCategoryState?.AccountNumber,
                                                    "ifsc_code": subCategoryState?.IFSCCode,
                                                    "branch_name": subCategoryState?.BranchName,
                                                    "account_holder_name": subCategoryState?.AccountHolderName,
                                                    "region_id": subCategoryState?.RelatedID?.value,
                                                    "region_manager_id": subCategoryState?.RMuser?.value,
                                                    "asm_id": subCategoryState?.ASMuser?.value,
                                                    "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                                    "is_active": subCategoryState?.Status, 
                                                    "location_url": subCategoryState?.LocationUrl,
                                                    "is_registered": Registered,
                                                    "aadhar_number": subCategoryState?.AadhaarNumber,
                                                    "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                                    "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                                },
                                                "DeviceId" : device_id
                                            };
                                            wsSend_request(websocket, param);
                                        } else {
                                            CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                        }
                                    } else {
                                        CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                        CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                        CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                        CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                        CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                        CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                        CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                        CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                        CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                        if(subCategoryState?.RelatedID === "") {
                                            seterrorRelatedID(`Please select regional manager`);
                                        } else {
                                            seterrorRelatedID("");
                                        };
                                        CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                        CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                        CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                        CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                        if(BankVerifiedStatus !== "Verified") {
                                            setBankVerifiedStatus("Unverified");
                                        };
                                    };
                                    setErrorSecondaryContact("");
                                } else {
                                    setErrorSecondaryContact("Please enter valid secondary contact");
                                };
                            } else {
                                if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                    subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                    subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                    subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                                ) {
                                    if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === ""){
                                        dispatch(ClearFormSetFutios({
                                            url: "franchise_add",
                                            action: true
                                        }));
                                        let param = { 
                                            "transmit": "broadcast", 
                                            "url": "franchise_add",
                                            "request" : { 
                                                "franchise_type": Tab, 
                                                "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                                "customer_name": subCategoryState?.RegisteredBusinessName,
                                                "poc_name": subCategoryState?.POCName,
                                                "mobile": subCategoryState?.PrimaryContact,
                                                "secondary_contact": subCategoryState?.SecondaryContact,
                                                "email": subCategoryState?.Email, 
                                                "pin_code_id": searchPincodeList?.pin?.id,
                                                "address": subCategoryState?.Address,
                                                "address2": subCategoryState?.address2,
                                                "pan_no": subCategoryState?.PANCard,
                                                "pan_image" : subCategoryState?.PANImage,
                                                "advance_amount": subCategoryState?.AdvanceAmount,
                                                "bank_account_number": subCategoryState?.AccountNumber,
                                                "ifsc_code": subCategoryState?.IFSCCode,
                                                "branch_name": subCategoryState?.BranchName,
                                                "account_holder_name": subCategoryState?.AccountHolderName,
                                                "region_id": subCategoryState?.RelatedID?.value,
                                                "region_manager_id": subCategoryState?.RMuser?.value,
                                                "asm_id": subCategoryState?.ASMuser?.value,
                                                "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                                "is_active": subCategoryState?.Status, 
                                                "location_url": subCategoryState?.LocationUrl,
                                                "is_registered": Registered,
                                                "aadhar_number": subCategoryState?.AadhaarNumber,
                                                "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                                "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                            },
                                            "DeviceId" : device_id
                                        };
                                        wsSend_request(websocket, param);
                                    } else {
                                        CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    }
                                } else {
                                    CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                    CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                    CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                    CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                    CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                    CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                    CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
            
                                    if(subCategoryState?.RelatedID === "") {
                                        seterrorRelatedID(`Please select regional manager`);
                                    } else {
                                        seterrorRelatedID("");
                                    };
                                    CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                    CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                    CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                    CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                    if(BankVerifiedStatus !== "Verified") {
                                        setBankVerifiedStatus("Unverified");
                                    };
                                };
                            };
                        } else {
                            CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                        };
                    } else {
                        if(subCategoryState?.SecondaryContact?.length > 0) {
                            if(subCategoryState?.SecondaryContact?.length === 10) {
                                if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                    subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                    subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                    subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                                ) {
                                    if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === "") {
                                        dispatch(ClearFormSetFutios({
                                            url: "franchise_add",
                                            action: true
                                        }));
                                        let param = { 
                                            "transmit": "broadcast", 
                                            "url": "franchise_add",
                                            "request" : { 
                                                "franchise_type": Tab, 
                                                "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                                "customer_name": subCategoryState?.RegisteredBusinessName,
                                                "poc_name": subCategoryState?.POCName,
                                                "mobile": subCategoryState?.PrimaryContact,
                                                "secondary_contact": subCategoryState?.SecondaryContact,
                                                "email": subCategoryState?.Email, 
                                                "pin_code_id": searchPincodeList?.pin?.id,
                                                "address": subCategoryState?.Address,
                                                "address2": subCategoryState?.address2,
                                                "pan_no": subCategoryState?.PANCard,
                                                "pan_image" : subCategoryState?.PANImage,
                                                "advance_amount": subCategoryState?.AdvanceAmount,
                                                "bank_account_number": subCategoryState?.AccountNumber,
                                                "ifsc_code": subCategoryState?.IFSCCode,
                                                "branch_name": subCategoryState?.BranchName,
                                                "account_holder_name": subCategoryState?.AccountHolderName,
                                                "region_id": subCategoryState?.RelatedID?.value,
                                                "region_manager_id": subCategoryState?.RMuser?.value,
                                                "asm_id": subCategoryState?.ASMuser?.value,
                                                "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                                "is_active": subCategoryState?.Status, 
                                                "location_url": subCategoryState?.LocationUrl,
                                                "is_registered": Registered,
                                                "aadhar_number": subCategoryState?.AadhaarNumber,
                                                "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                                "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                            },
                                            "DeviceId" : device_id
                                        };
                                        wsSend_request(websocket, param);
                                    } else {
                                        CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                    }
                                } else {
                                    CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                    CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                    CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                    CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                    CheckValid(subCategoryState?.PANCard === null ? subCategoryState?.PANCard : subCategoryState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard});
                                    CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                    CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                                    CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                    if(subCategoryState?.RelatedID === "") {
                                        seterrorRelatedID(`Please select regional manager`);
                                    } else {
                                        seterrorRelatedID("");
                                    };
                                    CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                    CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                    CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                    CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                    if(BankVerifiedStatus !== "Verified") {
                                        setBankVerifiedStatus("Unverified");
                                    };
                                };
                                setErrorSecondaryContact("");
                            } else {
                                setErrorSecondaryContact("Please enter valid secondary contact");
                            };
                        } else {
                            if(subCategoryState?.FranchiseTypeid !== "" && subCategoryState?.RegisteredBusinessName.trim() !== "" && 
                                subCategoryState?.POCName.trim() !== "" && subCategoryState?.PrimaryContact.trim() !== "" && subCategoryState?.Email.trim() !== "" && 
                                subCategoryState?.PINCode.trim() !== "" && subCategoryState?.RelatedID !== "" && subCategoryState?.RMuser !== "" && subCategoryState?.Address.trim() !== "" && subCategoryState?.Address?.length <= 100 &&
                                subCategoryState?.address2?.length <= 100 && subCategoryState?.AadhaarNumber !== "" && subCategoryState.AccountNumber !== "" && subCategoryState.IFSCCode !== "" && BankVerifiedStatus === "Verified"
                            ) {
                                if(EmailRegex.test(subCategoryState?.Email) === true && ErrorMessageFromReddux?.Error === ""){
                                    dispatch(ClearFormSetFutios({
                                        url: "franchise_add",
                                        action: true
                                    }));
                                    let param = { 
                                        "transmit": "broadcast", 
                                        "url": "franchise_add",
                                        "request" : { 
                                            "franchise_type": Tab, 
                                            "franchise_type_id": subCategoryState?.FranchiseTypeid,
                                            "customer_name": subCategoryState?.RegisteredBusinessName,
                                            "poc_name": subCategoryState?.POCName,
                                            "mobile": subCategoryState?.PrimaryContact,
                                            "secondary_contact": subCategoryState?.SecondaryContact,
                                            "email": subCategoryState?.Email, 
                                            "pin_code_id": searchPincodeList?.pin?.id,
                                            "address": subCategoryState?.Address,
                                            "address2": subCategoryState?.address2,
                                            "pan_no": subCategoryState?.PANCard,
                                            "pan_image" : subCategoryState?.PANImage,
                                            "advance_amount": subCategoryState?.AdvanceAmount,
                                            "bank_account_number": subCategoryState?.AccountNumber,
                                            "ifsc_code": subCategoryState?.IFSCCode,
                                            "branch_name": subCategoryState?.BranchName,
                                            "account_holder_name": subCategoryState?.AccountHolderName,
                                            "region_id": subCategoryState?.RelatedID?.value,
                                            "region_manager_id": subCategoryState?.RMuser?.value,
                                            "asm_id": subCategoryState?.ASMuser?.value,
                                            "is_flexible_pricing": subCategoryState?.is_flexible_pricing,
                                            "is_active": subCategoryState?.Status, 
                                            "location_url": subCategoryState?.LocationUrl,
                                            "is_registered": Registered,
                                            "aadhar_number": subCategoryState?.AadhaarNumber,
                                            "is_bank_verified": BankVerifiedStatus === "Verified" ? true : false,
                                            "is_aadhar_verified": AadharVerifiedStatus === "Verified" ? true : false,
                                        },
                                        "DeviceId" : device_id
                                    };
                                    wsSend_request(websocket, param);
                                } else {
                                    CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail });
                                }
                            } else {
                                CheckValid(subCategoryState?.FranchiseTypeid, {type: 'FranchiseTypeid', error: seterrorFranchiseType});
                                CheckValid(subCategoryState?.RegisteredBusinessName.trim(), {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName});
                                CheckValid(subCategoryState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                                CheckValid(subCategoryState?.PrimaryContact.trim(), {type: 'PrimaryContact', error: seterrorPrimaryContact});
                                CheckValid(subCategoryState?.Email.trim(), {type: 'email', error: seterrorEmail});
                                CheckValid(subCategoryState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                                CheckValid(subCategoryState?.address2?.trim(), {type: 'address2', error: seterrorAddress2})
                                CheckValid(subCategoryState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
        
                                if(subCategoryState?.RelatedID === "") {
                                    seterrorRelatedID(`Please select regional manager`);
                                } else {
                                    seterrorRelatedID("");
                                };
                                CheckValid((subCategoryState?.RMuser === "" || subCategoryState?.RMuser === null) ? "" : subCategoryState?.RMuser, {type: 'RMuser', error: seterrorRMuser});
                                CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                CheckValid(subCategoryState?.AccountNumber, { type: 'AccountNumber', error: setErrorAccountNumber });
                                CheckValid(subCategoryState.IFSCCode, { type: 'IFSCCode', error: setErrorIFSCcodeNumber });
                                CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber });
                                if(BankVerifiedStatus !== "Verified") {
                                    setBankVerifiedStatus("Unverified");
                                };
                            };
                        };
                    };
                };
            };  
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "franchise_add" || ClearFormSet?.url === "franchise_edit")) {
            Navigate(FRANCHISE_USER_REPORT_URL);
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    const handlePaste = (event) => {
        // Prevent the default paste behavior
        event.preventDefault();
        // Read the clipboard data
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        // Clean the pasted data and set it as the new input value
        const cleanedPaste = paste.replace(/[^0-9.]/g, '');
        // setValue(cleanedPaste);
        setSubCategoryState({...subCategoryState, AdvanceAmount: cleanedPaste.replace(/[^0-9.]/g, "")})
    };

    // Bank and Aadhaar details reset function
    const reset_form = (url) => {
        if(url === "bank_details") {
            setSubCategoryState({
                ...subCategoryState,
                AccountNumber: "", 
                IFSCCode: "", 
            });
            setBankVerifiedStatus("");
        } else {
            setSubCategoryState({
                ...subCategoryState,
                AadhaarNumber: "",
            });
            setAadharVerifiedStatus("");
        };
    };

    // verify succes and fail modal
    const verifyDetailsSuccess = async (url) => {
        const payload = {};
        if(url === "bank_account_details") {
            payload.id_number = subCategoryState.AccountNumber;
            payload.ifsc = subCategoryState.IFSCCode;
            payload.ifsc_details = true;

            setcommonVerifyLoader({
                ...commonVerifyLoader,
                bank_details_loader: true,
            })
        } else {
            payload.id_number = subCategoryState.AadhaarNumber;

            setcommonVerifyLoader({
                ...commonVerifyLoader,
                adhar_loader: true,
            });
        };
        const bankDetailsResponse = await BankDetailVerify(
            url === "bank_account_details" ? REACT_APP_BANK_ACCOUNT_VERIFY_URL : REACT_APP_ADHAAR_CARD_VERIFY_URL
        , payload, url);

        if(bankDetailsResponse?.status === 200) {
            if(url === "aadhaar_card_details") {
                setcommonVerifyLoader({
                    ...commonVerifyLoader,
                    adhar_loader: false,
                });
                setVerifyModalState({
                    ...VerifyModalState,
                    open: true,
                    title: "Enter the OTP to verify your Aadhaar card.",
                    modalType: url,
                    data: bankDetailsResponse?.data,
                });
                toast.info("OTP for Aadhaar verification has been successfully generated and sent to your registered mobile number.");
            } else {
                setcommonVerifyLoader({
                    ...commonVerifyLoader,
                    bank_details_loader: false,
                });
                setSubCategoryState({
                    ...subCategoryState,
                    AccountHolderName: bankDetailsResponse?.data?.full_name,
                    BranchName: bankDetailsResponse?.data?.ifsc_details?.bank_name,
                });

                setVerifyModalState({
                    ...VerifyModalState,
                    open: true,
                    title: "Your bank details verified successfully.",
                    modalType: url,
                    data: bankDetailsResponse?.data,
                });
                setBankVerifiedStatus("Verified");
                toast.info("Bank details verified successfully.");
            };
        } else {
            if(url === "bank_account_details") {
                setcommonVerifyLoader({
                    ...commonVerifyLoader,
                    bank_details_loader: false,
                });
                setBankVerifiedStatus("Unverified");
            } else {
                setcommonVerifyLoader({
                    ...commonVerifyLoader,
                    adhar_loader: false,
                });
                setAadharVerifiedStatus("Unverified");
            };
            if(bankDetailsResponse?.data?.message) {
                toast.error(bankDetailsResponse?.data?.message);
            } else {
                toast.error(bankDetailsResponse?.message);
            };
        };
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            {LocationRoute === "edit-franchise-user-report" ? (<ul className="MainHeader_Tab">
                {Tab === "dealer" ? (
                    <li className={Tab === "dealer" ? "active" : ""} >Dealer</li>
                ) : (
                    <li  className={Tab === "sub_dealer" ? "active" : ""}>Sub Dealer</li>
                )}
            </ul>) : (<ul className="MainHeader_Tab">
                <li className={Tab === "dealer" ? "active" : ""} onClick={() => setTab("dealer")}>Dealer</li>
                <li  className={Tab === "sub_dealer" ? "active" : ""} onClick={() => setTab("sub_dealer")}>Sub Dealer</li>
            </ul>)}
            <Link to={FRANCHISE_USER_REPORT_URL} onClick={() => ClearData()} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappersmAN">
            <Scrollbars 
                style={{ height: "calc(100vh - 147px)" }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                <div className="Bigform_custom_scroll" style={{ height: "calc(100vh - 147px)" }} >
                <div className="formloginset">
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">Basic Details</h6>
                        <div className="row">
                            {Tab === "sub_dealer" && (<div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Parent Dealer Type<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select 
                                            value={subCategoryState?.ParentId}
                                            onChange={(e) => ParentDealerTypeSelectFCT(e)} 
                                            defaultValue={defaultParentDealerType} 
                                            isClearable 
                                            options={OptionFranchiseArray}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                        {errorParentTypeId !== "" && (<span className="error_medotory">{errorParentTypeId}</span>)}
                                    </div>
                                </Form.Group>
                            </div>)}
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Franchise Type<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select 
                                            onChange={(e) => FranchiseTypeSelectFCT(e)} 
                                            defaultValue={defaultFranchiseTypeOption} 
                                            isClearable 
                                            options={optionsFranchiseTyp}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                        {errorFranchiseType !== "" && (<span className="error_medotory">{errorFranchiseType}</span>)}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Registered Business Name<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={subCategoryState?.RegisteredBusinessName} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, RegisteredBusinessName: e.target.value})}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'RegisteredBusinessName', error: seterrorRegisteredBusinessName})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter Registered Business Name"
                                    />
                                    {errorRegisteredBusinessName !== "" && (<span className="error_medotory">{errorRegisteredBusinessName}</span>)}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>POC Name<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={subCategoryState?.POCName} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, POCName: e.target.value})}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'POCName', error: seterrorPOCName})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter POC Name" 
                                    />
                                    {errorPOCName !== "" &&<span className="error_medotory">{errorPOCName}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Primary Contact<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control type="number" 
                                        value={subCategoryState?.PrimaryContact}
                                        className="inputBoxs"
                                        onChange={(e) => setSubCategoryState({...subCategoryState, PrimaryContact: e.target.value.replace(/^0/, "")})}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'PrimaryContact', error: seterrorPrimaryContact})}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter Primary Contact"
                                    />
                                    {errorPrimaryContact !== "" &&<span className="error_medotory">{errorPrimaryContact}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Secondary Contact</Form.Label>
                                    <Form.Control type="number" 
                                        value={subCategoryState?.SecondaryContact} 
                                        className="inputBoxs"
                                        onChange={(e) => setSubCategoryState({...subCategoryState, SecondaryContact: e.target.value.replace(/^0/, "")})}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter Primary Contact"
                                    />
                                    {errorSecondaryContact !== "" &&<span className="error_medotory">{errorSecondaryContact}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email Address<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={subCategoryState?.Email} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, Email: e.target.value})}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: seterrorEmail})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        placeholder="Enter Email Address" 
                                        disabled={UploadFileLoader}
                                    />
                                    {errorEmail !== "" &&<span className="error_medotory">{errorEmail}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Region Master<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                        <Select 
                                            value={subCategoryState?.RelatedID}
                                            onChange={(e) => SelectRelatedType(e, "regional manager")} 
                                            defaultValue={defaultRegionOption} 
                                            isClearable 
                                            options={optionsRegion}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                        />
                                    </div>
                                    {errorRelatedID !== "" &&<span className="error_medotory">{errorRelatedID}</span>}
                                </Form.Group>
                            </div>
                            {subCategoryState?.RelatedID !== "" && (<div className="col-md-4">
                                <Form.Group className="mb-3 " >
                                    <Form.Label>Regional Manager<span className="mendatory_star">*</span></Form.Label>
                                    <div className="selectMultiselect" id="selectMultiselectBrand">
                                        <Select 
                                            value={subCategoryState?.RMuser}
                                            onChange={(e) => RmValueSelect(e)} 
                                            isClearable 
                                            options={optionsUserList}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                            placeholder="Select RM User"
                                        />
                                        {errorRMuser !== "" &&<span className="error_medotory">{errorRMuser}</span>}
                                    </div>
                                </Form.Group>
                            </div>)}
                            {subCategoryState?.RMuser !== "" && (<div className="col-md-4">
                                <Form.Group className="mb-3 " >
                                    <Form.Label>Assistant Sales Manager</Form.Label>
                                    <div className="selectMultiselect" id="selectMultiselectBrand">
                                        <Select 
                                            value={subCategoryState?.ASMuser}
                                            onChange={(e) => ASMValueSelect(e)} 
                                            isClearable 
                                            options={optionsASMUserList}
                                            className="godown-select-container" 
                                            classNamePrefix="godown-select"
                                            styles={customStyles}
                                            placeholder="Select RM User"
                                        />
                                    </div>
                                </Form.Group>
                            </div>)}
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>
                                        PIN Code
                                        <span className="mendatory_star">*</span>
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 100 }}
                                            overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                            Search by either hitting the enter button or clicking on the search icon.
                                        </Tooltip>}
                                        >
                                            <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                        </OverlayTrigger>
                                    </Form.Label>
                                    <div className="serachBoxWIth">
                                        <Form.Control 
                                            type="text" 
                                            value={subCategoryState?.PINCode} 
                                            onChange={(e) => PinCOdeSearchFunction(e)}
                                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'PINCode', error: seterrorPINCode})}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e);
                                                onEnterPincodeSearch(e)
                                            }}
                                            disabled={commonLoader}
                                            maxLength={6}
                                            placeholder="Enter PIN Code" 
                                        />
                                        <Button className="searchbtn" onClick={() => SearchPincode()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                                <path d="M21 21l-6 -6"></path>
                                            </svg>
                                        </Button>
                                        {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                            <div className="pincode_error">
                                                {ErrorMessageFromReddux?.Error !== "" ? (
                                                    <i className="bi bi-x-circle-fill" onClick={() => setSubCategoryState({...subCategoryState, PINCode: ""})} style={{ color: "red" }}></i>
                                                ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                            </div>
                                        </>)}
                                        {commonLoader && (<Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>)}
                                    </div>
                                    {errorPINCode !== "" &&<span className="error_medotory">{errorPINCode}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>State<span className="mendatory_star">*</span></Form.Label>
                                    <div className="defaultvalueField">
                                        {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.state?.state_name : ""}
                                    </div>
                                    {errorParentSubParentStateSame !== "" &&<span className="error_medotory">{errorParentSubParentStateSame}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>City<span className="mendatory_star">*</span></Form.Label>
                                    <div className="defaultvalueField">
                                        {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.city?.district_name : ""}
                                    </div>
                                </Form.Group>
                            </div>
                            
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Location Url</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={subCategoryState?.LocationUrl} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, LocationUrl: e.target.value})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter Location Url" 
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <CommonAddressTextField 
                                    Label={"Address Line 1"}
                                    MainState={subCategoryState}
                                    setMainState={setSubCategoryState} 
                                    Address={"Address"}
                                    isMendatory={true}
                                    ErrorAddress={errorAddress} 
                                    setErrorAddress={seterrorAddress}
                                    MaxLength={100}
                                />
                            </div>
                            <div className="col-md-4">
                                <CommonAddressTextField 
                                    Label={"Address Line 2(Optional)"}
                                    MainState={subCategoryState}
                                    setMainState={setSubCategoryState} 
                                    Address={"address2"}
                                    isMendatory={false}
                                    MaxLength={100}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">
                            KYC Details
                            {Tab === "dealer" && (<div className="kvyregisterdnoregs">
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="custom-switch"
                                    label="Registered"
                                    value={Registered}
                                    checked={Registered}
                                    onChange={() => setRegistered(!Registered)}
                                />
                            </div>)}
                        </h6>
                        <div className="row">
                            {Tab === "dealer" && (<>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Permanent Account Number (PAN){Registered &&(<span className="mendatory_star">*</span>)}</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            value={subCategoryState?.PANCard} 
                                            onChange={(e) => setSubCategoryState({...subCategoryState, PANCard: e.target.value.toUpperCase()})}
                                            onKeyUp={(e) => CheckValid(e.target.value, {type: 'PANCard', error: seterrorPANCard})}
                                            onKeyDown={EmptySpaceFieldValid}
                                            placeholder="Enter PAN Card" 
                                            disabled={UploadFileLoader}
                                        />
                                        {Registered ? (<>
                                            {errorPANCard !== "" && (<span className="error_medotory">{errorPANCard}</span>)}
                                        </>): (<>
                                            {errorPANCard !== "PAN card cannot be empty" && (<span className="error_medotory">{errorPANCard}</span>)}
                                        </>)}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>PAN Image{Registered &&(<span className="mendatory_star">*</span>)}</Form.Label>
                                        {(ImageDisplay !== undefined && ImageDisplay !== "" && ImageDisplay?.name !== null) ? (<>{
                                            ImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                                <i className="bi bi-filetype-pdf"></i> {ImageDisplay?.name}
                                                <span className="closeIcon" onClick={() => RemoveUploadImage()}></span>
                                        </div>) : (
                                            <div className="imageuploads">
                                                <Image src={ImageDisplay?.url} onError={(e) => e.target.src = ProductDefaultImage} alt="charity avatar" />
                                                <span className="closeIcon" onClick={() => RemoveUploadImage()}></span>
                                            </div>)}
                                        </>) : (<Form.Control type="file" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" />)}
                                        {(Registered && errorBrandImage !== "") &&<span className="error_medotory">{errorBrandImage}</span>}
                                    </Form.Group>
                                </div>
                            </>)}  
                            {Registered && (<React.Fragment>
                                {Tab === "dealer" ? (<>
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>GST Number<span className="mendatory_star">*</span></Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Enter GST Number" 
                                                value={subCategoryState?.GSTNumber}
                                                onChange={(e) => GSTINvalid(e?.target?.value)}
                                                onKeyUp={(e) => GSTINvalidKeyUp(e?.target?.value)}
                                                onKeyDown={EmptySpaceFieldValid}
                                                disabled={UploadFileLoader}
                                            />
                                            {errorGSTNumber !== "" &&<span className="error_medotory">{errorGSTNumber}</span>}
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>GST Certificate Image<span className="mendatory_star">*</span></Form.Label>
                                            {(GSTINImageDisplay !== undefined && GSTINImageDisplay !== "") ? (<>{
                                                GSTINImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                                    <i className="bi bi-filetype-pdf"></i> {GSTINImageDisplay?.name}
                                                    <span className="closeIcon" onClick={() => RemoveGSTINImage()}></span>
                                            </div>) : (
                                                <div className="imageuploads">
                                                    <Image src={GSTINImageDisplay?.url} onError={(e) => e.target.src = ProductDefaultImage} alt="charity avatar" />
                                                    <span className="closeIcon" onClick={() => RemoveGSTINImage()}></span>
                                                </div>)}
                                            </>) : (<Form.Control type="file" onChange={(e) => UploadGSTINImage(e)} accept=".png, .jpg, .pdf" />)}
                                            {errorGSTImage !== "" &&<span className="error_medotory">{errorGSTImage}</span>}
                                        </Form.Group>
                                    </div>
                                </>) : (<>
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>GST Number<span className="mendatory_star">*</span></Form.Label>
                                            <div className="defaultvalueField">
                                                {ParentFranchiseDetails?.gst_no === null ? "-" : ParentFranchiseDetails?.gst_no}
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-4">
                                        <Form.Group className="mb-3" >
                                            <Form.Label>GST Certificate Image<span className="mendatory_star">*</span></Form.Label>
                                            {(GSTINImageDisplay !== undefined && GSTINImageDisplay !== "") ? (<>{
                                                GSTINImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                                    <i className="bi bi-filetype-pdf"></i> {GSTINImageDisplay?.name}
                                                    <span className="closeIcon" onClick={() => RemoveGSTINImage()}></span>
                                            </div>) : (
                                                <div className="imageuploads">
                                                    <Image src={GSTINImageDisplay?.url} onError={(e) => e.target.src = ProductDefaultImage} alt="charity avatar" />
                                                    <span className="closeIcon" onClick={() => RemoveGSTINImage()}></span>
                                                </div>)}
                                            </>): (<div className="defaultvalueField">No File</div>)}
                                        </Form.Group>
                                    </div>
                                </>)} 
                            </React.Fragment>)}
                        </div>
                    </div>
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">Wallet Details</h6>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Credit Limit<span className="mendatory_star">*</span></Form.Label>
                                    <div className="defaultvalueField">
                                        {FranchiseTypeCreditLimit()}
                                    </div>
                                </Form.Group>
                            </div>
                            {Tab === "dealer" ? (<>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Advance Amount</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            value={subCategoryState?.AdvanceAmount}
                                            className="inputBoxs"
                                            id="numberInput"
                                            onChange={(e) => setSubCategoryState({...subCategoryState, AdvanceAmount: e.target.value.replace(/[^0-9.]/g, "")})}
                                            onKeyDown={(e) => {
                                                EmptySpaceFieldValid(e)
                                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                            }}
                                            onPaste={handlePaste}
                                            disabled={UploadFileLoader}
                                            placeholder="Enter Advance Amount" 
                                        />
                                    </Form.Group>
                                </div>
                            </>) : (<>
                                <div className="col-md-4">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Advance Amount</Form.Label>
                                        <div className="defaultvalueField">
                                            {ParentFranchiseDetails?.advance_amount === null ? "-" : ParentFranchiseDetails?.advance_amount}
                                        </div>
                                    </Form.Group>
                                </div>
                            </>)}
                        </div>
                    </div>
                    {Tab === "dealer" && (<React.Fragment>
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">
                            <i className="bi bi-bank"></i> Account Details
                            {BankVerifiedStatus !== "" && (<>
                                {BankVerifiedStatus === "Verified" ? (<div className="verifySpan"><i className="bi bi-check-circle-fill"></i> Verified</div>) : (<div className="verifySpan verifySpanerror"><i className="bi bi-x-circle-fill"></i> Unverified</div>)}
                            </>)}
                        </h6>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Account Number<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        type="number" 
                                        value={subCategoryState?.AccountNumber} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, AccountNumber: e.target.value})}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'AccountNumber', error: setErrorAccountNumber })}
                                        disabled={UploadFileLoader || commonVerifyLoader?.bank_details_loader || BankVerifiedStatus === "Verified"}
                                        placeholder="Enter Account Number" 
                                    />
                                    {errorAccountNumber !== "" && <span className="error_medotory">{errorAccountNumber}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>IFSC Code<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        type="text"
                                        value={subCategoryState?.IFSCCode} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, IFSCCode: e.target.value})}
                                        onKeyUp={(e) => CheckValid(e.target.value, { type: 'IFSCCode', error: setErrorIFSCcodeNumber })}
                                        onKeyDown={EmptySpaceFieldValid}
                                        disabled={UploadFileLoader || commonVerifyLoader?.bank_details_loader || BankVerifiedStatus === "Verified"}
                                        placeholder="Enter IFSC Code" 
                                    />
                                    {errorIFSCcodeNumber !== "" && <span className="error_medotory">{errorIFSCcodeNumber}</span>}
                                </Form.Group>
                            </div>
                            {/* <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Branch Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={subCategoryState?.BranchName} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, BranchName: e.target.value})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter Branch Name" 
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Account Holder Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        value={subCategoryState?.AccountHolderName} 
                                        onChange={(e) => setSubCategoryState({...subCategoryState, AccountHolderName: e.target.value})}
                                        onKeyDown={EmptySpaceFieldValid}
                                        disabled={UploadFileLoader}
                                        placeholder="Enter Account Holder Name" 
                                    />
                                </Form.Group>
                            </div> */}
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>&nbsp;</Form.Label>
                                    <div style={{ float: "left", width: "auto" }}>
                                        <Button className="verify_btn" disabled={(!subCategoryState?.AccountNumber || !subCategoryState?.IFSCCode || BankVerifiedStatus === "Verified") || commonVerifyLoader?.bank_details_loader} onClick={() => verifyDetailsSuccess("bank_account_details")}>
                                            <i className="bi bi-check2-circle"></i> Verify
                                        </Button>
                                        <Button className="reset_btn" id="reset_btn" onClick={() => reset_form("bank_details")} disabled={!subCategoryState?.AccountNumber && !subCategoryState?.IFSCCode}>
                                            <i className="bi bi-x-circle"></i> Reset
                                        </Button>
                                        {commonVerifyLoader?.bank_details_loader && (<div className="loader_wrapper">
                                            <Spinner />
                                        </div>)}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        {BankVerifiedStatus === "Unverified" && (<Alert variant="danger" className="bank_details_alerts"><i className="bi bi-x-circle-fill"></i> Bank account details must be verified first.</Alert>)}
                    </div>
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">
                            <i className="bi bi-postcard"></i> Aadhaar Card Details
                            {AadharVerifiedStatus !== "" && (<>
                                {AadharVerifiedStatus === "Verified" ? (<div className="verifySpan"><i className="bi bi-check-circle-fill"></i> Verified</div>) : (<div className="verifySpan verifySpanerror"><i className="bi bi-x-circle-fill"></i> Unverified</div>)}
                            </>)}
                        </h6>
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Aadhaar Number<span className="mendatory_star">*</span></Form.Label>
                                    <Form.Control 
                                        type="number" 
                                        className="inputBoxs"
                                        value={subCategoryState?.AadhaarNumber}
                                        onChange={(e) => setSubCategoryState({...subCategoryState, AadhaarNumber: e.target.value.replace(/^0/, "")})}
                                        onKeyUp={(e) => CheckValid(subCategoryState?.AadhaarNumber, { type: 'AadhaarNumber', error: setErrorAadhaarNumber })}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e)
                                            exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                        }}
                                        disabled={UploadFileLoader || commonVerifyLoader?.adhar_loader || AadharVerifiedStatus === "Verified"}
                                        placeholder="Enter Number"
                                        maxLength={12} 
                                    />
                                    {errorAadhaarNumber !== "" && <span className="error_medotory">{errorAadhaarNumber}</span>}
                                </Form.Group>
                            </div>
                            <div className="col-md-4">
                                <Form.Group className="mb-3" >
                                    <Form.Label>&nbsp;</Form.Label>
                                    <div style={{ float: "left", width: "auto" }}>
                                        <Button 
                                            className="verify_btn" 
                                            disabled={(AadharVerifiedStatus === "Verified" || subCategoryState?.AadhaarNumber?.length !== 12) || commonVerifyLoader?.adhar_loader} 
                                            onClick={() => verifyDetailsSuccess("aadhaar_card_details")}
                                        >
                                            <i className="bi bi-check2-circle"></i> Verify
                                        </Button>
                                        <Button className="reset_btn" id="reset_btn" onClick={() => reset_form("aadhaar_details")} disabled={!subCategoryState?.AadhaarNumber}>
                                            <i className="bi bi-x-circle"></i> Reset
                                        </Button>
                                        {commonVerifyLoader?.adhar_loader && (<div className="loader_wrapper">
                                            <Spinner />
                                        </div>)}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        {/* {AadharVerifiedStatus === "Unverified" && (<Alert variant="danger" className="bank_details_alerts"><i className="bi bi-x-circle-fill"></i> Aadhaar card details must be verified first.</Alert>)} */}
                    </div>
                    </React.Fragment>)}

                    <div className="row">
                        <div className="col-md-4">
                            <CommonToggle 
                                isBadgeAllow={true}
                                badgeTitle={"yes_no"}
                                valueToggle={subCategoryState?.is_flexible_pricing}
                                setValueToggle={() => setSubCategoryState({...subCategoryState, is_flexible_pricing: !subCategoryState?.is_flexible_pricing})}
                                name={"Is Flexible Pricing"}
                            />
                        </div>
                        <div className="col-md-4">
                            <CommonToggle 
                                isBadgeAllow={true}
                                badgeTitle={"active_inactive"}
                                valueToggle={subCategoryState?.Status}
                                setValueToggle={() => setSubCategoryState({...subCategoryState, Status: !subCategoryState?.Status})}
                                name={"Status"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: "right" }}>
                            <Button 
                                className="formbtn" 
                                disabled={ClearFormSet?.action === true && (ClearFormSet?.url === "franchise_add" || ClearFormSet?.url === "franchise_edit")}
                                onClick={() => CreateFranchiseFct()} 
                                style={{ width: "auto" }} 
                                variant="primary" 
                            >
                                {LocationRoute === "edit-franchise-user-report" ? "Update" : "Add"}
                            </Button>
                        </div>
                    </div>
                </div>
                </div>
            </Scrollbars>
        </div>

        {VerifyModalState?.open && (<VerifyModal 
            VerifyModalState={VerifyModalState}
            setVerifyModalState={setVerifyModalState}
            setAadharVerifiedStatus={setAadharVerifiedStatus}
            ApplicationState={subCategoryState}
            setApplicationState={setSubCategoryState}
        />)}

        {(ClearFormSet?.action === true && (ClearFormSet?.url === "franchise_add" || ClearFormSet?.url === "franchise_edit")) && (<CommonLoader />)}
    </section>)
}

export default CreateFranchiseUserReport;