/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import { WebSocketContext } from "../../../App";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonExport from "../../Common/CommonExport";
import SendMailModal from "../../Common/SendMailModal";
import CommonTable from "../../Common/CommonTable";
import CommonDatePicker from "../../Common/CommonDatePicker";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";

const StoreDetailsReport = (props) => {
    const { websocket } = useContext(WebSocketContext);
    const { selfInfo, device_id, storedDetailsReportList, accessoriesReportList, wareHouselistall, productlistall, StockInwardList } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ orderType, setOrderType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    
    const [ selectWarehouse, setselectWarehouse ] = useState("");
    const [ selectProduct, setselectProduct ] = useState("");

    const [ selectWarehouseBike, setselectWarehouseBike ] = useState("");
    const [ selectProductBike, setselectProductBike ] = useState("");

    const [ FilterOpen, setFilterOpen ] = useState(false);
    const [ Tab, setStoreTab ] = useState("bike");
    const [ FromEndDate, setFromEndDate ] = useState([null, null]);
    const [ FromDate, ToDate ] = FromEndDate;
    const reportListData = Tab === "bike" ? storedDetailsReportList : accessoriesReportList;
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} (${item?.warehouse_code}) - (${item?.display_name})` }));
    const optionsProducts = productlistall?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_name }));
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ sendEmailModal, setsendEmailModal ] = useState({
        open: false,
        title: ""
    });

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "product_list",
                "request": {
                    "status": true,
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "warehouse_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get product list
    useEffect(() => {
        if (websocket) {
            callPayloadReport(Tab, currentPage);
        }
    }, [websocket, userSearch, userType, orderType, currentPage, userLimit, selectWarehouse, selectProduct, selectWarehouseBike, selectProductBike, ToDate]);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    const setTab = (item) => {
        setStoreTab(item);
        callPayloadReport(item, 1);
        setCurrentPage(1);
        setUserLimit(10);
    };
    
    const callPayloadReport = (Tab, currentPage) => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) {
            let param;
            if (Tab === "stock_inward") {
                param = {
                    "transmit": "broadcast",
                    "url": "stock_inward_list",
                    "request": {
                        "limit": userLimit,
                        "page_no": currentPage,
                        "warehouse_id": selectWarehouse?.value,
                        "product_id": selectProduct?.value,
                        "order_by": "-updated_at"
                    },
                    "DeviceId": device_id
                };
            } else {
                param = {
                    "transmit": "broadcast",
                    "url": "store_frame_list",
                    "request": {
                        "status": userType,
                        "limit": userLimit,
                        "page_no": currentPage,
                        "search": userSearch.trim(),
                        "from_date": null,
                        "to_date": null,
                        "order_type": orderType,
                        "warehouse_id": selectWarehouseBike?.value,
                        "product_id": selectProductBike?.value,
                    },
                    "DeviceId": device_id
                };
                if (Tab !== "bike") {
                    param.url = "store_accessories_list";
                }
                if (FromDate !== "" && ToDate !== "") {
                    param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                    param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
                }
            };
            wsSend_request(websocket, param);
        };
    };

    // send email function
    const SendEmailFunction = () => {
        setsendEmailModal({
            ...sendEmailModal,
            open: !sendEmailModal?.open,
            title: "Send Mail"
        });
    };

    // E-BIKE TABLE COLUMNS ARRAY
    const BikeTableColumns = [
        { key: 'frame_number', label: 'Frame Number', type: "label", sort: "", isCollapsable: false },
        { key: 'warehouse_id', label: 'Warehouse Name', type: "label_get_from_list", filterFrom: { list: "warehouse", label: "display_name" }, sort: "", isCollapsable: false },
        { key: 'created_at', label: 'Added On', type: "date", sort: "", isCollapsable: false },
        { key: 'order_at', label: 'Sold On', type: "date", sort: "", isCollapsable: false },
        { key: 'order_code', label: 'Order No', type: "label", sort: "", isCollapsable: false },
        { key: 'bill_no', label: 'Billing No', type: "label", sort: "", isCollapsable: false },
        { key: 'status', label: 'Status', type: "status_sold_unsold", sort: "", isCollapsable: false },
        { key: 'order_type', label: 'User Type', type: "label", sort: "", isCollapsable: true },
        { key: 'battery_number', label: 'Battery Number', type: "label", sort: "", isCollapsable: true },
        { key: 'motor_number', label: 'Motor Number', type: "label", sort: "", isCollapsable: true },
        { key: 'charger_number', label: 'Charger Number', type: "label", sort: "", isCollapsable: true },
    ];

    // STOCK INWARD TABLE COLUMNS ARRAY
    const StockInwardTableColumns = [
        { key: 'product_id', label: 'FsCode', type: "label_get_from_fscode_list", filterFrom: { list: "product", label: "product_name" }, sort: "", isCollapsable: false },
        { key: 'product_id', label: 'Purchase Price', type: "label_get_from_list", filterFrom: { list: "product", label: "franchise_rate" }, sort: "", isCollapsable: false },
        { key: 'batch_no', label: 'Batch No', type: "label", sort: "", isCollapsable: false },
        { key: 'mfg_date', label: 'Mfg Date', type: "date", sort: "", isCollapsable: false },
        { key: 'exp_date', label: 'Expiry Date', type: "date", sort: "", isCollapsable: false },
        { key: 'qty', label: 'Quantity', type: "label", sort: "", isCollapsable: false },
    ];

    return (<section className="Main_section StockDetailsReportPage">
        <div className="MainHeader">
            <ul className="MainHeader_Tab" style={{ width: "100%" }}>
                <li className={Tab === "bike" ? "active" : ""} onClick={() => setTab("bike")}>Bike</li>
                <li className={Tab === "stock_inward" ? "active" : ""} onClick={() => setTab("stock_inward")}>Stock Inward</li>
                {((selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.StockDetailsReportSendMailActive) && Tab === "bike") && (<button type="button" className="btn sendemailstocks" onClick={() => SendEmailFunction()}>
                    <i className="bi bi-envelope-at"></i> Send Mail
                </button>)}
            </ul>
        </div>
        {Tab === "stock_inward" ? (<div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            <CommonReactSelectMenu 
                                label={"Warehouse"}
                                options={optionsWarehouse} 
                                selectValue={selectWarehouse} 
                                setSelectValue={setselectWarehouse} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" id="mb-3">
                            <CommonReactSelectMenu 
                                label={"Product"}
                                options={optionsProducts}
                                selectValue={selectProduct} 
                                setSelectValue={setselectProduct} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <button className="btn exportbtn" style={{ width: "auto", float: "left" }} onClick={() => CommonExport("export_stock_inward", { "product_id": selectProduct?.value, "warehouse_id": selectWarehouse?.value, "limit": userLimit })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>) : (<div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <CommonDatePicker 
                            dateRange={FromEndDate} 
                            setDateRange={setFromEndDate}
                            placeholderText={"Select From Date & To Date"}
                            dependantState={{
                                allow: true,
                                statType: "current_page",
                                stateFunction: setCurrentPage
                            }} 
                        />
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            <CommonReactSelectMenu 
                                label={"Warehouse"}
                                options={optionsWarehouse} 
                                selectValue={selectWarehouseBike} 
                                setSelectValue={setselectWarehouseBike} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" id="mb-3">
                            <CommonReactSelectMenu 
                                label={"Product"}
                                options={optionsProducts}
                                selectValue={selectProductBike} 
                                setSelectValue={setselectProductBike} 
                                dependantState={{
                                    allow: true,
                                    statType: "current_page",
                                    stateFunction: setCurrentPage
                                }} 
                                isMendatory={false}
                                isAllowClearable={true}
                                customWidth={"100%"}
                                customeHeight={"38px"}
                                isMulti={false}
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        <Form.Group className="mb-3" >
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 250, hide: 100 }}
                                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                    {FilterOpen ? "Filter Hide" : "Filter Show"}
                                </Tooltip>}
                            >
                                <button
                                    className="filtercollapsebtn"
                                    onClick={() => setFilterOpen(!FilterOpen)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={FilterOpen}
                                >
                                    {FilterOpen ? (<i className="bi bi-funnel-fill"></i>) : (<i className="bi bi-funnel"></i>)} Filter
                                </button>
                            </OverlayTrigger>
                            <button className="btn exportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto", float: "right" }} onClick={() => CommonExport("export_stock_frame", { "FromDate": FromDate, "ToDate": ToDate, "search": userSearch.trim(), "product_id": selectProductBike?.value, "warehouse_id": selectWarehouseBike?.value, "status": userType, "order_type": orderType, "limit": userLimit })}>
                                <i className="bi bi-filetype-xlsx"></i>
                                Export
                            </button>
                        </Form.Group>
                    </div>
                </div>
            </div>
            <Collapse in={FilterOpen}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3" id="mb-3">
                                <select className="form-control" value={userType} onChange={(e) => { setUserType(e?.target?.value); setCurrentPage(1) }}>
                                    <option value="">Select Status</option>
                                    <option value="SOLD">Sold</option>
                                    <option value="UNSOLD">Un-Sold</option>
                                </select>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3" id="mb-3">
                                <select className="form-control" value={orderType} onChange={(e) => { setOrderType(e?.target?.value); setCurrentPage(1) }}>
                                    <option value="">All User Type</option>
                                    <option value="super_admin">Admin</option>
                                    <option value="Franchise">Franchise</option>
                                </select>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>)}

        <div className={Tab === "stock_inward" ? "filter_stock_inward active" : "filter_stock_inward"}>
            {Tab === "stock_inward" ? (<DataTableFilter
                filterType={Tab === "bike" ? "Frame Number" : "Accessories"}
                searchType={"Frame Number"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userLimit={userLimit}
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                buttonvisible={false}
                searchdisable={false}
                ActiveInactiveFilter={false}
            />) : (<DataTableFilter
                filterType={Tab === "bike" ? "Frame Number" : "Accessories"}
                searchType={"Frame Number, Order No"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userLimit={userLimit}
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                buttonvisible={false}
                searchdisable={true}
                ActiveInactiveFilter={false}
            />)}
        </div>
        <div className="tableView">
            {Tab === "stock_inward" ? (<>
                <CommonTable 
                    style={{
                        height: FilterOpen === true ?
                            StockInwardList?.pagination?.total_records > 10 ? "calc(100vh - 253px)" : "calc(100vh - 265px)" :
                            StockInwardList?.pagination?.total_records > 10 ? "calc(100vh - 239px)" : "calc(100vh - 205px)"
                    }}
                    srNumber={{
                        clickable: false,
                        events: ""
                    }}
                    isCollapsable={false}
                    tabName={""} // TAB URL
                    PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
                    PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
                    TableColumns={StockInwardTableColumns} // TABLE COLLUMNS & ROWS
                    OrderList={StockInwardList} // PRODUCT LIST
                    Pagination={StockInwardList?.pagination} // CURRENT TAB PAGINATION
                    EditAccess={false} // EDITABLE ACCESS STATE
                    isActionColumnAvailable={{
                        isShow: false,
                        label: "",
                        naviagte: null
                    }}
                    isActionTwoColumnAvailable={{
                        isShow: false,
                        url: "",
                    }}
                    isOdiFilterRowColorsAllow={false}
                />
            </>) : (<CommonTable 
                style={{
                    height: FilterOpen === true ?
                        reportListData?.pagination?.total_records > 10 ? "calc(100vh - 335px)" : "calc(100vh - 304px)" :
                        reportListData?.pagination?.total_records > 10 ? "calc(100vh - 293px)" : "calc(100vh - 304px)"
                }}
                srNumber={{
                    clickable: false,
                    events: ""
                }}
                isCollapsable={true}
                tabName={""} // TAB URL
                PaginationCurrentPage={currentPage} // TAB CURRENT PAGE
                PaginationFunction={currentFunction} // PAGINATION CHNAGE FUNCTION
                TableColumns={BikeTableColumns} // TABLE COLLUMNS & ROWS
                OrderList={reportListData} // PRODUCT LIST
                Pagination={reportListData?.pagination} // CURRENT TAB PAGINATION
                EditAccess={false} // EDITABLE ACCESS STATE
                isActionColumnAvailable={{
                    isShow: false,
                    label: "",
                    naviagte: null
                }}
                isActionTwoColumnAvailable={{
                    isShow: false,
                    url: "",
                }}
                isOdiFilterRowColorsAllow={false}
            />)}
        </div>
        
        {/* send mail for Stock Details Report modal */}
        {sendEmailModal?.open && (<SendMailModal sendEmailModal={sendEmailModal} setsendEmailModal={setsendEmailModal} />)}
    </section>)
}

export default StoreDetailsReport;