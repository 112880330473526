/* eslint-disable */
import React from "react";
import SearchCommon from "../../Common/SearchCommon";
import CommonDatePicker from "../../Common/CommonDatePicker";
import { Tooltip, OverlayTrigger, Image, Button } from "react-bootstrap";
import generatePDF from "../../Common/generatePDF";
import PDF_Icon from "../../../assets/img/PDF_Icons.svg";
import XLS_Icon from "../../../assets/img/xls_Icons.svg";
import CommonReactSelectMenu from "../../Common/CommonReactSelectMenu";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getActiveSchemeListReportDashboard, getActiveAssociatedReportDashboard } from "../../../redux/actions/adminActions";

const CommonHeader = (props) => {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { heading, isSearchAllow, userSearch, setUserSearch, searchType, isSkuTypeAllow, SkuType, setSkuType, isOrderStatusAllow, OrderStatus, setOrderStatus, setCurrentPage, isDatePickerAllow, dateRange, setDateRange, isAllowDownloadPdf, isAllowDownloadExel, isDealerAllow, Dealer, setDealer, isStateAllow, stateSelect, setStateSelect, isRegionAllow, regionSelect, setRegionSelect, isProductAllow, Productselect, setProductselect, isBackButtonAllow, isFranchiseMultiAllow, selectMultiFranchise, setselectMultiFranchise } = props;
    const { AllDealerFranchiseTypeListAll, statelistall, Regionlistall, productlistall, selfInfo } = useSelector((state) => state.adminReducers);
    const DealerOptions = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.customer_name }));
    const DealerOptionsMulti = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.customer_name + ` (${item?.franchise_code})` + " - " + (item.erp_id !== null ? item.erp_id : "") }));
    const StateOptions = statelistall?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.state_name }));
    const RegionOptions = Regionlistall?.filter((elm) => elm?.is_active)?.map((item) => ({ key: item.id, value: item.id, label: item.region_name }));
    const ProductsOptions = productlistall?.map((item) => ({ key: item.id, value: item.id, label: item.product_name }));

    // DOWNLOAD PDF FUNCTION
    const DonwloadPDFfile = (id) => {
        if(id === "TransactionHistoryAgeing") {
            generatePDF(id, "Transaction History Ageing");
        };
    };

    // DOWNLOAD EXCEL FUNCTION
    const DownloadExcelfile = (id) => {
        exportToCSV(id);
    };

    // EXPORT TO CSV
    function exportToCSV(filterType) {
        let table = document.getElementById(filterType);
        let rows = table.querySelectorAll('tr');
        let csvContent = "";

        rows.forEach(row => {
            let cols = row.querySelectorAll('th, td');
            let rowData = [];

            cols.forEach(col => {
                let cellText = col.innerText.replace(/,/g, ''); // Escape double quotes
                rowData.push(`${cellText}`); // Wrap each cell in quotes
            });

            csvContent += rowData.join(",") + "\n";
        });

        let blob = new Blob([csvContent], { type: 'text/csv' });
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filterType + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // BACK BUTTON FUNCTION
    const BackButtonFunction = (navigate_url) => {
        Navigate(navigate_url);
        dispatch(getActiveSchemeListReportDashboard({}));
        dispatch(getActiveAssociatedReportDashboard({}));
    };

    const RegionOptionUpdate = RegionOptions?.filter((elm) => {
        if(selfInfo?.user?.user_type === "regional_sales_team_rm") {
            if(elm?.value === selfInfo?.user?.related_id) {
                return elm;
            };
        }
    });

    return(<div className="DashboardMainHeader">
        <h4>{heading}</h4>
        <div className="MainHeader_filters">
            {/* SEARCH FILTER */}
            {isSearchAllow && (<SearchCommon 
                userSearch={userSearch} 
                setUserSearch={setUserSearch}
                searchType={searchType}
                setCurrentPage={setCurrentPage}
            />)}

            {/* DATEPICKER FILTERS */}
            {isDatePickerAllow && (<CommonDatePicker 
                dateRange={dateRange} 
                setDateRange={setDateRange}
                placeholderText={"Select start date & end date"}
                dependantState={{
                    allow: false,
                }} 
            />)}

            {/* SELECT SKU TYPE */}
            {isSkuTypeAllow && (<select value={SkuType} onChange={(e) => setSkuType(e.target.value)}>
                <option value="">SKU Type</option>
                <option value="E-Bikes">E-Bikes</option>
                <option value="Accessories">Accessories</option>
            </select>)}

            {/* SELECT ORDER STATUS */}
            {isOrderStatusAllow && (<select value={OrderStatus} onChange={(e) => setOrderStatus(e.target.value)}>
                <option value="ST_APPROVED,DISPATCHED,DELIVERED">Order Status</option>
                <option value="ST_APPROVED">Store Approved</option>
                <option value="DISPATCHED">Dispatched</option>
                <option value="DELIVERED">Delivered</option>
            </select>)}

            {/* SELECT PRODUCT  */}
            {isProductAllow && (<CommonReactSelectMenu 
                label={"product"}
                options={ProductsOptions} 
                selectValue={Productselect} 
                setSelectValue={setProductselect} 
                dependantState={{
                    allow: false,
                }} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"170px"}
                customeHeight={"32px"}
                isMulti={false}
            />)}

            {/* SELECT FRANCHISE DEALER */}
            {isDealerAllow && (<CommonReactSelectMenu 
                label={"dealer"}
                options={DealerOptions} 
                selectValue={Dealer} 
                setSelectValue={setDealer} 
                dependantState={{
                    allow: false,
                }} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"170px"}
                customeHeight={"32px"}
                isMulti={false}
            />)}

            {/* SELECT FRANCHISE DEALER */}
            {isFranchiseMultiAllow && (<CommonReactSelectMenu 
                label={"franchise"}
                options={DealerOptionsMulti} 
                selectValue={selectMultiFranchise} 
                setSelectValue={setselectMultiFranchise} 
                dependantState={{
                    allow: false,
                }} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"300px"}
                customeHeight={"32px"}
                isMulti={true}
            />)}

            {/* SELECT STATE FILTER */}
            {isStateAllow && (<CommonReactSelectMenu 
                label={"state"}
                options={StateOptions}
                selectValue={stateSelect} 
                setSelectValue={setStateSelect} 
                dependantState={{
                    allow: false,
                }} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"170px"}
                customeHeight={"32px"}
                isMulti={false}
            />)}

            {/* SELECT REGION FILTER */}
            {isRegionAllow && (<CommonReactSelectMenu 
                label={"region"}
                options={RegionOptions}
                selectValue={selfInfo?.user?.user_type === "regional_sales_team_rm" ? RegionOptionUpdate[0] : regionSelect}
                setSelectValue={setRegionSelect} 
                dependantState={{
                    allow: false,
                }} 
                isMendatory={false}
                isAllowClearable={true}
                customWidth={"140px"}
                customeHeight={"32px"}
                disabledMenu={selfInfo?.user?.user_type === "regional_sales_team_rm"}
                isMulti={false}
            />)}

            {/* DOWNLOAD PDF */}
            {isAllowDownloadPdf?.open && (<OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Download PDF
            </Tooltip>}>
                <button className="btn downloadfilesBtn exportExcel" onClick={() => DonwloadPDFfile(isAllowDownloadPdf?.id)} >
                    <Image src={PDF_Icon} alt="excel" />
                </button>
            </OverlayTrigger>)}

            {/* DONWLOAD EXCEL */}
            {isAllowDownloadExel?.open && (<OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 100 }}
                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                Download Excel
            </Tooltip>}>
                <button className="btn downloadfilesBtn downloadPdf" onClick={() => DownloadExcelfile(isAllowDownloadExel?.id)} >
                    <Image src={XLS_Icon} alt="excel" />
                </button>
            </OverlayTrigger>)}

            {/* BACK BUTTON ALLOW */}
            {isBackButtonAllow?.open && (<Button className="btn backbtnnew" onClick={() => BackButtonFunction(isBackButtonAllow?.navigate_url)}>
                <i className="bi bi-arrow-left-short"></i> Back
            </Button>)}
        </div>
    </div>)
}

export default CommonHeader;