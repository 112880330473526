import React from "react";
import { Table, Modal, Button, Badge } from "react-bootstrap";

const CommonSplitScreenList = (props) => {
    const { splitScreenModal, setsplitScreenModal } = props;

    const closeModal = () => {
        setsplitScreenModal({
            ...splitScreenModal,
            open: false,
            error: false,
            title: "",
            list: [],
        });
    };

    return(<Modal className='commonModal salesFranchiseErrorModal'
        show={splitScreenModal?.open}
        onHide={closeModal}
    >
        <Modal.Header closeButton>
            <Modal.Title style={{ width: "100%" }}>{splitScreenModal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="tableView">
                <Table>
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Email</th>
                            <th>Incentive Code</th>
                            <th>Scheme Code</th>
                            <th>Target</th>
                            <th>Error</th>
                        </tr>
                    </thead>
                    <tbody>
                        {splitScreenModal?.list && splitScreenModal?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>{index + 1}</td>
                                <td>{elm?.email}</td>
                                <td>{elm?.incentive_code === undefined ? "-" : elm?.incentive_code}</td>
                                <td>{elm?.scheme_code}</td>
                                <td>{elm?.monthly_target}</td>
                                <td><Badge bg="danger">{elm?.msg}</Badge></td>
                            </tr>)
                        })}
                    </tbody>
                </Table>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
    )
}

export default CommonSplitScreenList;